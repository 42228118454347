
/*     
function isEmpty(str)                  字串是否有值   
function isChinese(str)                js判断判断是否中文   
function isEmail(strEmail)              js判断是否电子邮件  
function isMobile(str)                  js判断是否是手机号   
function isPhone(str)                  js判断是否是电话号码必须包含区号,可以含有分机号   
function isQQ(str)                      js判断是否合法的QQ号码     
function isDate(str)                    js判断是否日期类型(例:2005-12-12)   
function isIdCardNo(idNumber)          js判断是否是合法的身份证号   
*/
 
export function isChinese(str) {
	var str = str.replace(/(^\s*)|(\s*$)/g, '');
	if (! (/^[\u4E00-\uFA29]*$/.test(str) && (!/^[\uE7C7-\uE7F3]*$/.test(str)))) {
		return false;
	}
	return true;
}
 
export function isEmail(str) {
	if (/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str)) {
		return true
	}
	return false;
}

export function isMobile(str) {
	if (/^1[35]\d{9}/.test(str)) {
		return true;
	}
	return false;
}
 
export function isPhone(str) {
	if (/^(0[1-9]\d{1,2}-)\d{7,8}(-\d{1,8})?/.test(str)) {
		return true;
	}
	return false;
}
 
export function isQQ(str) {
	if (/^\d{5,9}$/.test(str)) {
		return true;
	}
	return false;
}
 
//element手机校验
export const checkPhone = (rule, value, callback) => { // 手机号验证
  if (!value) {
    return callback(new Error('不能为空'))
  } else {
    if (!Number.isInteger(+value)) {
      callback(new Error('手机号码必须为11位真实有效的号码'))
    } else {
      const phoneReg = /^1[0-9]{10}$/
      if (phoneReg.test(value)) {
        callback()
      } else {
        callback(new Error('电话号码格式不正确'))
      }
    }
  }
}

//element微信校验
export const checkWeixin = (rule, value, callback) => { // 微信号验证
  if (!value) {
    return callback(new Error('不能为空'))
  } else {
    const weixinReg = /^[0-9a-zA-Z_-]{5,20}$/
    if (weixinReg.test(value)) {
      callback()
    } else {
      callback(new Error('请填写5-20位正确的微信号'))
    }
  }
}
//element检验身份证
export const isIdCardNo2 = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入身份证'));
  } else if (!checkID(value)) {
    return callback(new Error('身份证输入有误'));
  } else {
    return callback()
  }
}

export function isDate(str) {
	var reg = /^((((1[6-9]|[2-9]\d)\d{2})-(0?[13578]|1[02])-(0?[1-9]|[12]\d|3[01]))|(((1[6-9]|[2-9]\d)\d{2})-(0?[13456789]|1[012])-(0?[1-9]|[12]\d|30))|(((1[6-9]|[2-9]\d)\d{2})-0?2-(0?[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))-0?2-29-))$/;
	if (reg.test(str)) {
		return true;
	}
	return false;
}
 
export function isIdCardNo(idNumber) {
	var factorArr = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1);
	var varArray = new Array();
	var lngProduct = 0;
	var intCheckDigit;
 
	if ((idNumber.length != 15) && (idNumber.length != 18)) {
		return false;
	}
	for (i = 0; i < idNumber.length; i++) {
		varArray[i] = idNumber.charAt(i);
		if ((varArray[i] < '0' || varArray[i] > '9') && (i != 17)) {
			return false;
		} else if (i < 17) {
			varArray[i] = varArray[i] * factorArr[i];
		}
	}
	if (idNumber.length == 18) {
		var date8 = idNumber.substring(6, 14);
		if (checkDate(date8) == false) {
			return false;
		}
		for (i = 0; i < 17; i++) {
			lngProduct = lngProduct + varArray[i];
		}
		intCheckDigit = 12 - lngProduct % 11;
		switch (intCheckDigit) {
		case 10:
			intCheckDigit = 'X';
			break;
		case 11:
			intCheckDigit = 0;
			break;
		case 12:
			intCheckDigit = 1;
			break;
		}
		if (varArray[17].toUpperCase() != intCheckDigit) {
			return false;
		}
	} else {
		var date6 = idNumber.substring(6, 12);
		if (checkDate(date6) == false) {
			return false;
		}
	}
	return true;
}

//出生日期码校验
function checkDate(val) {
  var pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
  if (pattern.test(val)) {
    var year = val.substring(0, 4);
    var month = val.substring(4, 6);
    var date = val.substring(6, 8);
    var date2 = new Date(year + "-" + month + "-" + date);
    if (date2 && date2.getMonth() == (parseInt(month) - 1)) {
      return true;
    }
  }
  return false;
}
//校验码校验
function checkCode(val) {
  var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
  var code = val.substring(17);
  if (p.test(val)) {
    var sum = 0;
    for (var i = 0; i < 17; i++) {
      sum += val[i] * factor[i];
    }
    if (parity[sum % 11] == code.toUpperCase()) {
      return true;
    }
  }
  return false;
}
//身份证规则校验
function checkID(val) {
  if (checkCode(val)) {
    var date = val.substring(6, 14);
    if (checkDate(date)) {
      return true;
    }
  }
  return false;
}
