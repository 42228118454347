<template>
  <div v-if="memberList.length>0" >
    <el-radio-group v-model="tabPosition" @change="handleClick" style="margin-top : 10px;">
    <el-radio-button label="testReport">测试报告</el-radio-button>
    <el-radio-button label="testDetails">测试明细</el-radio-button>
    <el-radio-button v-for="(item,index) in memberList" :key="index" :label="item.id">{{item.name}}</el-radio-button>
  </el-radio-group>
    <div>
      <test-report v-if="tabPosition=='testReport'"></test-report>
      <test-details v-if="tabPosition=='testDetails'"></test-details>
      <frim-result v-if="tabPosition=='1'||tabPosition=='2'|| tabPosition=='3'" :memberId = tabPosition></frim-result>
    </div>
  </div>
</template>

<script>
import testDetails from "./componet/testDetails"
import frimResult from "./componet/frimResult"
import testReport from "./componet/testReport"
import store from '@/store/index.js';
export default {
  beforeRouteEnter: (to,from,next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:to.query.breadcrumbName, path: to.fullPath} );
    next()
  },
  components: {testDetails,frimResult,testReport},
  data() {
    return {
      tabPosition:"testReport",
      memberList:[],
    };
  },
  created() {
    this.getMemberList();
  },
  methods: {
    handleClick(value) {
      this.tabPosition = value;
    }, 
    //获取厂商
    getMemberList(){
      this.$apis.unionAttestServer.enableMemberList().then(res=>{
         if (res['code'] == 0 && res.data) {
          this.memberList = res.data;
        }
      })
    },
  },
  beforeRouteLeave(to, from, next){
    //返回上一页：  缓存上一页  不缓存当前页(释放缓存，方便数据重新加载) 
    if(to.name === 'unionAttest') {
      to.meta.keepAlive = true;
      from.meta.keepAlive = false
    }
    //去下一页 缓存当前 在去下一页
    if(to.name === 'standardTestReport') {
      from.meta.keepAlive = true;
    }
    next()
  }
};
</script>

<style scoped lang="scss">
</style>
