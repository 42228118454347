let apiBase = {
  querySummary:'/api/termTask/querySummary',//查询配置概要
  queryTermList:'/api/termTask/queryTermList',//查询配置列表
  updateSetting:'/api/termTask/updateSetting',//更新配置
  queryStandardRuleList:'/api/termTask/queryStandardRuleList',//标准规则列表
  queryStandardList: '/api/termTask/queryStandardList',//标准列表
}
  let unionDeployServerObject = null
  class UnionDeployServer {
    constructor() {}
    querySummary(data = {}) {
      return $http({
        url: apiBase.querySummary,
        method: 'post',
        data: data,
      })
    }
    queryTermList(data = {}) {
      return $http({
          url: apiBase.queryTermList,
          method: 'post',
          data: data,
      })
    }
    updateSetting(data = {}) {
      return $http({
          url: apiBase.updateSetting,
          method: 'post',
          data: data,
      })
    }
    editTestMethod(data = {}) {
      return $http({
          url: apiBase.editTestMethod,
          method: 'post',
          data: data,
      })
    } 
    queryStandardRuleList(data = {}) {
      return $http({
          url: apiBase.queryStandardRuleList,
          method: 'post',
          data: data,
      })
    }
    queryStandardList(data = {}) {
      return $http({
          url: apiBase.queryStandardList,
          method: 'post',
          data: data,
      })
    }
  }
  
  function singUnionDeployServer() {
    if (unionDeployServerObject === null) {
      unionDeployServerObject = new UnionDeployServer()
    }
    return unionDeployServerObject
  }
  export default singUnionDeployServer()