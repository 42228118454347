

import banner from './banner';//banner
import news from "./news"; //资讯
import dataCensus from "./dataCensus";//数据统计
import document from "./document";//文档中心
import recommend from "./recommend";//推荐文档
import notice from "./notice";//推荐文档
import member from "./member"
export default [
	...banner,
	...news,
	...dataCensus,
	...document,
	...recommend,
	...notice,
	...member,
]	