export default {
  develop_manage:{
    "develop_menu": {
      value: "开发者管理",
      route: "/developer/list",
      icon: "开发者",
    },
    "manufacturer_menu": {
      value: "厂商账号绑定",
      route: "/accountBind/list",
      icon: "账号绑定",
    },
  },
  gold_action_manage:{
    "gold_action_menu": {
      value: "金标认证",
      route: "/goldStandard/list",
      icon: "金标认证",
    },
    // "application": {
    //   value: "应用信息管理",
    //   route: "/application/list",
    //   icon: "应用管理",
    // },
    "appeal_action_menu": {
      value: "申诉管理",
      route: "/appeal/list",
      icon: "申诉管理",
    },
    "alliance_detection_menu": {
      value: "联盟检测",
      route: "/unionAttest/list",
      icon: "联盟检测",
    },
    "term_manage_menu": {
      value: "金标认证配置",
      route: "/goldDeploy/list",
      icon: "金标认证配置",
    },
    
    "union_deploy_menu": {
      value: "联盟检测配置",
      route: "/unionDeploy/list",
      icon: "联盟检测配置",
    },
  },
  operate_manage:{
    "banner_action_menu": {
      value: "banner管理",
      route: "/banner/list",
      icon: "banner",
    },
    "news_action_menu": {
      value: "资讯管理",
      route: "/news/list",
      icon: "资讯",
    },
    "data_statistics_menu": {
      value: "数据统计",
      route: "/dataCensus/list",
      icon: "数据统计",
    },
    "recommend_text_menu": {
      value: "推荐文档",
      route: "/recommend/list",
      icon: "推荐文档",
    },
    "document_center_menu": {
      value: "文档中心",
      route: "/document/list",
      icon: "文档中心",
    },
    "notice_manage_menu": {
      value: "公告管理",
      route: "/notice/list",
      icon: "公告管理",
    },
    "member_menu": {
      value: "联盟成员",
      route: "/member/list",
      icon: "联盟成员",
    },
  },
  basic_functions_manage:{
    "user_action_menu": {
      value: "用户管理",
      route: "/userManager/list",
      icon: "用户管理",
    },
    "authority_deploy_menu": {
      value: "权限配置",
      route: "/authorityDeploy/list",
      icon: "权限配置",
    },
    "authority_manage_menu": {
      value: "权限管理",
      route: "/authority/list",
      icon: "权限管理",
    },
    "alert_action_menu": {
      value: "邮件预警",
      route: "/emailAlert/list",
      icon: "邮件预警",
    },
    "tool_action_menu": {
      value: "同步日志",
      route: "/sync/list",
      icon: "同步日志",
    },
    "bath_tool_action_menu": {
      value: "批量同步日志",
      route: "/batchSync/list",
      icon: "批量同步日志",
    },
  }
}

