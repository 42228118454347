const routes = [
  {
    path: '/sync',
    name: '',
    redirect:'/sync/list'
  },
  {
    path: '/sync/list',
    name: '',
    meta:{module:'basic_functions_manage',menu:'/sync/list',title:'同步日志'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/sync/list.vue')
  },
]

export default routes;
