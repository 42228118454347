<template>
  <div class="wrapBox">
    <el-tabs v-model="activeName"  @tab-click="handleClick">
      <el-tab-pane label="概况" name="survey">
        <test-overview></test-overview>
      </el-tab-pane>
      
     <el-tab-pane v-for="(item,index) in navList" :key="index" :label="item.name" :name="item.code">
        <standard-results  v-if="activeName==item.code" :standard = activeName></standard-results >
      </el-tab-pane>

      <!-- <el-tab-pane label="兼容性测试" name="compatible">
        <standard-results v-if="activeName=='compatible'" :standard = activeName></standard-results>
      </el-tab-pane>
      <el-tab-pane label="稳定性测试" name="stable">
        <standard-results v-if="activeName=='stable'" :standard = activeName></standard-results>
      </el-tab-pane>
      <el-tab-pane label="安全测试" name="security">
        <standard-results v-if="activeName=='security'" :standard = activeName></standard-results>
      </el-tab-pane>
      <el-tab-pane label="功耗测试" name="consumption">
        <standard-results v-if="activeName=='consumption'" :standard = activeName></standard-results>
      </el-tab-pane>
      <el-tab-pane label="性能测试" name="performance">
        <standard-results v-if="activeName=='performance'" :standard = activeName></standard-results>
      </el-tab-pane>
      <el-tab-pane label="大屏标准" name="large">
        <standard-results v-if="activeName=='large'" :standard = activeName></standard-results>
      </el-tab-pane>  -->
      
    </el-tabs>
  </div>
</template>

<script>
import standardResults from '../template/standardResults';
import testOverview from '../template/testOverview'
export default {
  components: {standardResults,testOverview},
  data(){
    return{
      activeName:"survey",
      navList:[]
    }
  },
  // computed: {
  //   standardList(){
  //     return this.$store.getters['standardStore/getsStandardList'];
  //   }
  // },
  mounted(){
    this.queryStandardList()
  },
  methods: {
    handleClick(tab) {
      sessionStorage.setItem('standardTestReport_tab',tab.name);
    },
    queryStandardList(){
      this.$apis.unionDeployServer.queryStandardList().then(res=>{
        if (res['code'] == 0) {
          this.navList=res.data;
        }
      })
    },
  },
  activated(){
    if(sessionStorage.getItem('standardTestReport_tab')){
      this.activeName = sessionStorage.getItem('standardTestReport_tab')
    }
  },
};
</script>

<style scoped lang="scss">
.wrapBox{
  padding-top: 10px;
}
.el-tabs {
  height: 100%;
  ::v-deep .is-active {
    font-weight:bolder ;
  }
}
</style>

