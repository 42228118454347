<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <div class="wrapBox">
       <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
        <div>
          <el-button v-auth="permission.TEST_DETAILS_DATA_EXPORT" class="search-button" type="primary" @click="exportData()" :loading="buttonLoading">
            <span v-if="!buttonLoading">导出数据</span> 
            <span v-else>正在导出</span> 
          </el-button>
        </div>
      </div>
      <el-table
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        class="tableBox W100"
      >
        <el-table-column width="60" type="index" label="序号"></el-table-column>
        <el-table-column prop="apkName" label="应用名称"></el-table-column>
        <el-table-column prop="apkPackage" label="包名"></el-table-column>
        <el-table-column prop="state" label="认证结果">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">认证中</span>
            <span v-if="scope.row.state == 2">认证通过</span>
            <span v-if="scope.row.state == 3">认证未通过</span>
          </template>
        </el-table-column>
        <!-- <el-table-column v-for="(item,index) in navList" :key="index"  :label="item.name" width="90">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover">
              <div v-for="(item2,index2) in memberList1[index].stateList" :key="index2" >{{item2.member}}:{{ item2.state | versionsTask_memberState()}}</div>
              <div class="reference-box" slot="reference">
                <p class="flex" v-for="(item3,index3) in memberList1[index].stateList" :key="index3" > 
                  <svg-icon class="svg-review-item" :svg-name="json_versionsStandard_memberState(item3.state)"></svg-icon> 
                  <label>{{item3.member}} </label>
                </p>
                <svg-icon class="svg-review" :svg-name="scope.row.compatible | versionsTask_memberState()"></svg-icon>
              </div>
            </el-popover>
           
          </template>
        </el-table-column> -->
        <el-table-column prop="compatible" label="兼容标准" width="90">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span>
                    {{ item.compatible | versionsTask_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="json_versionsStandard_memberState(item.compatible)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.compatible | versionsTask_memberState()"></svg-icon>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="stable" label="稳定标准" width="90">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span>
                    {{ item.stable | versionsTask_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="json_versionsStandard_memberState(item.stable)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.stable | versionsTask_memberState()"></svg-icon>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="security" label="安全标准" width="90">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span>
                    {{ item.security | versionsTask_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="json_versionsStandard_memberState(item.security)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.security | versionsTask_memberState()"></svg-icon>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="consumption" label="功耗标准" width="90">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span>
                    {{ item.consumption | versionsTask_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="json_versionsStandard_memberState(item.consumption)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.consumption | versionsTask_memberState()"></svg-icon>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="performance" label="性能标准" width="90">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span>
                    {{ item.performance | versionsTask_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="json_versionsStandard_memberState(item.performance)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.performance | versionsTask_memberState()"></svg-icon>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="large" label="大屏标准" width="90">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span>
                    {{ item.large | versionsTask_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="json_versionsStandard_memberState(item.large)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.large | versionsTask_memberState()"></svg-icon>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="未通过项" width="390">
          <template slot-scope="scope">
            <div  style="text-align: left;">
              <span v-for="(item,index) in scope.row.failCode" :key="index" class="red cursor" >
                <span v-if="item.termType==1" @click="onTestDetails(item.termCode,scope.row.apkPackage)"><i v-if="index">、</i>{{item.termCode}}</span>
              </span> 
            </div> 
          </template>
        </el-table-column>
         <el-table-column label="警告项" width="390">
          <template slot-scope="scope">
            <template >
              <div style="text-align: left;">
                <span v-for="(item,index) in scope.row.failCode" :key="index" class="warning cursor" >
                  <span v-if="item.termType==2" @click="onTestDetails(item.termCode,scope.row.apkPackage)">{{item.termCode}}<i v-if="index != scope.row.failCode.length-1">、</i></span>
                </span> 
              </div>                  
            </template> 
          </template>
        </el-table-column>
        <el-table-column label="测试报告">
          <template slot-scope="scope">
            <el-button v-auth="permission.TEST_DETAILS_FRIM_LOOK_REPORT" class="color-blue link-button" type="text" @click="goLookReport(scope.row)">
              查看报告
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer  margin-t30">
        <div class="tips">说明：测试警告项不影响测试结果</div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
       <test-item-details v-if="isShowlModal" :modalShow="isShowlModal" :form="form" @closeModal="closeModal()" ></test-item-details>
    </div>
  </div>
</template>

<script>
import testItemDetails from "../dialogs/testItemDetails";
export default {
  components: { testItemDetails },
  data() {
    return {
      buttonLoading:false,
      isShowlModal:false,
      form:{},
      searchFormData: {
        taskId:this.$route.query.id,
        page: 1,
        perPage: 10,
      },
      total: 0,
      tableData: [],
      //搜索form组件
      searchControlData: [
        {
          type: "input",
          label: "应用名称",
          placeholder: "应用名称",
          key: "apkName",
        },
        {
          type: "select",
          label: "认证结果",
          placeholder: "认证结果",
          key: "state",
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '兼容标准',
          key: 'compatible',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '稳定标准',
          key: 'stable',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '安全标准',
          key: 'security',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '功耗标准',
          key: 'consumption',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '性能标准',
          key: 'performance',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '大屏标准',
          key: 'large',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
      ],
      // memberList1:[
      //   {
      //     code:"compatible",
      //     stateList:[
      //       {
      //         member:"OPPO",
      //         state:1,
      //       },
      //       {
      //         member:"vivo",
      //         state:2,
      //       },
      //       {
      //         member:"小米",
      //         state:3,
      //       }
      //     ],
          
      //   },
      //     {
      //     code:"stable",
      //     stateList:[
      //       {
      //         member:"OPPO",
      //         state:2,
      //       },
      //       {
      //         member:"vivo",
      //         state:2,
      //       },
      //       {
      //         member:"小米",
      //         state:2,
      //       }
      //     ],
          
      //   },
      //   {
      //     code:"security",
      //     stateList:[
      //       {
      //         member:"OPPO",
      //         state:3,
      //       },
      //       {
      //         member:"vivo",
      //         state:2,
      //       },
      //       {
      //         member:"小米",
      //         state:3,
      //       }
      //     ],
          
      //   },
      //    {
      //     code:"consumption",
      //     stateList:[
      //       {
      //         member:"OPPO",
      //         state:3,
      //       },
      //       {
      //         member:"vivo",
      //         state:3,
      //       },
      //       {
      //         member:"小米",
      //         state:3,
      //       }
      //     ],
      //   },
      //   {
      //     code:"performance",
      //     stateList:[
      //       {
      //         member:"OPPO",
      //         state:1,
      //       },
      //       {
      //         member:"vivo",
      //         state:1,
      //       },
      //       {
      //         member:"小米",
      //         state:1,
      //       }
      //     ], 
      //   },
      //   {
      //     code:"large",
      //     stateList:[
      //       {
      //         member:"OPPO",
      //         state:1,
      //       },
      //       {
      //         member:"vivo",
      //         state:2,
      //       },
      //       {
      //         member:"小米",
      //         state:3,
      //       }
      //     ], 
      //   },
      // ],
      // navList:[],
      // memberList:[],
    };
  },
  created(){
    this.search();
    this.queryStandardList();
    this.getMemberList();
  },
  methods:{
    getMemberList(){
      this.$apis.unionAttestServer.enableMemberList().then(res=>{
         if (res['code'] == 0 && res.data) {
          this.memberList = res.data;
        }
      })
    },
    queryStandardList(){
      this.$apis.unionDeployServer.queryStandardList().then(res=>{
        if (res['code'] == 0) {
          this.navList=res.data;
        }
      })
    },
    // 关闭modal
    closeModal(val) {
      this.isShowlModal = val;
    },
    search(data) {
      this.loading = true
      if (data) {
        Object.assign(this.searchFormData, data)
        this.searchFormData.page = 1
      }
      this.$apis.unionAttestServer.queryPageByTaskId(this.searchFormData).then(res => {
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total;
          this.tableData = res.data.data;

        } else {
          this.total = 0;
          this.tableData = [];
        }
        this.loading = false;
      })
    },
    json_versionsStandard_memberState(value){
        return { '1': "认证中", '2': "认证通过", '3': '认证未通过'}['' + value] || value;
    },
    goLookReport(row) {
      this.$router.push({
        path:'/unionAttest/attestDetails/standardTestReport',
        query:{
          id: this.$route.query.id,
          apkPackage:row.apkPackage,
          title: this.$route.query.title,
          apkName: row.apkName?row.apkName:'',
          breadcrumbName: `测试明细${row.apkName?'-'+row.apkName:''}`,
        }
      })
    },

    onTestDetails(value,apkPackage,){
       this.form={
        taskId:this.$route.query.id,
        code:value,
        apkPackage:apkPackage,
      }
      this.isShowlModal = true;
     
    },

    exportData(){
      this.buttonLoading = true;
      this.$apis.unionAttestServer.taskIdentifyExport({taskId:this.$route.query.id,}).then(res => {
        if (res['code'] == 0 && res['data']['link']) {
          this.buttonLoading = false;
          window.location.href = res.data.link;
        }
      });
    },

    //table点击更改一页多少行
    handleSizeChange(val) {
      this.searchFormData.perPage = val;
      this.search();
    },
    //table点击修改页码
    handleCurrentChange(val) {
      this.searchFormData.page = val;
      this.search();
    },
  },
};
</script>

<style scoped lang="scss">
.label-box{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.reference-box{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 70px;
  .flex{
    width: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
    .svg-review-item{
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }
  }
  .svg-review{
    position: absolute;
    right: -5px;
    bottom: 0;
    opacity: 0.5;
    width: 70px;
    height: 70px;
  }
}
.footer{
  display: flex;
  justify-content: space-between;
  .tips{
    color: #666;
    font-size: 14px;
  }
}
</style>