<template>
<div class="exportBox">
 <el-form   class="exportContent" ref="formRef" :inline="true" :model="formData" label-width="80px" size="small">
  <el-form-item
    v-for="(item,index) in controlData" :key="index"
    :prop="item.key"
    :rules="item.type == 'rangDate'?[ { required: true, message: '请选择日期时间', trigger: 'blur' },]:[]"
    :label="item.label"
   >
   <el-input  v-if="item.type == 'input'" v-model="formData[item.key]"></el-input>
   <el-select
     @change="selectChange"
     v-if="item.type == 'select'"
     v-model="formData[item.key]"
     :placeholder="item.placeholder?item.placeholder:''">
    <el-option
     v-for="(optionData,index) in item.option"
     :key="index"
     :label="optionData.label"
     :value="optionData.value"></el-option>
   </el-select>
   <el-date-picker
    v-if="item.type == 'rangDate'"
    value-format="timestamp"
    v-model="formData[item.key]"
    type="datetimerange"
    align="right"
    start-placeholder="开始日期"
    end-placeholder="结束日期">
   </el-date-picker>
  </el-form-item>
 </el-form>
 <div class="exportButtonBox">
  <wk-button class="exportButton" type="primary" @click="onSubmit()">导出数据</wk-button>
 </div>
</div>

</template>

<script>

  export default {
    name: "WkExport",
    data(){
      return{
        formData:{},
      }
    },
    props:{
     /*
  *controlData:[
  * {
      type: 'input', 表单组件的类型,必填，可选值为：input: 输入框；select:下拉选择框；rangDate: 日期范围选择器，
      label: '账号邮箱', 表单的label
      placeholder: '请输入账号邮箱'  表单控件的placeholder，可传可不传
      key: 'email'  导出参数，与表单控件绑定的值
    },
   * ]
  *
  * */
      controlData:Array,
      id:String,
    },
    methods: {
      onSubmit() {
       this.$refs['formRef'].validate(valid =>{
        if(valid){
         this.$emit('export',this.formData)
        }
       })
      },
      selectChange(data){
        this.$emit('renzhenChange',data)
      },
      resetForm() {
        this.formData = {}
        this.$emit('export',this.formData)
      }
    }
  }
</script>

<style scoped lang="scss">
 /deep/ .el-select {
  display:block;
  position: relative;
 }
 .exportBox{
  display: flex;
  padding: 22px 20px 4px 47px ;
  .exportContent{
   display: flex;
   flex-wrap: wrap;
   flex-basis:90%;
  }
  .exportButtonBox{
  display: flex;
   flex-basis: 10%;
   flex-direction: column;
   align-items: flex-end;

   .exportButton{
    height: 32px;
    line-height: 0;
    text-align: center;
    width: 110px;

    }
  }
 
 }
</style>