let apiBase = {
    queryList:'/api/tool/queryTaskIdentifySync',                  // 同步列表     
    queryAgain:'/api/tool/taskSyncAgain',                         // 接口重试         
  }
  let BathSyncServerObject = null
  class BathSyncServer{
    constructor(){}
    queryList(data={}){
      return $http({
        url: apiBase.queryList,
        method: 'post',
        data: data,
      })
    }
    queryAgain(data={}){
      return $http({
        url: apiBase.queryAgain,
        method: 'post',
        data: data,
      })
    }
  }
  function singleBathSyncServer(){
    if(BathSyncServerObject === null){
      BathSyncServerObject = new BathSyncServer()
    }
    return BathSyncServerObject
  }
  export default singleBathSyncServer()