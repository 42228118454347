let apiBase = {
  export:'/api/accountBind/export',
  getMemberList:'/api/accountBind/memberList', // 厂商列表
  queryList:'/api/accountBind/queryList',
}
let AccountBindServerObject = null
class AccountBindServer{
  constructor(){}

  // 导出
  export(data={}){
    return $http({
      url: apiBase.export,
      method: 'post',
      data: data,
    })
  }

  // 获取厂商列表
  getMemberList(data={}){
    return $http({
      url: apiBase.getMemberList,
      method: 'post',
      data: data,
    })
  }

   // 查询账号绑定信息
  queryList(data={}){
    return $http({
      url: apiBase.queryList,
      method: 'post',
      data: data,
    })
  }

}
function singleAccountBindServer(){
  if(AccountBindServerObject === null){
    AccountBindServerObject = new AccountBindServer()
  }
  return AccountBindServerObject
}
export default singleAccountBindServer()