<template>
  <div class="page">
    <div class="wrapBox">
      <el-table
        class="tableBox"
        :data="tableData"
        stripe
        style="width: 100%">
        <el-table-column
          width="60"
          align="center"
         type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="选项">
        </el-table-column>
        <el-table-column
          align="center"
          prop="num"
          label="投票数">
        </el-table-column>
        <el-table-column
          align="center"
          prop="percentage"
          label="票数比例">
        </el-table-column>
        <el-table-column
          align="center"
          v-if="userInfo.roleName != '普通账号'"
          label="操作">
          <template slot-scope="scope">
            <el-button v-auth="permission.NEWS_ACTION_VOTEINFOLIST"  class="color-blue link-button" type="text"
                       @click="gotoDetail(scope.row)">投票明细
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import store from '@/store/index.js';
  export default {
    beforeRouteEnter: (to,from,next) => {
      store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:to.query.title+'-投票信息', path: to.fullPath} );
      next()
    },
    data() {
      return {
        voteId:null,
        total: 0,
        loading: false,
        searchFormData: {
          page: 1,
          perPage: 10,
        },
        tableData: []
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
      }
    },
    created() {
      this.voteId = this.$route.query.id;
      this.search()
    },
    methods: {
      gotoDetail(data){
        this.$router.push({
          path:'/news/vot/detail',
          query:{
            voteId: this.voteId,
            id: data.id,
            title:data.title
          }
        })
      },
      search() {
        this.loading = true
        this.$apis.newsServer.voteList({
          infoVoteId:this.voteId
        }).then(res => {
          if (res['code'] == 0 && res.data.length > 0) {
            this.tableData = res.data
          } else {
            this.tableData = []
          }
          this.loading = false
        })
      },

      handleSizeChange(val) {
        this.searchFormData.perPage = val
        this.search()
      },
      handleCurrentChange(val) {
        this.searchFormData.page = val
        this.search()
      }
    }
  }
</script>
<style>
  .el-message-box--center .el-message-box__message {
    font-size: 20px;
    color: #000000;
  }

  .cancelButton {
    width: 98px;
    margin-left: 14px;
    margin-top: 14px;
  }

  .confirmButton {
    width: 98px;
    margin-left: 60px !important;
  }
</style>
<style scoped lang="scss">
  .el-message-box--center .el-message-box__message {
    font-size: 20px;
    color: #000000;
  }
  .page {
  }
</style>