<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>

    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{ total }}条数据</div>
        <el-button
          v-auth="permission.NEWS_ACTION_REGISTE_EXPORT"
          class="common-button"
          type="primary"
          @click="exportClick()"
        >
          导出
        </el-button>
      </div>
      <el-table class="tableBox" :data="tableData" stripe style="width: 100%">
        <el-table-column width="60" align="center" type="index" label="序号">
        </el-table-column>
        <el-table-column align="center" prop="accountId" label="开发者ID">
        </el-table-column>
        <el-table-column prop="email" label="注册邮箱"> </el-table-column>
        <el-table-column width="70" align="center" prop="type" label="类型">
          <template slot-scope="scope">
            <span>{{
              scope.row.type == "1"
                ? "企业"
                : scope.row.type == "2"
                ? "个人"
                : "政府、事业单位"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="companyName" label="企业名称">
        </el-table-column>
        <el-table-column align="center" prop="contactName" label="联系人">
        </el-table-column>
        <el-table-column align="center" prop="contactPhone" label="联系电话">
        </el-table-column>
        <el-table-column align="center" prop="contactEmail" label="联系邮箱">
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="报名时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | parseTime() }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import store from '@/store/index.js';
export default {
  beforeRouteEnter: (to,from,next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:to.query.title+'-报名信息', path: to.fullPath} );
    next()
  },
  data() {
    return {
      voteId: null,
      total: 0,
      dialogVisible: false,
      loading: false,
      searchFormData: {
        id: null,
        page: 1,
        perPage: 10,
      },
      searchControlData: [
        {
          type: "input",
          label: "注册邮箱",
          placeholder: "请输入注册邮箱",
          key: "email",
        },
        {
          type: "input",
          label: "企业名称",
          placeholder: "请输入企业名称",
          key: "companyName",
        },
        {
          type: "input",
          label: "联系人",
          placeholder: "请输入联系人",
          key: "contactName",
        },
      ],
      tableData: [],
      exportData: [
        {
          type: "rangDate",
          label: "绑定时间",
          key: "time",
        },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
    },
  },
  created() {
    this.voteId = this.$route.query.id;
    this.search();
  },
  methods: {
    // 导出
    exportClick(data) {
      this.$apis.newsServer
        .SignUpExport({
          id: this.voteId,
        })
        .then((res) => {
          if (res["code"] == 0 && res["data"]["link"]) {
            let link = res.data.link;
            window.location.href = link;
          }
        });
    },
    search(data) {
      this.loading = true;
      if (data) {
        Object.assign(this.searchFormData, data);
        this.searchFormData.page = 1;
      }
      this.searchFormData.id = this.voteId;
      this.$apis.newsServer.infoSignUpList(this.searchFormData).then((res) => {
        if (res["code"] == 0 && res.data.data) {
          this.total = res.data.total;
          this.tableData = res.data.data;
        } else {
          this.total = 0;
          this.tableData = [];
        }
        this.loading = false;
      });
    },
    addBanner() {
      this.$refs.modalRef.show("add");
    },
    editBanner(id) {
      this.$refs.modalRef.loadData(id);
      this.$refs.modalRef.show("edit");
    },
    changeStatus(data) {
      // state: banner的当前状态： 1：开启；2：关闭
      if (data.state == "1") {
        this.$confirm("确定关闭该banner?", "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "cancelButton",
          confirmButtonClass: "confirmButton",
          center: true,
        }).then(() => {
          this.$apis.bannerServer
            .updateStateDisable({
              id: data.id,
            })
            .then((res) => {
              if (res["code"] == 0) {
                this.search();
                this.$message({
                  type: "success",
                  message: "关闭成功!",
                });
              }
            });
        });
      } else {
        this.$confirm("确定启用该banner?", "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "cancelButton",
          confirmButtonClass: "confirmButton",
          type: "info ",
          center: true,
        }).then(() => {
          this.$apis.bannerServer
            .updateStateEnable({
              id: data.id,
            })
            .then((res) => {
              if (res["code"] == 0) {
                this.search();
                this.$message({
                  type: "success",
                  message: "启用成功!",
                });
              }
            });
        });
      }
    },
    deleteClick(id) {
      this.$confirm("确定删除该banner", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "cancelButton",
        confirmButtonClass: "confirmButton",
        type: "info ",
        center: true,
      }).then(() => {
        this.$apis.bannerServer
          .deleteBanner({
            id: id,
          })
          .then((res) => {
            if (res["code"] == 0) {
              this.search();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
      });
    },
    handleSizeChange(val) {
      this.searchFormData.perPage = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.searchFormData.page = val;
      this.search();
    },
  },
};
</script>
<style scoped lang="scss">
.el-message-box--center .el-message-box__message {
  font-size: 20px;
  color: #000000;
}
</style>
