<template>
  <el-dialog
    title="编辑金标有效期"
    :visible="dialogVisible"
    width="650px"
    @close="dialogVisible = false"
    :close-on-click-modal="false"
    center
  >
    <el-form :model="formData" ref="refForm" label-width="120px">
      <el-form-item label="名称">
        <template slot="label">
          <el-image class="assetsImg" :src="formData.apkIcon">
            <template slot="error">
              <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
            </template>
          </el-image>
        </template>
        <p>{{formData.apkName}}</p>
        <p>
          <span v-if="formData.state == 1">应用信息未通过审核</span>
          <span v-if="formData.state == 2">金标认证有效</span>
          <span v-if="formData.state == 3">金标认证失败</span>
          <span v-if="formData.state == 4">金标已过期</span>
        </p>
      </el-form-item>
      <el-form-item
        label="金标有效期"
        prop="expireDay"
        :rules="[
          { required: true, message: '不能为空'},
          { type: 'number', message: '必须为数字值'},
          { validator: this.numberRule, trigger: 'blur'},  
        ]">
        <el-input class="expireDay-input" type="age" v-model.number="formData.expireDay" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item 
      label="人工干预说明" 
      prop="remark"
      :rules="[
          { required: true, message: '不能为空'}, 
        ]">
				<el-input
					:rows="6"
					:maxlength="250"
					show-word-limit
					type="textarea"
          
					class="w400" v-model="formData.remark" placeholder="请输入内容"></el-input>
			</el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" :disabled="isDisabled" @click="onSubmit()">确定</el-button>
        <el-button size="medium" type="info" @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

export default {
  props: {
    apkPackage: {
      type: String,
      required: true
    }
  },
  data() {
    const numberRule = (rule, value, callback) => {
      if (Number(value) >= this.formData.expireDay) {
        if (Number.isInteger(Number(value)) && Number(value) >= 0) {
          if(Number(value)<=180){
            callback()
          }else{
            callback(new Error('不可大于180'))
          }
        } else {
          callback(new Error('请输入大于零的正整数'))
        }
      } else {
        callback(new Error('不能小于0'))
      }
    }
    return {
      numberRule: numberRule,
      //显示dialog
      dialogVisible: false,

      isDisabled:false,
      //数据
      formData: {
        expireDay:'',
        remark:''
      }
    };
  },
  methods: {
    //提交
    onSubmit() {
      this.isDisabled=true;
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
         this.$apis.goldStandardServer.updateExpireTime({
            apkPackage:this.formData.apkPackage,//	必选	string	包名
            expireDay:this.formData.expireDay,//	可选	string	有效期时间
            remark:this.formData.remark,
          }).then(res => {
            if (res['code'] == 0) {
              this.$emit("update");
              this.hide();
              this.isDisabled=false;
            } else {
              this.$message({
                message: res['msg'],
                type: "error"
              });
            }
          });
        } else {
          this.isDisabled=false;
          return false;
        }
      });
    },
    //关闭dialog
    hide() {
      this.dialogVisible = false;
      this.$refs['refForm'].resetFields();
    },
    //展示dialog，供父组件调用
    show(data) {
      this.dialogVisible = true;
      Object.assign(this.formData,data);
    }
  }
};
</script>

<style scoped>
.cancelButton {
  width: 98px;
  margin-left: 14px;
  margin-top: 14px;
}

.confirmButton {
  width: 98px;
  margin-left: 60px !important;
}

.assetsImg {
  display: inline-block;
  right: 0;
  top: 10px;
  width: 60px;
  height: 60px;
}
.expireDay-input {
  width: 200px;
}
</style>