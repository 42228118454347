<template>
  <div class="wrapBox">
    <el-tabs v-model="activeName" v-auth="permission.STAT_ACCOUNT_LIST">

      <el-tab-pane label="开发者数据" name="devData">
        <dev-data v-if="activeName=='devData'"></dev-data>
      </el-tab-pane>

      <el-tab-pane label="应用数据" name="appData">
        <app-data v-if="activeName=='appData'"></app-data>
      </el-tab-pane>
      
      <el-tab-pane label="金标认证数据" name="goldData">
        <gold-data v-if="activeName=='goldData'"></gold-data>
      </el-tab-pane>

      <el-tab-pane label="时效数据" name="timeData">
        <time-data v-if="activeName=='timeData'"></time-data>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import devData from "./template/devData.vue"
import appData from "./template/appData.vue"
import goldData from "./template/goldData.vue"
import timeData from "./template/timeData.vue"
export default {
  components: {devData,appData,goldData,timeData},
  data(){
    return{
      activeName:"devData"
    }
  },
  methods:{
  }
}
</script>

<style scoped lang="scss">
.wrapBox{
  min-height: 500px;
}

</style>