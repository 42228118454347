<template>
  <div class="page">
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px"></div>
        <el-button v-auth="permission.AUTHORITY_MANAGE_ADD"  class="common-button"  type="primary" @click="addAuthority()">
          添加自定义用户组
        </el-button>
      </div>
      <el-table
        v-auth="permission.AUTHORITY_MANAGE_LIST"
        class="tableBox"
        stripe
        :data="tableData"
        style="width: 100%">
        <el-table-column
          width="60"
          align="center"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="用户组">
        </el-table-column>
        <el-table-column
          align="center"
          label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type==1">默认组</span>
            <span v-if="scope.row.type==2">自定义组</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="编辑时间">
          <template slot-scope="scope">
            <span>{{ scope.row.modifyTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="adminName"
          label="操作人">
        </el-table-column>
        <el-table-column
          align="center"
          label="操作">
          <template slot-scope="scope">
            <el-button
              v-auth="permission.AUTHORITY_MANAGE_EDIT"
              class="color-blue link-button"
              type="text"
              @click="editAuthority(scope.row.id)">编辑
            </el-button>
            <el-button
              v-if="scope.row.type!=1"
              v-auth="permission.AUTHORITY_MANAGE_DELETE"
              class="color-blue deleteButton link-button"
              type="text" @click="deleteEmail(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <add v-if="dialogVisible" :dialogVisible="dialogVisible" :id="id" :type="type" @closeModal="closeModal"></add>
  </div>
</template>

<script>
import add from "./dialogs/add";
export default {
 components: {add},
  data(){
    return{
      dialogVisible:false,
      type:"",
      id:"",
      total: 0,
      searchFormData: {
        page: 1,
        perPage: 10,
      },
      searchControlData: [
        {
          type: 'input',
          label: '账号邮箱',
          placeholder: '请输入账号邮箱',
          key: 'title'
        },
        {
          type: 'input',
          label: '姓名',
          placeholder: '请输入权限姓名',
          key: 'title'
        },
        {
          type: 'select',
          label: '归属',
          placeholder: '请选择',
          key: 'state',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '功能',
              value: '1'
            },
            {
              label: '菜单',
              value: '2'
            },
          ]
        }
      ],
      tableData: [],
    }
  },
  created(){
    this.search();
  },
  methods:{
    search(data) {
      if (data) {
        Object.assign(this.searchFormData, data)
        this.searchFormData.page = 1
      }
      this.$apis.authorityServer.queryPage(this.searchFormData).then(res => {
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total
          this.tableData = res.data.data
        } else {
          this.total = 0
          this.tableData = []
        }
      })
    },
    addAuthority() {
      this.dialogVisible = true;
      this.type = "add";
      this.id = 1;
    },
    editAuthority(id) {
      this.dialogVisible = true;
      this.id = id;
      this.type = "edit";
    },
    deleteEmail(data) {
      this.$dialogs
      .confirm({
        content: `确定删除用户组${data.name}管理员？`,
      })
      .then(() => {
        this.$apis.authorityServer.delete({
          id:data.id
        }).then(res => {
          if (res['code'] == 0) {
            this.search()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })
      })
      .catch((val) => {});
    },
    
    closeModal() {
      this.dialogVisible=false;
      this.search();
    },
     handleSizeChange(val) {
      this.searchFormData.perPage = val
      this.search()
    },
    handleCurrentChange(val) {
      this.searchFormData.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-table .cell{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
</style>