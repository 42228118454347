<template>
  <el-dialog
    :title="title()"
    :visible="dialogVisible"
    width="650px"
    @close="dialogVisible = false"
    center
  >
    <el-form :model="formData" ref="refForm" label-width="100px">
      <el-form-item label="应用名称: ">
        <p>{{formData.apkName}}</p>
      </el-form-item>
      <el-form-item label="应用包名: ">
        <p>{{formData.apkPackage}}</p>
      </el-form-item>
      <!-- <el-form-item label="版本号: ">
        <p>{{formData.versionName}}({{formData.versionCode}})</p>
      </el-form-item>-->
      <el-form-item label="icon: ">
        <el-image class="assetsImg" :src="formData.apkIcon">
          <template slot="error">
            <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
          </template>
        </el-image>
      </el-form-item>
      <el-form-item label="应用信息: ">
        <a
          class="color-blue"
          :href="formData.fileUrl"
          target="_blank"
        >点击查看
        </a>
      </el-form-item>

      <el-form-item v-if="isReview" label="审核: ">
        <el-radio-group v-model="reviewState">
          <el-radio :label="1">审核通过</el-radio>
          <el-radio label="2">审核不通过</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="isReview">
        <el-col :span="15">
          <el-input
            type="textarea"
            :rows="5"
            :disabled="reviewState != 2"
            v-model="formData.reason"
            placeholder="审核不通过时请填写不通过原因"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item v-if="isReview">
        <el-button type="primary" @click="onSubmit()">确定</el-button>
        <el-button type="info" @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

  export default {
    name: "app-info",
    data() {
      return {
        //显示dialog
        dialogVisible: false,
        //是否审核
        isReview: false,

        //数据
        formData: {},
        //审核状态数据
        reviewState: null
      };
    },
    methods: {
      //提交
      onSubmit() {
        if(!this.reviewState){
          this.$message({
            message: "请选择审核结果",
            type: "error"
          });
          return
        }
        if (+this.reviewState == 1) {
          this.$apis.applicationServer.updateStateSuccess(this.formData).then(res => {
            if (res['code'] == 0) {
              this.$emit("update");
              this.hide();
            } else {
              this.$message({
                message: "失败",
                type: "error"
              });
            }
          });
        } else {
          if (!this.formData.reason) {
            this.$message({
              message: "请填写失败原因",
              type: "error"
            });
            return;
          }
          this.$apis.applicationServer.updateStateFail(this.formData).then(res => {
            if (res['code'] == 0) {
              this.$emit("update");
              this.hide();
            } else {
              this.$message({
                message: "失败",
                type: "error"
              });
            }
          });
        }
      },
      title() {
        if (this.isReview) {
          return "审核应用信息";
        }
        return "查看应用信息";
      },
      //关闭dialog
      hide() {
        this.dialogVisible = false;
        this.reviewState = null
        this.formData = {}
      },
      //展示dialog，供父组件调用
      show(type = "detail", {id}) {
        this.isReview = false;
        if (type == "review") {
          this.isReview = true;
        }

        this.dialogVisible = true;
        this.request(id);
      },
      request(id) {
        this.$apis.applicationServer.queryDetail({id}).then(res => {
          if (res['code'] == 0) {
            this.formData = res.data;
          } else {
            this.$message({
              message: "失败",
              type: "error"
            });
          }
        });
      }
    }
  };
</script>

<style scoped>
  .cancelButton {
    width: 98px;
    margin-left: 14px;
    margin-top: 14px;
  }

  .confirmButton {
    width: 98px;
    margin-left: 60px !important;
  }

  .assetsImg {
    width: 100px;
    height: 100px;
  }
</style>