<template>
  <div class="goldBox">
    <div class="chartBox">
      <div class="titleBox">
        <h1 class="title">金标认证数据图表</h1>
      </div>
      <div class="chart">
        <div class="screenBox">
          <el-radio-group v-model="searchFormData.type" style="margin-right:40px" @change="agreeChange()"> 
            <el-radio-button label="1">日</el-radio-button>
            <el-radio-button label="2">周</el-radio-button>
            <el-radio-button label="3">月</el-radio-button>
          </el-radio-group>
          <el-date-picker
            v-show="searchFormData.type!='3'"
            v-model="searchFormData.rangDate"
            @change="changeRangDate()"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
          <el-date-picker
            v-show="searchFormData.type =='3'"
            v-model="searchFormData.rangDate"
            @change="changeRangDate('3')"
            value-format="timestamp"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </div>
        <div id="goldBarChartBox"></div>
        <div id="goldColumnarChartBox"></div>
        <div class="pancakeChartBox">
          <div id="goldPancakeChartBox"></div>
          <div class="summary" v-for="(item,index) in this.pancakeOption.series[0].data" :key="index">
            <span>{{item.name}}</span>
            <span>{{item.value}}</span>
          </div>
        </div>     
      </div>
    </div>

    <div class="goldDataBox">
      <div class="titleBox">
        <h1 class="title">金标认证数据明细</h1>
        <el-button v-auth="permission.STAT_IDENTIFY_EXPORT" class="exportButton" type="primary" @click="exportClick()">导出数据</el-button>
      </div>
      <el-table
        class="tableBox"
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }">
        <el-table-column
          width="120" 
          prop="statTime"         
          label="日期">
        </el-table-column>
        <el-table-column      
          label="金标认证数据">
           <el-table-column           
              prop="newNum"
              label="新增认证">
            </el-table-column>
            <el-table-column        
              prop="approveNum"
              label="认证通过">
            </el-table-column>
            <el-table-column  
              prop="refuseNum"
              label="认证未通过">
            </el-table-column>
            <el-table-column
              prop="ingNum"
              label="认证中">
            </el-table-column>
            <el-table-column
              prop="exceptionNum"
              label="认证异常">
            </el-table-column>
            <el-table-column
              prop="cancelNum"
              label="认证取消">
            </el-table-column>
        </el-table-column>
        <el-table-column
          label="金标认证累计数据">
           <el-table-column             
              prop="totalApproveNum"
              label="认证通过">
            </el-table-column>
            <el-table-column
              prop="totalRefuseNum"
              label="认证未通过">
            </el-table-column>
            <el-table-column
              prop="totalIngNum"
              label="认证中">
            </el-table-column>
            <el-table-column              
              prop="totalExceptionNum"
              label="认证异常">
            </el-table-column>
            <el-table-column              
              prop="totalCancelNum"
              label="认证取消">
            </el-table-column>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  data(){
    return{
      pickerOptions:{
        disabledDate(time) {
          // const day = 730 * 24 * 3600 * 1000  // 31536000000
          // 返回小于当前日期并近一年内的日期
          return time.getTime() >= new Date(new Date().toLocaleDateString()).getTime()
          // || time.getTime() < (Date.now() - 8.64e7) - day
        },
      },
      // timePicker:"daterange",`
      total: 0,
      tableData: [],
      myBarChart:'',
      myColumnarChart:'',
      myPancakeChart:'',
      searchFormData:{
        type:"1",
        rangDate:[new Date(new Date().getFullYear(),new Date().getMonth(),-30).getTime(),new Date().getTime()- 8.64e7],
        startTime:"",
        endTime:"",
        page: 1,
        perPage: 10,
      },
      barOption:{
        title: {
          text: "金标认证数据",
          textStyle:{
            color:"#28262C",
            fontSize:16
          },
          left:"left",
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          top:30,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        barWidth:20,
        series: [
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#cdbc76'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#a1855e'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#dba245'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#d96136'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#76311b'
              }
            },
          },
          {
            name: '',
            type: 'line',
            data: [],
            itemStyle: {    //折线图颜色
              normal: {
                color: '#eb4e4e'
              }
            },
          },
        ], 
      },
      columnarOption:{
        title: {
          text: "金标认证累计数据",
          textStyle:{
            color:"#28262C",
            fontSize:16
          },
          left:"left",
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          top:30,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        barWidth:20,
        series: [
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#cdbc76'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#a1855e'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#dba245'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#d96136'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#f59a23'
              }
            },
          },
        ], 
      },
      pancakeOption: {
        title: {
          text: "",
          textStyle:{
            color:"#28262C",
          },
          left:"center",
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: "pie",
            data: [],
            label:{
              formatter:(arg=>{
                return arg.name + arg.value
              })
            },
            radius:110
          },
        ],
        color: ['#cdbc76', '#a1855e','#dba245','#d96136',"#f59a23"],     
      },
    }
  },
  mounted(){
    this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: ''
    this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: ''
    this.queryGoldList();
    this.setBarChart();
    this.setColumnarChar();
    this.setPancakeChart();
  },
  methods:{
     //改变日周月时触发  
    agreeChange(){
      this.searchFormData.page=1;
      if(this.searchFormData.type==1){
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-30).getTime(),new Date().getTime()- 8.64e7]
      } else if(this.searchFormData.type==2){
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-90).getTime(),new Date().getTime()- 8.64e7]
      }else{
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-350).getTime(),new Date().getTime()- 8.64e7]
      }
      this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0] : ''
      this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1] : ''
      this.queryGoldList();
      this.setBarChart();
      this.setColumnarChar();
      this.setPancakeChart();
    },


    //选择时间时触发
    changeRangDate(type){
      this.searchFormData.page=1;
      this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: '';
      if(type == '3'){
          var date = new Date(this.searchFormData.rangDate[1]);
          // 下月1号-1秒 
          this.searchFormData.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime()-1000;
      }else{
        this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: '';
      }
      this.queryGoldList();
      this.setBarChart();
      this.setColumnarChar();
      this.setPancakeChart();
  
    },


    setBarChart(){
      var barChart=document.getElementById("goldBarChartBox");
      barChart.style.width=window.innerWidth-300+'px';
      this.myBarChart = echarts.init(barChart);
      this.$apis.dataCensusServer.queryIdentifyForBar(this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data.series) {
          this.barOption.title.text="金标认证数据"; 
          this.barOption.legend.data=res.data.legend;
          this.barOption.xAxis.data=res.data.xAxis;        
          for(let key in res.data.series){
            this.barOption.series[key]["name"]=res.data.series[key]["name"];
            this.barOption.series[key]["data"]=res.data.series[key]["data"];
          }
          this.myBarChart.setOption(this.barOption,true);
          window.addEventListener('resize', () => {
            barChart.style.width=window.innerWidth-300+'px';
            this.myBarChart.resize();
          })
        }else{
          for (var i = 0; i < this.barOption.series.length; i++) {  //置空关键！！！  清空名字和数据
            this.barOption.series[i].data = [];         
          }
          this.barOption.xAxis.data=[]; 
          this.barOption.title.text="暂无数据"; 
          this.myBarChart = echarts.init(barChart)  //再次初始化
          this.myBarChart.setOption(this.barOption, true)  //重新setOption
        }
      })
    },

    setColumnarChar(){
      var columnarChart=document.getElementById("goldColumnarChartBox");
      columnarChart.style.width=window.innerWidth-300+'px';
      this.myColumnarChart = echarts.init(columnarChart);
      this.$apis.dataCensusServer.queryIdentifyTotalForBar(this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data.series) {
          this.barOption.title.text="金标累计数据"; 
          this.columnarOption.legend.data=res.data.legend;
          this.columnarOption.xAxis.data=res.data.xAxis;        
          for(let key in res.data.series){
            this.columnarOption.series[key]["name"]=res.data.series[key]["name"];
            this.columnarOption.series[key]["data"]=res.data.series[key]["data"];
          }
          this.myColumnarChart.setOption(this.columnarOption,true);
          window.addEventListener('resize', () => {
            columnarChart.style.width=window.innerWidth-300+'px';
            this.myColumnarChart.resize();
          })
        }else{
          for (var i = 0; i < this.columnarOption.series.length; i++) {  //置空关键！！！  清空名字和数据
            this.columnarOption.series[i].data = [];         
          }
          this.columnarOption.xAxis.data=[]; 
          this.columnarOption.title.text="暂无数据"; 
          this.myBarChart = echarts.init(columnarChart)  //再次初始化
          this.myBarChart.setOption(this.columnarOption, true)  //重新setOption
        }
      })
    },

    setPancakeChart(){
      var pancakeChart=document.getElementById("goldPancakeChartBox");
      pancakeChart.style.width=window.innerWidth-300+'px';
      this.myPancakeChart = echarts.init(pancakeChart);
      this.$apis.dataCensusServer.queryIdentifyForPie (this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data) {
          this.pancakeOption.series[0].data = res.data.series;
          this.pancakeOption.title.text = res.data.statTime+"应用金标状态概况";
          this.myPancakeChart.setOption(this.pancakeOption);
          window.addEventListener('resize', () => {
            pancakeChart.style.width=window.innerWidth-300+'px';
            this.mypancakeChart.resize();
          })
        }
      })
    },


    //获取列表数据
    queryGoldList(){
      this.$apis.dataCensusServer.queryGoldList(this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total
          this.tableData = res.data.data
        } else {
          this.total = 0
          this.tableData = []
        }
      })
    },

    exportClick(){
      var data={
        startTime:"",
        endTime:"",
        type:'',
      }
      data.type = this.searchFormData.type;
      data.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0] : '';
      if(data.type == '3'){
        var date = new Date(this.searchFormData.rangDate[1]);
        data.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime()-1000;
      }else{
        data.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1] : '';
      }
      this.$apis.dataCensusServer.exportGold(data).then(res => {
        if (res['code'] == 0 && res['data']['link']) {
          let link = res.data.link;
          window.location.href = link;
        }
      });
    },

    handleSizeChange(val) {
        this.searchFormData.perPage = val
        this.queryGoldList()
    },
    handleCurrentChange(val) {
      this.searchFormData.page = val
      this.queryGoldList()
    }
  }
}
</script>

<style scoped lang="scss">
.goldBox{
  .titleBox{
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #E4E7ED;
  }
  .chartBox{
    .chart{
      margin-top: 20px;
      #goldBarChartBox{
        margin-top:30px;
        width: 100%;
        height: 500px;
      }
      #goldColumnarChartBox{
        margin-top:50px;
        width: 100%;
        height: 500px;
      }
      .pancakeChartBox{
        margin-top:30px;
        width: 100%;
        height: 500px;
        #goldPancakeChartBox{
          width: 100%;
          height: 350px;
          margin: 0 auto;
        }
        .summary{
          width: 140px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
        }
      }  
    }
  }
  .goldDataBox{
    margin-top: 70px;
    /deep/ .el-tabs__content{
      overflow: initial;
    }
    /deep/ .el-table__header th {
      padding: 0;
      height: 32px!important;
    }
  }
  .tableBox{
    margin-top: 20px;
  }
}
</style>
<style>
.el-table thead.is-group th {
  background: #28262C;
  border:none;
}
</style>