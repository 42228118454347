let apiBase = {
  queryList:'/api/banner/queryList',                  // 列表
  getInfo:'/api/banner/getInfo',                      //  详情
  addBanner:'/api/banner/addBanner',                  //  添加
  updateBanner:'/api/banner/updateBanner',            // 更新
  updateStateEnable:'/api/banner/updateStateEnable',  // 启用
  updateStateDisable:'/api/banner/updateStateDisable',     //  停用
  deleteBanner:'/api/banner/deleteBanner',            // 删除
}
let BannerServerObject = null
class BannerServer{
  constructor(){}
  queryList(data={}){
    return $http({
      url: apiBase.queryList,
      method: 'post',
      data: data,
    })
  }

  getInfo(data={}){
    return $http({
      url: apiBase.getInfo,
      method: 'post',
      data: data,
    })
  }

  addBanner(data={}){
	  return $http({
      method: 'post',
      url: apiBase.addBanner,
      data:data
    })
   }

  updateBanner(data={}){
    return $http({
      url: apiBase.updateBanner,
      method: 'post',
      data: data,
    })
  }

  updateStateEnable(data={}){
    return $http({
      url: apiBase.updateStateEnable,
      method: 'post',
      data: data,
    })
  }

  updateStateDisable(data={}){
    return $http({
      url: apiBase.updateStateDisable,
      method: 'post',
      data: data,
    })
  }

  deleteBanner(data={}){
    return $http({
      url: apiBase.deleteBanner,
      method: 'post',
      data: data,
    })
  }

}
function singleBannerServer(){
  if(BannerServerObject === null){
    BannerServerObject = new BannerServer()
  }
  return BannerServerObject
}
export default singleBannerServer()