import * as permission from '../common/auth-config'

let accountMenu=[
  {
    label: '开发者管理', value: permission.DEVELOP_MANAGE, type: 'menu', index:0,checked: false,chidren: [
      {
        label: '开发者管理', value: permission.DEVELOP_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.DEVELOP_ACTION_LIST, type: 'control', index:1,checked: false,},
          {label: '导出', value: permission.DEVELOP_ACTION_EXPORT, type: 'control', index:1,checked: false,},
          {label: '审核', value: permission.DEVELOP_ACTION_CHECK, type: 'control', index:1,checked: false,}
        ],
      },
      {
        label: '厂商账号绑定', value: permission.MANUFACTURER_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.MANUFACTURER_ACTION_LIST, type: 'control', index:1,checked: false,},
          {label: '导出', value: permission.MANUFACTURER_ACTION_EXPORT, type: 'control', index:1,checked: false,},
        ],
      },
    ]
  },
  {
    label: '金标管理', value: permission.GOLD_ACTION_MANAG, type: 'menu', index:0,checked: false,authorityId:1, chidren: [
      {
        label: '金标认证', value: permission.GOLD_ACTION_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '应用列表', value: permission.IDENTIFY_APK_ACTION_LIST, type: 'control', index:1,checked: false,},
          {label: '导出应用数据', value: permission.IDENTIFY_APK_ACTION_EXPORT, type: 'control', index:1,checked: false,},
          {label: '导出应用认证数据', value: permission.GOLD_ACTION_EXPORT_ADV, type: 'control', index:1,checked: false,},
          {label: '修改金标有效期', value: permission.IDENTIFY_APK_ACTION_UNDATE_EXPIRE_TIME, type: 'control', index:1,checked: false,},
          {label: '人工干预', value: permission.IDENTIFY_APK_ACTION_QUERY_INTERVENE_LIST, type: 'control', index:1,checked: false,},
          {label: '版本列表', value: permission.GOLD_ACTION_LIST, type: 'control', index:1,checked: false,}, 
          {label: '导出版本数据', value: permission.GOLD_ACTION_EXPORT, type: 'control', index:1,checked: false,},
        ],
      },
      {
        label: '申诉管理', value: permission.APPEAL_ACTION_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.APPEAL_ACTION_LIST, type: 'control', index:1,checked: false,},
          {label: '审核', value: permission.APPEAL_ACTION_REVIEW, type: 'control', index:1,checked: false,},
          {label: '查看', value: permission.APPEAL_ACTION_DETAIL, type: 'control', index:1,checked: false,},
          {label: '关闭', value: permission.APPEAL_ACTION_CLOSE, type: 'control', index:1,checked: false,},     
        ],
      },
      {
        label: '联盟检测', value: permission.ALLIANCE_DETECTION_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '任务列表', value: permission.TASK_ACTION_QUERYPAGE, type: 'control', index:1,checked: false,},
          {label: '添加任务', value: permission.TASK_ACTION_ADDTASK , type: 'control', index:1,checked: false,},
          {label: '测试报告接受名单', value: permission.TEST_REPORT_RECEIVING_LIST, type: 'control', index:1,checked: false,},
          {label: '添加人员', value: permission.TEST_REPORT_ADD_PEOPLE, type: 'control', index:1,checked: false,},
          {label: '删除人员', value: permission.TEST_REPORT_DELETE_PEOPLE, type: 'control', index:1,checked: false,},
          {label: '查看认证报告', value: permission.LOOK_TEST_REPORT, type: 'control', index:1,checked: false,},
          {label: '概况数据导出', value: permission.SURVEY_DATA_EXPORT, type: 'control', index:1,checked: false,},
          {label: '各标准数据导出', value: permission.STANDARD_DATA_EXPORT, type: 'control', index:1,checked: false,},
          {label: '测试明细数据导出', value: permission.TEST_DETAILS_DATA_EXPORT, type: 'control', index:1,checked: false,},
          {label: '厂商数据导出', value: permission.FIRM_DATA_EXPORT, type: 'control', index:1,checked: false,},
          {label: '测试明细/厂商查看报告', value: permission.TEST_DETAILS_FRIM_LOOK_REPORT, type: 'control', index:1,checked: false,},
          {label: '明细/厂商查看详情', value: permission.TEST_DETAILS_FRIM_LOOK_DETAILS, type: 'control', index:1,checked: false,},
          {label: '任务合并', value: permission.TASK_MERGE, type: 'control', index:1,checked: false,},
        ],
      },
      {
        label: '金标认证配置', value: permission.TERM_MANAGE_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '配置概要列表', value: permission.TERM_SUMMARY_LIST, type: 'control', index:1,checked: false,},
          {label: '标准配置列表', value: permission.TERM_STANDARD_LIST, type: 'control', index:1,checked: false,},
          {label: '更新配置', value: permission.TERM_ACTION_EDIT, type: 'control', index:1,checked: false,},
          {label: '编辑测试方法', value: permission.EDIT_TEST_METHOD, type: 'control', index:1,checked: false,},
        ],
      },
      {
        label: '联盟检测配置', value: permission.UNION_DEPLOY_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '配置概要列表', value: permission.UNION_SUMMARY_LIST, type: 'control', index:1,checked: false,},
          {label: '标准配置列表', value: permission.UNION_STANDARD_LIST, type: 'control', index:1,checked: false,},
          {label: '更新配置', value: permission.UNION_ACTION_UNDATE, type: 'control', index:1,checked: false,},
        ],
      },
    ]
  },
  {
    label: '运营管理', value: permission.OPERATE_MANAGE, type: 'menu', index:0,checked: false, authorityId:2,chidren: [
      {
        label: 'banner管理', value: permission.BANNER_ACTION_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.BANNER_ACTION_LIST, type: 'control', index:1,checked: false,},
          {label: '添加', value: permission.BANNER_ACTION_ADD, type: 'control', index:1,checked: false,},
          {label: '编辑', value: permission.BANNER_ACTION_EDIT, type: 'control', index:1,checked: false,},
          {label: '删除', value: permission.BANNER_ACTION_DELETE, type: 'control', index:1,checked: false,},
          {label: '开启/关闭', value: permission.BANNER_ACTION_SWITCH, type: 'control', index:1,checked: false,}
        ],
      },
      {
        label: '资讯管理', value: permission.NEWS_ACTION_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.NEWS_ACTION_LIST, type: 'control', index:1,checked: false,},
          {label: '增加', value: permission.NEWS_ACTION_ADD, type: 'control', index:1,checked: false,},
          {label: '删除', value: permission.NEWS_ACTION_DELETE, type: 'control', index:1,checked: false,},
          {label: '编辑', value: permission.NEWS_ACTION_EDIT, type: 'control', index:1,checked: false,},
          {label: '详情', value: permission.NEWS_ACTION_DETAIL, type: 'control', index:1,checked: false,},
          {label: '开启/关闭', value: permission.NEWS_ACTION_CONTROL, type: 'control', index:1,checked: false,},
          {label: '投票信息列表', value: permission.NEWS_ACTION_VOTE_LIST, type: 'control', index:1,checked: false,},
          {label: '投票明细列表', value: permission.NEWS_ACTION_VOTEINFOLIST, type: 'control', index:1,checked: false,},
          {label: '投票明细列表导出', value: permission.NEWS_ACTION_VOTEINFOLIST_EXPORT, type: 'control', index:1,checked: false,},
          {label: '报名信息列表', value: permission.NEWS_ACTION_REGISTERLIST, type: 'control', index:1,checked: false,},  
          {label: '报名信息导出', value: permission.NEWS_ACTION_REGISTE_EXPORT, type: 'control', index:1,checked: false,},  
        ],
      },
      {
        label: '数据统计', value: permission.DATA_STATISTICS_MENU , type: 'menu', index:1,checked: false, chidren:[
          {label: '列表', value: permission.STAT_ACCOUNT_LIST, type: 'control', index:1,checked: false,},
          {label: '开发者统计导出', value: permission.STAT_ACCOUNT_EXPORT, type: 'control', index:1,checked: false,},
          {label: '应用导出', value: permission.STAT_APK_EXPORT , type: 'control', index:1,checked: false,},
          {label: '金标认证数据导出', value: permission.STAT_IDENTIFY_EXPORT , type: 'control', index:1,checked: false,},
          {label: '认证时效数据导出', value: permission.STAT_TIME_EXPORT , type: 'control', index:1,checked: false,},
        ]
      },
      {
        label: '推荐文档', value: permission.RECOMMEND_TEXT_MENU , type: 'menu', index:1,checked: false, chidren:[
          {label: '推荐文档列表', value: permission.RECOMMEND_TEXT_LIST, type: 'control', index:1,checked: false,},
          {label: '添加', value: permission.RECOMMEND_TEXT_ADD, type: 'control', index:1,checked: false,},
          {label: '编辑', value: permission.RECOMMEND_TEXT_EDIT, type: 'control', index:1,checked: false,},
          {label: '开启/关闭', value: permission.RECOMMEND_TEXT_CONTROL, type: 'control', index:1,checked: false,},
          {label: '删除', value: permission.RECOMMEND_TEXT_DELETE, type: 'control', index:1,checked: false,},   
        ]
      },
      {
        label: '文档中心', value: permission.DOCUMENT_CENTER_MENU , type: 'menu', index:1,checked: false, chidren:[
          {label: '文档管理员', value: permission.DOCUMENT_CENTER_MANAGE, type: 'control', index:1,checked: false,},
          {label: '添加节点', value: permission.DOCUMENT_PANEL_ADD, type: 'control', index:1,checked: false,},
          {label: '编辑节点', value: permission.DOCUMENT_PANEL_EDIT, type: 'control', index:1,checked: false,},
          {label: '删除节点', value: permission.DOCUMENT_PANEL_DELETE, type: 'control', index:1,checked: false,},
        ]
      },
      {
        label: '公告管理菜单', value: permission.NOTICE_MANAGE_MENU , type: 'menu', index:1,checked: false, chidren:[
          {label: '列表', value: permission.NOTICE_MANAGE_LIST, type: 'control', index:1,checked: false,},
          {label: '添加', value: permission.NOTICE_MANAGE_ADD, type: 'control', index:1,checked: false,},
          {label: '编辑', value: permission.NOTICE_MANAGE_EDIT, type: 'control', index:1,checked: false,},
          {label: '删除', value: permission.NOTICE_MANAGE_DELETE, type: 'control', index:1,checked: false,},
          {label: '开启/关闭', value: permission.NOTICE_MANAGE_SWITCH, type: 'control', index:1,checked: false,}
        ]
      },
      {
        label: '联盟成员菜单', value: permission.MEMBER_MENU , type: 'menu', index:1,checked: false, chidren:[
          {label: '列表', value: permission.MEMBER_LIST, type: 'control', index:1,checked: false,},
          {label: '添加', value: permission.MEMBER_ADD, type: 'control', index:1,checked: false,},
          {label: '编辑', value: permission.MEMBER_EDIT, type: 'control', index:1,checked: false,},
          {label: '删除', value: permission.MEMBER_DELETE, type: 'control', index:1,checked: false,},
          {label: '开启/关闭', value: permission.MEMBER_SWITCH, type: 'control', index:1,checked: false,}
        ]
      },
    ]
  },
  {
    label: '基础功能', value: permission.BASIC_FUNCTIONS_MANAGE , type: 'menu', index:0,checked: false, authorityId:3,chidren: [
      {
        label: '用户管理', value: permission.USER_ACTION_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.USER_ACTION_LIST, type: 'control', index:1,checked: false,},
          {label: '添加', value: permission.USER_ACTION_ADD, type: 'control', index:1,checked: false,},
          {label: '编辑', value: permission.USER_ACTION_EDIT, type: 'control', index:1,checked: false,},
          {label: '重置', value: permission.USER_ACTION_RESET, type: 'control', index:1,checked: false,},
          {label: '删除', value: permission.USER_ACTION_DELETE, type: 'control', index:1,checked: false,}
        ],
      },
      {
        label: '邮件预警', value: permission.ALERT_ACTION_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.ALERT_ACTION_LIST, type: 'control', index:1,checked: false,},  
          {label: '增加', value: permission.ALERT_ACTION_ADD, type: 'control', index:1,checked: false,},
          {label: '删除', value: permission.ALERT_ACTION_DELETE, type: 'control', index:1,checked: false,}
        ],
      },
      {
        label: '权限节点配置', value: permission.AUTHORITY_DEPLOY_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.AUTHORITY_DEPLOY_LIST, type: 'control', index:1,checked: false,},
          {label: '添加', value: permission.AUTHORITY_DEPLOY_ADD, type: 'control', index:1,checked: false,},
          {label: '编辑', value: permission.AUTHORITY_DEPLOY_EDIT, type: 'control', index:1,checked: false,},
          {label: '删除', value: permission.AUTHORITY_DEPLOY_DELETE, type: 'control', index:1,checked: false,}
        ],
      },
      {
        label: '权限管理', value: permission.AUTHORITY_MANAGE_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.AUTHORITY_MANAGE_LIST, type: 'control', index:1,checked: false,},
          {label: '添加', value: permission.AUTHORITY_MANAGE_ADD, type: 'control', index:1,checked: false,},
          {label: '编辑', value: permission.AUTHORITY_MANAGE_EDIT, type: 'control', index:1,checked: false,},
          {label: '删除', value: permission.AUTHORITY_MANAGE_DELETE, type: 'control', index:1,checked: false,}
        ],
      },
      {
        label: '同步日志', value: permission.TOOL_ACTION_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.TOOL_ACTION_QUERYIDENTIFYSYNC, type: 'control', index:1,checked: false,},
          {label: '厂商状态查询', value: permission.TOOL_ACTION_QUERYAPKNEWVERSION, type: 'control', index:1,checked: false,},
          {label: '厂商状态重试', value: permission.TOOL_ACTION_SYNCAGAIN , type: 'control', index:1,checked: false,},
        ],
      },
      {
        label: '批量同步日志', value: permission.BATH_TOOL_ACTION_MENU, type: 'menu', index:1,checked: false, chidren: [
          {label: '列表', value: permission.BATH_TOOL_ACTION_QUERYIDENTIFYSYNC, type: 'control', index:1,checked: false,},
          {label: '厂商状态重试', value: permission.BATH_TOOL_ACTION_SYNCAGAIN , type: 'control', index:1,checked: false,},
        ],
      },
    ]
  },
]
export default accountMenu