const routes = [
    {
      path: '/authorityDeploy',
      name: '',
      redirect:'/authorityDeploy/list'
    },
    {
      path: '/authorityDeploy/list',
      name: '',
      meta:{module:'basic_functions_manage',menu:'/authorityDeploy/list',title:'权限配置'},//menu 用于所属右侧菜单栏
      component: () => import('@/views/authorityDeploy/list.vue')
    },
  ]
  
  export default routes;
  