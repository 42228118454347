<template>
  <div class="page">
    <wk-search
      class="wrapBox padding-b20"
      :search-control-data="searchControlData"
      @search="search"
    ></wk-search>
    <div class="wrapBox">
      <div class="margin-b10 total-box">
        <div style="line-height: 40px">共{{total}}条数据</div>
      </div>
      <el-table
        v-auth="permission.BATH_TOOL_ACTION_QUERYIDENTIFYSYNC"
        :data="tableData"
        class="tableBox W100"
        stripe>
        <el-table-column
          width="60"
          align="center"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="taskId"
          label="任务ID">
        </el-table-column>
        <el-table-column
          align="center"
          prop="apkPackage"
          label="包名">
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberName"
          label="厂商">
        </el-table-column>    
        <el-table-column
          align="center"
          prop="state"
          label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">同步中</span>
            <span v-if="scope.row.state == 2">成功</span>
            <span v-if="scope.row.state == 3">失败</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="type"
          label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">云测</span>
            <span v-if="scope.row.type == 2">重新云测</span>
            <span v-if="scope.row.type == 3">发放金标</span>
            <span v-if="scope.row.type == 4">不发放金标</span>
            <span v-if="scope.row.type == 5">金标失效</span>
            <span v-if="scope.row.type == 6">系统二次重测</span>
            <span v-if="scope.row.type == 7">开发者云测</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="param"
          label="参数"
          width="650"
          >
        </el-table-column>
        <el-table-column
          align="center"
          prop="result"
          label="同步结果"
          width="350"
          >
        </el-table-column>
        <el-table-column
          align="center"
          prop="syncCount"
          label="同步次数">
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="modifyTime"
          label="修改时间"
          width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.modifyTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="apkCount"
          label="操作"
          fixed="right">
          <template slot-scope="scope">
				    <el-button v-auth="permission.BATH_TOOL_ACTION_SYNCAGAIN" @click="onAgain(scope.row.id)">重试</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        total: 0,
        searchFormData: {
          page: 1,
          perPage: 10,
        },
        tableData: [],
        searchControlData: [
          {
            type: 'input',
            label: '任务id',
            placeholder: '请输入任务id',
            key: 'taskId'
          },
          {
            type: 'input',
            label: '包名',
            placeholder: '请输入包名',
            key: 'apkPackage'
          },
          {
            type: 'select',
            label: '厂商',
            placeholder: '请选择厂商',
            key: 'memberId',
            option: [
              {
                label: '全部',
                value: ''
              },
            ]
          },        
          {
            type: 'select',
            label: '状态',
            placeholder: '请选择状态',
            key: 'state',
            option: [
              {
                label: '全部',
                value: 0
              },
              {
                label: '同步中',
                value: 1
              },
              {
                label: '成功',
                value: 2
              },
              {
                label: '失败',
                value: 3
              },
            ],
          },
        ],
      }
    },
    computed: {
      memberList(){
        return this.$store.getters['accountStore/getMemberList']
      }
    },
    created() {
      this.search()
      this.searchControlData.forEach((item, index) => {
        if (item.key == 'memberId') {
          this.searchControlData[index].option = this.searchControlData[index].option.concat(this.memberList.map(item=>{
            return {label:item.label,value:item.id}
          }));
        }
      })
    },
    methods: {
      search(data) {
        if (data) {
          Object.assign(this.searchFormData, data)
          this.searchFormData.page = 1;
        }
        this.$apis.bathSyncServer.queryList(this.searchFormData).then(res => {
          if (res['code'] == 0 && res['data']['data']) {
            this.total = res.data.total;
            this.tableData = res.data.data;
          } else {
            this.total = 0;
            this.tableData = [];
          }
        })
      },
      onAgain(id) {
        this.$apis.bathSyncServer.queryAgain({
          id,
        }).then(res => {
          if (res['code'] == 0) {
            this.$message.success('成功');
          } else {
            this.$message.error(res.message);
          }
        })
      },
      // 表格页脚方法
      handleSizeChange(val) {
        this.searchFormData.perPage = val;
        this.search();
      },
      handleCurrentChange(val) {
        this.searchFormData.page = val;
        this.search();
      }
    }
  }
</script>
<style scoped lang="scss">
  /deep/ .el-table .cell{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .total-box{
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }
</style>