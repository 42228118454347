<template>
  <div class="page">
    <el-form :model="form" label-width="160px" class="infoFormBox" >
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="开发者类型" prop="type">
              <el-radio-group
                aria-label="开发者类型"
                aria-autocomplete="none"
                placeholder="请勾选开发者类型"
                type="text"
                v-model="form.type"
              >
                <el-radio class="margin-r40" :label="1">企业</el-radio>
                <!-- <el-radio class="margin-r40" :label="2">个人</el-radio> -->
                <el-radio class="margin-r40" :label="3">政府/事业单位</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <Enterprise v-if="form.type==1" :form="form"></Enterprise>
        <Government v-if="form.type==3" :form="form"></Government>
    </el-form>
  </div>
</template>

<script>
import Enterprise from "./template/Enterprise"; //企业
import Government from "./template/Government"; //政府
import store from '@/store/index.js';
export default {
  beforeRouteEnter: (to,from,next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:"编辑账号资料", path: to.fullPath} );
    next()
  },
   components: {
    Enterprise,
    Government,
  },
  data(){
    return{
      form: {
        type: '', //1 企业 2 个人 3 事业单位
        /*type=1：企业*/
        companyName: "", //企业名称
        licinseNumber: "", //营业执照号
        licinseNumberPhoto: "", //营业执照扫描件
        /*type=2：个人*/
        developId: "", //开发者身份证号
        developPositivePhoto: "", //开发者身份证正面
        developNegativePhoto: "", //开发者身份证反面
        developHoldPhoto: "", //开发者手持身份证
        /*type=3：政府/事业单位*/
        // companyName: "", // 与companyName共用key，利于搜索
        creditCode: "", //统一社会信用代码
        legalPersonCertificate: "", //法人证书
        corporationName: "", //法人姓名
        corporationCardName: "", //法人身份证
        legalPersonPositivePhoto: "", //法人身份证正面照片
        legalPersonNegativePhoto: "", //法人身份证反面照片
        legalPersonHoldPhotoType: "1", //是否有法人手持身份证：1 是 2 否
        legalPersonHoldPhoto: "", //法人手持身份证照片
        authorizePositivePhoto: "", //被授权人正面照片
        authorizeNegativePhoto: "", //被授权人反面照片
        authorizeHoldPhoto: "", //被授权人持身份证照片
        authorizeCertificate: "", //授权书
        /*共用*/
        contactName: "", //联系人姓名
        contactEmail: "", //联系人邮箱
        contactPhone: "", //联系人电话
        weixin: "", //微信
        qq: "", //QQ
      },
    }
  },
  created(){
    this.loadData();
  },
  methods:{
    loadData(){
      this.$apis.developServer.getInfo({
        id: this.$route.query.id
      }).then(res => {
        if (res['code'] == 0) {
          this.form = res.data
          // if(res.data.type == 2){
          //   this.imageData.push(res.data.developPositivePhoto)
          //   this.imageData.push(res.data.developNegativePhoto)
          //   this.imageData.push(res.data.developHoldPhoto)
          // } else if(res.data.type == 3){
          //   this.imageData.push(res.data.legalPersonPositivePhoto)
          //   this.imageData.push(res.data.legalPersonNegativePhoto)
          //   this.authorizePhotoData.push(res.data.authorizePositivePhoto)
          //   this.authorizePhotoData.push(res.data.authorizeNegativePhoto)
          //   this.authorizePhotoData.push(res.data.authorizeHoldPhoto)
          // }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  background-color: #fff;
  padding: 45px 100px;
  display: block;
  margin-top: 30px;
  border-radius: 10px;

}
</style>
<style lang="scss">
.infoFormBox {
  .submitBotton {
    width: 400px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: rgba(40, 38, 44, 1);
    box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
