const routes = [
  {
    path: '/news',
    name: '',
    redirect:'/news/list'
  },
  {
    path: '/news/list',
    name: '',
    meta:{
      module:'operate_manage',
      menu:'/news/list',
      title:'资讯管理',
      keepAlive: true,
      breadcrumb:['news'],
      menuBreadcrumb: 'news',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/news/list.vue'),
  },
  {
    path: '/news/info',
    name: '',
    meta:{
      module:'operate_manage',
      menu:'/news/list',
      title:'添加咨询管理',
      breadcrumb:['news','info'],
      menuBreadcrumb: 'info',
    },//menu 用于所属右侧菜单栏,breadcrumb用于面包屑
    component: () => import('@/views/news/info.vue'),
  },
  {
    path: '/news/registration',
    name: '',
    meta:{
      module:'operate_manage',
      menu:'/news/list',
      title:'报名信息',
      breadcrumb:['news','registration'],
      menuBreadcrumb: 'registration',
    },//menu 用于所属右侧菜单栏,breadcrumb用于面包屑
    component: () => import('@/views/news/registration.vue')
  },
  {
    path: '/news/vot',
    name: '',
    meta:{
      module:'operate_manage',
      menu:'/news/list',
      title:'投票信息',
      breadcrumb:['news','vot'],
      menuBreadcrumb: 'vot',
    },//menu 用于所属右侧菜单栏,breadcrumb用于面包屑
    component: () => import('@/views/news/vot/vot.vue')
  },
  {
    path: '/news/vot/detail',
    name: '投票明细',
    meta:{
      module:'operate_manage',
      menu:'/news/list',
      title:'投票明细',
      breadcrumb:['news','vot','detail'],
      menuBreadcrumb: 'detail',
    },//menu 用于所属右侧菜单栏,breadcrumb用于面包屑
    component: () => import('@/views/news/vot/detail.vue')
  }
]

export default routes;
