<template>
  <el-dialog
    :title="title"
    :visible="dialogVisible"
    width="900px"
    @close="closeModal"
    center>
    <el-form 
      class="form-box"
      label-width="140px" 
      ref="form" 
      :rules="ruleData"
      :model="formData">
      <el-form-item  label="节点名称" prop="title">
        <el-input class="expireDay-input" show-word-limit :maxlength="40" v-model.trim="formData.title" autocomplete="off"></el-input>
        <p class="grey" v-if="title!='添加一级节点'&& type!='edit'">上级节点：{{pidTitle}}</p>
        <p class="grey">新添加的节点默认未发布状态；只有该节点或子节点下有文档发布时，节点才会发布。</p>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
				<el-input
					placeholder="数字越大越靠前"
					v-model.trim="formData.sort">
				 </el-input>
			</el-form-item>
      <el-form-item v-if="title!='添加一级节点'" label="上级节点" prop="docMenuPid">
        <el-select v-model="formData.pidTitle" clearable placeholder="请选择" :popper-append-to-body="false">
					<el-option :value="formData.pid" class="setstyle">
						<el-tree :data="menuData" :props="defaultProps" ref="tree" :expand-on-click-node="false" :highlight-current="true" @node-click="handleNodeClick" default-expand-all>
              <div slot-scope="{ node,data}">
                <span :class="{grey:!data.isSupportUpdate}">{{ node.label }}</span>
              </div>
            </el-tree>
					</el-option> 
				</el-select>
        <p class="grey">清空时当前节点变更为一级节点，置灰节点为不可选择项</p>
      </el-form-item>
      <el-form-item label="设置审核权限" prop="reviewAdminIds">
        <div class="edit_dev">
          <el-transfer
            :titles="['账号', '审核权限']"
            filter-placeholder="请输入任务名字"
            v-model="formData.reviewAdminIds"
            :data="userProcePanelLis">
          </el-transfer>
        </div>
      </el-form-item>
      <el-form-item label="设置编辑权限" prop="editAdminIds">
        <div class="edit_dev">
          <el-transfer
            :titles="['账号', '编辑权限']"
            filter-placeholder="请输入任务名字"
            v-model="formData.editAdminIds"
            :data="userEditPanelLis">
          </el-transfer>
        </div>
      </el-form-item>
    </el-form>
    <div class="footer">
      <wk-button type="primary" class="exportButton" @click="onSubmit('form')">提交</wk-button>
      <wk-button class="exportButton" @click="closeModal()">取消</wk-button>
    </div>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      var validateSort = (rule, value, callback) => {
        if ( value < 0) {
          callback("请填写大于或等于0的正整数");
        } else if ( !(/^[0-9]+.?[0-9]*/.test(value))) {
          callback("请填写数字");
        } else {
          callback();
        }
      };
			return {
        prevPane:true,//上级节点框是否展示
        menuData: [],//导航列表
        defaultProps: {
          children: 'children',
          label: 'title',
          disabled: function(data, node) {
            // 禁用回收站选项
            if (data.isSupportUpdate == false) {
              return true
            }
          }
        },
        type:"",//判断是添加还是编辑
        upperId:"",//上级节点id
        pidTitle:"",//上级节点名字
        title:"",
        userEditPanelLis:[],//可编辑用户列表
        userProcePanelLis:[],//可审核用户列表
				dialogVisible:false,
        formData:{
          title:"",
          sort:"",
          pid:"",
          pidTitle:"",
          reviewAdminIds:[],//审核权限
          editAdminIds:[],//编辑权限
        },
        ruleData: {//角色表单校验
          title: [
            {required: true, message: '请填写节点名称', trigger: 'blur'},
            {min: 1, max: 40, message: '节点名字最多支持40个字', trigger: ['change', 'blur']},
          ],
          sort: [
					{required: true,validator: validateSort, trigger: ['change', 'blur']},
				],
          reviewAdminIds: [
            {required: true, message: '请设置人员审核权限', trigger: ['blur',]}
          ],
          editAdminIds: [
            {required: true, message: '请设置人员编辑权限', trigger: ['blur',]}
          ],
        },
			}
    },
    created(){
      
    },
    methods: {
      //获取文档导航列表
      getQueryTreeList(){
        this.$apis.documentServer.queryTreeList({
          id:this.upperId ? this.upperId : 0,
        }).then(res=>{
          if (res['code'] == 0){
            this.menuData = res.data;
            // 设置节点禁用
            this.loopMuduleTreeDisabled(this.menuData);
          }
        })
      },

      loopMuduleTreeDisabled(arr) {
        for(let i=0;i<arr.length;i++) {
          if (arr[i].isSupportUpdate == false) {
            this.$set(arr[i], 'disabled', true);
            return;
          }
          this.loopMuduleTreeDisabled(arr[i].children);
        }
      },
      //获取有查看文档权限的用户
      getUserPanelList(){
        this.$apis.documentServer.queryAddDefault({
          pid:this.upperId ? this.upperId : 0
        }).then(res=>{
          if (res['code'] == 0){
      
            let editList=[];
            let proceList=[]
            //设置可编辑用户列表
            for(var i=0;i<res.data.editSelectAdminList.length;i++){
              editList.push({
                label: res.data.editSelectAdminList[i].adminName,
                key: res.data.editSelectAdminList[i].adminId,
              })
            }
            this.userEditPanelLis=editList;
            //设置可审核用户列表
            for(var i=0;i<res.data.editSelectAdminList.length;i++){
             proceList.push({
                label: res.data.editSelectAdminList[i].adminName,
                key: res.data.editSelectAdminList[i].adminId,
              })
            }
            this.userProcePanelLis=proceList;
            //设置默认选中编辑用户
            if(res.data.editDefaultAdminList.length>0){
              for(var i=0;i<res.data.editDefaultAdminList.length;i++){
                this.formData.editAdminIds.push(res.data.editDefaultAdminList[i].adminId,)
              }
            }

            //设置默认选中审核用户
            if(res.data.reviewDefaultAdminList.length>0){
              for(var i=0;i<res.data.reviewDefaultAdminList.length;i++){
                this.formData.reviewAdminIds.push(res.data.reviewDefaultAdminList[i].adminId,)
              }
            }
          }
        })
      },

      onSubmit(formName){
        this.$refs[formName].validate((valid) => {
          if(valid){
            if(this.formData.pidTitle ==""){//如果没有上级标题就是一级节点
              this.formData.pid=0
            }
            if(this.type=="add"){
              this.$apis.documentServer.addDocMenu({
                title:this.formData.title,
                sort:this.formData.sort,
                pid:this.formData.pid ? this.formData.pid : 0,
                editAdminIds:this.formData.editAdminIds.join(','),
                reviewAdminIds:this.formData.reviewAdminIds.join(','),
              }).then(res=>{
                if (res.code == 0) {
                  this.$emit('search', {})
                  this.closeModal();
                  this.$message({
                    type: 'success',
                    message: '添加成功!'
                  });
                }
              })
            }else {
               this.$apis.documentServer.updateDocMenu({
                title:this.formData.title,
                sort:this.formData.sort,
                docMenuPid:this.formData.pid,
                editAdminIds:this.formData.editAdminIds.join(','),
                reviewAdminIds:this.formData.reviewAdminIds.join(','),
                id:this.upperId
              }).then(res=>{
                if (res.code == 0) {
                  this.$emit('search', {})
                  this.closeModal();
                  this.$message({
                    type: 'success',
                    message: '更新成功!'
                  });
                }
              })
            } 
          }       
        })
      },

      //编辑默认选中已有权限的账号
      queryDocMenuDetail(){
        this.$apis.documentServer.queryDocMenuDetail({
          id:this.upperId 
        }).then(res=>{
          if (res['code'] == 0){
            this.formData.title=res.data.title;
            this.formData.sort=res.data.sort;
            this.formData.pid=res.data.pid;
            this.formData.pidTitle=res.data.pidTitle;
          }
        })
      },
    
      //树形选择文档
      handleNodeClick(data, self, child) {
        if(!data.isSupportUpdate==false){
          this.formData.pidTitle = data.title;//展示部分
          this.formData.pid = data.id;//传参---id
          // this.$message.error('当前节点不能作为此节点的子节点');
        } 
      },

      show(val,data) {
        this.type=val;
        if(val=="add"){
          if(!data){
            this.prevPane=false;
            this.title="添加一级节点"; 
          }else if(data.level==1){
            this.pidTitle=data.title
            this.upperId=data.id;
            this.formData.pidTitle=data.title
            this.formData.pid=data.id;
            this.title="添加二级节点";
          }else{
            this.pidTitle=data.title
            this.upperId=data.id;
            this.formData.pidTitle=data.title
            this.formData.pid=data.id;
            this.title="添加三级节点";
          }
          this.getUserPanelList();
          this.dialogVisible = true;
          
        }
        if(val=="edit"){
          this.upperId=data.id;
          if(data.level==1){
            this.title="编辑一级节点";
          }else if(data.level==2){
            this.title="编辑二级节点";
          }else{
            this.title="编辑三级节点";
          }
          this.dialogVisible = true;
          this.queryDocMenuDetail();
          this.getUserPanelList();
        }
        this.getQueryTreeList();
      },
      closeModal() {
        this.dialogVisible = false;
        this.showLoading = false;
        this.prevPane = true;
        this.upperId="";
        this.formData.pid="";
        this.formData.pidTitle="";
        this.resetForm();
      },
      resetForm() {
        this.$refs['form'].resetFields();
      },
    }
  }
</script>

<style lang="scss" scoped>
.form-box{
  padding-left: 20px;
  padding-right: 77px;
  .setstyle {
    min-height: 200px;
    padding: 0 !important;
    margin: 0;
    overflow: auto;
    cursor: default !important;
  }
}
.footer{
  margin-top: 20px;
  text-align: center;
  .exportButton{
    width: 150px;
    margin: 0 22px;
    letter-spacing: 6px !important;
  }
}
</style>
