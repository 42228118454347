const routes = [
  {
    path: '/document',
    name: '',
    redirect:'/document/list'
  },
  // {
  //   path: '/document/index',
  //   name: '',
  //   meta:{module:'operate_manage',menu:'/document/index',title:'文档中心',keepAlive: true,},//menu 用于所属右侧菜单栏
  //   component: () => import('@/views/document/index.vue')
  // },
  {
    path: '/document/list',
    name: '',
    meta:{module:'operate_manage',menu:'/document/list',title:'文档中心',keepAlive: true, breadcrumb:['document'],menuBreadcrumb: 'document',},//menu 用于所属右侧菜单栏
    component: () => import('@/views/document/index.vue')
  },
  {
    path: '/document/navManage',
    name: '',
    meta:{module:'operate_manage',menu:'/document/list',title:'导航管理',breadcrumb:['document','navManage'],menuBreadcrumb: 'navManage',},//menu 用于所属右侧菜单栏
    component: () => import('@/views/document/navManage.vue')
  },
  {
    path: '/document/detail',
    name: '',
    meta:{module:'operate_manage',menu:'/document/list',title:'文档详情'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/document/template/detail.vue')
  },
  {
    path: '/document/add',
    name: '',
    meta:{module:'operate_manage',menu:'/document/list',title:'新增文档',breadcrumb:['document','add'],menuBreadcrumb: 'add',},//menu 用于所属右侧菜单栏
    component: () => import('@/views/document/template/add.vue')
  },
  {
    path: '/document/edit',
    name: '',
    meta:{module:'operate_manage',menu:'/document/list',title:'编辑文档',breadcrumb:['document','edit'],menuBreadcrumb: 'edit',},//menu 用于所属右侧菜单栏
    component: () => import('@/views/document/template/edit.vue')
  },
  {
    path: '/document/record',
    name: '',
    meta:{module:'operate_manage',menu:'/document/list',title:'文档记录'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/document/template/record.vue')
  },
  {
    path: '/document/audit',
    name: '',
    meta:{module:'operate_manage',menu:'/document/list',title:'文档审核',breadcrumb:['document','audit'],menuBreadcrumb: 'audit',},//menu 用于所属右侧菜单栏
    component: () => import('@/views/document/template/audit.vue')
  },
  {
    path: '/document/contrast',
    name: '',
    meta:{module:'operate_manage',menu:'/document/list',title:'文档对比'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/document/template/contrast.vue')
  },
  {
    path: '/document/update',
    name: '',
    meta:{module:'operate_manage',menu:'/document/list',title:'文档更新',breadcrumb:['document','update'],menuBreadcrumb: 'update',},//menu 用于所属右侧菜单栏
    component: () => import('@/views/document/template/update.vue')
  },
]
  
  export default routes;
    