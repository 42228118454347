let apiBase = {
  queryList: '/api/identifyApkManage/queryList', // 应用列表
  exportApk: '/api/identifyApkManage/export', // 应用导出
  queryVersionList: '/api/identifyManage/queryList', // 版本列表
  exportVersion: '/api/identifyManage/export', // 版本导出
  queryDetail: '/api/identifyApkManage/queryDetail', //  详情
  updateExpireTime: '/api/identifyApk/updateExpireTime', //  更改金标有效期
  exportAdv: '/api/identifyManage/exportAdv', //所有版本认证导出
  queryInterveneList: '/api/identifyApk/queryInterveneList',//人工干预列表
  queryAppDetail: '/api/identifyManage/queryDetail',//应用认证信息
  queryApkDeviceList:'/api/identifyManage/queryApkDeviceList',//应用测试机型列表
  queryReport: '/api/identifyManage/queryReport',//各标准报告
  queryTermDetail:'/api/identifyManage/queryTermDetail',//各标准测试项详情
  queryFailTermDetail:'/api/identifyManage/queryFailTermDetail',//未通过项详情
}
let singleServerObject = null
class GoldStandardServer {
  constructor() {}
  queryList(data = {}) {
    return $http({
      url: apiBase.queryList,
      method: 'post',
      data: data,
    })
  }
  queryVersionList(data = {}) {
    return $http({
      url: apiBase.queryVersionList,
      method: 'post',
      data: data,
    })
  }
  queryDetail(data = {}) {
    return $http({
      url: apiBase.queryDetail,
      method: 'post',
      data: data,
    })
  }

  updateExpireTime(data = {}) {
    return $http({
      method: 'post',
      url: apiBase.updateExpireTime,
      data: data
    })
  }

  exportApk(data = {}) {
    return $http({
      url: apiBase.exportApk,
      method: 'post',
      data: data,
      timeout: 0
    })
  }
  exportVersion(data = {}) {
    return $http({
      url: apiBase.exportVersion,
      method: 'post',
      data: data,
    })
  }
  exportAdv(data = {}) {
    return $http({
      url: apiBase.exportAdv,
      method: 'post',
      data: data,
      timeout: 0
    })
  }
  queryInterveneList(data = {}) {
    return $http({
      url: apiBase.queryInterveneList,
      method: 'post',
      data: data,
    })
  }
  queryAppDetail(data = {}) {
    return $http({
      url: apiBase.queryAppDetail,
      method: 'post',
      data: data,
    })
  }
  queryApkDeviceList(data = {}) {
    return $http({
      url: apiBase.queryApkDeviceList,
      method: 'post',
      data: data,
    })
  }
  queryReport(data = {}) {
    return $http({
      url: apiBase.queryReport,
      method: 'post',
      data: data,
    })
  }
  queryTermDetail(data = {}) {
    return $http({
      url: apiBase.queryTermDetail,
      method: 'post',
      data: data,
    })
  }
  queryFailTermDetail(data = {}) {
    return $http({
      url: apiBase.queryFailTermDetail,
      method: 'post',
      data: data,
    })
  }
}

function singleServer() {
  if (singleServerObject === null) {
    singleServerObject = new GoldStandardServer()
  }
  return singleServerObject
}
export default singleServer()