<template>
  <el-dialog
    title="任务合并"
    :visible="taskMergeModal"
    width="1150px"
    @close="closeModal()"
    :close-on-click-modal="false"
    center
  >
    <el-form 
      label-width="140px" 
      ref="form" 
      :rules="ruleData"
      :model="formData">
      <el-form-item  label="新任务名称" prop="name">
        <el-input class="expireDay-input" :maxlength="20" v-model.trim="formData.name" autocomplete="off" placeholder="请输入新任务名称，最多20个字"></el-input>
      </el-form-item>
      <el-form-item label="选择合并到的任务" prop="newId">
        <el-select class="expireDay-input" v-model="formData.newId" filterable @change="getTaskList()" placeholder="请选择">
          <el-option
            v-for="item in mergeList"
            :key="item.id"
            :label="item.name+'+'+item.id"  
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择被合并的任务" prop="oldId">
        <div class="edit_dev">
          <el-transfer
            filterable
            :titles="['所有任务', '合并任务']"
            filter-placeholder="请输入任务名字"
            v-model="formData.oldId"
            :data="beMergedList">
          </el-transfer>
        </div>
      </el-form-item>
    </el-form>
    <div class="footer">
        <wk-button type="primary" class="exportButton" @click="onSubmit('form')">提&nbsp;&nbsp;交</wk-button>
        <wk-button class="exportButton" @click="closeModal()">取&nbsp;&nbsp;消</wk-button>
      </div>
  </el-dialog>
</template>

<script>
export default {
   props: {
    taskMergeModal: Boolean,
  },
  data(){
 
    return{
      mergeList:[],//合并数组
      beMergedList:[],//被合并的数组
      formData:{
        name:"",
        newId:"",
        oldId:[],
      },
      ruleData: {//角色表单校验
        name: [
          {required: true, message: '请填写新任务名称', trigger: 'blur'},
        ],
        newId: [
          {required: true, message: '请选择任务', trigger: ['blur', 'change']}
        ],
        oldId: [
          {required: true, message: '请选择需要合并的任务', trigger: ['blur',]}
        ],
      },
    }
  },
  created(){
    this.getTaskList();
  },
  methods:{

    getTaskList(){
      this.formData.oldId = [];
      this.$apis.unionAttestServer.queryListTask(
        {id:this.formData.newId ? this.formData.newId:""}
      ).then(res=>{
        if(res['code'] == 0){
          this.mergeList = res.data;
          if(this.formData.newId){
            this.getMergedList(this.mergeList);
          }  
        }       
      })
    },
    getMergedList(item){
      let data = [];
      for(var i=0;i<item.length;i++){
          data.push({
          label: item[i].name+"+"+item[i].id,
          key: item[i].id
        })
      }
      this.beMergedList = data
    },
     // 关闭弹框
    closeModal() {
      this.$emit("closeTaskModal", false);
    },
    onSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if(valid){
          this.$apis.unionAttestServer.taskMerge(this.formData).then(res=>{
            if (res.code == 0) {
              this.closeModal();
            }
          })
        }else {
          return false;
        }        
      })
    }
  }
};
</script>

<style scoped lang="scss">
.expireDay-input{
  width: 350px;
}
.footer{
  margin-top: 30px;
  text-align: center;
  .exportButton{
    width: 150px;
    margin: 0 22px;
    // letter-spacing: 6px !important;
  }
}
</style>
<style scoped>
.edit_dev >>> .el-transfer-panel {
  width:370px;
}
</style>

