let apiBase = {
	queryPage:'/api/docRecommend/queryPage',                  // 列表
	queryDetail:'/api/docRecommend/queryDetail',                      //  详情
	addDoc:'/api/docRecommend/add',                  //  添加
	updateDoc:'/api/docRecommend/update',            // 更新
	updateStateEnable:'/api/docRecommend/updateStateEnable',  // 启用
	updateStateDisable:'/api/docRecommend/updateStateDisable',     //  停用
	deleteDoc:'/api/docRecommend/delete',            // 删除
  queryPublishTreeList: '/api/docMenu/queryPublishTreeList',//文档选择
}
  let RecommendTextServerObject = null
  class RecommendTextServer{
    constructor(){}
    queryPage(data={}){
      return $http({
        url: apiBase.queryPage,
        method: 'post',
        data: data,
      })
    }
  
    queryDetail(data={}){
      return $http({
        url: apiBase.queryDetail,
        method: 'post',
        data: data,
      })
    }
  
    addDoc(data={}){
        return $http({
        method: 'post',
        url: apiBase.addDoc,
        data:data
      })
     }
  
    updateDoc(data={}){
      return $http({
        url: apiBase.updateDoc,
        method: 'post',
        data: data,
      })
    }
  
    updateStateEnable(data={}){
      return $http({
        url: apiBase.updateStateEnable,
        method: 'post',
        data: data,
      })
    }
  
    updateStateDisable(data={}){
      return $http({
        url: apiBase.updateStateDisable,
        method: 'post',
        data: data,
      })
    }
  
    deleteDoc(data={}){
      return $http({
        url: apiBase.deleteDoc,
        method: 'post',
        data: data,
      })
    }
    
    queryPublishTreeList(data={}){
      return $http({
        url: apiBase.queryPublishTreeList,
        method: 'post',
        data: data,
      })
    }

  }
  function singleRecommendTextServer(){
    if(RecommendTextServerObject === null){
      RecommendTextServerObject = new RecommendTextServer()
    }
    return RecommendTextServerObject
  }
  export default singleRecommendTextServer()