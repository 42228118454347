<template>
  <div class="testBox">
    <div class="export">
      <el-button v-auth="permission.SURVEY_DATA_EXPORT" class="export-button" type="primary" @click="exportData()" :loading="buttonLoading">
        <span v-if="!buttonLoading">导出数据</span> 
        <span v-else>正在导出</span> 
      </el-button>
    </div>
    <div class="glodTestBox">
      <h1 class="title">应用金标测试情况</h1>
      <div class="glodChartBox">
        <div class="pancakeBox">
          <div id="pancake">
          </div>
          <div class="summary">
            <p>认证应用{{pancakTitleList.apkCount}}个</p>
            <p>认证未通过{{pancakTitleList.failCount}}%</p>
            <p>认证通过{{pancakTitleList.successCount}}%</p>
            <p>认证中{{pancakTitleList.ingCount}}%</p>
          </div>
        </div>
        <div class="columnBox">
          
          <div id="column">
          </div>
          <div>
            <el-table
            :header-cell-style="{'text-align': 'center',}"
            :cell-style="{ 'text-align': 'center' }"
            class="tableBox2"
            :data="appGoldList"
            >
            <el-table-column label="标准" prop="standardName"></el-table-column>
            <el-table-column label="测试通过" prop="standardSuccess"></el-table-column>
            <el-table-column label="测试通过率" prop="standardSuccessRate">
              <template slot-scope="scope">
                {{scope.row.standardSuccessRate}}%
              </template>
            </el-table-column>
            </el-table>
          </div>
        </div> 
      </div>
    </div>

    <div class="cloudSurveyBox">
      <h1 class="title">各云测情况</h1>
      <div class="cloudChartBox">
        <div>
          <div id="cloudChart">
     
          </div>
          <div class="tips">计算说明：通过数量/认证任务总应用数</div>
        </div>
        
        <div>
          <el-table
            :header-cell-style="{'text-align': 'center',}"
            :cell-style="{ 'text-align': 'center',padding:'0px' }"
            class="tableBox2"
            :span-method="objectSpanMethod"
            :data="couldGoldList"
            >
            <el-table-column label="标准" prop="title"></el-table-column>
            <el-table-column label="厂商" prop="memberName"></el-table-column>
            <el-table-column label="测试通过" prop="standardPassCount"></el-table-column>
            <el-table-column label="测试通过率" prop="standardPassRate">
              <template slot-scope="scope">
                {{scope.row.standardPassRate}}%
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      
    </div>

    <div class="modelTestBox">
      <h1 class="title">机型测试概况</h1>
      <div class="modelList">
        <div class="searchBox">
          <div class="searchItem">
            测试方：
            <el-select v-model="model.memberId" @change="onMemberIdChange" placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option
              v-for="(item,index) in memberList"
              :key="index"
              :label="item.name"
              :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="searchItem">
            机型：
            <el-select v-model="model.device" @change="onDeviceChange" placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(item,index) in deviceList"
                :key="index"
                :label="item"
                :value="item">
            </el-option>
          </el-select> 
          </div>      
        </div>
        <el-table
          :header-cell-style="{'text-align': 'center', }"
          :cell-style="{ 'text-align': 'center' }"
          class="tableBox W100"
          :data="modelList"
          stripe>
            <el-table-column label="测试方" prop="memberName"></el-table-column>
            <el-table-column label="测试机型" prop="device"></el-table-column>
            <!-- <el-table-column label="android版本" prop="androidVersion"></el-table-column> -->
            <el-table-column label="测试通过" prop="apkCount"></el-table-column>
            <el-table-column label="兼容标准" prop="compatibleCount"></el-table-column>
            <el-table-column label="稳定标准" prop="stableCount"></el-table-column>
            <!-- <el-table-column label="安全标准" prop="securityCount"></el-table-column> -->
            <el-table-column label="功耗标准" prop="consumptionCount"></el-table-column>
            <el-table-column label="性能标准" prop="performanceCount"></el-table-column>
            <el-table-column label="大屏标准" prop="largeCount"></el-table-column>
        </el-table>
        <div class="text-right margin-t30">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="model.perPage"
            :current-page="model.page"
            layout="total, prev, pager, next, sizes"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  data(){
    return{
      buttonLoading:false,
      taskId:this.$route.query.id,
      myPancakeChart:"",//应用金标测试饼状图
      myColumnChart:"",//应用金标测试柱状图
      cloudChart:"",//各云测概况图表
      pancakTitleList:{},//饼状图各项通过数据
      model:{//机型测试情况筛选
        memberId:"",
        device:"",
        page:1,
        perPage:10,
      },
      total:0,
      modelList:[],//机型结果列表
      deviceList:[],//机型列表
      appGoldList:[],//应用金标整体测试情况列表
      memberList: [],//厂商列表
      couldGoldList:[],//各云测概况列表
      pancakeOption: {//饼状图数据
        title: {
          text: "应用金标测试通过率",
          textStyle:{
            color:"#28262C",
          },
          left:"center",
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: "pie",
            data: [],
            label:{
              formatter:(arg=>{
                return arg.name + arg.value
              })
            },
            radius:110
          },
        ],
        color: ['#A1855F', '#CDBC74',"#dba245"],     
      },
      columnOption:{
        title: {
          text: "各标准测试概况",
          textStyle:{
            color:"#28262C",
          },
          left:"center",
        },
        xAxis:{
          type:"category",
          data:[]
        },
        yAxis:{
          type:"value",
        },
        series:[
          {
            type:"bar",
            data:[],
            barWidth:20,
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              formatter: '{c}%',//显示百分号
            }
          }
        ],
        color: ['#CCBC74' ],    
      },
      cloudOption:{
        // title: {
        //   text: "各云测概况",
        //   textStyle:{
        //     color:"#28262C",
        //   },
        //   left:"left",
        // },
        // tooltip: {
        //   trigger: 'axis',
        //   formatter:'{c}%',
        //   axisPointer: {
        //     type: 'shadow'
        //   }
        // },
        legend: {
          data: [],
          top:10,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value',
        },
        barWidth:20,
        series: [
          {
            name: '',
            type: 'bar',
            data: [],
            barGap:0.5,
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#cdbc76'
              }
            },
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              formatter: '{c}%',//显示百分号
              // textStyle: { //数值样式
              //   color: 'black',//字体颜色
              //   fontSize: 5//字体大小
              // }
            }
          },
          {
            name: '',
            type: 'bar',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#a1855e'
              }
            },
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              formatter: '{c}%',//显示百分号
            }
          },
          {
            name: '',
            type: 'bar',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#dba245'
              }
            },
            label: {
              show: true, //开启显示
              position: 'top', //在上方显示
              formatter: '{c}%',//显示百分号  
            }
          },
        ], 
      },
    }
  },
  mounted(){
    this.setPancake();
    this.setCloudChart();
    this.queryDeviceList();
    this.getModelList();
    this.getMemberList();
  },
  methods:{
    
    //应用金标测试情况
    setPancake() {
      this.myPancakeChart = echarts.init(document.getElementById("pancake"));
      this.myColumnChart = echarts.init(document.getElementById("column"));
      this.$apis.unionAttestServer.queryTestReport({taskId:this.taskId}).then(res=>{
        if(res['code'] == 0) {
          this.pancakeOption.series[0].data = res.data.pie;//设置饼图数据
          this.pancakTitleList = res.data.title;
          this.columnOption.xAxis.data = res.data.bar.title;
          this.columnOption.series[0].data = res.data.bar.value;
          this.appGoldList = res.data.reportList;
          this.myPancakeChart.setOption(this.pancakeOption);
          this.myColumnChart.setOption(this.columnOption);
        }
      })   
    },

    //各云测情况
    setCloudChart(){
      this.cloudChart = echarts.init(document.getElementById("cloudChart"));
      this.$apis.unionAttestServer.queryTestMemeberStandard({taskId:this.taskId}).then(res=>{
        if(res['code']==0){
          this.cloudOption.legend.data=res.data.bar.legend;
          this.cloudOption.xAxis.data=res.data.bar.xAxis;        
          for(let key in res.data.bar.series){
            this.cloudOption.series[key]["name"]=res.data.bar.series[key]["name"];
            this.cloudOption.series[key]["data"]=res.data.bar.series[key]["data"];
          }
          this.cloudChart.setOption(this.cloudOption);
          this.couldGoldList=res.data.standardList
        }
      })
    },

    //机型测试概况改变测试方时
    onMemberIdChange(val){
      this.model.memberId=val;
      this.model.device="";
      this.model.page = 1;
      this.getModelList();
      this.queryDeviceList();
    },  

    //机型测试概况改变机型时
    onDeviceChange(val){
      this.model.device=val;
      this.model.page = 1;
      this.getModelList()
    },

    //获取机型列表
    queryDeviceList(){
      this.$apis.unionAttestServer.queryDeviceList({taskId:this.taskId,memberId:this.model.memberId}).then(res=>{
         if (res['code'] == 0 && res.data.deviceList) {
          this.deviceList = res.data.deviceList;
        }
      })
    },

    //获取机型测试表格数据
    getModelList(){
      this.$apis.unionAttestServer.queryMemberDeviceList({taskId:this.taskId,...this.model}).then(res=>{
         if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total;
          this.modelList = res.data.data;
        } else {
          this.total = 0;
          this.modelList = [];
        }
      })
    },

    //获取厂商列表
    getMemberList(){
      this.$apis.unionAttestServer.enableMemberList().then(res=>{
         if (res['code'] == 0 && res.data) {
          this.memberList = res.data;
        }
      })
    },
    

    //table点击更改一页多少行
    handleSizeChange(val) {
      this.model.perPage = val;
      this.getModelList()
    },
    //table点击修改页码
    handleCurrentChange(val) {
      this.model.page = val;
      this.getModelList()
    },
    
    mergeCol(id, rowIndex) { // 合并单元格
    // id：属性名
    // rowIndex：行索引值
      var idName = this.couldGoldList[rowIndex][id] // 获取当前单元格的值
      if (rowIndex > 0) { // 判断是不是第一行
        // eslint-disable-next-line eqeqeq
        if (this.couldGoldList[rowIndex][id] != this.couldGoldList[rowIndex - 1][id]) { // 先判断当前单元格的值是不是和上一行的值相等
          var i = rowIndex; var num = 0 // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
          while (i < this.couldGoldList.length) { // 当索引值小于table的数组长度时，循环执行
            if (this.couldGoldList[i][id] === idName) { // 判断循环的单元格的值是不是和当前行的值相等
              i++ // 如果相等，则索引值加1
              num++ // 合并的num计数加1
            } else {
              i = this.couldGoldList.length // 如果不相等，将索引值设置为table的数组长度，跳出循环
            }
          }
          return {
            rowspan: num, // 最终将合并的行数返回
            colspan: 1
          }
        } else {
          return {
            rowspan: 0, // 如果相等，则将rowspan设置为0
            colspan: 1
          }
        }
      } else { // 如果是第一行，则直接返回
        let i = rowIndex; let num = 0
        while (i < this.couldGoldList.length) { // 当索引值小于table的数组长度时，循环执行
          if (this.couldGoldList[i][id] === idName) {
            i++
            num++
          } else {
            i = this.couldGoldList.length
          }
        }
        return {
          rowspan: num,
          colspan: 1
        }
      }
    },

    // 这个方法是 element-ui提供的单元格合并方法
    // objectSpanMethod 传入了 { row, column, rowIndex, columnIndex }
    // row: 当前行
    // column: 当前列
    // rowIndex：当前行号
    // columnIndex ：当前列号
    // 1代表：独占一行
    // 更大的自然数：代表合并了若干行
    // 0：代表“消失”的哪那一个单元格，后面的单元格向前推一格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) { // 合并单元格
      switch (columnIndex) { // 将列索引作为判断值
      // 通过传递不同的列索引和需要合并的属性名，可以实现不同列的合并
        case 0:
          return this.mergeCol('title', rowIndex)
    
      }
    },

    //导出数据
    exportData(){
      this.buttonLoading = true;
      this.$apis.unionAttestServer.taskIdentifyChartExport({taskId:this.$route.query.id,}).then(res => {
        if (res['code'] == 0 && res['data']['link']) {
          this.buttonLoading = false;
          window.location.href = res.data.link;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">

  /deep/ .el-table .cell{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

.testBox{
  padding: 10px;
  height: 100%;
  .export{
    float: right;
    .export-button{
      position: absolute;
      right: 0;
      top: -60px;
      z-index: 2;
   }
  }
  .title{
      margin-bottom: 20px;
      font-size: 20px;
      color: #28262C;
    }
  .glodTestBox{ 
    margin-top: 10px;
    .glodChartBox{
      padding: 30px 10px;
      background: #FAFAFA;
      .pancakeBox{
        width: 650px;
        height: 467px;
        // padding-right: 200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        #pancake{
          margin: 0 auto;
          width:500px;
          height: 380px;
        }
        .summary{
          text-align: left;
          p{
            margin-bottom: 5px;
          }
        }
      }
      .columnBox{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        #column{
          width: 700px;
          height: 467px;
        }
        .tableBox2{
          margin-left: 110px;
          width: 520px;
          height: auto;
        }
      } 
    }
  }
  .cloudSurveyBox{
    margin-top: 50px;
    .cloudChartBox{
      width: 100%;
      display: flex;
      justify-content: space-around;
      #cloudChart{
        width: 800px;
        height: 490px;
      }
      .tableBox2{
        width: 520px;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        border-radius: 5px; 
        td{
          border-right: 1px solid #EBEEF5!important;
        }
      }
    }
    .tips{
      margin-top: 10px;
      margin-left: 20px;
      color: #666;
      font-size: 16px;
    }
  }

  .modelTestBox{
    margin-top: 50px;
    .modelList{
      .searchBox{
        margin: 30px 0;
        display: flex;
        .searchItem{
          margin-right: 40px;
        }
      }
    }
  }
  
}

</style>
