import modulesFiles from './template';
// 列1 确认框
// this.$dialogs
//   .confirm({
//     content: "确定删除用户组管理员？",
//   })
//   .then(() => {})
//   .catch((val) => {});

// 列2 弹出框
// this.$dialogs
//   .alert({
//     title:'日志详情',
//     align:'right',
//     content: "日志：AndroidManifest xml文件中少android allowBackup属性或属性 android allowBackup=,可以被用户导出应用中所有数据，适数据世透。下载日志文件 ",
//   })
//   .then(async (data) => {})
//   .catch((val) => {});

// 列3 自定义组件弹出确定框
// this.$dialogs
//   .custom({
//     title:'添加联系人',
//     contentSlot:companyInfo,
//     contentData:{}
//   })
//   .then(async (data) => {})
//   .catch((val) => {);

const MessageBox = {
  install: function (Vue, options) {
    Vue.prototype.$dialogs={};
    for(let name in modulesFiles){
      let instance = Vue.extend(modulesFiles[name]);
      Vue.prototype.$dialogs[name] = 
        (options)=> {
          let newInstance = new instance();
          if (typeof options === 'object') {
            Object.assign(newInstance, options);
            let a = newInstance.$mount().$el;
            document.body.appendChild(a);
          }
          return new Promise((resolve, reject) =>{
            newInstance.show()
              .then(val => {
                newInstance = null;
                resolve(val);
              })
              .catch(err => {
                newInstance = null;
                reject(err);
              });
          })
        };
    }
  }
};
export default MessageBox;