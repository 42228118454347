const routes = [
  {
    path: '/appeal',
    name: '',
    redirect:'/appeal/list'
  },
  {
    path: '/appeal/list',
    name: '',
    meta:{module:'gold_action_manage',menu:'/appeal/list',title:'申诉管理'},
    component: () => import('@/views/appeal/list.vue')
  },
]

export default routes;
