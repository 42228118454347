const routes = [
  {
    path: '/authority',
    name: '',
    redirect:'/authority/list'
  },
  {
    path: '/authority/list',
    name: '',
    meta:{module:'basic_functions_manage',menu:'/authority/list',title:'权限管理'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/authority/list.vue')
  },
]

export default routes;
