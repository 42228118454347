let apiBase = {
  querySummary:'/api/term/querySummary',//查询配置概要
  queryTermList:'/api/term/queryTermList',//查询配置列表
  updateSetting:'/api/term/updateSetting',//更新配置
  editTestMethod:'/api/term/editTestMethod',//更新测试方法
  queryStandardRuleList:'/api/term/queryStandardRuleList',//标准规则列表
  queryStandardList:'/api/term/queryStandardList',//标准列表
}
  let goldDeployServerObject = null
  class GoldDeployServer {
    constructor() {}
    querySummary(data = {}) {
      return $http({
        url: apiBase.querySummary,
        method: 'post',
        data: data,
      })
    }
    queryTermList(data = {}) {
      return $http({
          url: apiBase.queryTermList,
          method: 'post',
          data: data,
      })
    }
    updateSetting(data = {}) {
      return $http({
          url: apiBase.updateSetting,
          method: 'post',
          data: data,
      })
    }
    editTestMethod(data = {}) {
      return $http({
          url: apiBase.editTestMethod,
          method: 'post',
          data: data,
      })
    } 
    queryStandardRuleList(data = {}) {
      return $http({
          url: apiBase.queryStandardRuleList,
          method: 'post',
          data: data,
      })
    }
    queryStandardList(data = {}) {
      return $http({
          url: apiBase.queryStandardList,
          method: 'post',
          data: data,
      })
    }
  }
  
  function singGoldDeployServer() {
    if (goldDeployServerObject === null) {
      goldDeployServerObject = new GoldDeployServer()
    }
    return goldDeployServerObject
  }
  export default singGoldDeployServer()