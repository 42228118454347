<template>
  <div class="wrapBox">
    <el-tabs v-model="activeName"  @tab-click="handleClick">
        <el-tab-pane label="测试项总览" name="testOverview">
          <test-overview v-if="activeName=='testOverview'"></test-overview>
        </el-tab-pane>
        <el-tab-pane v-for="(item,index) in navList" :key="index" :label="item.name" :name="item.code">
          <test-deploy  v-if="activeName==item.code" :standard = activeName></test-deploy >
        </el-tab-pane>
        <!-- <el-tab-pane label="兼容性测试" name="compatible">
          <test-deploy  v-if="activeName=='compatible'" :standard = activeName></test-deploy >
        </el-tab-pane>
        <el-tab-pane label="稳定性测试" name="stable">
          <test-deploy  v-if="activeName=='stable'" :standard = activeName></test-deploy >
        </el-tab-pane>
        <el-tab-pane label="安全测试" name="security">
          <test-deploy  v-if="activeName=='security'" :standard = activeName></test-deploy >
        </el-tab-pane>
        <el-tab-pane label="功耗测试" name="consumption">
          <test-deploy  v-if="activeName=='consumption'" :standard = activeName></test-deploy >
        </el-tab-pane>
        <el-tab-pane label="性能测试" name="performance">
          <test-deploy  v-if="activeName=='performance'"  :standard = activeName ></test-deploy >
        </el-tab-pane>
        <el-tab-pane label="大屏标准" name="large">
          <test-deploy  v-if="activeName=='large'"  :standard = activeName ></test-deploy >
        </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import testDeploy from "./componet/testDeploy"
import testOverview from './componet/testOverview';
import store from '@/store/index.js';
export default {
	beforeRouteEnter: (to,from,next) => {
		store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: "联盟检测配置", path: to.fullPath} );
		next()
	},
	components: {testDeploy,testOverview},
	data(){
		return{
			activeName:"testOverview",
			navList:[]	
		}
	},
  mounted(){
    this.queryStandardList();
  },
	methods: {
    queryStandardList(){
      this.$apis.unionDeployServer.queryStandardList().then(res=>{
        if (res['code'] == 0) {
          this.navList=res.data;
        }
      })
    },
    handleClick(tab) {
      sessionStorage.setItem('standardTestReport_tab',tab.name);
    },
  },
  activated(){
    if(sessionStorage.getItem('standardTestReport_tab')){
      this.activeName = sessionStorage.getItem('standardTestReport_tab')
    }
  },
}
</script>

<style scoped lang="scss">
.wrapBox{
  padding: 20px 30px;
}
.el-tabs {
  height: 100%;
  ::v-deep .is-active {
    font-weight:bolder ;
  }
}
</style>