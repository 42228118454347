import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils/util'
import * as filters from './filters'
import Service from './common/axios-config'
import ElementUI from 'element-ui'
import { Message } from 'element-ui'
import './assets/css/base.css'
// import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/element-style/theme/index.css'
import 'default-passive-events'
import vuescroll from 'vuescroll';
import * as authDirective from './directives/auth'
import * as permission from './common/auth-config'
import apis from "@/common/api-config";
import './icons' //SVG图
import dialogs from './components/dialogs/index.js'; //封装的弹框：商品弹框
import wkCustom from "./components/wk-custom/index.js";
import $ from 'jquery'
import "babel-polyfill"; //#处理低版本兼容

Vue.use(vuescroll, {
  ops: { // 在这里设置全局默认配置
    bar: {
      background: '#ccc',
      keepShow: true, //是否一直显示
    },
    scrollPanel: {
      scrollingX: false,
    }
  },
});
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(dialogs); //封装的对话框
Vue.use(wkCustom); //封装的玩咖自用组件
// 全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// 全局指令
Object.keys(authDirective).forEach(key => {
  Vue.directive(key, authDirective[key])
})

// 全局工具
window.utils = Vue.prototype.utils = utils
window.$http = Vue.prototype.$http = Service
window.$apis = Vue.prototype.$apis = apis
window.$msgbox = Message
window.permission = Vue.prototype.permission = permission
window.configBaseURL = Vue.prototype.configBaseURL = process.env.VUE_APP_API
Vue.prototype.$ = $

const vm =new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')