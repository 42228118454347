<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
		<div class="wrapBox">
			<div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
        <el-button 	v-auth="permission.RECOMMEND_TEXT_ADD" class="common-button" type="primary" @click="addSuggest()">
          添加推荐
        </el-button>
      </div>
			<el-table
				v-auth="permission.RECOMMEND_TEXT_LIST"
        class="tableBox"
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%">
				<el-table-column
          width="60"
          type="index"
          label="序号">
        </el-table-column>
				<el-table-column
          width="60"
          prop="sort"
          label="排序">
        </el-table-column>
				<el-table-column
          prop="title"
          label="文档名称">
        </el-table-column>
				<el-table-column
          width="80"
          prop="picDefaultUrl"
          label="图标1">
					<template slot-scope="scope">
            <el-image
              alt="banner缩略图"
              style="width: 50px; height: 40px;display:block"
              :src="scope.row.picDefaultUrl">
                            <template slot="error">
                <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
				<el-table-column
          width="80"
          prop="picActiveUrl"
          label="图标2">
					<template slot-scope="scope">
            <el-image
              alt="banner缩略图"
              style="width: 50px; height: 40px;display:block"
              :src="scope.row.picActiveUrl">
                            <template slot="error">
                <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
				<!-- <el-table-column
          width="600"
          prop="content"
          label="内容预览">
					<template slot-scope="scope">
            <p v-html="scope.row.content" class=""></p>
          </template>
        </el-table-column> -->
				<el-table-column
          width="publishTime"
          prop="sort"
          label="发布时间">
					<template slot-scope="scope">
            <span>{{ scope.row.publishTime | parseTime() }}</span>
          </template>
        </el-table-column>
				<el-table-column
          prop="state"
          label="状态">
					<template slot-scope="scope">
            <span>{{ scope.row.state == 1?'启用':'关闭'}}</span>
          </template>
        </el-table-column>
				<el-table-column
					width="200"
          label="操作">
					<template slot-scope="scope">
            <el-button class="color-blue link-button" type="text"
                       @click="editSuggest(scope.row.id)" v-auth="permission.RECOMMEND_TEXT_EDIT" >编辑
            </el-button>
            <el-button  class="color-blue link-button" type="text"
                       @click="changeStatus(scope.row)" v-auth="permission.RECOMMEND_TEXT_CONTROL">{{scope.row.state == '1'?'关闭':'开启'}}
            </el-button>
            <el-button class="color-blue link-button" type="text"
                       @click="deleteClick(scope.row.id)" v-auth="permission.RECOMMEND_TEXT_DELETE">删除
            </el-button>
          </template>
        </el-table-column>
			</el-table>
			<div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
		</div>
		<router-view/>
  </div>
</template>

<script>
import store from '@/store/index.js';
export default {
	beforeRouteEnter: (to,from,next) => {
		store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: "推荐文档管理", path: to.fullPath} );
		next()
  },
  data(){
		return{
			tableData: [],
			total: 0,
			dialogVisible: false,
			loading: false,
			searchFormData: {
				page: 1,
				perPage: 10,
      },
			searchControlData: [
				{
					type: 'input',
					label: '文档名称',
					placeholder: '请输入文档名称',
					key: 'title'
				},
				{
					type: 'rangDate',
					label: '选择时间',
					key: 'rangDate'
				},
				{
					type: 'select',
					label: '状态',
					placeholder: '请选择状态',
					key: 'state',
					option: [
						{
							label: '全部',
							value: ''
						},
						{
							label: '开启',
							value: 1
						},
						{
							label: '关闭',
							value: 2
						},
					]
				},
			],
		}
	},
	created() {
    this.search()
  },
	methods:{
		search(data) {
			this.loading = true
			if (data) {
				Object.assign(this.searchFormData, data)
				this.searchFormData.startTime = data.rangDate ? data.rangDate[0] : ''
				this.searchFormData.endTime = data.rangDate ? data.rangDate[1] : ''
				this.searchFormData.page = 1
			}
			this.$apis.recommendTextServer.queryPage(this.searchFormData).then(res => {
				if (res['code'] == 0 && res.data.data) {
					this.total = res.data.total
					this.tableData = res.data.data
				} else {
					this.total = 0
					this.tableData = []
				}
				this.loading = false
			})
		},
		
		changeStatus(data) {
			// state: banner的当前状态： 1：开启；2：关闭
			if (data.state == '1') {
				this.$confirm('确定关闭该文档?', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					center: true
				}).then(() => {
					this.$apis.recommendTextServer.updateStateDisable({
						id: data.id
					}).then(res => {
						if (res['code'] == 0) {
							this.search()
							this.$message({
								type: 'success',
								message: '关闭成功!'
							});
						}
					})
				})
			} else {
				this.$confirm('确定启用该文档?', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					type: 'info ',
					center: true
				}).then(() => {
					this.$apis.recommendTextServer.updateStateEnable({
						id: data.id
					}).then(res => {
						if (res['code'] == 0) {
							this.search()
							this.$message({
								type: 'success',
								message: '启用成功!'
							});
						}
					})
				})
			}
    },
		deleteClick(id) {
			this.$confirm('确定删除该文档', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'info ',
				center: true
			}).then(() => {
				this.$apis.recommendTextServer.deleteDoc({
					id: id
				}).then(res => {
					if (res['code'] == 0) {
						this.search()
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					}
				})
			})
    },
		addSuggest(){
			this.$router.push({
				path:'/recommend/info',
				query:{
					type: 'add'
				}
			})
		},
		editSuggest(id){
			this.$router.push({
				path:'/recommend/info',
				query:{
					type: 'edit',
					id:id
				}
			})
		},
		handleSizeChange(val) {
			this.searchFormData.perPage = val
			this.search()
		},
		handleCurrentChange(val) {
			this.searchFormData.page = val
			this.search()
		}	
	}
}
</script>

<style>

</style>