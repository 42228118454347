const routes = [
    {
        path: '/application',
        name: '',
        redirect:'/application/list'
    },
    {
        path: '/application/list',
        name: '',
        meta:{module:'gold_action_manage',menu:'/application/list',title:'应用信息管理'},//menu 用于所属右侧菜单栏
        component: () => import('@/views/application/list.vue')
    },
]

export default routes;
