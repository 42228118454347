<template>
  <div class="userBox">
    <h1 class="user">{{userInfo.name}}，{{getTimeState()}}</h1>
    <div class="userText">
        <div class="msg">您有{{reviewList.total ? reviewList.total : 0}}个文档待审核</div>
        <div  v-if="reviewList.list.length">
          <router-link 
            tag="span" 
            class="title" 
            :to="{path:'/document/audit',query:{docMenuId:item.docMenuId,id:item.id}}" 
            v-for="(item,index) in reviewList.list" 
            :key="index" >
            <span v-if="index">、</span>
            <span class="b">《{{item.title}}》</span> 
          </router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {
	computed: {
    userInfo() {
      return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
    },
  },
  watch: {
    '$route.query.docMenuId' () {
      this.getReviewList();
    },
    '$route.query.date' () {
      this.getReviewList();
    },
  },
	data(){
		return{
			reviewList:{
        list:[]
      },//待审核文档中心列表
		}
	},
	created(){
		this.getReviewList();
	},
	methods:{
		//获取待审核列表
    getReviewList(){
      this.$apis.documentServer.queryReviewList().then(res=>{
        if (res['code'] == 0){
          if(typeof res.data.list !='undefined' ){
            this.reviewList = res.data;
          }
        }
      })
    },
     getTimeState(){
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      // 设置默认文字
      let state= ``;
      // 判断当前时间段
      if (hours >= 0 && hours <= 10) {
          state = `早上好 ！`;
      } else if (hours > 10 && hours <= 14) {
          state= `中午好 ！`;
      } else if (hours > 14 && hours <= 18) {
          state= `下午好 ！`;
      } else if (hours > 18 && hours <= 24) {
          state= `晚上好 ！`;
      }
      return state;
    },
	}
}
</script>

<style scoped lang="scss">
.userBox{
  margin-top: 10px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	min-height: 43px;
  padding: 13px 20px;
  background-color:#28262C;
  color: #fff;
	.user{
		font-size: 20px;
	}
  .userText{
    flex: 1;
    font-size: 16px;
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid #ccc;
    .msg{
      margin-bottom: 5px;
      padding-left:5px;
    }
    .title{
      color: #fff;
      cursor: pointer;
      .b:hover{
        color: #E7DCAE;
      }
    }
  }
}
</style>