const routes = [
  {
    path: '/recommend',
    name: '',
    redirect:'/recommend/list'
  },
  {
    path: '/recommend/list',
    name: '',
    meta:{
      module:'operate_manage',
      menu:'/recommend/list',
      title:'推荐文档管理',
      breadcrumb:['recommend'],
      menuBreadcrumb: 'recommend',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/recommendText/list.vue')
  },
  {
    path: '/recommend/info',
    name: '',
    meta:{
      module:'operate_manage'
      ,menu:'/recommend/list',
      title:'添加推荐',
      breadcrumb:['recommend','info'],
      menuBreadcrumb: 'info',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/recommendText/info.vue')
  },
]
  
  export default routes;
    