<template>
  <el-dialog
    :title=" ruleForm.dataState == 4 ?'审核账号信息':'查看账号信息'"
    :visible="dialogVisible"
    width="680px"
    @close="dialogVisible = false"
    center>
    <el-form :model="ruleForm" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <el-form-item label="开发者类型">
        <el-col :span="18">
          <p>{{ utils.getDevelopType(ruleForm.type)}}</p>
        </el-col>
      </el-form-item>
      <company-info v-if="ruleForm.type == 1" :formData="ruleForm"></company-info>
      <personal-info :imageData="imageData" v-if="ruleForm.type == 2" :formData="ruleForm"></personal-info>
      <government-info :imageData="imageData" :authorizePhotoData="authorizePhotoData" v-if="ruleForm.type == 3" :formData="ruleForm"></government-info>
      <el-form-item label="状态">
        <el-col :span="18">
          <p>{{getStatus(ruleForm.dataState)}}</p>
        </el-col>
      </el-form-item>
      <el-form-item label="不通过原因" v-if="ruleForm.dataState == 5">
        <el-col :span="18">
          <p>{{ruleForm.reason}}</p>
        </el-col>
      </el-form-item>
    </el-form>
    <el-form v-if="ruleForm.dataState == 4" label-width="150px" class="demo-ruleForm">
      <el-form-item
        label="审核"
        :error="stateError">
        <el-col :span="18">
          <el-radio-group :span="16" v-model="state">
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="2">审核不通过</el-radio>
          </el-radio-group>
        </el-col>
      </el-form-item>
      <el-form-item :error="reasonError">
        <el-col :span="18">
        <el-input placeholder="请填写不通过原因" :row="3" :disabled="state != 2" type="textarea" v-model="reason"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button class="w375" type="primary" @click="onSubmit('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import companyInfo from '../componet/companyInfo'
  import governmentInfo from '../componet/governmentInfo'
  import personalInfo from '../componet/personalInfo'

  export default {
    components:{companyInfo,governmentInfo,personalInfo},
    data() {
      return {
        dialogVisible: false,
        reason: '',
        reasonError: '',
        stateError: '',
        state: '',
        ruleForm: {
          sort: '',  // 排序
          email: '', // 标题
          dataState: '', // 资料状态
          type: '',  // 资料状态
          companyName: '',  // 企业名称
          contactName: '',  // 联系人
          contactEmail: '',  // 联系人邮箱
          contactPhone: '',  // 联系人电话
          qq: '',  // 联系人电话
          weixin: '',  // 联系人电话
          developId: '',  // 联系人电话
          apkCount: '',  //
          createTime: '',  //
        },
        imageData: [],
        authorizePhotoData: [], // 政府事业单位被授权人照片
      }
    },
    methods: {
      loadData(id) {
        this.imageData = []
        this.authorizePhotoData = []
        this.$apis.developServer.getInfo({
          id: id
        }).then(res => {
          if (res['code'] == 0) {
            this.ruleForm = res.data
            if(res.data.type == 2){
              this.imageData.push(res.data.developPositivePhoto)
              this.imageData.push(res.data.developNegativePhoto)
              this.imageData.push(res.data.developHoldPhoto)
            } else if(res.data.type == 3){
              this.imageData.push(res.data.legalPersonPositivePhoto)
              this.imageData.push(res.data.legalPersonNegativePhoto)
              this.authorizePhotoData.push(res.data.authorizePositivePhoto)
              this.authorizePhotoData.push(res.data.authorizeNegativePhoto)
              this.authorizePhotoData.push(res.data.authorizeHoldPhoto)
            }
          }
        })
      },
      onSubmit() {
        // 审核选择与不通过理由错误表单提示信息
        this.reasonError = ''
        this.stateError = ''
        if (!this.state) {
          this.stateError = '请选择审核结果'
          return
        }
        if (this.state == 2) {
          if (!this.reason) {
            this.reasonError = '请输入审核不通过原因!'
            return
          }
        }
        this.$refs['ruleForm'].validate(valid => {
          if (valid) {
            this.$apis.developServer.updateState({
              id: this.ruleForm.id,
              dataState: this.state,
              reason: this.reason,
            }).then(res => {
              if (res['code'] == 0) {
                this.$emit('search', {})
                this.closeModal()
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
              } else {
                this.$message({
                  type: 'success',
                  message: '审核失败!'
                });
              }
            })
          }
        })
      },
      getStatus(data) {
        switch (data) {
          case 1:
            return '未完善'
          case 2:
            return '审核通过'
          case 4:
            return '待审核'
          case 5:
            return '审核不通过'
        }
      },
      closeModal() {
        this.dialogVisible = false
        this.ruleForm = {}
        this.reason = ''
        this.state = ''
      },
      show(id) {
        this.dialogVisible = true
        this.loadData(id)
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.$emit('search', this.formData)
      }
    }
  }
</script>

<style scoped>

</style>
<style lang="scss">

  .assetsImg-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .assetsImg-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .assetsImg-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .assetsImg {
    width: 178px;
    height: 178px;
    display: block;
   padding-right: 17px;
    padding-bottom: 11px;
  }
</style>