let apiBase = {
    addInfo:'/api/permission/addInfo',  //添加权限
    updateInfo:'/api/permission/updateInfo',
    queryPage:'/api/permission/queryPage',//节点列表
    queryOneById:'/api/permission/queryOneById',//编辑权限节点
    delete:'/api/permission/delete', //删除权限节点
    permissionImport:'/api/permission/permissionImport'
  }
  let AuthorityDeployServerObject = null
  class AuthorityDeployServer{
    constructor(){}
  
    // 新增权限节点
    addInfo(data={}){
      return $http({
        url: apiBase.addInfo,
        method: 'post',
        data: data,
      })
    }
    // 更新权限节点
    updateInfo(data={}){
      return $http({
        url: apiBase.updateInfo,
        method: 'post',
        data: data,
      })
    }
    //节点列表
    queryPage(data={}){
      return $http({
        url: apiBase.queryPage,
        method: 'post',
        data: data,
      })
    }

    queryOneById(data={}){
      return $http({
        url: apiBase.queryOneById,
        method: 'post',
        data: data,
      })
    }
    
    delete(data={}){
      return $http({
        url: apiBase.delete,
        method: 'post',
        data: data,
      })
    }

    permissionImport(data={}){
      return $http({
        url: apiBase.permissionImport,
        method: 'post',
        data: data,
      })
    }
  }
  function singleAuthorityDeployServer(){
    if(AuthorityDeployServerObject === null){
      AuthorityDeployServerObject = new AuthorityDeployServer()
    }
    return AuthorityDeployServerObject
  }
  export default singleAuthorityDeployServer()