<template>
  <div class="alertMessageBox" v-show="isShow">
    <div class="mask"></div>
    <div class="contentBox" :style="{width}">
      <i class="el-icon-close closeIcon" @click="cancel()"></i>
      <h3 class="title" v-if="title">{{title}}</h3>
      <div class="content" :style="{'text-align':align}">{{content}}</div>
    </div>
  </div>
</template>
    
  
<script>
export default {
  name:'alert',
  props: {
    width: {
      type: String,
      default: "500px"
    },
    align: {
      type: String,
      default: "center"
    },
    title: {
      type: String,
      default: ""
    },
    content: {
      required:true,
      type: String,
      default: ""
    },
  },
  created() {
  },
  data() {
    return {
      isShow: false,
      resolve: "",
      reject: "",
      promise: "" // 保存promise对象
    };
  },
  watch: {},
  methods: {
    // 确定,将promise断定为resolve状态
    confirm: function(row) {
      this.isShow = false;
      if (row) {
        this.resolve(row);
      } else {
        this.resolve();
      }
      this.remove();
    },
    // 取消,将promise断定为reject状态
    cancel: function() {
      this.isShow = false;
      this.reject("cancel");
      this.remove();
    },
    // 弹出messageBox,并创建promise对象
    show: function() {
      this.isShow = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      return this.promise;
    },
    remove: function() {
      setTimeout(() => {
        this.destroy();
      }, 300);
    },
    destroy: function() {
      this.timer && clearInterval(this.timer);
      this.$destroy();
      document.body.removeChild(this.$el);
    }
  }
};
</script>
<style scoped lang="scss">
.alertMessageBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: 999;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    background: rgba($color: #000000, $alpha: 0.5);
  }
  .contentBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    min-height: 150px;
    border-radius: 5px;
    background: #fff;
    padding: 40px 75px 25px 75px;
    color: #27252B;
    box-sizing: border-box;
    .closeIcon {
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 18px;
      z-index: 9;
      cursor: pointer;
    }
    .title {
      padding-bottom: 20px ;
      margin-bottom: 20px ;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      border-bottom:1px solid #ccc;
    }
    .content {
      font-size: 16px;
      text-align: center;
      word-break: break-all;
      line-height: 28px;
      letter-spacing:6px;
    }
  
  }
}

</style>
<style >
.confirmMessageBox .contentBox .operation .btn {
  letter-spacing: 6px !important;
  text-align: center !important;
}
</style>