let apiBase = {
  queryPage:'/api/notice/queryPage',        // 列表     
  queryDetail:'/api/notice/queryDetail',    // 详情      
  addNotice:'/api/notice/add',              // 添加公告    
  updateNotice:'/api/notice/update',         //更新公告
  updateStateDisable:'/api/notice/updateStateDisable',//停用
  updateStateEnable:'/api/notice/updateStateEnable',//启用
  delete:'/api/notice/delete',//删除
}
let NoticeServerObject = null
class NoticeServer{
  constructor(){}
  queryPage(data={}){
    return $http({
      url: apiBase.queryPage,
      method: 'post',
      data: data,
    })
  }
  queryDetail(data={}){
    return $http({
      url: apiBase.queryDetail,
      method: 'post',
      data: data,
    })
  }
  addNotice(data={}){
    return $http({
      url: apiBase.addNotice,
      method: 'post',
      data: data,
    })
  }
  updateNotice(data={}){
    return $http({
      url: apiBase.updateNotice,
      method: 'post',
      data: data,
    })
  }
  updateStateDisable(data={}){
    return $http({
      url: apiBase.updateStateDisable,
      method: 'post',
      data: data,
    })
  }
  updateStateEnable(data={}){
    return $http({
      url: apiBase.updateStateEnable,
      method: 'post',
      data: data,
    })
  }
  delete(data={}){
    return $http({
      url: apiBase.delete,
      method: 'post',
      data: data,
    })
  }

}
function singleNoticeServer(){
  if(NoticeServerObject === null){
    NoticeServerObject = new NoticeServer()
  }
  return NoticeServerObject
}
export default singleNoticeServer()