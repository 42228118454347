let apiBase = {
  emailQueryList:'/api/emailAlert/queryList',                  // 列表
  addEmail:'/api/emailAlert/addEmail',                      //  详情
  deleteEmail:'/api/emailAlert/deleteEmail',                  //  删除
}
let EmailAlertServerObject = null
class EmailAlertServer{
  constructor(){}
  emailQueryList(data={}){
    return $http({
      url: apiBase.emailQueryList,
      method: 'post',
      data: data,
    })
  }

  addEmail(data={}){
    return $http({
      url: apiBase.addEmail,
      method: 'post',
      data: data,
    })
  }

  deleteEmail(data={}){
	  return $http({
      method: 'post',
      url: apiBase.deleteEmail,
      data:data
    })
   }


}
function singleEmailAlertServer(){
  if(EmailAlertServerObject === null){
    EmailAlertServerObject = new EmailAlertServer()
  }
  return EmailAlertServerObject
}
export default singleEmailAlertServer()