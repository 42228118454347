<template>
  <el-dialog
    :title="type == 'add'?'添加权限节点':'更新权限节点'"
    :visible="dialogVisible"
    width="600px"
    @close="closeModal"
    center>
    <el-form :model="ruleForm" :rules="ruleData" ref="formRef" label-width="105px" class="demo-ruleForm">
      <el-form-item label="权限节点名" prop="name">
          <el-input class="w360" v-model="ruleForm.name" placeholder="请输入权限节点名"></el-input>
      </el-form-item>
      <el-form-item label="权限Key" prop="authorizedKey">
          <el-input class="w360" :span="16" placeholder="请输入权限Key" v-model="ruleForm.authorizedKey"></el-input>
      </el-form-item>
      <el-form-item label="接口路由" prop="routeUrl">
          <el-input class="w360" :span="16" placeholder="请输入接口路由" v-model="ruleForm.routeUrl"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select class="w360" :disabled="selectDisable" size="medium" v-model="ruleForm.type" placeholder="请选择类型">
          <el-option
                  v-for="(item,index) in typeList"
                  :key="index"
                  :label="item.label"
                  :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <div class="footer">
        <wk-button class="exportButton onSubmit" type="primary" @click="onSubmit()">提交</wk-button>
        <div class="apkFormFileBox">
          <el-upload
              class="upload-demo"
              ref="file"
              accept=".xls,.xlsx"
              :on-change="onUploadChange"
              :data="{ token: token}"
              :on-success="handleUpload"
              :action="configBaseURL + '/api/permission/permissionImport'"
              :show-file-list="false"
            >
            <wk-button  class="exportButton" type="primary" >上传权限列表</wk-button>
          </el-upload>   
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        token: localStorage.getItem('Token') ? localStorage.getItem('Token') :  '',
        dialogVisible: false,
        type: '',
        ruleForm: {
          id: '',  // 列表传过来的id
          name: '',
          authorizedKey:'',
          routeUrl:'',
          type:'',
        },
        ruleData: {//角色表单校验
          name: [
            {required: true, message: '请输入权限节点名', trigger: 'blur'},
          ],
          authorizedKey: [
            {required: true, message: '请输入权限Key', trigger: 'blur'},
          ],
          type: [
            {required: true, message: '请选择类型', trigger: ['blur', 'change']}
          ],
        },
        typeList: [
          {
            label:'菜单',
            id:1,
          },
          {
            label:'功能',
            id:2,
          },
        ],
        selectDisable: false
      }
    },
    mounted() {
    },
 
    methods: {
      onUploadChange(item) {
      // console.log(item.response);
       if(item.response ){
        if(item.response.code=="30010")
         this.$message({
            type: 'error',
            message: item.response.message
            
        });
       }else{
        this.$emit('search', {})
        this.closeModal()
        this.$message({
          type: 'success',
          message: '添加成功!'
        });
       }
     },
      onSubmit() {
        this.$refs['formRef'].validate(valid => {
          if(this.type=="add"){
            if (valid) {
                this.$apis.authorityDeployServer.addInfo(this.ruleForm).then(result => {
                if (result.code == 0) {
                  this.$emit('search', {})
                  this.closeModal()
                  this.$message({
                    type: 'success',
                    message: '添加成功!'
                  });
                }
              })
            }
          }else{
            this.$apis.authorityDeployServer.updateInfo(this.ruleForm).then(result => {
              if (result.code == 0) {
                this.$emit('search', {})
                this.closeModal()
                this.$message({
                  type: 'success',
                  message: '更新成功!'
                });
              }
            })
          }
        })
      },
      handleUpload(){

      },
      loadData(id) {
        this.ruleForm.id = id
        this.$apis.authorityDeployServer.queryOneById({id: id}).then(res => {
          if (res['code'] == 0) {
            this.ruleForm = res.data
          }
        })
      },
      closeModal() {
        this.dialogVisible = false
        this.showLoading = false
        this.resetForm()
      },
      show(type) {
        this.type = type
        this.dialogVisible = true
      },
      resetForm() {
        this.$refs['formRef'].resetFields();
      },
    }
  }
</script>
<style lang="scss" scoped>
/deep/.el-dialog--center .el-dialog__header {
  font-weight: bolder;
}

/deep/.el-select{
  width: 400px;
}
/deep/.el-input--medium .el-input__inner{
  height: 40px!important;
}
.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  .exportButton {
    width: 150px;
    height: 32px!important;
    margin:0 15px!important;
  }
  .onSubmit{
    letter-spacing: 6px;
  }
}
.apkFormFileBox {
  display: flex;  
   /deep/ .el-upload {
    border: none !important;
    border-radius: 0;
  }
}
</style>