const routes = [
  {
    path: '/emailAlert',
    name: '',
    redirect:'/emailAlert/list'
  },
  {
    path: '/emailAlert/list',
    name: '',
    meta:{module:'basic_functions_manage',menu:'/emailAlert/list',title:'邮件预警'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/emailAlert/list.vue')
  },
]

export default routes;
