const routes = [
  {
    path: '/goldDeploy',
    name: '',
    redirect:'/goldDeploy/list'
  },
  {
    path: '/goldDeploy/list',
    name: '',
    meta:{
      module:'gold_action_manage',
      menu:'/goldDeploy/list',
      title:'金标认证配置',
      keepAlive: true,
      breadcrumb: ['goldDeploy'],
      menuBreadcrumb: 'goldDeploy',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/goldDeploy/list.vue')
  },
]

export default routes;
