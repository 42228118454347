<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
        <div>
          <el-button class="search-button" type="primary" v-auth="permission.TASK_MERGE"  @click="onTaskMerge()">任务合并</el-button>
          <el-button class="search-button" type="primary" v-auth="permission.TEST_REPORT_RECEIVING_LIST" @click="goTestReportList()">测试报告接收名单</el-button>
          <el-button class="search-button" type="primary" v-auth="permission.TASK_ACTION_ADDTASK" @click="addTask()">添加任务</el-button>
        </div>
      </div>
      <el-table
        v-auth="permission.TASK_ACTION_QUERYPAGE"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        class="tableBox W100"
        stripe
      >
        <el-table-column prop="id" width="150" label="任务ID"></el-table-column>
        <el-table-column prop="name" label="任务名称"></el-table-column>
        <el-table-column prop="adminName" label="创建人"></el-table-column>
        <el-table-column prop="state" label="任务状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">认证中</span>
            <span v-if="scope.row.state == 2">认证完成</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180">
           <template slot-scope="scope">
            <span>{{ scope.row.createTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="reviewTime" label="完成时间" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.reviewTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="apkPackageCount" label="应用数量"></el-table-column>
        <el-table-column prop="successCount" label="认证通过"></el-table-column>
        <el-table-column prop="compatibleCount" label="兼容标准"></el-table-column>
        <el-table-column prop="stableCount" label="稳定标准"></el-table-column>
        <el-table-column prop="securityCount" label="安全标准"></el-table-column>
        <el-table-column prop="consumptionCount" label="功耗标准"></el-table-column>
        <el-table-column prop="performanceCount" label="性能标准"></el-table-column>
        <el-table-column prop="largeCount" label="大屏标准"></el-table-column>
        <el-table-column  label="认证报告">
          <template slot-scope="scope">
            <el-button v-auth="permission.LOOK_TEST_REPORT" class="color-blue link-button" type="text" @click="goAttestDetails(scope.row)">
              认证报告
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <add-task v-if="isShowlModal" :modalShow="isShowlModal" @closeModal="closeModal()" ></add-task>
    <task-merge v-if="taskMergeModal" :taskMergeModal="taskMergeModal" @closeTaskModal="closeTaskModal()"></task-merge>
  </div>
</template>

<script>
import addTask from "./dialogs/addTask";
import taskMerge from "./dialogs/taskMerge"
import store from '@/store/index.js';
export default {
  beforeRouteEnter: (to,from,next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: "联盟检测", path: to.fullPath} );
    next()
  },
  components: { addTask,taskMerge},
  data() {
    return {
      isShowlModal:false,//添加任务弹窗
      taskMergeModal:false,//任务合并弹窗
      total: 0,
      searchFormData: {
        page: 1,
        perPage: 10,
      },
      tableData: [],
      //搜索form组件
      searchControlData: [
        {
          type: "input",
          label: "任务ID",
          placeholder: "任务ID",
          key: "taskId",
        },
        {
          type: "input",
          label: "任务名称",
          placeholder: "任务名称",
          key: "name",
        }, 
        {
          type: "rangDate",
          label: "创建时间",
          key: "rangDate",
        },
      ],
    };
  },
  created(){
    this.search(this.searchFormData)
  },
  methods: {
    // 关闭modal
    closeModal(val) {
      this.isShowlModal = val;
      this.search();
    },
    //打开弹窗
    addTask() { 
      this.isShowlModal = true  
    },

    //打开弹窗
    onTaskMerge() { 
      this.taskMergeModal = true  
    },
    closeTaskModal(){
      this.taskMergeModal = false;
      this.search();
    },
    goTestReportList() {
      this.$router.push({ path: "/unionAttest/testReportList" });
    },
    
    search(data) {
      if (data) {
        Object.assign(this.searchFormData, data);
        this.searchFormData.startTime = data.rangDate ? data.rangDate[0] : ''
        this.searchFormData.endTime = data.rangDate ? data.rangDate[1] : 
        this.searchFormData.page = 1;
      }
      this.$apis.unionAttestServer.getTaskList(this.searchFormData).then((res) => {
        if (res["code"] == 0 && res["data"]["data"]) {
          this.total = res.data.total;
          this.tableData = res.data.data;
        } else {
          this.total = 0;
          this.tableData = [];
        }
      });
    },
    goAttestDetails(data) {
      this.$router.push({
        path:'/unionAttest/attestDetails',
        query:{
          id:data.id,
          breadcrumbName: data.name
        }
      })
    },
  //table点击更改一页多少行
    handleSizeChange(val) {
      this.searchFormData.perPage = val;
      this.search();
    },
    //table点击修改页码
    handleCurrentChange(val) {
      this.searchFormData.page = val;
      this.search();
    },
  },
};
</script>

<style scoped lang="scss">
  /deep/ .el-table .cell{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
</style>
