
import Vue from 'vue'
let hour  = '5'
class DocumentAutoServer extends Vue {
  constructor() { 
    super()
  }
  editMsg(){
    const h = this.$createElement;
    $('.el-message-box__btns .el-button--primary span').html('确 定');
    this.$msgbox({
        center: true,
        closeOnClickModal:false,
        title: '编辑提示',
        message: h('ul', {style:'list-style: none;text-align: left;'}, [
            h('li', {style:'margin-bottom: 10px;'}, [
              h('b', null, `编辑锁定：`),
              h('span', null, `当您编辑草稿时，其他人无法进行编辑；`),
            ]),
            h('li', {style:'margin-bottom: 10px;'}, [
              h('b', null, `刷新/关闭：`),
              h('span', null, `当您刷新或关闭浏览器时，您编辑的内容将会丢失，请注意及时保存；`),
            ]),
            h('li', {style:'margin-bottom: 10px;'}, [
              h('b', null, `编辑时间：`),
              h('span', null, `每次编辑最长时间不超过5小时，5小时候后会提示您保存草稿结束编辑；`),
            ]),
            h('li', {style:'margin-bottom: 10px;'}, [
              h('b', null, `保存提示：`),
              h('span', null, `系统每隔1小时会提示您保存草稿，若您不在电脑前，系统会自动保存草稿，并关闭编辑窗口，以便他人编辑。`),
            ]),
        ]),
        showClose: false,
        showCancelButton: false,
        confirmButtonText: '确 定',
    }).then(() => {
    }).catch((val) => {});
  }
  autoSave(time='',callback=''){
    this.$msgbox.close();//防止多个弹框
    let count = 60;
    $('.el-message-box__btns .el-button--primary span').html(time>=hour ?`立即保存 ${count}`:`我在编辑 ${count}`);
    let h = this.$createElement;
    let countClearInterval = null;
    this.$msgbox({
        closeOnClickModal:false,
        title: '保存提示',
        message: h('p', null, [
          time>=hour ? h('p', null, `您的编辑时间已达5小时，请保存后再次编辑`)
         :h('p', null, `您的编辑时间已达${time}小时，若您还在编辑，请点击我在编辑，否则系统将自动保存并结束编辑`),
        ]),
        showClose: false,
        showCancelButton: false,
        confirmButtonClass: 'isEdit',
        confirmButtonText:`我在编辑 ${count}`,
        center: true
    }).then(() => {
      $('.el-message-box__btns .el-button--primary span').html('确 定');
      clearInterval(countClearInterval);
      callback && callback(time>=hour);
    }).catch((val) => {});
    //倒计时
    countClearInterval = setInterval(()=>{
      count--;
      if(count<0){
        $('.el-message-box__btns .el-button--primary span').html('确 定');
        this.$msgbox.close();
        clearInterval(countClearInterval);
        callback && callback(true);
      }else{
        $('.isEdit span').html( time>=hour ?`立即保存 ${count}`:`我在编辑 ${count}`);
      }
    },1000);

  }
}
export default DocumentAutoServer