<template>
    <el-form :model="formData" ref="formData" label-width="150px" class="demo-formData">
      <el-form-item label="政府/事业单位名称">
        <el-col :span="18">
          <el-input disabled v-model="formData.contactName"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="统一社会信用代码">
        <el-col :span="18">
          <el-input disabled v-model="formData.creditCode"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="信用证书">
        <el-col :span="24" style="display: flex;flex-wrap: wrap">
          <el-image
            class="assetsImg"
            :src="formData.legalPersonCertificate"
            alt="信用证书"
            :preview-src-list="formData.legalPersonCertificate.split()"
          >
            <template slot="error">
              <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
            </template>
          </el-image>
        </el-col>
      </el-form-item>

      <el-form-item label="法人姓名">
        <el-col :span="18">
          <el-input disabled v-model="formData.corporationName"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="法人身份证号">
        <el-col :span="18">
          <el-input disabled v-model="formData.corporationCardName"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="法人身份证">
        <el-col :span="24" style="display: flex;flex-wrap: wrap">
          <el-image
            v-for="(item,index) in imageData"
            :key="index"
            class="assetsImg"
            :src="item"
            alt="法人身份证"
            :preview-src-list="imageData"
          >
            <template slot="error">
              <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
            </template>
          </el-image>
        </el-col>
      </el-form-item>
      <el-form-item label="是否有法人手持照片">
        <el-col :span="18">
          <p>{{ getHolderPhotoName(formData.legalPersonHoldPhotoType)}}</p>
        </el-col>
      </el-form-item>
      <!--   v-if="formData.legalPersonHoldPhotoType:
   legalPersonHoldPhotoType可取值：1；2
     1：有法人手持身份证照片，2无法人手持身份证照片，
     当没有法人手持身份证照片时，显示被授权人相关信息-->
      <el-form-item v-if="formData.legalPersonHoldPhotoType ==1" label="法人手持身份证照片">
        <el-col :span="24" style="display: flex;flex-wrap: wrap">
          <el-image
            class="assetsImg"
            :src="formData.legalPersonHoldPhoto"
            alt="法人手持身份证照片"
            :preview-src-list="formData.legalPersonHoldPhoto"
          >
            <template slot="error">
              <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
            </template>
          </el-image>
        </el-col>
      </el-form-item>
      <el-form-item v-if="formData.legalPersonHoldPhotoType ==2"  label="被授权人身份证照">
        <el-col :span="24" style="display: flex;flex-wrap: wrap">
          <el-image
            v-for="(item,index) in authorizePhotoData"
            :key="index"
            class="assetsImg"
            :src="item"
            alt="法人身份证"
            :preview-src-list="authorizePhotoData"
          >
            <template slot="error">
              <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
            </template>
          </el-image>
        </el-col>
      </el-form-item>
      <el-form-item v-if="formData.legalPersonHoldPhotoType ==2" label="授权书">
        <el-col :span="24" style="display: flex;flex-wrap: wrap">
          <el-image
            class="assetsImg"
            :src="formData.authorizeCertificate"
            alt="授权书"
            :preview-src-list="formData.authorizeCertificate.split()"
          >
            <template slot="error">
              <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
            </template>
          </el-image>
        </el-col>
      </el-form-item>
      <el-form-item label="联系人姓名">
        <el-col :span="18">
          <el-input disabled v-model="formData.contactName"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="联系人邮箱">
        <el-col :span="18">
          <el-input disabled v-model="formData.email"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="联系人电话">
        <el-col :span="18">
          <el-input disabled v-model="formData.contactPhone"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="微信">
        <el-col :span="18">
          <el-input disabled v-model="formData.weixin"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="QQ">
        <el-col :span="18">
          <el-input disabled v-model="formData.qq"></el-input>
        </el-col>
      </el-form-item>
    </el-form>
</template>

<script>

  export default {
    name: "governmentInfo",
    props:{
      formData: Object,
      imageData: Array,
      authorizePhotoData: Array,
    },
    methods:{
      getHolderPhotoName(data){
        return data == 1? '有':'无'
      }
    }
  }
</script>

<style scoped>

</style>
<style lang="scss">

  .assetsImg-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .assetsImg-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .assetsImg-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .assetsImg {
    width: 178px;
    height: 178px;
    display: block;
   padding-right: 17px;
    padding-bottom: 11px;
  }
</style>