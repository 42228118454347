const routes = [
  {
    path: '/goldStandard',
    name: '',
    redirect:'/goldStandard/list'
  },
  {
    path: '/goldStandard/list',
    name: 'goldStandard',
    meta:{
      module:'gold_action_manage',
      menu:'/goldStandard/list',
      title:'金标认证',
      keepAlive: true,
      breadcrumb: ['goldStandard'],
      menuBreadcrumb: 'goldStandard',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/goldStandard/list.vue')
  },
  {
    path: '/goldStandard/versions/list',
    name: 'versions',
    meta:{
      module:'gold_action_manage',
      menu:'/goldStandard/list',
      title:'版本认证记录',
      keepAlive: false,
      breadcrumb:['goldStandard','versions'],
      menuBreadcrumb: 'versions',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/goldStandard/versions/list.vue')
  },
  {
    path: '/goldStandard/versions/standardReport',
    name: 'standardReport',
    meta:{
      module:'gold_action_manage',
      menu:'/goldStandard/list',
      title:'认证报告',
      keepAlive: true,
      breadcrumb:['goldStandard','versions',"standardReport"],
      menuBreadcrumb: 'standardReport',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/goldStandard/versions/standardReport.vue')
  },
  {
    path: '/goldStandard/versions/reportDetails',
    name: 'versionsReportDetails',
    meta:{
      module:'gold_action_manage',
      menu:'/goldStandard/list',
      title:'报告详情',
      keepAlive: false,
      breadcrumb:['goldStandard','versions',"standardReport","reportDetails"],
      menuBreadcrumb: 'reportDetails',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/goldStandard/versions/reportDetails.vue')
  },
  {
    path: '/goldStandard/meddle/list',
    name: '',
    meta:{
      module:'gold_action_manage',
      menu:'/goldStandard/list',
      title:'人工干预记录',
      breadcrumb:['goldStandard','meddle'],
      menuBreadcrumb: 'meddle',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/goldStandard/meddle/list.vue')
  },
]

export default routes;
