<template>
  <el-dialog
    :title="type == 'add'?'添加用户':'更新用户'"
    :visible="dialogVisible"
    width="600px"
    @close="closeModal()"
    center>
    <el-form :model="ruleForm" :rules="ruleData" ref="formRef" label-width="80px" class="demo-ruleForm">
      <el-form-item label="账号邮箱" prop="email">
        <el-input v-model.trim="ruleForm.email" placeholder="请输入账号邮箱"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model.trim="ruleForm.name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="厂商" prop="memberCode">
        <el-select :disabled="selectDisable" size="medium" v-model="ruleForm.memberCode" placeholder="请选择厂商">
          <el-option
            v-for="(item,index) in memberList"
            :key="index"
            :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户组" prop="roleId">
        <el-select size="medium" v-model="ruleForm.roleId" placeholder="请选择用户组">
          <el-option
            v-for="(item,index) in roleList"
            :key="index"
            :label="item.name"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <div class="footer">
        <wk-button type="primary" class="exportButton" @click="onSubmit('formRef')">确定</wk-button>
        <wk-button class="exportButton" @click="closeModal()">取消</wk-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        type: '',
        id: '',  // 列表传过来的id
        ruleForm: {
          email: '',
          name: '',
          roleId: '',
          memberCode: '',
        },
        ruleData: {//角色表单校验
          name: [
            {required: true, message: '请填写姓名', trigger: 'blur'},
          ],
          email: [
            {required: true, message: '请填写邮箱', trigger: 'blur'},
            {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
          ],
          memberCode: [
            {required: true, message: '请选择厂商', trigger: ['blur', 'change']}
          ],
          roleId: [
            {required: true, message: '请选择用户组', trigger: ['blur', 'change']}
          ],
        },
        memberList: [],
        roleList: [],
        selectDisable: false
      }
    },
    computed: {
      getUserInfo() {
        return this.$store.getters["accountStore/getUserInfo"]
      }
    },
    methods: {
      getSelectList() {
        this.memberList = this.$store.getters["accountStore/getMemberList"]
        this.getRoleList();
        this.memberList.forEach(item => {
          if (item.value == this.getUserInfo.memberCode) {
            if (this.getUserInfo.memberCode != 'ita') {
              this.selectDisable = true
              this.ruleForm.memberCode = this.getUserInfo.memberCode
            }
          }
        })
      },
      
      getRoleList(){
        this.$apis.accountServer.roleSelect().then(res => {
          if (res['code'] == 0) {
             this.roleList = res.data;
          }
        })
      },
      onSubmit(formName) {
        let self = this
        if (this.type == 'add') {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.$apis.accountServer.add(this.ruleForm).then(res => {
                if (res['code'] == 0) {
                  this.$emit('search', {})
                  this.closeModal()
                  this.$message({
                    type: 'success',
                    message: '添加成功!'
                  });
                }
              })
            }
          })
        } else {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.$apis.accountServer.update(this.ruleForm).then(res => {
                if (res['code'] == 0) {
                  // 获取当前用户信息:用户基础信息与按钮权限
                  this.$apis.accountServer.getCurrentInfo().then(res => {
                    if (res['code'] == 0) {
                      if (this.id == this.getUserInfo.id) {
                        localStorage.clear();
                        // 先执行清除本地缓存，再跳转登录页
                        setTimeout(function () {
                          self.$router.push({path: "/login"});
                        }, 10);
                        return
                      }
                      this.$emit('search', {})
                      this.closeModal()
                      this.$message({
                        type: 'success',
                        message: '更新成功!'
                      });
                    }
                  })

                }
              })
            }
          })

        }
      },

      loadData(id) {
        this.id = id
        this.$apis.accountServer.getInfo({id: id}).then(res => {
          if (res['code'] == 0) {
            this.ruleForm = res.data
          }
        })
      },
      closeModal() {
        this.dialogVisible = false
        this.resetForm()
      },
      resetForm() {
        this.$refs['formRef'].resetFields();
      },
      show(type) {
        this.type = type
        this.getSelectList()
        this.dialogVisible = true
      },

    }
  }
</script>

<style scoped lang="scss">

/deep/.el-select{
  width: 400px;
}
/deep/.el-input--medium .el-input__inner{
  height: 40px!important;
}
.footer{
  margin-top: 20px;
  text-align: center;
  .exportButton{
    width: 150px;
    margin: 0 22px;
    letter-spacing: 6px !important;
  }
}
</style>