let apiBase = {
  login:'/api/admin/login',
  loginOut:'/api/admin/logout',
  queryList:'/api/admin/queryList',
  update:'/api/admin/updateInfo',
  add:'/api/admin/addInfo',
  currentInfo:'/api/admin/currentInfo', // 获取用户信息
  getInfo:'/api/admin/getInfo', // 获取用户信息
  resetPwd:'/api/admin/resetPwd',  // 重置密码
  updatePwd:'/api/admin/updatePwd',  // 修改密码
  deleteInfo:'/api/admin/deleteInfo',  // 修改密码
  roleSelect:'/api/role/selectList',  // 权限：管理员 and 普通用户
}
let AccountServerObject = null
class AccountServer{
  constructor(){}

  // 登录
  login(data={}){
    return $http({
      url: apiBase.login,
      method: 'post',
      data: data,
    })
  }

  // 登出
  loginOut(data={}){
    return $http({
      url: apiBase.loginOut,
      method: 'post',
      data: data,
    })
  }

   // 查询用户信息
  queryList(data={}){
    return $http({
      url: apiBase.queryList,
      method: 'post',
      data: data,
    })
  }

  update(data={}){
    return $http({
      url: apiBase.update,
      method: 'post',
      data: data,
    })
  }

  add(data={}){
    return $http({
      url: apiBase.add,
      method: 'post',
      data: data,
    })
  }

  getCurrentInfo(data={}){
    return $http({
      url: apiBase.currentInfo,
      method: 'post',
      data: data,
    })
  }

  getInfo(data={}){
    return $http({
      url: apiBase.getInfo,
      method: 'post',
      data: data,
    })
  }

  resetPwd(data={}){
    return $http({
      url: apiBase.resetPwd,
      method: 'post',
      data: data,
    })
  }

  updatePwd(data={}){
    return $http({
      url: apiBase.updatePwd,
      method: 'post',
      data: data,
    })
  }
  deleteInfo(data={}){
    return $http({
      url: apiBase.deleteInfo,
      method: 'post',
      data: data,
    })
  }

  roleSelect(data={}){
    return $http({
      url: apiBase.roleSelect,
      method: 'post',
      data: data,
    })
  }
}
function singleAccountServer(){
  if(AccountServerObject === null){
    AccountServerObject = new AccountServer()
  }
  return AccountServerObject
}
export default singleAccountServer()