const routes = [
  {
    path: '/banner',
    name: '',
    redirect:'/banner/list'
  },
  {
    path: '/banner/list',
    name: '',
    meta:{module:'operate_manage',menu:'/banner/list',title:'banner管理'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/banner/list.vue')
  },
]

export default routes;
 