let apiBase={
  queryNewForLine:'/api/stat/account/queryNewForLine',//新增开发者折线图
  queryDevList:'/api/stat/account/queryList',//开发者数量列表
  queryTotalForLine:'/api/stat/account/queryTotalForLine',//累计开发者折线图
  exportDev:'/api/stat/account/export',//导出开发者数据

  exportApp:'/api/stat/apk/export',//应用数据导出
  queryAppList:'/api/stat/apk/queryList',//应用数据列表
  queryForLine:'/api/stat/apk/queryForLine',//应用数据折线图
  queryForPie:'/api/stat/apk/queryForPie',//应用数据饼图

  queryGoldList:'/api/stat/identify/queryList',//金标认证数据列表
  queryIdentifyForBar:'/api/stat/identify/queryIdentifyForBar',//金标认证数据柱状图
  queryIdentifyTotalForBar: '/api/stat/identify/queryIdentifyTotalForBar',//累计金标认证数据柱状图
  queryIdentifyForPie:'/api/stat/identify/queryIdentifyForPie',//金标认证数据饼图
  exportGold:'/api/stat/identify/export',//导出金标认证数据

  queryTimelinessForLine: '/api/stat/identify/queryTimelinessForLine',//时效统计图
  queryTimelinessPage:'/api/stat/identify/queryTimelinessPage',//时效统计列表
  exportTimeliness:'/api/stat/identify/exportTimeliness'//时效数据导出
}

let DataCensusServerObject = null;
class DataCensusServer{
  constructor(){}

  queryNewForLine(data={}){
    return $http({
      url: apiBase.queryNewForLine,
      method: 'post',
      data: data,
    })
  }

  queryDevList(data={}){
    return $http({
      url: apiBase.queryDevList,
      method: 'post',
      data: data,
    })
  }

  queryTotalForLine(data={}){
    return $http({
      url: apiBase.queryTotalForLine,
      method: 'post',
      data: data,
    })
  }

  exportDev(data={}){
    return $http({
      url: apiBase.exportDev,
      method: 'post',
      data: data,
    })
  }

  exportApp(data={}){
    return $http({
      url: apiBase.exportApp,
      method: 'post',
      data: data,
    })
  }

  queryAppList(data={}){
    return $http({
      url: apiBase.queryAppList,
      method: 'post',
      data: data,
    })
  }

  queryForLine(data={}){
    return $http({
      url: apiBase.queryForLine,
      method: 'post',
      data: data,
    })
  }

  queryForPie(data={}){
    return $http({
      url: apiBase.queryForPie,
      method: 'post',
      data: data,
    })
  }

  queryGoldList(data={}){
    return $http({
      url: apiBase.queryGoldList,
      method: 'post',
      data: data,
    })
  }

  queryIdentifyForBar(data={}){
    return $http({
      url: apiBase.queryIdentifyForBar,
      method: 'post',
      data: data,
    })
  }

  queryIdentifyTotalForBar(data={}){
    return $http({
      url: apiBase.queryIdentifyTotalForBar,
      method: 'post',
      data: data,
    })
  }

  queryIdentifyForPie(data={}){
    return $http({
      url: apiBase.queryIdentifyForPie,
      method: 'post',
      data: data,
    })
  }

  exportGold(data={}){
    return $http({
      url: apiBase.exportGold,
      method: 'post',
      data: data,
    })
  }

  queryTimelinessForLine(data={}){
    return $http({
      url: apiBase.queryTimelinessForLine,
      method: 'post',
      data: data,
    })
  }
  queryTimelinessPage(data={}){
    return $http({
      url: apiBase.queryTimelinessPage,
      method: 'post',
      data: data,
    })
  }
  exportTimeliness(data={}){
    return $http({
      url: apiBase.exportTimeliness,
      method: 'post',
      data: data,
      timeout: 0,
    })
  }


}

function singDataCensusServer(){
  if(DataCensusServerObject===null){
    DataCensusServerObject = new DataCensusServer()
  }
  return DataCensusServerObject
}
export default singDataCensusServer()