<template>
  <el-dialog
    title="认证未通过原因"
    :visible="modalShow"
    width="650px"
    @close="closeModal()"
    :close-on-click-modal="false"
    center>
    <el-form v-if="modalShow" :model="formData" ref="refForm" label-width="100px">
      <el-form-item class="form-item" label="错误代码：" v-if="formData.code">
        <div class="manage">{{formData.code}}</div>
      </el-form-item>
      <el-form-item class="form-item" label="问题描述：" v-if="formData.name">
        <div class="manage">{{formData.name}}</div>
      </el-form-item>
      <el-form-item class="form-item" label="出现机型：" v-if="formData.deviceList!=''">
        <el-table
          :header-cell-style="{'text-align': 'center', 'height':'10px','background':'#fff','color':'#606266'}"
          :cell-style="{ 'text-align': 'center' }"
          :data="formData.deviceList"
          border
          class="margin-t10"
          :highlight-current-row='false'>
            <el-table-column label="厂商" prop="memberName" width="80"></el-table-column>
            <el-table-column label="机型" prop="standardSuccess">
              <template slot-scope="scope" >
                <div style="text-align: left;">
                  <span class="manage" v-for="(item,index) in scope.row.device" :key="index">
                    <span ><i v-if="index">、</i>{{item}}</span>
                  </span>
                </div> 
              </template>
            </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div class="text-center margin-t30">
      <el-button size="medium" type="info" @click="closeModal()">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    modalShow: Boolean,
    form: Object,
  },
  data(){
    return{
      formData:{},
    }
  },
  created(){
    this.getData();
  },
  methods:{
    getData(){
      this.$apis.unionAttestServer.queryFailTermDetail(this.form).then(res=>{
        if(res["code"] == 0){
          this.formData=res.data;
        }
      })
    },
    // 关闭弹框
    closeModal() {
      this.$emit("closeModal", false);
    },
  }
}
</script>

<style  scoped lang="scss">
.form-item{
  margin-bottom: 0; 
}
/deep/ .el-table {
  border-radius: 5px;
}
/deep/ .el-table__header th {
  padding: 0;
  height: 12px!important;

}
/deep/.el-form-item__label {
    line-height: 30px;
  }
  /deep/.el-form-item__content{
    line-height: 30px;
  }
// 修改弹框边框圆角
  /deep/.el-dialog.el-dialog--center {
    padding-top: 10px;
  }

</style>

