let apiBase = {
  queryList: '/api/account/queryList',                 // 列表
  getInfo: '/api/account/getInfo',                     //  详情
  updateState: '/api/account/updateState',               //  更改状态
  export: '/api/account/export',                // 导出
  update:'/api/account/update',//更新用户资料
}
let DeveloperServerObject = null
class DevelopServer{
  constructor(){}
  queryList(data={}){
    return $http({
      url: apiBase.queryList,
      method: 'post',
      data: data,
    })
  }

  getInfo(data={}){
    return $http({
      url: apiBase.getInfo,
      method: 'post',
      data: data,
    })
  }

  updateState(data={}){
	  return $http({
      method: 'post',
      url: apiBase.updateState,
      data:data
    })
   }

  export(data={}){
    return $http({
      url: apiBase.export,
      method: 'post',
      data: data,
    })
  }
  update(data={}){
    return $http({
      url: apiBase.update,
      method: 'post',
      data: data,
    })
  }
}
function singleDevelopServer(){
  if(DeveloperServerObject === null){
    DeveloperServerObject = new DevelopServer()
  }
  return DeveloperServerObject
}
export default singleDevelopServer()