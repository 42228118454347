<template>
  <el-dialog
    title="添加任务"
    :visible="modalShow"
    width="650px"
    @close="closeModal()"
    :close-on-click-modal="false"
    center
  >
    <el-form 
      label-width="100px" 
      :rules="rules"
      ref="form" 
      @validate="formValidate" 
      :model="formData">
      <el-form-item label="任务名称" prop="name">
         <el-input class="expireDay-input" v-model.trim="formData.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="应用表单" prop="application">
        <div class="apkFormFileBox">
          <el-upload
              class="upload-demo"
              ref="file"
              accept=".xls,.xlsx"
              :on-change="onUploadChange"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
               action="#"
              :auto-upload="false"
              :limit=1
            >
            <div class="apkFormBtn">上传附件</div>
          </el-upload>   
        </div>
        <div class="msg">
          请根据
          <a
            class="blue"
            href="https://statres.itgsa.com/renzheng/联盟认证名单.xlsx"
            download
          >
            《<span class="defaultHref">联盟认证名单</span>》
          </a> 模板上传应用表单
        </div>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="submitForm('form')">确定</el-button>
        <el-button size="medium" type="info" @click="closeModal()">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
   props: {
    modalShow: Boolean,
  },
  data(){
    return{
      formValid: {}, //用于检验form是否通过
      //数据
      formData: {
        name:'',
        application:[],
        // excelName:''
      },
      rules: {
        name: [
          { required: true, message: "请填写应用名称", trigger: "blur" },
          { max: 15, message: '最大限制15个字', trigger: 'blur' }
        ],     
        application: [
          { required: true, message: "请上传应用表单", trigger: "blur" },
        ],
      },
    }
  },
  methods:{
    //检查每个input是否通过
    formValidate(key, valid) {
      if (valid) {
        this.$set(this.formValid, key, valid);
      } else {
        this.$delete(this.formValid, key);
      }
    },
     // 关闭弹框
    closeModal() {
      this.$emit("closeModal", false);
    },
     onUploadChange(item) {
       this.formData.excelName=item.name
      this.formData.application.push(item)
     },
     handleRemove(item){   
        this.formData.application=[]
     },
      handleSuccess(res, file) {
       this.$refs.file.clearFiles(); //上传成功之后清除历史记录
    },
    submitForm(formName){ 
      this.$refs[formName].validate((valid) => {
        if(valid){
        let formData = new FormData();
          formData.append('application', this.formData.application[0].raw);
          formData.append('token', localStorage.getItem('Token'));
          formData.append('name', this.formData.name);
          this.$apis.unionAttestServer.addTask(formData).then(res=>{
            if (res.code == 0) {
              this.closeModal();
            }
          })
        }else {
          return false;
        }        
      })
    },
  }
};
</script>

<style scoped lang="scss">
.expireDay-input {
  width: 350px;
}
.apkFormFileBox {
  display: flex;
  .apkFormFile {
    margin-right: 9px;
    width: 200px;
    height: 40px;
    padding-left: 15px;
    border: 1px solid rgba(227, 227, 227, 1);
    overflow:hidden;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    -webkit-text-overflow:ellipsis;
    -moz-text-overflow:ellipsis;
    white-space:nowrap;
  }    
   /deep/ .el-upload {
    border: none !important;
    border-radius: 0;
  }
  .apkFormBtn {
    position: relative;
    width: 121px;
    height: 40px;
    background: rgba(33, 33, 37, 1);
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    text-align: center;
    .formFile {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      
    }
  }
}
</style>
