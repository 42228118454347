<template>
  <div class="index-box">
    <router-view></router-view>
    <commen-foot></commen-foot>
  </div>
</template>
<script>
import commenFoot from "@/components/Foot";

export default {
  name: "index",
  components: {
    commenFoot
  }
};
</script>

<style scoped lang="scss">
.index-box {
  height: 100%;
  background:url('~@/assets/img/account/loginBg.jpg');
}

</style>

