<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
        <el-button class="common-button" v-auth="permission.ALERT_ACTION_ADD" type="primary" @click="add">
          添加邮箱
        </el-button>
      </div>
      <el-table
        class="tableBox"
        :data="tableData"
        v-auth="permission.ALERT_ACTION_LIST"
        stripe
        style="width: 100%">
        <el-table-column
          width="60"
          align="center"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="email"
          label="邮箱">
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="姓名">
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberName"
          width="80"
          label="厂商">
        </el-table-column>
        <el-table-column
          align="center"
          prop="modifyTime"
          label="添加时间">
          <template slot-scope="scope">
            <span>{{ scope.row.modifyTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="adminName"
          width="80"
          label="操作人">
        </el-table-column>
        <el-table-column
          v-if="userInfo.roleName != '普通账号'"
          align="center"
          width="80"
          label="操作">
          <template slot-scope="scope"  v-if=" scope.row.memberId == userInfo.memberId || userInfo.memberCode== 'ita'">
            <el-button v-auth="permission.ALERT_ACTION_DELETE" class="color-blue link-button" type="text"
              @click="deleteEmail(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <add ref="modalRef" @search="search"></add>
  </div>
</template>
<script>
  import add from "./dialogs/add";

  export default {
    components: {add},
    data() {
      return {
        total: 0,
        dialogVisible: false,
        loading: false,
        searchFormData: {
          type:1,
          page: 1,
          perPage: 10,
        },
        searchControlData: [
          {
            type: 'input',
            label: '邮箱',
            placeholder: '请输入邮箱',
            key: 'email'
          },
          {
            type: 'input',
            label: '姓名',
            placeholder: '请输入姓名',
            key: 'name'
          },
          {
            type: 'select',
            label: '厂商',
            placeholder: '请选择厂商',
            key: 'memberId',
            option: []
          }
        ],
        tableData: []
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
      },
      memberList(){
        return this.$store.getters['accountStore/getMemberList']
      }
    },
    created() {
      this.search()
      this.searchControlData.forEach((item, index) => {
        if (item.key == 'memberId') {
          this.searchControlData[index].option = this.memberList.map(item=>{
            return {label:item.label,value:item.id}
          })
        }
      })
    },
    methods: {
      search(data) {
        this.loading = true
        if (data) {
          Object.assign(this.searchFormData, data)
          this.searchFormData.page = 1
        }
        this.$apis.emailAlertServer.emailQueryList(this.searchFormData).then(res => {
          if (res['code'] == 0 && res.data.data) {
            this.total = res.data.total
            this.tableData = res.data.data
          } else {
            this.total = 0
            this.tableData = []
          }
          this.loading = false
        })
      },
      add() {
        this.$refs.modalRef.show('add')
      },
      deleteEmail(data) {
        // state: banner的当前状态： 1：开启；2：关闭
          this.$confirm('点击删除将会删除该邮箱，是否删除？', `邮箱：${data.email}?`, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            cancelButtonClass: 'cancelButton',
            confirmButtonClass: 'confirmButton',
            type: 'warning',
            center: true
          }).then(() => {
            this.$apis.emailAlertServer.deleteEmail({
              id: data.id
            }).then(res => {
              if (res['code'] == 0) {
                this.search()
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
              }
            })
          })
      },
      deleteClick(id) {
        this.$confirm('确定删除该banner', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'cancelButton',
          confirmButtonClass: 'confirmButton',
          type: 'info ',
          center: true
        }).then(() => {
          this.$apis.bannerServer.deleteBanner({
            id: id
          }).then(res => {
            if (res['code'] == 0) {
              this.search()
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        })
      },
      handleSizeChange(val) {
        this.searchFormData.perPage = val
        this.search()
      },
      handleCurrentChange(val) {
        this.searchFormData.page = val
        this.search()
      }
    }
  }
</script>
<style>
.el-message-box--center .el-message-box__message {
  font-size: 20px;
  color: #000000;
}

.cancelButton {
  width: 98px;
  margin-left: 14px;
  margin-top: 14px;
}

.confirmButton {
  width: 98px;
  margin-left: 60px !important;
}
</style>
<style scoped lang="scss">
.el-message-box--center .el-message-box__message {
  font-size: 20px;
  color: #000000;
}
</style>