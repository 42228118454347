<template>
  <el-dialog
    :title="type == 'add'?'添加用户组':'更新用户组'"
    :visible="dialogVisible"
    width="800px"
    @close="closeModal()"
    class="menuWarp"
    center>
    <div class="line"></div>
    <div class="userGroup">
      <h1>用户组名称</h1>
      <div>用户组名称<el-input style="margin-left:15px;" v-model.trim="formData.name" placeholder="请输入内容"></el-input></div>
    </div>
    <div class="menuBox">
      <h1>权限管理</h1>
      <div class="menuItem">
        <role-Check class="roleCheck" v-for="(item,index) in authorityMenu" :key="index" :checkData="item" ></role-Check>
      </div> 
    </div>
    
    <div class="footer">
      <wk-button class="exportButton" type="primary" @click="handleOk()">确定</wk-button>
    </div>
    
  </el-dialog>
</template>

<script>
  import accountMenu from '@/utils/navData'
  import roleCheck from '../components/roleCheck'
  export default {
    props: {
      dialogVisible: Boolean,
      type: String,
      id: {
        type:Number,
        required: true,
        default: 1,
      }
    },
    components: {roleCheck},
    
    data() {
      return {
        formData:{
          name:"",
          authorizedKey:[],
        },
        selectcheckBoxData:[],
        authorityMenu:JSON.parse(JSON.stringify(accountMenu)),//深拷贝权限表
      }
    },
    created(){
      if(this.type == "edit"){
        this.loadData(this.id);
      }
    },
    methods: {
      // 提交
      handleOk(){
        if(this.getValidateState()){
          this.getParams(this.authorityMenu)
          this.formData.authorizedKey =new Set (this.selectcheckBoxData)
          if(this.type=="add"){
            this.$apis.authorityServer.addAdminRole(this.formData).then(res=>{
              if (res['code'] == 0) {
                this.closeModal()
                this.$message({
                  type: 'success',
                  message: '添加成功!'
                });
              }
            })
          }
          if(this.type=="edit"){
            this.$apis.authorityServer.updateAdminRole({...this.formData,id:this.id}).then(res=>{
              if (res['code'] == 0) {
                this.closeModal()
                this.$message({
                  type: 'success',
                  message: '更新成功!'
                });
              }
            })
          }
        }  
      },
      //编辑权限节点
      loadData(id) {
        this.$apis.authorityServer.queryDetail({id:id}).then(res => {
          if (res['code'] == 0) {
            this.formData.name=res.data.name;
            this.formData.authorizedKey = res.data.authorizedKey;
            this.getDataToForm(this.authorityMenu);
          }
        })  
      },
      // 编辑时选中已有权限
      getDataToForm(data){
        data.map(item =>{
          if(this.formData.authorizedKey.indexOf(item.value) != -1){
            item.checked = true
          }
          if(!item.chidren || item.chidren.length == 0) return
          this.getDataToForm(item.chidren)
        })
      },

      //选中的节点添加到数组中
      getParams(data){
        data.map(item =>{
          if(item.checked){
            this.selectcheckBoxData.push(item.value)
          }
          if(!item.chidren || item.chidren.length == 0) return
          this.getParams(item.chidren)
        })
      },


      // 验证用户组和权限是否填写
      getValidateState(){
        let flag = true
        if(!this.formData.name){
          this.$message({
            message: '请填写用户组',
            type: 'warning'
          });
          flag = false
          return flag
        }
        if(!this.validateButton(this.authorityMenu)) {
          this.$message({
            message: '请勾选权限',
            type: 'warning'
          });
          flag = false
          return flag
        }
        return flag
      },

      validateButton(checkBoxDataArr){
        let validateStatus = false
        for (let item of checkBoxDataArr){
          if(item.checked){
            validateStatus = true
          }
          if(item.chidren){
            this.validateButton(item.chidren)
          }
        }
        return validateStatus
      },
      // 关闭弹框
      closeModal() {
        this.$emit("closeModal", false);
      },
    }
  }
</script>

<style scoped lang="scss">
/deep/.el-dialog--center .el-dialog__header {
  font-weight: bolder;
}
/deep/ .el-dialog__body{
    padding: 25px 50px 30px ;
  }
.menuWarp{
  padding-left: 50px;
  h1{
    margin-bottom: 10px;
  }
  .line{
    height: 1px!important;
    background: #ccc!important;
  }
  .userGroup{
    margin-top: 25px;
    /deep/.el-input{
      width:400px;
    }
  }
  .menuBox{
    margin-top: 20px;
    .menuItem{
      margin-bottom: 20px;
    }
    .roleCheck{
      margin-top: 20px;
    }
  }
  .footer{
    margin-top: 55px;
    text-align: center;
    .exportButton{
      width: 200px;
      letter-spacing: 6px;
    }
  }
}
</style>