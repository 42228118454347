<template>
  <section class="login-box">
    <div class="login-content">
      <div class="titleBox">
        <svg-icon class="logoImg" svg-name="icon"></svg-icon>
        <h3 class="title padding-l20">移动智能终端联盟管理后台</h3>
      </div>
      <el-form
        :model="loginForm"
        status-icon
        :rules="rules"
        ref="loginForm"
        label-width="100px"
        class="loginForm"
      >
        <el-form-item label="账户" prop="email">
          <el-input class="input" type="text" v-model="loginForm.email"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input class="input" type="password" v-model="loginForm.password"></el-input>
        </el-form-item>
        <div class="text-center  padding-l20">
          <div class=" button " @click="submitForm('loginForm')">登录
          </div>
        </div>
      </el-form>
    </div>
  </section>
</template>
<script>
  import md5 from "js-md5";

  export default {
    data() {
      return {
        loginForm: {
          email: "",
          password: ""
        },
        formValid: {},
        rules: {
          email: [{required: true, message: '请输入邮箱地址', trigger: "blur"},
            {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
          ],
          password: [{required: true, message: '请输入密码', trigger: "blur"},]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.$apis.accountServer
            .login({
              email: this.loginForm.email,
              password: md5(this.loginForm.password)
            })
            .then(res => {
              if (res['code'] == 0) {
                localStorage.setItem('Token', res.data.token);
                // 请求所有厂商数据
                this.$apis.accountBindServer.getMemberList().then(res => {
                  if (res['code'] == 0) {
                    this.$store.commit("accountStore/set", {memberList: res.data});
                  }
                })
                // 请求添加用户权限数据：管理员 or 普通用户
                this.$apis.accountServer.roleSelect().then(res => {
                  if (res['code'] == 0) {
                    this.$store.commit("accountStore/set", {roleList: res.data});
                  }
                })
                // 获取标准列表
                this.$apis.standardServer.queryStandardList().then(res => {
                  if (res['code'] == 0) {
                    this.$store.commit("standardStore/set", {standardList: res.data});
                  }
                })
                // 获取当前用户信息:用户基础信息与按钮权限
                this.$apis.accountServer.getCurrentInfo().then(res => {
                  if (res['code'] == 0) {
                    this.$store.commit('accountStore/setUserInfo', res.data);
                    if(res.data.roleName == "数据分析员"){
                      this.$router.replace({
                        path: "/unionAttest"
                      });
                    }else{
                      this.$router.replace({
                        path: this.$route.query.path?this.$route.query.path: "/developer"
                      });
                    }
                  }
                })
              }
            });
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  };
</script>

<style scoped lang="scss">
  .login-box {
    position: relative;
    width: 100%;
    height: calc(100% - 54px);

    .titleBox {
      display: flex;
      height: 91px;
      border-radius: 5px 5px 0px 0px;
      background-color: black;
      text-align: center;
      justify-content: center;
      align-items: center;

      .logoImg {
        margin-right: 5px;
        width: 40px;
        height: 40px;
        color: #000000;
      }

      .title {
        line-height: 90px;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        color: rgba(230, 219, 173, 1);
      }
    }

    .el-form-item {
      margin-bottom: 26px;
    }

    .login-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 614px;
      height: 400px;
      background: #FFFFFF;
      padding: 0px 0px 40px 0;
      border-radius: 5px;

      .loginForm {
        margin: 50px 64px;
        .input {
          width: 300px;
        }
        .button {
          width: 300px;
          height: 50px;
          line-height: 50px;
          margin: 20px auto;
          background: rgba(40, 38, 44, 1);
          box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          cursor: pointer;
        }
      }
    }
  }
</style>