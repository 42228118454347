import { render, staticRenderFns } from "./head.vue?vue&type=template&id=60112463&scoped=true"
import script from "./head.vue?vue&type=script&lang=js"
export * from "./head.vue?vue&type=script&lang=js"
import style0 from "./head.vue?vue&type=style&index=0&id=60112463&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60112463",
  null
  
)

export default component.exports