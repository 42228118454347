<template>
  <div class="page standardResults-box">
    <div class="export">
      <el-button v-auth="permission.STANDARD_DATA_EXPORT" class="export-button" type="primary" @click="exportData()" :loading="buttonLoading">
        <span v-if="!buttonLoading">导出数据</span> 
        <span v-else>正在导出</span> 
      </el-button>
    </div>
    <div>
      <el-table
      :header-cell-style="{ 'text-align': 'center',}"
      :cell-style="{ 'text-align': 'center'}"
      class="tableBox2"
      :data="tableData"
      :span-method="objectSpanMethod">
        <el-table-column label="测试项" prop="title" width="200"></el-table-column>
        <el-table-column label="测试编号" prop="code"  width="130"></el-table-column>
        <el-table-column label="测试标准" prop="name">
          <template slot-scope="scope">
          <div style="text-align: left;">
            {{scope.row.name}}
          </div>
        </template> 
        </el-table-column>
        <el-table-column label="测试通过数量">
          <el-table-column label="通过" width="90" prop="labelCount"></el-table-column>
          <el-table-column label="OPPO" width="90" prop="OPPOCount"></el-table-column>
          <el-table-column label="vivo" width="90" prop="vivoCount" ></el-table-column>
          <el-table-column label="小米" width="90" prop="xiaomiCount"></el-table-column>
        </el-table-column>
        <el-table-column label="测试通过率">
          <el-table-column label="通过" width="90" prop="labelRate">
            <template slot-scope="scope">
              {{scope.row.labelRate}}%
            </template>
          </el-table-column>
          <el-table-column label="OPPO" width="90" prop="OPPORate">
            <template slot-scope="scope">
              {{scope.row.OPPORate}}%
            </template>
          </el-table-column>
          <el-table-column label="vivo" width="90" prop="vivoRate">
            <template slot-scope="scope">
              {{scope.row.vivoRate}}%
            </template>
          </el-table-column>
          <el-table-column label="小米" width="90" prop="xiaomiRate">
            <template slot-scope="scope">
              {{scope.row.xiaomiRate}}%
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    
    </div>
</template>

<script>
export default {
  props: {
      standard: {
        type: String,
        required: true
      }
  },
  data(){
    return{
      buttonLoading:false,
      form:{
        taskId:this.$route.query.id,
        code:this.standard,
      },
      tableData: [],
      memberList:[],
    }
  },
  mounted(){
    this.getTableData();
  },
  watch:{
    standard(){
      this.form.code=this.standard;
      this.getTableData();
    },
  },
  methods:{
    getTableData(){
      this.$apis.unionAttestServer.queryTermPass(this.form).then(res=>{
         if (res['code'] == 0 && res.data) {
          this.tableData = res.data;
        }
      })
    },
    mergeCol(id, rowIndex) { // 合并单元格
    // id：属性名
    // rowIndex：行索引值
      var idName = this.tableData[rowIndex][id] // 获取当前单元格的值
      if (rowIndex > 0) { // 判断是不是第一行
        // eslint-disable-next-line eqeqeq
        if (this.tableData[rowIndex][id] != this.tableData[rowIndex - 1][id]) { // 先判断当前单元格的值是不是和上一行的值相等
          var i = rowIndex; var num = 0 // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
          while (i < this.tableData.length) { // 当索引值小于table的数组长度时，循环执行
            if (this.tableData[i][id] === idName) { // 判断循环的单元格的值是不是和当前行的值相等
              i++ // 如果相等，则索引值加1
              num++ // 合并的num计数加1
            } else {
              i = this.tableData.length // 如果不相等，将索引值设置为table的数组长度，跳出循环
            }
          }
          return {
            rowspan: num, // 最终将合并的行数返回
            colspan: 1
          }
        } else {
          return {
            rowspan: 0, // 如果相等，则将rowspan设置为0
            colspan: 1
          }
        }
      } else { // 如果是第一行，则直接返回
        let i = rowIndex; let num = 0
        while (i < this.tableData.length) { // 当索引值小于table的数组长度时，循环执行
          if (this.tableData[i][id] === idName) {
            i++
            num++
          } else {
            i = this.tableData.length
          }
        }
        return {
          rowspan: num,
          colspan: 1
        }
      }
    },

    // 这个方法是 element-ui提供的单元格合并方法
    // objectSpanMethod 传入了 { row, column, rowIndex, columnIndex }
    // row: 当前行
    // column: 当前列
    // rowIndex：当前行号
    // columnIndex ：当前列号
    // 1代表：独占一行
    // 更大的自然数：代表合并了若干行
    // 0：代表“消失”的哪那一个单元格，后面的单元格向前推一格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) { // 合并单元格
      switch (columnIndex) { // 将列索引作为判断值
      // 通过传递不同的列索引和需要合并的属性名，可以实现不同列的合并
        case 0:
          return this.mergeCol('title', rowIndex)
        // case 1:
        //   return this.mergeCol('title', rowIndex)
        // case 2:
        //   return this.mergeCol('title', rowIndex)
      }
    },

    exportData(){
      this.buttonLoading = true;
      this.$apis.unionAttestServer.taskIdentifyStandardExport({taskId:this.$route.query.id,}).then(res => {
        if (res['code'] == 0 && res['data']['link']) {
          this.buttonLoading = false;
          window.location.href = res.data.link;
        }
      });
    }

  },
}
</script>

<style scoped lang="scss">
/deep/ .el-tabs__content{
  overflow: initial;
}
/deep/ .el-table__header th {
    padding: 0;
    height: 32px!important;
}
.export{
  float: right;
  .export-button{
    position: absolute;
    right: 0;
    top: -60px;
    z-index: 2;
  }
}
</style>
<style>
.el-table thead.is-group th {
  background: #28262C;
  border:none;
}
.el-tabs__content{
  overflow: initial;
}

</style>