


//金标审核状态
export function reviewGoldStateString(value) {
    return { '1': "审核中", '2': '审核通过', '3': '审核失败', '4': '未审核' }['' + value] || value;
}

//金标认证结果
export function authGoldStateString(value) {
    return { '1': "认证中", '2': '认证通过', '3': '认证不通过', '4': '认证过期', }['' + value] || value;
}

//app审核状态
export function reviewAPPStateString(value) {
    return { '0': ' ', '1': "审核中", '2': '审核通过', '3': '审核失败', '4': '信息已过期' }['' + value] || value;
}

//版本标准_审核状态：:0-认证中1-认证通过2-认证未通过3-认证终止
export function versionsStandard_reviewState(value) {
  return { '1': "认证中", '2': "认证通过", '3': '认证未通过', '4': '认证终止'}['' + value] || value;
}
//厂商版本标准_状态：:0-认证中1-认证通过2-认证未通过3-认证终止
export function versionsStandard_memberState(value) {
  return { '1': "认证中", '2': "认证通过", '3': '认证未通过', '4': '认证终止'}['' + value] || value;
}

//厂商版本认证_进度：:1、认证完成2、认证中
export function versionsAuth_memberState(value) {
  return { '1': "认证完成", '2': '认证中',}['' + value] || value;
}

//厂商任务认证_进度 必测
export function versionsTask_memberState(value) {
  return { '1': "认证中", '2': '认证通过','3': '认证未通过',}['' + value] || value;
}

//厂商任务认证_进度 警告
export function warnVersionsTask_memberState(value) {
  return { '1': "认证中", '2': '认证通过','3': '警告项未通过',}['' + value] || value;
}