const routes = [
  {
    path: '/dataCensus',
    name: '',
    redirect:'/dataCensus/list'
  },
  {
    path: '/dataCensus/list',
    name: '',
    meta:{module:'operate_manage',menu:'/dataCensus/list',title:'数据统计'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/dataCensus/list.vue')
  },
]

export default routes;
