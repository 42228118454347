/**
 * 权限认证指令
 *
 */

export const auth = {
  inserted(el, binding, vnode) {
    const data = vnode.context.$store.getters["accountStore/getUserInfo"]['authorizedKey'] || [];
    const _parentElement = el.parentNode;
    if (data.includes(binding.value)) {
      
    }else{
        _parentElement.removeChild(el);// 没有权限就删掉节点
    }
  }
}
