const routes = [
	{
		path: '/notice',
		name: '',
		redirect:'/notice/list'
	},
	{
		path: '/notice/list',
		name: '',
		meta:{
			module:'operate_manage',
			menu:'/notice/list',
			title:'公告管理',
			// keepAlive: true,
			breadcrumb:['notice'],
			menuBreadcrumb: 'notice',
		},//menu 用于所属右侧菜单栏
		component: () => import('@/views/notice/list.vue'),
	},
	{
		path: '/notice/info',
		name: '',
		meta:{
			module:'operate_manage',
			menu:'/notice/list',
			title:'添加公告',
			breadcrumb:['notice','info'],
			menuBreadcrumb: 'info',
		},//menu 用于所属右侧菜单栏,breadcrumb用于面包屑
		component: () => import('@/views/notice/info.vue'),
	},
]
  
export default routes;
  