const routes = [
  {
    path: '/userManager',
    name: '',
    redirect:'/userManager/list'
  },
  {
    path: '/userManager/list',
    name: '',
    meta:{module:'basic_functions_manage',menu:'/userManager/list',title:'用户管理'}, //menu 用于所属右侧菜单栏
    component: () => import('@/views/userManager/userManager.vue')
  },
]

export default routes;
