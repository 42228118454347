<template>
	<div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}">
		<textarea :id="tinymceId" class="tinymce-textarea"/>
		<div v-show="uploadBtn" class="editor-custom-btn-container">
			<editorImage v-if="initState" color="#1890ff" class="editor-upload-btn" @successCBK="imageSuccessCBK"/>
		</div>
	</div>
</template>

<script>
    /**
     * docs:
     * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
     */
    import editorImage from './components/EditorImage'
    import plugins from './plugins'
    import toolbar from './toolbar'
    import load from './dynamicLoadScript'

    // why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
    // const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js'
    // const tinymceCDN = 'http://test.so-quick.cn/quickapp/test/tinymce/tinymce.min.js'


    export default {
        name: 'Tinymce',
        components: {editorImage},
        props: {
            id: {
                type: String,
                default: function () {
                    return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
                }
            },
            value: {
                type: String,
                default: ''
            },
            uploadBtn: {
                type: Boolean,
            },
            toolbar: {
                type: Array,
                required: false,
                default() {
                    return []
                }
            },
            menubar: {
                type: String,
                default: 'file edit insert view format table'
            },
            height: {
                type: [Number, String],
                required: false,
                default: 360
            },
            width: {
                type: [Number, String],
                required: false,
                default: 'auto'
            }
        },
        data() {
            return {
                initState: false,
                hasChange: false,
                hasInit: false,
                tinymceId: this.id,
                fullscreen: false,
            }
        },
        computed: {
            containerWidth() {
                const width = this.width
                if (/^[\d]+(\.[\d]+)?$/.test(width)) { // matches `100`, `'100'`
                    return `${width}px`
                }
                return width
            }
        },

        mounted() {
            this.init()
        },
        activated() {
            if (window.tinymce) {
                this.initTinymce()
            }
        },
        deactivated() {
            this.destroyTinymce()
        },
        destroyed() {
            this.destroyTinymce()
        },
        methods: {
            init() {
                this.initTinymce()
            },
            initTinymce() {
                const _this = this
                window.tinymce.init({
                    selector: `#${this.tinymceId}`,
                    language: 'zh_CN',
                    height: this.height,
                    images_upload_url: configBaseURL + '/api/uploadFile',
                    images_upload_handler: function (blobInfo, succFun, failFun) {
                        var xhr, formData;
                        var file = blobInfo.blob();//转化为易于理解的file对象
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open('POST', configBaseURL + '/api/uploadFile');
                        xhr.onload = function () {
                            var json;
                            if (xhr.status != 200) {
                                failFun('HTTP Error: ' + xhr.status);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.data.link != 'string') {
                                failFun('Invalid JSON: ' + xhr.responseText);
                                return;
                            }
                            succFun(json.data.link);
                        };
                        formData = new FormData();
                        formData.append('file', file, file.name);//此处与源文档不一样
                        xhr.send(formData);
                    },
                    lineheight_formats: '1 1.2 1.5 1.6 1.8 2 2.4 2.6 2,8 3 3.5 4 4.5',
                    body_class: 'panel-body ',
                    object_resizing: false,
                    toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
                    menubar: this.menubar,
                    plugins: plugins,
                    toc_depth:9,
                    content_css : '/css/TinymceChange.css',
                    end_container_on_empty_block: true,
                    powerpaste_word_import: 'clean',
                    code_dialog_height: 450,
                    code_dialog_width: 1000,
                    advlist_bullet_styles: 'square',
                    advlist_number_styles: 'default',
                    imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
                    default_link_target: '_blank',
                    link_title: false,
                    nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
                    init_instance_callback: editor => {
                        if (_this.value) {
                            editor.setContent(_this.value)
                        }
                        _this.hasInit = true
                        _this.initState = true
                        
                        editor.on('NodeChange Change KeyUp SetContent', () => {
                            this.hasChange = true
                            this.$emit('input', editor.getContent())
                        })
                    },
                    setup(editor) {
                        editor.on('FullscreenStateChanged', (e) => {
                            _this.fullscreen = e.state
                        })
                    },
                    convert_urls: false
                })
            },
            destroyTinymce() {
                const tinymce = window.tinymce.get(this.tinymceId)
                if (this.fullscreen) {
                    tinymce.execCommand('mceFullScreen')
                }
                if (tinymce) {
                    tinymce.destroy()
                }
            },
            setContent(value) {
                window.tinymce.get(this.tinymceId).setContent(value)
            },
            getContent() {
                window.tinymce.get(this.tinymceId).getContent()
            },
            imageSuccessCBK(arr) {
                arr.forEach(v => window.tinymce.get(this.tinymceId).insertContent(`<video controls="controls"><source src="${v.url}" type="video/mp4"></video>`))
            }
        }
    }
</script>

<style lang="scss" scoped>
	.tinymce-container {
		position: relative;
		line-height: normal;
	}

	.tinymce-container {
		::v-deep {
			.mce-fullscreen {
				z-index: 10000;
			}
		}
	}

	.tinymce-textarea {
		visibility: hidden;
		z-index: -1;
	}

	.editor-custom-btn-container {
		position: absolute;
		right: 4px;
		top: 6px;
		z-index: 99 ;
	}

	.fullscreen .editor-custom-btn-container {
		z-index: 10000;
		position: fixed;
	}

	.editor-upload-btn {
		display: inline-block;
	}
</style>
