<template>
  <el-dialog
    title="修改密码"
    :visible="dialogVisible"
    width="500px"
    @close="closeModal"
    center>
      <el-form
        :model="accountForm"
        :rules="rules"
        ref="modifyRef"
        label-width="100px"
        class="accountForm"
      >
        <el-form-item  label="账户" prop="account">
          <el-col :span="19">
            <p class="input">{{accountForm.account}}</p>
          </el-col>
        </el-form-item>
        <el-form-item label="旧密码" prop="oldPassword">
          <el-col :span="20">
          <el-input class="input" type="password" v-model="accountForm.oldPassword"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-col :span="20">
          <el-input class="input" type="password" v-model="accountForm.password" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="确认新密码" prop="checkPass">
          <el-col :span="20">
          <el-input class="input" type="password" v-model="accountForm.checkPass" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>
          <el-form-item>
            <el-col :span="20" class="dialogButton">
              <el-button class="margin-r10 w120" type="primary" @click="submitForm()">修改密码</el-button>
              <el-button  class="w120" type="default"   @click="closeModal()">取消</el-button>
            </el-col>
          </el-form-item>
      </el-form>
  </el-dialog>
</template>
<script>
  import md5 from "js-md5";

  export default {
    data() {
      var validateAccount = (rule, value, callback) => {
        if (value == "") {
          callback(new Error("请输入账户"));
        } else {
          callback();
        }
      };
      var validatePassword = (rule, value, callback) => {
        if (value == "") {
          callback(new Error("请输入旧密码"));
        } else {
          callback();
        }
      };
      var validatePass1 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入新密码'));
        } else {
          if (this.accountForm.checkPass !== '') {
            this.$refs['modifyRef'].validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.accountForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        accountForm: {
          account: "",
          password: "",
          oldPassword: '',
          checkPass: '',
        },
        dialogVisible: false,
        rules: {
          account: [{validator: validateAccount, trigger: "blur"}],
          password: [{required: true,validator: validatePass1, trigger: "blur"}],
          oldPassword: [
            {required: true,validator: validatePassword, trigger: 'blur'}
          ],
          checkPass: [
            {required: true,validator: validatePass2, trigger: 'blur'}
          ],
        }
      };
    },
    methods: {
      submitForm() {
        this.$refs['modifyRef'].validate(valid => {
          if (valid) {
            this.$apis.accountServer.updatePwd({
              oldPassword: md5(this.accountForm.oldPassword),//	是	string	旧密码
              password: md5(this.accountForm.password),//	是	string	新密码
            }).then(res => {
              if (res['code'] == 0) {
                localStorage.clear()
                this.$store.commit('set', {token: ''})
                this.dialogVisible = false
                this.$confirm('修改密码成功，请重新登录', '提示', {
                  confirmButtonText: '确定',
                  showCancelButton: false,
                }).then(() => {
                  this.goLogin();
                }).catch(() => {
                });
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            return false;
          }
        });
      },
      goLogin(formName) {
        this.$router.push({path:'/login'});
      },
      closeModal(){
        this.dialogVisible = false
        this.resetForm()
      },
      show(){
        this.dialogVisible = true
        this.accountForm.account = this.$store.getters["accountStore/getUserInfo"]['email'];
      },
      resetForm() {
        this.$refs['modifyRef'].resetFields();
      },
    }
  };
</script>

<style scoped lang="scss">
  .modifyPassword-box {
    position: relative;
    width: 100%;
    height: calc(100% - 54px);

    .modifyPassword-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      background: #ccc;
      padding: 40px 0;
      border-radius: 5px;

      .title {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
      }

      .accountForm {
        margin: 20px 0;
        .input {
          width: 300px;
        }
      }
    }
  }
</style>
<style>
  .dialogButton{
    display: flex;
    justify-content: space-between;
  }
</style>