let apiBase = {
	queryStandardList:'/api/term/queryStandardList'
}
  let StandardServerObject = null
  class StandardServer{
    constructor(){}
    queryStandardList(data={}){
      return $http({
        url: apiBase.queryStandardList,
        method: 'post',
        data: data,
      })
    }
  }
  function singleStandardServer(){
    if(StandardServerObject === null){
      StandardServerObject = new StandardServer()
    }
    return StandardServerObject
  }
  export default singleStandardServer()