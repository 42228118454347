


import userManager from './userManager'//用户管理
import emailAlert from './emailAlert'//邮件预警
import syncLog from './syncLog'//同步日志
import batchSync from './batchSync'//批量同步日志
import authorityDeploy from './authorityDeploy'
import authority from './authority'
export default [
	...userManager,
	...emailAlert,
  	...syncLog,
	...batchSync,
	...authorityDeploy,
	...authority
]