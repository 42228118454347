<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px"></div>
        <el-button class="common-button" v-auth="permission.AUTHORITY_DEPLOY_ADD"  type="primary" @click="addAuthorityDeploy()">
          新增权限节点
        </el-button>
      </div>
      <el-table
        v-auth="permission.AUTHORITY_DEPLOY_LIST"
        class="tableBox"
        :data="tableData"
        stripe
        style="width: 100%">
        <el-table-column
          width="60"
          align="center"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="权限名">
        </el-table-column>
        <el-table-column
          align="center"
          prop="authorizedKey"
          label="权限Key">
        </el-table-column>
        <el-table-column
          align="center"
          prop="routeUrl"
          label="接口路由">
        </el-table-column>
        <el-table-column
          align="center"
          label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type==1">菜单</span>
            <span v-if="scope.row.type==2">功能</span>
          </template>
        </el-table-column>
         <el-table-column
          align="center"
          label="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作">
          <template slot-scope="scope">
            <el-button
              class="color-blue link-button"
              type="text"
              v-auth="permission.AUTHORITY_DEPLOY_EDIT"
              @click="edit(scope.row.id)">编辑
            </el-button>
            <el-button
              class="color-blue deleteButton link-button"
              v-auth="permission.AUTHORITY_DEPLOY_DELETE"
              type="text" 
              @click="deleteAuthority(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <add ref="modalRef" @search="search"></add>
  </div>
</template>

<script>
import add from "./dialogs/add";
export default {
  components: {add},
  data(){
    return{
      total: 0,
      searchFormData: {
        page: 1,
        perPage: 10,
      },
      searchControlData: [
        {
          type: 'input',
          label: '权限名',
          placeholder: '请输入权限名',
          key: 'name'
        },
        {
          type: 'input',
          label: '权限key',
          placeholder: '请输入权限key',
          key: 'authorizedKey'
        },
        {
          type: 'input',
          label: '接口路由',
          placeholder: '请输入接口路由',
          key: 'routeUrl'
        },
        {
          type: 'select',
          label: '权限类型',
          placeholder: '请选择类型',
          key: 'type',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '菜单',
              value: '1'
            },
            {
              label: '功能',
              value: '2'
            },
          ]
        }
      ],
      tableData: [],
    }
  },
  mounted(){
    this.search()
  },
  methods:{
    addAuthorityDeploy() {
      this.$refs.modalRef.show('add')
    },
    edit(id) {
      this.$refs.modalRef.loadData(id)
      this.$refs.modalRef.show('edit')
    },
    deleteAuthority(data) {
      this.$dialogs
      .confirm({
        content: `确定删除${data.name}该权限节点？`,
      })
      .then(() => {
        this.$apis.authorityDeployServer.delete({
          id:data.id
        }).then(res => {
          if (res['code'] == 0) {
            this.search()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })
      })
      .catch((val) => {});

    },
    search(data) {
      if (data) {
        Object.assign(this.searchFormData, data)
        this.searchFormData.page = 1
      }
      this.$apis.authorityDeployServer.queryPage(this.searchFormData).then(res => {
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total
          this.tableData = res.data.data
        } else {
          this.total = 0
          this.tableData = []
        }
      })
    },
    handleSizeChange(val) {
      this.searchFormData.perPage = val
      this.search()
    },
    handleCurrentChange(val) {
      this.searchFormData.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-table .cell{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
</style>