<template>
  <div :class="{authWarp:checkData.chidren,flex:checkData.type == 'control'}">
    <div :class="{flexDis:checkData.chidren,flex:checkData.type == 'control'}" >
      <el-checkbox  
        v-model="checkData.checked" 
        @change="selectRole(checkData)"
        :class="{flex:checkData.type == 'control'}">
        {{checkData.label}}
      </el-checkbox>
      <br v-if="checkData.chidren&&checkData.index">
      <span  v-for="(item,index) in checkData.chidren" :key="index" >
          <role-check :checkData="item" @isParentChecked="isParentChecked"></role-check>
      </span>       
    </div>
  </div>
</template>

<script>
export default {
  name:'role-check',
  props: {
    checkData: {
      type: Object ,
      required: true
    },
  },
  data(){
    return{

    }
  },

  methods:{
    selectRole(currentData){
      this.isChidrenChecked(currentData)
    },
    //子集是否选中：通过循环子集chidren判断选中
    isChidrenChecked(currentItem){
      if( currentItem.chidren){
        currentItem.chidren.forEach(item => {
          item.checked = currentItem.checked
          if(item.chidren) {
            this.isChidrenChecked(item)
          }
        })
      }
      if(this.$parent){
        this.$emit('isParentChecked');
      }
    },
    //父集是否选中：子集调用父集方法检测子集是否选中
    isParentChecked(){
      for(let i=0;i<this.checkData.chidren.length;i++){
        let item = this.checkData.chidren[i];
        if(this.checkData.index){
          this.$emit('isParentChecked');
        }
        if(item.checked){
          this.checkData.checked=true;
          this.$emit('isParentChecked');
          break;
        }else{
          this.checkData.checked=false;
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
 .authWarp{
    margin-top: 15px;
 } 
 .flexDis{
  padding-left: 20px;
 }
 .flex{
  display: inline-block;
  margin-left: 8px;
  margin-top: 3px;
 }
</style>