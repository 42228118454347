<template>
  <el-dialog
    :title="type == 'add'?'添加banner':'编辑banner'"
    :visible="dialogVisible"
    width="650px"
    @close="closeModal"
    center>
    <el-form :model="ruleForm" :rules="ruleData" ref="formRef" label-width="100px" class="demo-ruleForm">
      <el-form-item label="名称" prop="title">
          <el-input class="w360" v-model="ruleForm.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="位置" prop="type">
        <el-select class="w360"  size="medium" v-model="ruleForm.type" placeholder="位置">
          <el-option
            v-for="(item,index) in newsSelectData"
            :key="index"
            :label="item.name"
            :value="item.value">{{item.name}}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-col :span="11">
          <el-radio-group :span="16" v-model="ruleForm.state">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="2">关闭</el-radio>
          </el-radio-group>
        </el-col>
      </el-form-item>
      <el-form-item label="序号" prop="sort">
          <el-input  class="w360" placeholder="请输入序号" v-model="ruleForm.sort"></el-input>
      </el-form-item>
      <el-form-item label="生效时间" prop="datetimerange">
          <el-date-picker
            class="w360"
            unlink-panels
                          value-format="timestamp"
                          v-model="ruleForm.datetimerange"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
      </el-form-item>
      <el-form-item label="图片" prop="picUrl" :error="uploadErrorInfo">
          <wk-upload
           class="w360"
           @uploadChange="handleImgChange"
           :imgUrl="ruleForm.picUrl"
           @uploadError="handleImgError"
          ></wk-upload>
        <span  v-if="ruleForm.type == 1"> 图片尺寸2560*600</span>
        <span  v-else> 图片尺寸1200*400</span>
      </el-form-item>
      <el-form-item label="链接" prop="link">
          <el-input class="w360" :span="16" placeholder="请输入banner链接" v-model="ruleForm.link"></el-input>
      </el-form-item>
      <el-form-item class="w460 text-center">
        <el-button class="w150" type="primary" @click="onSubmit()">提交</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,

        uploadErrorInfo:'', // 上传文件错误
        type: '',
        ruleForm: {
          sort: '',  // 排序
          datetimerange: [],
          title: '', // 标题
          picUrl: '', // 资源地址
          link: '',  // 链接地址
          startTime: '',  // 有效期开始时间
          endTime: '',  // 有效期结束时间
          state: 1,  // 状态:1、启用；2、停用
          type:1, // 1：首页，2：资讯
        },
        newsSelectData:[
          {
            value:1,
            name:'首页'
          },
          {
            value:2,
            name:'资讯动态'
          },
        ],
        ruleData: {//角色表单校验
          title: [
            {required: true, message: '请填写banner名称', trigger: 'blur'},
          ],
          state: [
            {required: true, message: '请选择状态', trigger: ['blur', 'change']}
          ],
          sort: [
            {required: true, message: '请填写序号', trigger: 'blur'}
          ],
          picUrl: [
            {required: true, message: '请上传图片', trigger: ['blur', 'change']}
          ],
          type: [
            {required: true, message: '请选择位置', trigger: ['blur', 'change']}
          ],
          datetimerange: [
            {required: true, message: '请选择发布时间', trigger: 'blur'}
          ],
          /* imageURL: [
             { required: true, message: '请上传图片', trigger: ['blur','change'] }
           ],*/
        },
        imageURL: '',
      }
    },
    methods: {
      handleImgChange(res) {
        this.uploadErrorInfo = ''
        if (res['code'] == 0) {
          this.ruleForm.picUrl = res.data.link
          this.$refs['formRef'].validateField('picUrl');//对图片进行移除校验
        } else {
          this.uploadErrorInfo = result.message
        }
      },
      handleImgError(res) {
        console.log(res)
      },
      loadData(id) {
        this.$apis.bannerServer.getInfo({
          id: id
        }).then(res => {
          if (res['code'] == 0) {
            Object.assign(this.ruleForm, res.data)
            this.ruleForm.datetimerange.push(res.data.startTime)
            this.ruleForm.datetimerange.push(res.data.endTime)
          }
        })
      },
      onSubmit() {
        if (this.type == 'add') {
          this.$refs['formRef'].validate(valid => {
            if (valid) {
              this.ruleForm.startTime = this.ruleForm.datetimerange ? this.ruleForm.datetimerange[0] : '',
                this.ruleForm.endTime = this.ruleForm.datetimerange ? this.ruleForm.datetimerange[1] : '',
                this.$apis.bannerServer.addBanner(this.ruleForm).then(result => {
                  if (result.code == 0) {
                    this.$emit('search', {})
                    this.closeModal()
                  }
                })
            }
          })
        } else {
          this.$refs['formRef'].validate(valid => {
            if (valid) {
              this.ruleForm.startTime = this.ruleForm.datetimerange ? this.ruleForm.datetimerange[0] : this.ruleForm.startTime
                this.ruleForm.endTime = this.ruleForm.datetimerange ? this.ruleForm.datetimerange[1] : this.ruleForm.endTime
                this.$apis.bannerServer.updateBanner(this.ruleForm).then(result => {
                if (result.code == 0) {
                  this.$emit('search', {})
                  this.closeModal()
                }
              })
            }
          })
        }
      },
      closeModal() {
        this.dialogVisible = false
        this.showLoading = false
        this.initUploadData()
        this.resetForm()
      },
      show(type) {
        this.type = type
        this.dialogVisible = true
      },
      resetForm() {
        this.$refs['formRef'].resetFields();
      },
      // 初始化上传控件数据：进度条、错误信息
      initUploadData(){
        this.percentage = 0
        this.uploadErrorInfo = ''
        this.progresStatus = null
      },
    }
  }
</script>

<style scoped>
</style>
