<template>
  <div class="menu-box">
    <el-scrollbar style="height:100%">
      <el-menu
       class="menu-content"
       background-color="#28262C"
       text-color="#81818C"
       active-text-color="#121212"
       :default-active="$route.meta.menu"
       unique-opened
       router>
        <template v-for="(item,key) in moduleRouters" >
          <el-menu-item u-item class="menu-item" :key="key"  v-if="userInfo.authorizedMenuKey.includes(key)"  :index="item.route" @click="onGoherf(item.route)">
           
            <template slot="title">
              <svg-icon class="icon-img" :svg-name="item.icon"></svg-icon>
              {{item.value}}
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
  export default {
    computed:{
      userInfo() {
        return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
      },
      moduleRouters() {
        return this.$store.getters["navModuleStore/getModuleRouters"]; //获取导航模块菜单
      },
    },
    methods:{
      onGoherf(route){
        this.$store.commit("breadcrumbStore/clearMenuBreadcrumb");// 切换菜单，清理当前菜单的所有面包屑
        sessionStorage.clear();// 用于缓存时候，tab丢失问题(standardTestReport_tab)；
        window.location.href=route;
      }
    }
  };
</script>

<style scoped lang="scss">
  .menu-box {
    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    /deep/ .el-scrollbar__view {
      height: 100%;
    }
    /deep/ .el-menu-item.is-active {
      background-color: #c6bd96 !important;
    }
    z-index: 100;
    .menu-content {
      width: 200px;
      height: 100%;
      .menu-item {
        display: flex;
        align-items: center;
        height:70px;
        padding: 10px 20px;
        font-weight:400;
        .icon-img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }
  }
</style>
