<template>
  <div class="page">
    <div class="standardBox" v-auth="permission.UNION_SUMMARY_LIST" v-for="(item,index) in dataList" :key="index">
      <div class="titleBox">
        <p>{{item.name}} </p>
        <p v-if="item.code!='security'" >{{item.standardPassRuleName}}</p>
      </div>
      <div class="testItemBox">
        <div class="itemBox" v-for="(item2,index2) in item.children" :key="index2">
          <div class="itemTitle">
            <el-tooltip :content="item2.name"  placement="top" :disabled="isShowTooltip">
              <p @mouseover="onMouseOver(item2.name )" class="standardName"><span :ref='item2.name'>{{item2.name}}</span></p>
          </el-tooltip>
          </div>
          <div class="item" v-for="(item3,index3) in item2.children" :key="index3" :class="{itemBorder:index3!=item2.children.length-1}">
            <div class="itemNumber" :class="{warn:item3.type==2}">
              {{item3.code}}
            </div>
            <div class="memberState">
              <span v-for="(item4,index4) in item3.openState" :key="index4" 
              :class="{
                oppo:item4.memberId==2,
                vivo:item4.memberId==3,
                xiaomi:item4.memberId==1,
                close:item4.state==2
              }">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      dataList:[],
      isShowTooltip:true
    }
  },
  created(){
    this.getList();
  },
  methods:{
    getList(){
      this.$apis.unionDeployServer.querySummary().then(res=>{
        if (res['code'] == 0 && res.data) {
          this.total = res.data.total
          this.dataList = res.data
        } else {
          this.total = 0
          this.dataList = []
        }
      })
    },
    onMouseOver(str) { // 内容超出，显示文字提示内容
        const tag = this.$refs[str];
        console.log(tag);
        const parentWidth = tag[0].parentNode.offsetWidth;// 获取元素父级可视宽度要减去应用包名的长度
        const contentWidth = tag[0].offsetWidth; // 获取元素可视宽度
        this.isShowTooltip = contentWidth <= parentWidth;
    },
  }
}
</script>

<style scoped lang="scss">
.page{
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  .standardBox{
    width: 250px;
    background: #F8F6F7;
    border-radius: 10px 10px 0 0;
    .titleBox{
      display: flex;
      justify-content: space-between;
      height: 46px;
      background: #28262C;
      font-size: 16px;      
      color: #C6BD96;
      font-weight: bold;
      line-height: 46px;
      padding: 0 16px;
      border-radius: 10px 10px 0 0;
    }
    .testItemBox{
      padding: 15px;
      .itemBox{
        background: #FFFFFF;
        border: 1px solid #E1E0E0;
        border-radius: 5px;
        margin-bottom: 10px;
        .itemTitle{
          height: 41px;
          line-height: 41px;
          padding-left:22px ;
          font-weight: bold;
          background: #F8F6F7;
          .standardName{
            overflow: hidden; //块元素超出隐藏
            width: 200px;
            cursor: pointer;
            text-overflow:ellipsis;
            -o-text-overflow:ellipsis;
            -webkit-text-overflow:ellipsis;
            -moz-text-overflow:ellipsis;
            white-space:nowrap;
          }        
        }
        .item{
          display: flex;
          min-height: 40px;
          padding: 0 20px;
          align-items: center;
          justify-content: space-between;
          .itemNumber{
            width: 60px;
            color: #28262C;
          }
          .warn{
            color: #EBBA58;
          }
          .memberState{
            width: 60px;
            display: flex;
            justify-content: space-around;
            span{
              display: inline-block;
              width: 14px!important;
              height: 14px!important;
              border-radius: 50%;
            }
            .oppo{
              background: #006C32;
            }
            .vivo{
              background: #415FFF;
            }
            .xiaomi{
              background: #FF6900;
            }
            .close{
              background: #CCCCCC;
            }
          }
        }
        .itemBorder{
          border-bottom: 1px solid #E1E0E0;
        }
      }
    }
  }
}
</style>