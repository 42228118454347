<template>
  <div class="error-box ">
    <h2 v-if='$route.query.error' class="title">{{$route.query.error}}</h2>
    <h2 v-else class="title">抱歉，页面未找到</h2>
    <el-button type="primary" @click="go()">返回</el-button>
  </div>
</template>

<script>
export default {
  name: 'error',
  created(){
    
  },
  methods: {
    go(){
      this.$router.go(-1);
    }
  },
}
</script>

<style scoped lang="scss">
  .error-box{
    width: 100%;
    height:calc(100% - 54px);
    display: flex;
    flex-direction:  column;
    justify-content: center;
    align-items: center;
    .title{
      font-size: 30px;
      margin: 20px 0;
    }
  }
</style>
