<template>
    <el-dialog
      title="关闭申诉"
      :visible="dialogVisible"
      width="800px"
      @close="close"
      :close-on-click-modal="false"
      center>
      <el-form class="form-box" :model="rulesData" :rules="rules" ref="refForm" label-width="100px">
        <el-form-item class="form-item" label="处理方">
            <span v-for="(item,index) in formData.deal" :key="item" >
              <span v-if="index">、</span>
              <span v-if="item == '1'">兼容性标准小组</span>
              <span v-if="item == '2'">稳定性标准小组</span>
              <span v-if="item == '3'">功耗标准小组</span>
              <span v-if="item == '4'">性能标准小组</span>
              <span v-if="item == '5'">安全标准小组</span>
              <span v-if="item == '6'">联盟及其他</span>
            </span>
        </el-form-item>
        <el-form-item class="manage-box" label="处理记录"  prop="dealDesc">
          <el-input
            class="manage"
            type="textarea"
            :autosize="{ minRows: 8}"
            placeholder="请输入内容"
            v-model="rulesData.dealDesc">
          </el-input>
        </el-form-item>
        <el-form-item class="form-item" label="">
          <div>还可输入 {{500-rulesData.dealDesc.length>0?500-rulesData.dealDesc.length:0}} 字</div>
        </el-form-item>
      </el-form>
      <div class="text-center margin-t30  margin-b10">
          <el-button size="medium" type="info" @click="close">取消</el-button>
          <el-button size="medium" type="primary" @click="onSubmit()">提交</el-button>
      </div>
    </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      //显示dialog
      dialogVisible: false,

      //数据
      formData: {},
      rulesData: {
        dealDesc:'',
      },
      rules: {
        dealDesc: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    //提交
    onSubmit() {
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
          this.$apis.appealServer.closeAppeal({
            id:this.formData.id,//	必选	string	id
            dealDesc:this.rulesData.dealDesc,//	必选	string	处理描述
          }).then(res => {
            if (res['code'] == 0) {
              this.$emit("callback",'update');
              this.dialogVisible = false;
            } else {
              this.$message({
                type: 'error',
                message: res['message']
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //获取申诉详情
    queryAppealDetail(id) {
      this.$apis.appealServer.queryAppealDetail({id}).then(res => {
        if (res['code'] == 0) {
          this.formData=res['data'];
        } 
      });
    },
    //展示dialog，供父组件调用
    show(id) {
      this.dialogVisible = true;
      if (id) this.queryAppealDetail(id);
    },
    close() {
      this.dialogVisible = false;
      this.$emit("callback");
    }
  }
};
</script>

<style scoped lang="scss">
.form-box{
  padding-right: 10px;
  .form-item {
    margin-bottom: 0;
  }
  .manage-box{
    margin-bottom: 10px;
    .manage {
      padding-top: 12px;
      line-height: 24px;
      /deep/ .el-textarea__inner{
        padding: 10px ;
      }
    }
  }
}

</style>