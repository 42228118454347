<template>
  <div class="footBox ">
    <div class="ICP-centent text-center">
        Copyright © 2020 itgsa.com All rights reserved.
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a class="ICP-url" href="http://beian.miit.gov.cn/">京ICP备14038000号-18</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'foot',
}
</script>

<style scoped lang="scss">
  .footBox{
    // position: fixed;
    // bottom: 0;
    width: 100%;
    // height: 80px;
    z-index: 99;
    .ICP-centent{
      width:800px;
      margin:auto;
      line-height: 54px;
      color: #757581;
      .ICP-url{
        color: #757581;
      }
    }
  }
</style>
