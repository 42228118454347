const routes = [
  {
    path: '/accountBind',
    name: '',
    redirect:'/accountBind/list'
  },
  {
    path: '/accountBind/list',
    name: '',
    meta:{module:'develop_manage',menu:'/accountBind/list',title:'厂商账号绑定'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/accountBind/list.vue')
  },
]

export default routes;
