let  apiBase={
    getTaskList:'/api/task/queryPage',//任务列表
    addTask:'/api/task/addTask',//添加任务
    queryPageByTaskId:'/api/task/queryPageByTaskId',//金标认证详情列表
    queryTestReport:'/api/task/queryTestReport',//应用金标测试情况
    getMemberList:'/api/accountBind/memberList', // 厂商列表
    queryTestStandard:'/api/task/queryTermPass',//各云测情况
    queryMemberDeviceList:'/api/task/queryMemberDeviceList',//机型测试列表
    queryDeviceList:'/api/task/queryDeviceList',//机型列表
    queryTermPass:'/api/task/queryTermPass',//各个测试项情况
    queryIdentifyMemberList:'/api/task/queryIdentifyMemberList',//任务详情厂商列表
    queryApkDetail: '/api/task/queryApkDetail',//联盟检测认证详情
    queryApkDeviceList:'/api/task/queryApkDeviceList',//应用测试机型列表
    queryTermReport:'/api/task/queryTermReport',//各个测试项情况(应用)
    queryTestMemeberStandard:'/api/task/queryTestMemberStandard',//各云测情况图表
    queryFailTermDetail:'/api/task/queryFailTermDetail',//查看未通过项详情
    enableMemberList:'/api/accountBind/enableMemberList',// 没有联盟的厂商列表
    queryTermReportDetail:'/api/task/queryTermReportDetail',//各个测试项情况说明
    queryMemberApkDetail:'/api/task/queryMemberApkDetail',//厂商应用详情(应用)
    taskIdentifyChartExport:'/api/task/taskIdentifyChartExport',//检测概况导出
    taskIdentifyStandardExport:'/api/task/taskIdentifyStandardExport',//标准检测明细导出
    taskIdentifyExport:'/api/task/taskIdentifyExport',//应用测试明细导出
    taskIdentifyMemberExport:'/api/task/taskIdentifyMemberExport',//厂商测试明细导出
    downloadFile:'/api/tool/downloadFile',//附件下载
    queryListTask:'/api/task/queryList',//获取任务列表
    taskMerge:'/api/task/taskMerge',//合并任务
  }
let UnionAttestServerObject = null
class UnionAttestServer{
  constructor(){}

  // 获取任务列表
  getTaskList(data={}){
    return $http({
      url: apiBase.getTaskList,
      method: 'post',
      data: data,
    })
  }

  addTask(data={}){
    return $http({
      url: apiBase.addTask,
      method: 'post',
      data: data,
      timeout: 0
    })
  }

  queryPageByTaskId(data={}){
    return $http({
      url: apiBase.queryPageByTaskId,
      method: 'post',
      data: data,
      
    })
  }

  queryTestReport(data={}){
    return $http({
      url: apiBase.queryTestReport,
      method: 'post',
      data: data,
      
    })
  }

  queryTestStandard(data={}){
    return $http({
      url: apiBase.queryTestStandard,
      method: 'post',
      data: data,
      
    })
  }

  queryMemberDeviceList(data={}){
    return $http({
      url: apiBase.queryMemberDeviceList,
      method: 'post',
      data: data,
      
    })
  }

  queryDeviceList(data={}){
    return $http({
      url: apiBase.queryDeviceList,
      method: 'post',
      data: data,
      
    })
  }

  queryTermPass(data={}){
    return $http({
      url: apiBase.queryTermPass,
      method: 'post',
      data: data,
      
    })
  }

  queryIdentifyMemberList(data={}){
    return $http({
      url: apiBase.queryIdentifyMemberList,
      method: 'post',
      data: data,
      
    })
  }

  queryApkDetail(data={}){
    return $http({
      url: apiBase.queryApkDetail,
      method: 'post',
      data: data,
      
    })
  }

  queryApkDeviceList(data={}){
    return $http({
      url: apiBase.queryApkDeviceList,
      method: 'post',
      data: data,
      
    })
  }

  queryTermReport(data={}){
    return $http({
      url: apiBase.queryTermReport,
      method: 'post',
      data: data,
      
    })
  }

  queryTestMemeberStandard(data={}){
    return $http({
      url: apiBase.queryTestMemeberStandard,
      method: 'post',
      data: data,
      
    })
  }

  queryFailTermDetail(data={}){
    return $http({
      url: apiBase.queryFailTermDetail,
      method: 'post',
      data: data,
      
    })
  }
  
  enableMemberList(data={}){
    return $http({
      url: apiBase.enableMemberList,
      method: 'post',
      data: data,
      
    })
  }

  queryTermReportDetail(data={}){
    return $http({
      url: apiBase.queryTermReportDetail,
      method: 'post',
      data: data,
      
    })
  }

  queryMemberApkDetail(data={}){
    return $http({
      url: apiBase.queryMemberApkDetail,
      method: 'post',
      data: data, 
    })
  }

  taskIdentifyChartExport(data={}){
    return $http({
      url: apiBase.taskIdentifyChartExport,
      method: 'post',
      data: data,
      timeout: 0
    })
  }

  taskIdentifyStandardExport(data={}){
    return $http({
      url: apiBase.taskIdentifyStandardExport,
      method: 'post',
      data: data,
      timeout: 0
    })
  }

  taskIdentifyExport(data={}){
    return $http({
      url: apiBase.taskIdentifyExport,
      method: 'post',
      data: data,
      timeout: 0
    })
  }

  taskIdentifyMemberExport(data={}){
    return $http({
      url: apiBase.taskIdentifyMemberExport,
      method: 'post',
      data: data,
      timeout: 0
    })
  }

  downloadFile(data){
    return $http({
      url: apiBase.downloadFile,
      method: 'get',
      data: data,
    })
  }

  queryListTask(data={}){
    return $http({
      url: apiBase.queryListTask,
      method: 'post',
      data: data,
    })
  }
  taskMerge(data={}){
    return $http({
      url: apiBase.taskMerge,
      method: 'post',
      data: data,
    })
  }
}


function singleUnionAttestServer(){
  if(UnionAttestServerObject === null){
    UnionAttestServerObject = new UnionAttestServer
  }
  return UnionAttestServerObject
}

export default singleUnionAttestServer()