<template>
  <div class="page">
    <wk-search class="wrapBox" :search-control-data="searchControlData" @search="search($event)"></wk-search>
    <div class="wrapBox" v-auth="permission.APPEAL_ACTION_LIST">
      <div class="flex justify-space-between margin-b10" >
        <div class="line-40" >共{{total}}条数据</div>
      </div>
      <el-table class="tableBox W100" :data="tableData" stripe >
        <el-table-column fixed align="center" width="60" type="index" label="序号"></el-table-column>
        <el-table-column fixed prop="apkName" label="申诉应用"></el-table-column>
        <el-table-column prop="email" label="开发者账号"></el-table-column>
        <el-table-column prop="contactName" label="公司主体/开发者名称"></el-table-column>
        <el-table-column prop="type" label="申诉类型">
          <template slot-scope="scope">
            <p v-for="(item) in scope.row.type" :key="item">
              <span v-if="item == '1'">兼容性问题</span>
              <span v-if="item == '2'">稳定性问题</span>
              <span v-if="item == '3'">功耗问题</span>
              <span v-if="item == '4'">性能问题</span>
              <span v-if="item == '5'">安全问题</span>
              <span v-if="item == '6'">其他问题</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="申诉时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column width="70" align="center" prop="state" label="状态">
          <template slot-scope="scope">
              <span v-if="scope.row.state == 1">待审核</span>
              <span v-if="scope.row.state == 2">无效反馈</span>
              <span v-if="scope.row.state == 3">待处理</span>
              <span v-if="scope.row.state == 4">已处理</span>
          </template>
        </el-table-column>
        <el-table-column prop="reviewTime" label="审核时间">
          <template slot-scope="scope">
            <span>{{ scope.row.reviewTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dealTime" label="处理时间">
          <template slot-scope="scope">
            <span>{{ scope.row.dealTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deal" label="处理方">
          <template slot-scope="scope">
            <p v-for="(item) in scope.row.deal" :key="item">
              <span v-if="item == '1'">兼容性标准小组</span>
              <span v-if="item == '2'">稳定性标准小组</span>
              <span v-if="item == '3'">功耗标准小组</span>
              <span v-if="item == '4'">性能标准小组</span>
              <span v-if="item == '5'">安全标准小组</span>
              <span v-if="item == '6'">联盟及其他</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button v-auth="permission.APPEAL_ACTION_REVIEW" v-if="scope.row.state == 1" class="color-blue link-button" type="text" @click="onAudit(scope.row.id)">
              审核
            </el-button>
            <el-button v-auth="permission.APPEAL_ACTION_DETAIL" v-else class="color-blue link-button" type="text" @click="onLook(scope.row.id)">
              查看
            </el-button>
            <el-button v-auth="permission.APPEAL_ACTION_CLOSE" v-if="scope.row.state == 3" class="color-blue link-button" type="text" @click="onClose(scope.row.id)">
              关闭
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <component ref="componentView" :is="componentView"  @callback="onCallback" :key="componentKey"></component>
  </div>
</template>
<script>
  import audit from './dialogs/audit';
  import look from './dialogs/look';
  import close from './dialogs/close';
  export default {
    components: {audit,look,close},
    data() {
      return {
        //搜索form组件
        searchControlData: [
          {
            type: "input",
            label: "申诉应用",
            placeholder: "申诉应用",
            key: "apkName"
          },
          {
            type: 'rangDate',
            label: '选择时间',
            key: 'rangDate',
            key: "time",
          },
          {
            type: "select",
            label: "状态",
            placeholder: "状态",
            key: "state",
            option: [
              {
                label: "全部",
                value: "0"
              },
              {
                label: "待审核",
                value: "1"
              },
              {
                label: "待处理",
                value: "3"
              },
              {
                label: "已处理",
                value: "4"
              },
              {
                label: "无效反馈",
                value: "2"
              },
            ]
          }
        ],
        //列表数据
        tableData: [],
        total: 0,
        //搜索form数据
        searchFormData: {
          page: 1,
          perPage: 10
        },
        componentView:'',
        componentKey: 0,
      };
    },
    created() {
      this.search();
    },
    methods: {
      //搜索方法
      search(data) {
        if (data) {
          this.searchFormData = {
            page: 1,
            perPage: this.searchFormData.perPage,
            startTime:data.time?data.time[0]:'',
            endTime:data.time?data.time[1]:'',
            ...data
          };
        }
        this.$apis.appealServer.queryAppealList(this.searchFormData).then(res => {
          if (res['code'] == 0 && res.data.data) {
            this.total = res.data.total;
            this.tableData = res.data.data;
          } else {
            this.total = 0;
            this.tableData = [];
          }
        });
      },
      //点击审核
      onAudit(id) {
        this.componentView='audit';
        this.$nextTick(()=>{
          this.$refs["componentView"].show(id);
        })
      },
      //点击查看
      onLook(id) {
        this.componentView='look';
        this.$nextTick(()=>{
          this.$refs["componentView"].show(id);
        })
      },
      //点击关闭
      onClose(id) {
        this.componentView='close';
        this.$nextTick(()=>{
          this.$refs["componentView"].show(id);
        })
      },
      //编辑成功后回调
      onCallback(type) {
        this.componentKey= new Date().getTime();
        if(type == 'update') this.search();
      },
      //table点击更改一页多少行
      handleSizeChange(val) {
        this.searchFormData.perPage = val;
        this.search();
      },
      //table点击修改页码
      handleCurrentChange(val) {
        this.searchFormData.page = val;
        this.search();
      },
    }
  };
</script>
<style  scoped lang="scss">
  /deep/ .el-message-box--center .el-message-box__message {
    font-size: 20px;
  }
</style>
