<template>
  <div class="page">
    <wk-search class="wrapBox" :search-control-data="searchControlData" @search="search($event)"></wk-search>

    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
      </div>
      <el-table class="tableBox" :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" width="60" type="index" label="序号"></el-table-column>
        <el-table-column prop="apkName" label="应用名称"></el-table-column>
        <el-table-column width="70" prop="apkIcon" label="ICON">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.apkIcon"
              :preview-src-list="[scope.row.apkIcon]"
            >
              <template slot="error">
                <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="apkPackage" label="应用包名"></el-table-column>
        <el-table-column prop="versionName" label="最新版本号">
          <template slot-scope="scope">
            <span>{{scope.row.versionName}}({{ scope.row.versionCode}})</span>
          </template>
        </el-table-column>
        <el-table-column prop="accountId" label="开发者ID"></el-table-column>
        <el-table-column prop="companyName" label="企业名称"></el-table-column>
        <el-table-column prop="modifyTime" label="提交/更新时间">
          <template slot-scope="scope">
            <span>{{ scope.row.modifyTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="reviewTime" label="审核时间">
          <template slot-scope="scope">
            <span>{{ +scope.row.currReviewTime == 0 ?scope.row.prevReviewTime:scope.row.currReviewTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="currReviewState" label="状态">
          <template slot-scope="scope">
            <div v-if="isShowHistoryInfoBtn(scope.row)">
              <p>{{ scope.row.prevReviewState | reviewAPPStateString }}</p>
              <p>更新信息{{ scope.row.currReviewState | reviewAPPStateString }}</p>
            </div>
            <div v-else>
              <p>{{ scope.row.currReviewState | reviewAPPStateString }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <div>
              <el-button
                v-auth="permission.APPLICATION_ACTION_CHECK"
                class="color-blue link-button"
                v-if="isShowReviewBtn(scope.row)"
                type="text"
                @click="reviewInfo(scope.row)"
              >审核信息</el-button>
            </div>
            <div>
              <el-button
                class="color-blue link-button"
                v-if="isShowInfoBtn(scope.row)"
                type="text"
                @click="showInfo(scope.row)"
              >查看信息</el-button>
            </div>
            <div>
              <el-button
                class="color-blue link-button"
                v-if="isShowNewInfoBtn(scope.row)"
                type="text"
                @click="showNewsInfo(scope.row)"
              >查看更新信息</el-button>
            </div>
            <div>
              <el-button
                class="color-blue link-button"
                v-if="isShowHistoryInfoBtn(scope.row)"
                type="text"
                @click="showhistoryInfo(scope.row)"
              >查看历史信息</el-button>
            </div>
            <div>
              <el-button
                class="color-blue link-button"
                v-if="isShowReasonBtn(scope.row)"
                type="text"
                @click="showReason(scope.row)"
              >不通过原因</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <info ref="dialog" @update="onUpdate"></info>
  </div>
</template>
<script>
  import info from "./dialogs/info";

  export default {
    components: { info },
    data() {
      return {
        //搜索form组件
        searchControlData: [
          {
            type: "input",
            label: "应用名称",
            placeholder: "应用名称",
            key: "apkName"
          },
          {
            type: "input",
            label: "应用包名",
            placeholder: "应用包名",
            key: "apkPackage"
          },
          {
            type: "input",
            label: "开发者ID",
            placeholder: "开发者ID",
            key: "accountId"
          },
          {
            type: "select",
            label: "状态",
            placeholder: "请选择状态",
            key: "state",
            option: [
              {
                label: "全部",
                value: "0"
              },
              {
                label: "待审核",
                value: "1"
              },
              {
                label: "审核通过",
                value: "2"
              },
              {
                label: "审核不通过",
                value: "3"
              },
              {
                label: "信息已过期",
                value: "4"
              }
            ]
          }
        ],
        //列表数据
        tableData: [],
        total: 0,
        //搜索form数据
        searchFormData: {
          page: 1,
          perPage: 10
        }
      };
    },
    created() {
      this.search();
    },
    methods: {
      //table修改一页多少行
      handleSizeChange(val) {
        this.searchFormData.perPage = val;
        this.search();
      },
      //修改页码
      handleCurrentChange(val) {
        this.searchFormData.page = val;
        this.search();
      },
      //搜索方法
      search(data) {
        if (data) {
          Object.assign(this.searchFormData, data);
          this.searchFormData.page = 1
        }

        this.$apis.applicationServer.queryList(this.searchFormData).then(res => {
          if (res['code'] == 0 && res.data.data) {
            this.total = res.data.total;
            this.tableData = res.data.data;
          } else {
            this.total = 0;
            this.tableData = [];
          }
        });
      },
      //点击审核
      reviewInfo(data) {
        this.$refs["dialog"].show("review", { id: data.currReviewId });
      },
      //审核状态：1审核中；2审核成功；3审核失败
      //是否显示审核按钮
      isShowReviewBtn(data) {
        if (+data.currReviewState == 1) {
          return true;
        }
        return false;
      },

      //点击查看信息
      showInfo(data) {
        this.$refs["dialog"].show("detail", { id: data.currReviewId });
      },
      //是否显示查看信息安按钮
      isShowInfoBtn(data) {
        if (+data.currReviewState != 1 && +data.prevReviewState == 0) {
          return true;
        }
        return false;
      },
      //点击查看最新信息
      showNewsInfo(data) {
        this.$refs["dialog"].show("detail", { id: data.currReviewId });
      },
      //是否显示查看最新信息按钮
      isShowNewInfoBtn(data) {
        if (+data.currReviewState != 1 && +data.prevReviewState != 0) {
          return true;
        }
        return false;
      },
      //点击历史信息
      showhistoryInfo(data) {
        this.$refs["dialog"].show("detail", { id: data.prevReviewId });
      },
      //是否显示历史信息按钮
      isShowHistoryInfoBtn(data) {
        if (+data.prevReviewState != 0) {
          return true;
        }
        return false;
      },
      //展示失败原因
      showReason(data) {
        let reviewId = data.currReviewId;
        if (+data.prevReviewState == 3) {
          reviewId = data.prevReviewId;
        }
        if (+data.currReviewState == 3) {
          reviewId = data.currReviewId;
        }

        this.$apis.applicationServer.queryDetail({ id: reviewId }).then(res => {
          if (res['code'] == 0) {
            let formData = res.data;
            this.$alert(formData.reason, "审核失败原因", {
              confirmButtonText: "确定",
              callback: action => {}
            });
          } else {
            this.$message({
              message: "失败",
              type: "error"
            });
          }
        });
      },
      //是否显示审核失败原因按钮
      isShowReasonBtn(data) {
        if (+data.currReviewState == 3 || +data.prevReviewState == 3) {
          return true;
        }
        return false;
      },
      //编辑成功后回调
      onUpdate() {
        this.search();
      }
    }
  };
</script>
<style>
  .el-message-box--center .el-message-box__message {
    font-size: 20px;
  }

  .cancelButton {
    width: 98px;
    margin-left: 14px;
    margin-top: 14px;
  }

  .confirmButton {
    width: 98px;
    margin-left: 60px !important;
  }

</style>
