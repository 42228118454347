<template>
  <el-dialog
    title="审核"
    :visible.sync="dialogVisible"
    width="800px"
    @close="close"
    :close-on-click-modal="false"
    center>
    <el-form class="form-box" :model="rulesData" :rules="rules" ref="refForm" label-width="100px">
      <el-form-item class="form-item" label="申诉平台">
        <span v-for="(item,index) in formData.memberName" :key="item">
          <span v-if="index">、</span>
          <span>{{item}}</span>
        </span>
      </el-form-item>
      <el-form-item class="form-item" label="问题类型" v-if="formData.type">
        <span v-for="(item,index) in formData.type" :key="item">
          <span v-if="index">、</span>
          <span v-if="item == '1'">兼容性问题</span>
          <span v-if="item == '2'">稳定性问题</span>
          <span v-if="item == '3'">功耗问题</span>
          <span v-if="item == '4'">性能问题</span>
          <span v-if="item == '5'">安全问题</span>
          <span v-if="item == '6'">其他问题</span>
        </span>
      </el-form-item>
      <el-form-item class="form-item describe-box" label="问题描述"  v-if="formData.appealPicUrl">
        <div class="describe" v-html="textareaFormat(formData.appealDesc)" ></div>
        <div class="">
          <el-image class="screenshot" v-for="item in formData.appealPicUrl" :key="item" :src="item" :preview-src-list="formData.appealPicUrl"></el-image>
        </div>
      </el-form-item>
      <el-form-item label="是否有效: " >
        <el-radio-group v-model="rulesData.state" prop="state">
          <el-radio :label="2">无效申诉</el-radio>
          <el-radio :label="3">有效申诉</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="manage-box" label="处理方: " prop="deal" v-if="rulesData.state!=2">
          <el-checkbox-group class="manage" v-model="rulesData.deal">
            <el-checkbox label="1" name="deal">兼容性标准小组</el-checkbox>
            <el-checkbox label="2" name="deal">稳定性标准小组</el-checkbox>
            <el-checkbox label="3" name="deal">功耗标准小组</el-checkbox>
            <el-checkbox label="4" name="deal">性能标准小组</el-checkbox>
            <el-checkbox label="5" name="deal">安全标准小组</el-checkbox>
            <el-checkbox label="6" name="deal">联盟及其他</el-checkbox>
          </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div class="text-center margin-t30  margin-b10">
        <el-button size="medium" type="info" @click="close">取消</el-button>
        <el-button size="medium" type="primary" @click="onSubmit()">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      //显示dialog
      dialogVisible: false,

      //数据
      formData: {},
      rulesData: {
        state:2,
        deal:[],
      },
      rules: { 
        state: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        deal: [
          { type: 'array', required: true, message: '请至少选择一个处理方', trigger: 'change' }
        ],
      }
    };
  },
  methods: {
    //获取申诉详情
    queryAppealDetail(id) {
      this.$apis.appealServer.queryAppealDetail({id}).then(res => {
        if (res['code'] == 0) {
          this.formData=res['data'];
        } 
      });
    },
    //提交
    onSubmit() {
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
          this.$apis.appealServer.reviewAppeal({
            id:this.formData.id,//	必选	string	id
            deal:this.rulesData.deal.toString(),//	必选	string	处理方，多个用逗号隔开
            state:this.rulesData.state,//	必选	int	状态
          }).then(res => {
            if (res['code'] == 0) {
              this.$emit('callback','update');
              this.dialogVisible = false;
            } else {
              this.$message({
                type: 'error',
                message: res['message']
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    textareaFormat(text) {
      return text.replace(/\n|\r\n/g, '<br/>')
    },
    //展示dialog，供父组件调用
    show(id) {
      this.dialogVisible = true;
      if (id) this.queryAppealDetail(id);
    },
    close() {
      this.dialogVisible = false;
      this.$emit("callback");
    }
  }
};
</script>

<style scoped lang="scss">
.form-box{
  padding-right: 15px;
  .form-item {
    margin-bottom: 0;
  }
  .describe-box{
    .describe{
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 24px;
    }
    .screenshot {
      display: inline-block;
      margin-right:  20px;
      width: 80px;
      height: 120px;    
      border: 1px solid #ccc;
    }
  }
  .manage-box{
    .manage {
      padding-top: 8px;
      line-height: 24px;
    }
  }
}

</style>