<template>
  <div class="page">
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
        <div>
          <el-button v-auth="permission.TEST_REPORT_ADD_PEOPLE" class="search-button" type="primary" @click="addUser()">添加人员</el-button>
        </div>
      </div>
      <el-table
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        class="tableBox W100"
      >
        <el-table-column width="60" type="index" label="序号"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="厂商" prop="memberName"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="添加时间" prop="modifyTime">
          <template slot-scope="scope">
            <span>{{ scope.row.modifyTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="添加人" prop="adminName"></el-table-column>
        <el-table-column label="操作" v-if="userInfo.roleName != '普通账号'">
          <template slot-scope="scope"  v-if=" scope.row.memberId == userInfo.memberId || userInfo.memberCode== 'ita'">
            <el-button v-auth="permission.TEST_REPORT_DELETE_PEOPLE" class="color-blue link-button" type="text"
              @click="deleteEmail(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <add-user ref="modalRef"  @search="getReportList"></add-user>
  </div>
</template>

<script>
import addUser from "./dialogs/addUser";

export default {
  components: { addUser },
  data() {
    return {
      tableData:[],
      total:0,
      searchFormData: {
        type:2,
        page: 1,
        perPage: 10,
      },
    };
  },
  created() {
    this.getReportList();
  },
  computed: {
    userInfo() {
      return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
    },
  },
  methods: {
    getReportList(){
      this.$apis.emailAlertServer.emailQueryList(this.searchFormData).then(res => {
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total
          this.tableData = res.data.data
        } else {
          this.total = 0
          this.tableData = []
        }
      })
    },
    //点击删除
    deleteEmail(data) {
      // state: banner的当前状态： 1：开启；2：关闭
        this.$confirm('点击删除将会删除该邮箱，是否删除？', `邮箱：${data.email}?`, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'cancelButton',
          confirmButtonClass: 'confirmButton',
          type: 'warning',
          center: true
        }).then(() => {
          this.$apis.emailAlertServer.deleteEmail({
            id: data.id
          }).then(res => {
            if (res['code'] == 0) { 
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getReportList()
            }
          })
        })
      },
    //点击添加
    addUser() {
      this.$refs.modalRef.show('add')
    },

    //table点击更改一页多少行
    handleSizeChange(val) {
      this.searchFormData.perPage = val;
      this.getReportList();
    },
    //table点击修改页码
    handleCurrentChange(val) {
      this.searchFormData.page = val;
      this.getReportList();
    },
  },
};
</script>

<style>
</style>