<template>
  <div class="head-box">
    <div class="logo-box">
      <svg-icon class="logoImg" svg-name="icon"></svg-icon>
      <h3 class="title">移动智能终端联盟管理后台</h3>
    </div>
    <div class="nav-box">
        <template v-for="(item,name) in moduleRouters"  >
          <div class="nav-item" 
            :key="name"
            v-if="userInfo.authorizedMenuKey.includes(name)"
            :class="{active:name == moduleName}"
            @click="changeModule(name)">
            {{item}}
          </div>
         
        </template>
    </div>
    <div class="account-menu">
      <span class="userBox">{{userInfo.name}}</span>
      <span class="userBox cursor" @click="modifyPassword">修改密码</span>
      <span class="cursor" @click="logout()">退出</span>
    </div>
    <modify-pwd-component ref="modifyRef"></modify-pwd-component>
  </div>
</template>

<script>
import modifyPwdComponent from "@/views/login/modifyPassword";

export default {
  computed: {
    userInfo() {
      return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
    },
    moduleName() {
      return this.$store.getters["navModuleStore/getModuleName"]; //获取导航模块名称
    },
  },
  components: {modifyPwdComponent},
  watch: {
    $route(to, from) {
      this.$store.commit('navModuleStore/setNavModule', to.meta.module);//设置归属模块
    }
  },
  created(){
    this.$store.commit('navModuleStore/setNavModule', this.$route.meta.module);
  },
  data() {
    return {
      moduleRouters:{
        'develop_manage':'开发者管理',
        'gold_action_manage':'金标管理',
        'operate_manage':'运营管理',
        'basic_functions_manage':'基础功能',
      },
    };
  },
  methods: {
    changeModule(name){
      this.$store.commit('navModuleStore/setNavModule', name);
    },
    modifyPassword(){
      this.$refs.modifyRef.show();
    },
    logout() {
      this.$apis.accountServer.loginOut().then(res => {
        if (res['code'] == 0) {
          localStorage.clear();
          // 先执行清除本地缓存，再跳转登录页
          this.$router.push({
            path: "/login" ,
            query:{
              path: this.$route.fullPath
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.head-box {
  display: flex;
  justify-content: space-between;
  background: #121212;
  .logo-box {
    display: flex;
    padding: 15px 50px;
    align-items: center;
    line-height: 50px;
    .logoImg {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      color: '#000000';
    }
    .title {
      color: #E7DCAE;
      font-size: 18px;
    }
  }
  .nav-box{
    flex: 1;
    display: flex;
    .nav-item{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 30px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      &:hover{
        color: #E7DCAE ;
      }
    }
    .active{
      color: #E7DCAE ;
      font-weight: bold;
      &::after{
        position: absolute;
        bottom: 14px;
        content: '';
        width: 30%;
        height: 4px;
        border-radius: 2px;
        background: #c6bd96;
      }
    }
    
  }
  .account-menu {
    padding: 15px 50px;
    line-height: 50px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    .userBox {
      margin-right: 25px;
      &:after {
        content: "";
        display: inline-block;
        position: relative;
        top: 4px;
        left: 11px;
        width: 1px;
        height: 20px;
        background-color: white;
      }
    }
    .divideLine {
      display: block;
      height: 44px;
      width: 2px;
      color: white;
    }
  }
}
</style>