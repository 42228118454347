const routes = [
    {
      path: '/batchSync',
      name: '',
      redirect:'/batchSync/list'
    },
    {
      path: '/batchSync/list',
      name: '',
      meta:{module:'basic_functions_manage',menu:'/batchSync/list',title:'批量同步日志'},//menu 用于所属右侧菜单栏
      component: () => import('@/views/batchSync/list.vue')
    },
  ]
  
  export default routes;
  