let apiBase = {
  queryList: '/api/accountWhite/queryList',                //  列表
  getInfo: '/api/accountWhite/getInfo',                    //  详情
  addInfo: '/api/accountWhite/addInfo',                    //  增加
  deleteInfo: '/api/accountWhite/deleteInfo',              //  删除
  export: '/api/accountWhite/export',                      // 导出
}
let AccountWhiteServerObject = null
class AccountWhiteServer{
  constructor(){}
  queryList(data={}){
    return $http({
      url: apiBase.queryList,
      method: 'post',
      data: data,
    })
  }

  getInfo(data={}){
    return $http({
      url: apiBase.getInfo,
      method: 'post',
      data: data,
    })
  }

  addInfo(data={}){
	  return $http({
      method: 'post',
      url: apiBase.addInfo,
      data:data
    })
   }

  deleteInfo(data={}){
	  return $http({
      method: 'post',
      url: apiBase.deleteInfo,
      data:data
    })
   }

  export(data={}){
    return $http({
      url: apiBase.export,
      method: 'post',
      data: data,
    })
  }
}
function accountWhiteServer(){
  if(AccountWhiteServerObject === null){
    AccountWhiteServerObject = new AccountWhiteServer()
  }
  return AccountWhiteServerObject
}
export default accountWhiteServer()