<template>
  <div class="page">
		<el-form label-position="left" :model="ruleForm" :rules="ruleData" ref="formRef" label-width="100px" class="demo-ruleForm">
			<el-form-item label="标题" prop="title">
				<el-input show-word-limit :maxlength="20" class="w400" v-model="ruleForm.title" placeholder="请输入名称"></el-input>
			</el-form-item>
			<el-form-item label="发布时间" prop="publishTime">
				<el-date-picker
					class="w400"
					unlink-panels
					value-format="timestamp"
					v-model="ruleForm.publishTime"
					placeholder="请选择发布时间"
					type="datetime">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="状态" prop="state">
				<el-col :span="11">
					<el-radio-group :span="16" v-model="ruleForm.state">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="2">关闭</el-radio>
					</el-radio-group>
				</el-col>
			</el-form-item>
			<el-form-item label="排序" prop="sort">
				<el-input
					class="w340"
					placeholder="数字越大越靠前"
					v-model.trim="ruleForm.sort">
				 </el-input>
			</el-form-item>
			<el-form-item label="图片" prop="picUrl" :error="uploadErrorInfo">
				<el-col :span="11">
					<wk-upload
						class="w400"
						@uploadChange="handleImgChange"
						:imgUrl="ruleForm.picUrl"
						@uploadError="handleImgError"
					></wk-upload>
					<span> 图片尺寸600*212</span>
				</el-col>
			</el-form-item>
			<el-form-item label="公告内容" prop="content">
				<el-input 
            type="textarea"
            class="desc"
            v-model="ruleForm.content"
     				show-word-limit
            resize="none"
						:maxlength="350"
            placeholder="请填写公告内容，最多350字"
          ></el-input>
			</el-form-item>
			<el-form-item label="链接" prop="link">
				<el-input class="w400" v-model="ruleForm.link" placeholder="请输入链接"></el-input>
			</el-form-item>
			<div class="foot">
				<el-button class="w150" type="info" @click="cancel()">取消</el-button>
				<el-button class="w150" type="primary" @click="onSubmit()">提交</el-button>
			</div>
		</el-form>
  </div>
</template>

<script>
import store from '@/store/index.js';
export default {
	beforeRouteEnter: (to,from,next) => {
		store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:to.query.id? "编辑公告":'添加公告', path: to.fullPath} );
		next()
	},
	data(){
		var validateSort = (rule, value, callback) => {
		if ( value < 0) {
				callback("请填写大于或等于0的正整数");
			} else if ( !(/^[0-9]+.?[0-9]*/.test(value))) {
				callback("请填写数字");
			} else {
				callback();
			}
		};
		return{
			uploadErrorInfo: '', // 上传的错误
			type: '',//判断是添加还是编辑
			ruleForm: {
					id: '',  // id
					title: '', // 标题
					picUrl: '', // 缩略图
					content: '',  // 资讯正文
					sort:'',//排序
					publishTime: '',  // 发布时间
					state: 1,  // 状态:1、启用；2、停用
					link:"",
			},
			 ruleData: {
				title: [
					{required: true, message: '请填写标题', trigger: 'blur'},
					{min: 1, max: 20, message: '标题最多支持20个字', trigger: ['change', 'blur']},
				],
				publishTime: [
					{required: true, message: '请选择发布时间', trigger: ['change', 'blur']},
				],
				picUrl: [
					{required: true, message: '请上传图片', trigger: ['change', 'blur']},
				],
				state: [
					{required: true, message: '', trigger: 'blur'}
				],
				content: [
					{required: true, message: '请输入公告内容', trigger: ['change', 'blur']},
					{min: 1, max: 350, message: '内容最多支持350个字', trigger: ['change', 'blur']},
				],
				sort: [
					{validator: validateSort,required: true, trigger: ['change', 'blur']},
				],
			},
		}
	},
	created(){
		this.type = this.$route.query.type;
		if (this.type == 'edit'){
			this.loadData(this.$route.query.id);
		}
	},
	methods:{
		loadData(id) {
			this.$apis.noticeServer.queryDetail({
					id: id
			}).then(res => {	
				if (res['code'] == 0) {
					Object.assign(this.ruleForm, res.data);
					this.$refs['noticetRef'].setContent(res.data.content)
				}
			})
    },
		handleImgChange(res) {
			this.uploadErrorInfo = ''
			if (res['code'] == 0) {
					this.ruleForm.picUrl = res.data.link
					this.$refs['formRef'].validateField('picUrl');//对图片进行移除校验
			} else {
					this.uploadErrorInfo = result.message
			}
		},
		handleImgError(res) {
				console.log(res)
		},
		//提交
		onSubmit() {
			this.$refs['formRef'].validate( async valid => {
				if (valid) {
					this.requestInfo()
				}
			})
		},
		 // 提交
    requestInfo() {
			let params = {
				id: this.ruleForm.id,
				title: this.ruleForm.title, // 标题
				publishTime: this.ruleForm.publishTime,  // 发布时间
				state: this.ruleForm.state,  // 状态:1、启用；2、停用
				sort: this.ruleForm.sort,
				picUrl: this.ruleForm.picUrl, // 默认图
				content: this.ruleForm.content, // 选中图
				link:this.ruleForm.link, 
			}
			if (this.type == 'add') {
				this.$apis.noticeServer.addNotice(params).then(res => {
					if (res['code'] == 0) {
						this.$message.success('添加成功！')
						this.close()
					}
				})
			} else {
				this.$apis.noticeServer.updateNotice(params).then(res => {
					if (res['code'] == 0) {
						this.$message.success('修改成功！')
						this.close()
					}
				})
			}
    },

		cancel() {
			const h = this.$createElement;
			this.$msgbox({
					title: '',
					message: h('p', null, [
							h('p', null, '确定取消发布吗？ '),
							h('p', null, '离开页面后已编辑的内容将会丢弃')
					]),

					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					center: true
			}).then(() => {
					this.$router.back()
			})
		},

		close() {
			this.$refs['formRef'].resetFields();
			this.$router.push({
					path: '/notice/list'
			})
		}
	}
}
</script>


<style scoped lang="scss">
	.el-message-box--center .el-message-box__message {
		font-size: 20px;
		color: #000000;
	}

	.page {
		background-color: #fff;
		padding: 45px 100px;
		display: block;
		margin-top: 30px;
		border-radius: 10px;
		.desc {
      width: 495px;
      height: 300px;
      /deep/ .el-textarea__inner {
        height: 300px;
      }
    }
	}

	.foot {
		text-align: center;
	}

	.select-input {
		display: flex;
		margin-bottom: 30px;
	}

	.icon-img {
		width: 40px;
		height: 40px;
		// padding: 10px;
		margin-right: 10px;
		cursor: pointer;
		margin-left: 20px;
	}

	.w340 {
		width: 340px;
	}

	.w60 {
		width: 60px;
	}
</style>