<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      ref="searchRef"
      :search-control-data="searchControlData"
      :initData="searchFormData"
      @search="search"
    ></wk-search>
    <wk-export class="wrapBox" v-auth="permission.DEVELOP_ACTION_EXPORT" :controlData="exportData"
            @export="exportClick($event)"></wk-export>
    <div class="wrapBox">
      <div class="flex margin-b10" >
        <div >共{{total}}条数据</div>
      </div>
      <el-table
        v-auth="permission.DEVELOP_ACTION_LIST"
        :data="tableData"
        class="tableBox"
        stripe
        style="width: 100%">
        <el-table-column
          width="60"
          align="center"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="id"
          label="开发者ID">
        </el-table-column>
        <el-table-column
          align="center"
          prop="email"
          label="注册邮箱">
        </el-table-column>
        <el-table-column
          align="center"
          prop="type"
          label="类型">
          <template slot-scope="scope">
            <span>{{ utils.getDevelopType(scope.row.type)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="companyName"
          label="企业名称">
        </el-table-column>
        <el-table-column
          align="center"
          prop="contactName"
          label="联系人">
        </el-table-column>
        <el-table-column
          align="center"
          prop="contactPhone"
          label="联系电话">
        </el-table-column>
        <el-table-column
          align="center"
          prop="contactEmail"
          label="联系邮箱">
        </el-table-column>
        <el-table-column
          align="center"
          prop="dataState"
          label="状态">
          <template slot-scope="scope">
            <span>{{getStatus(scope.row.dataState) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="注册时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="reviewTime"
          label="首次审核通过时间">
          <template slot-scope="scope">
            <span>{{ scope.row.reviewTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="apkCount"
          label="应用数量">
          <template slot-scope="scope">
            <el-button class="color-blue link-button" type="text" @click="goGoldStandard(scope.row.id)">{{ scope.row.apkCount }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.dataState == 4" v-auth="permission.DEVELOP_ACTION_CHECK" class="color-blue link-button"
                       type="text" @click="openDialog(scope.row.id)">审核
            </el-button>
            <el-button v-else class="color-blue link-button" type="text"  @click="openDialog(scope.row.id)">查看
            </el-button>
            <el-button v-if="scope.row.dataState==2" class="color-blue link-button" type="text"  @click="openEdit(scope.row.id)">编辑
            </el-button>
             <el-button v-if="scope.row.dataState==5" class="color-blue link-button" type="text"  @click="onShowError(scope.row)">原因
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
      <info ref="modalRef" @search="search"></info>
    </div>
  </div>
</template>
<script>
  import info from './dialogs/info'
  import companyInfo from './companyInfo'
  import form from './form'

  export default {
    components: {info},
    data() {
      return {
        total: 0,
        searchFormData: {
          page: 1,
          perPage: 10,
          dataState:0,
        },
        tableData: [],
        searchControlData: [
          {
            type: 'input',
            label: '开发者ID',
            placeholder: '请输入开发者ID',
            key: 'id'
          },
          {
            type: 'input',
            label: '注册邮箱',
            placeholder: '请输入注册邮箱',
            key: 'email'
          },
          {
            type: 'input',
            label: '企业名称',
            placeholder: '请输入企业名称',
            key: 'companyName'
          },
          {
            type: 'select',
            label: '类型',
            placeholder: '请选择类型',
            key: 'type',
            option: [
              {
                label: '全部',
                value: 0
              },
              {
                label: '企业',
                value: 1
              },
              {
                label: '个人',
                value: 2
              },
              {
                label: '政府/事业单位',
                value: 3
              },
            ],
          },
          {
            type: 'input',
            label: '联系人',
            placeholder: '请选择联系人',
            key: 'contactName'
          },
          {
            type: 'input',
            label: '联系电话',
            placeholder: '请输入联系电话',
            key: 'contactPhone'
          },
          {
            type: 'input',
            label: '联系邮箱',
            placeholder: '请输入联系邮箱',
            key: 'contactEmail'
          },
          {
            type: 'select',
            label: '状态',
            placeholder: '请选择状态',
            key: 'dataState',
            option: [
              {
                label: '全部',
                value: 0
              },
              {
                label: '待审核',
                value: 4
              },
              {
                label: '已通过',
                value: 2
              },
              {
                label: '未完善',
                value: 1
              },
              {
                label: '审核不通过',
                value: 5
              },
            ],
          }
        ],
        exportData: [
          {
            type: 'rangDate',
            label: '注册时间',
            key: 'time'
          },
          {
            type: 'select',
            label: '状态',
            key: 'dataState',
            placeholder: '请选择状态',
            option: [
              {
                label: '全部',
                value: 0
              },
              {
                label: '待审核',
                value: 4
              },
              {
                label: '已通过',
                value: 2
              },
              {
                label: '未完善',
                value: 1
              },
              {
                label: '审核不通过',
                value: 5
              },
            ],
          },
        ],
      }
    },
    created() {
      this.search(this.searchFormData)
    },
    methods: {
      openDialog(id) {
        this.$refs['modalRef'].show(id)
      },
      search(data) {
        if (data) {
          Object.assign(this.searchFormData, data)
          this.searchFormData.page = 1
        }
        this.$apis.developServer.queryList(
          this.searchFormData
        ).then(res => {
          if (res['code'] == 0 && res['data']['data']) {
            this.total = res.data.total
            this.tableData = res.data.data
          } else {
            this.total = 0
            this.tableData = []
          }
        })

      },
      // 导出
      exportClick(data) {
        data.regStartTime = data.time ? data.time[0] : "";
        data.regEndTime = data.time ? data.time[1] : "";
        this.$apis.developServer.export(data).then(res => {
          if (res['code'] == 0 && res['data']['link']) {
            let link = res.data.link;
            window.location.href = link;
          }
        });
      },

       openEdit(id) {
       this.$router.push({
         path:'/developer/edit',
         query:{
          id: id
         }
       })
      },


      onShowError(item) {
      this.$alert(item.reason,{
        center: true,
        showConfirmButton:false,
        title:"不通过原因",
      }).then(_ => {
        done();
      })
    },
      // 转换状态
      getStatus(data) {
        switch (data) {
          case 1:
            return '未完善'
          case 2:
            return '审核通过'
          case 4:
            return '待审核'
          case 5:
            return '审核不通过'
        }
      },

      goGoldStandard(id){
        let routeUrl = this.$router.resolve({ path:'/goldStandard/list',query:{id:id}});
        window.open(routeUrl.href, '_blank');
      },

      // 表格页脚方法
      handleSizeChange(val) {
        this.searchFormData.perPage = val
        this.search()
      },
      handleCurrentChange(val) {
        this.searchFormData.page = val
        this.search()
      }
    }
  }
</script>
<style scoped lang="scss">
  /deep/ .el-table .cell{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
</style>