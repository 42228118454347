

import goldStandard from './goldStandard'//金标认证
import application from './application'//应用信息管理
import appeal from './appeal'//申诉管理
import unionAttest from './unionAttest'//联盟认证
import goldDeploy from './goldDeploy'//金标认证配置
import unionDeploy from './unionDeploy'//联盟检测配置
export default [
	...goldStandard,
	...application,
  	...appeal,
	...unionAttest,
	...goldDeploy,
	...unionDeploy
]