<template>
    <div class="confirmMessageBox" v-show="isShow">
      <div class="mask"></div>
      <div class="contentBox" :style="{width}">
        <h3 class="title" v-if="title" v-html="title"></h3>
        <div class="content" :style="{'text-align':align}" v-html="content"></div>
        <div class="operation">
          <div class="btn confirm" @click="confirm()">确定</div>
          <div v-if="isCancelBtn" class="btn cancel" @click="cancel()">取消</div>
        </div>
      </div>
    </div>
</template>
    
  
<script>
export default {
    name:'confirm',
    props: {
        width: {
          type: String,
          default: "500px"
        },
        align: {
          type: String,
          default: "center"
        },
        isShowConfimrBtn: {
            type: Boolean,
            default:true,
        },
        isCancelBtn: {
            type: Boolean,
            default:true,
        },
        title: {
          type: String,
          default: ""
        },
        content: {
          required:true,
          type: String,
          default: ""
        },
    },
    created(){
      
    },
    data() {
        return {
            isShow: false,
            resolve: '',
            reject: '',
            promise: '', // 保存promise对象
        };
    },
    methods: {
        // 确定,将promise断定为resolve状态
        confirm: function () {
          this.isShow = false;
          this.resolve();
          this.remove();
        },
        // 取消,将promise断定为reject状态
        cancel: function () {
          this.isShow = false;
          this.reject();
          this.remove();
        },
        // 弹出messageBox,并创建promise对象
        show: function () {
          this.isShow = true;
          this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
          });
          return this.promise;
        },
        remove: function () {
          setTimeout(() => {
            this.destroy();
          }, 300);
        },
        destroy: function () {
          this.$destroy();
          document.body.removeChild(this.$el);
        }
    }
};
</script>
<style scoped lang="scss">
.confirmMessageBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: 999;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    background: rgba($color: #000000, $alpha: 0.5);
  }
  .contentBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    min-height: 150px;
    border-radius: 5px;
    background: #fff;
    padding: 40px 35px 30px 35px;
    color: #27252B;
    box-sizing: border-box;
    .title {
      margin-bottom: 15px ;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .content {
      font-size: 16px;
      text-align: center;
      word-break: break-all;
    }
    .operation{
      display: flex;
      justify-content:space-around;
      padding-top: 30px ;
      .btn {
        width: 150px;
        height: 31px;
        border-radius: 5px;
        border: 1px solid #ccc;
        background: #fff;
        text-align: center;
        line-height: 30px;
        font-size: 16px;
        color: #444;
        cursor: pointer;
      }
      .confirm {
        background: #27252B;
        color: #fff;
      }
      .cancel{
        &:hover {
          border-color: #ccc;
          background: #27252B;
          color: #fff;
        }
      }
    }
  }
}
</style>