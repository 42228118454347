<template>
  <el-dialog
    title="查看申诉记录"
    :visible.sync="dialogVisible"
    width="800px"
    @close="dialogVisible = false"
    :close-on-click-modal="false"
    center>
    <el-form v-if="dialogVisible" class="form-box" :model="formData" ref="refForm" label-width="100px">
      <el-form-item class="form-item" label="问题类型" v-if="formData.type">
        <span v-for="(item,index) in formData.type" :key="item">
          <span v-if="index">、</span>
          <span v-if="item == '1'">兼容性问题</span>
          <span v-if="item == '2'">稳定性问题</span>
          <span v-if="item == '3'">功耗问题</span>
          <span v-if="item == '4'">性能问题</span>
          <span v-if="item == '5'">安全问题</span>
          <span v-if="item == '6'">其他问题</span>
        </span>
      </el-form-item>
      <el-form-item class="form-item describe-box" label="问题描述"  v-if="formData.appealPicUrl">
        <div class="describe" v-html="textareaFormat(formData.appealDesc)" ></div>
        <div class="">
          <el-image class="screenshot" v-for="item in formData.appealPicUrl" :key="item" :src="item"  :preview-src-list="formData.appealPicUrl"></el-image>
        </div>
      </el-form-item>
      <el-form-item class="manage-box" label="处理方: ">
        <div v-if="formData.state == 2">
            无效申诉，不做处理
        </div>
        <div v-else>
          <span v-for="(item,index) in formData.deal" :key="item" >
            <span v-if="index">、</span>
            <span v-if="item == '1'">兼容性标准小组</span>
            <span v-if="item == '2'">稳定性标准小组</span>
            <span v-if="item == '3'">功耗标准小组</span>
            <span v-if="item == '4'">性能标准小组</span>
            <span v-if="item == '5'">安全标准小组</span>
            <span v-if="item == '6'">联盟及其他</span>
          </span>
        </div>
      </el-form-item>
      <el-form-item class="form-item manage-box" label="处理记录"  v-if="formData.dealDesc">
        <div class="manage">{{formData.dealDesc}}</div>
      </el-form-item>
    </el-form>
    <div class="text-center margin-t30  margin-b10">
        <el-button size="medium" type="info" @click="dialogVisible = false">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      //显示dialog
      dialogVisible: false,
      //数据
      formData: {},
    };
  },
  methods: {
    //获取申诉详情
    queryAppealDetail(id) {
      this.$apis.appealServer.queryAppealDetail({id}).then(res => {
        if (res['code'] == 0) {
          this.formData=res['data'];
        } 
      });
    },
    textareaFormat(text) {
      return text.replace(/\n|\r\n/g, '<br/>')
    },
    //展示dialog，供父组件调用
    show(id) {
      this.dialogVisible = true;
      if (id) this.queryAppealDetail(id);
    }
  }
};
</script>

<style scoped lang="scss">
.form-box{
  padding-right: 10px;
  .form-item {
    margin-bottom: 0;
  }
  .describe-box{
    .describe{
      padding-top: 8px;
      padding-bottom: 8px;
      line-height: 24px;
    }
    .screenshot {
      display: inline-block;
      margin-right:  20px;
      width: 80px;
      height: 120px;
    }
  }
  .manage-box{
    .manage {
      padding-top: 8px;
      line-height: 24px;
    }
  }
}

</style>