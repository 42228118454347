let apiBase = {
  queryAppealList:'/api/appeal/queryList', 
  queryAppealDetail:'/api/appeal/detail', 
  reviewAppeal:'/api/appeal/review', 
  closeAppeal:'/api/appeal/close', 
}
let AppealServerObject = null
class AppealServer{
  constructor(){}

  // 获取申诉列表
  queryAppealList(data={}){
    return $http({
      url: apiBase.queryAppealList,
      method: 'post',
      data: data,
    })
  }
  // 获取申诉详情
  queryAppealDetail(data={}){
    return $http({
      url: apiBase.queryAppealDetail,
      method: 'post',
      data: data,
    })
  }
  // 审核申诉
  reviewAppeal(data={}){
    return $http({
      url: apiBase.reviewAppeal,
      method: 'post',
      data: data,
    })
  }
  // 关闭申诉
  closeAppeal(data={}){
    return $http({
      url: apiBase.closeAppeal,
      method: 'post',
      data: data,
    })
  }


}
function singleAppealServer(){
  if(AppealServerObject === null){
    AppealServerObject = new AppealServer()
  }
  return AppealServerObject
}
export default singleAppealServer()