<template>
  <div class="page">
    <el-form class="search-box" :model="formData" label-width="80px" size="small " ref="searchFormRef">
      <el-row  class="search-content" :gutter="20">
        <el-col :span="6" >
          <div class="app-info">
            <div class="logo">
              <el-image :src="appData.apkIcon"></el-image>
            </div>
            <div class="border"></div>
            <div>
              <p><b>{{appData.apkName}}</b></p>
              <p>
                <span v-if="appData.state == 1">金标认证中</span>
                <span v-if="appData.state == 2">金标认证有效</span>
                <span v-if="appData.state == 3">金标认证失败</span>
                <span v-if="appData.state == 4">金标已过期</span>
              </p>
              <p v-if="appData.expireTime">金标有效期至 {{appData.expireTime | parseTime}}</p>
              <p >包名 {{appData.apkPackage}}</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6" >
          <el-form-item label="版本号" prop="versionCode">
            <el-input v-model="formData.versionCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9" >
          <el-form-item label="认证时间"  prop="time">
            <el-date-picker
                    style="width: 350 px"
                    value-format="timestamp"
                    v-model="formData.time"
                    type="datetimerange"
                    align="right"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="3" class="text-right">
          <el-button class="search-button" type="default" @click="resetForm()">清空</el-button>
          <el-button class="search-button" type="primary" @click="search()">查询</el-button>
        </el-col>
      </el-row>
    </el-form>

    <gold-export
      v-auth="permission.GOLD_ACTION_EXPORT"
      class="wrapBox"
      :type="type"
      @export="exportClick($event)">
    </gold-export>

    <div class="wrapBox">
      <div class="flex justify-space-between margin-b10" >
        <div style="line-height: 40px">共{{total}}条数据</div>
      </div>
      <el-table class="tableBox W100" :data="tableData" stripe >
        <el-table-column width="80" type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="versionCode" label="版本号" width="100">
          <template slot-scope="scope">
            <span>{{scope.row.versionName}} ({{ scope.row.versionCode}})</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="认证结果" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">认证中</div>
            <div v-if="scope.row.state == 2">认证通过</div>
            <div v-if="scope.row.state == 3">认证未通过</div>
            <div v-if="scope.row.state == 4">
              <el-popover
                placement="top-start"
                :content="scope.row.failReason"
                trigger="hover">
                 <span slot="reference">认证异常</span>
              </el-popover>
            </div>
            <div v-if="scope.row.state == 5">
              <el-popover
                placement="top-start"
                :content="scope.row.failReason"
                trigger="hover">
                 <span slot="reference">认证取消</span>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="认证类型">
          <template slot-scope="scope">
            <p>
              <span v-if="scope.row.retestType == 0">版本首次认证</span>
              <span v-if="scope.row.retestType == 1">系统重新认证</span>
              <span v-if="scope.row.retestType == 2">开发者重新认证</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="提交时间" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="reviewTime" label="认证时间" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.reviewTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="兼容标准" align="center">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.itemReviewState"
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span v-if="item.compatibleRetestStandard">
                    重新
                  </span>
                  <span>
                    {{ item.compatible | versionsStandard_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="(item.compatibleRetestStandard?'重新':'')+json_versionsStandard_memberState(item.compatible)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.itemReviewState.compatible | versionsStandard_reviewState()"></svg-icon>
              </div>
            </el-popover>
            <div v-else>/</div>
          </template>
        </el-table-column>
        <el-table-column label="稳定标准" align="center">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.itemReviewState"
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span v-if="item.stableRetestStandard">
                    重新
                  </span>
                  <span>
                    {{ item.stable | versionsStandard_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="(item.stableRetestStandard?'重新':'')+json_versionsStandard_memberState(item.stable)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.itemReviewState.stable | versionsStandard_reviewState()"></svg-icon>
              </div>
            </el-popover>
            <div v-else>/</div>
          </template>
        </el-table-column>
        <el-table-column label="安全标准" align="center">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.itemReviewState"
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span v-if="item.securityRetestStandard">
                    重新
                  </span>
                  <span>
                    {{ item.security | versionsStandard_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="(item.securityRetestStandard?'重新':'')+json_versionsStandard_memberState(item.security)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.itemReviewState.security | versionsStandard_reviewState()"></svg-icon>
              </div>
            </el-popover>
            <div v-else class="text-center">/</div>
          </template>
        </el-table-column>
        <el-table-column label="功耗标准" align="center">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.itemReviewState"
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span v-if="item.consumptionRetestStandard">
                    重新
                  </span>
                  <span>
                    {{ item.consumption | versionsStandard_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="(item.consumptionRetestStandard?'重新':'')+json_versionsStandard_memberState(item.consumption)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.itemReviewState.consumption | versionsStandard_reviewState()"></svg-icon>
              </div>
            </el-popover>
            <div v-else>/</div>
          </template>
        </el-table-column>
        <el-table-column label="性能标准" align="center">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.itemReviewState"
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span v-if="item.performanceRetestStandard">
                    重新
                  </span>
                  <span>
                    {{ item.performance | versionsStandard_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="(item.performanceRetestStandard?'重新':'')+json_versionsStandard_memberState(item.performance)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.itemReviewState.performance | versionsStandard_reviewState()"></svg-icon>
              </div>
            </el-popover>
            <div v-else>/</div>
          </template>
        </el-table-column>
        <el-table-column label="大屏标准" align="center">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.itemReviewState"
              placement="top-start"
              trigger="hover">
              <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span v-if="item.largeRetestStandard">
                    重新
                  </span>
                  <span>
                    {{ item.large | versionsStandard_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="item in scope.row.memberReviewList" :key="item.id">
                  <p class="flex">
                    <svg-icon class="svg-review-item" :svg-name="(item.largeRetestStandard?'重新':'')+json_versionsStandard_memberState(item.large)"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" :svg-name="scope.row.itemReviewState.large | versionsStandard_reviewState()"></svg-icon>
              </div>
            </el-popover>
            <div v-else>/</div>
          </template>
        </el-table-column>
        <el-table-column label="隐私标准" align="center">
          <template>
            <el-popover placement="top-start" trigger="hover">
              <div class="label-box" v-for="(item, index) in privacyData" :key="index">
                <p class="flex">
                  <label>{{item.memberName}}:</label>
                  <span>
                    {{ item.state | versionsStandard_memberState()}}
                  </span>
                </p>
              </div>
              <div class="reference-box" slot="reference">
                <div class="label-box" v-for="(item, index) in privacyData" :key="index">
                  <p class="flex">
                    {{json_versionsStandard_memberState(item.large)}}
                    <svg-icon class="svg-review-item" svg-name="认证通过"></svg-icon>
                    <label>{{item.memberName}}</label>
                  </p>
                </div>
                <svg-icon class="svg-review" svg-name="认证通过"></svg-icon>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="memberState" label="认证进度">
          <template slot-scope="scope">
            <div class="label-box" v-for="item in scope.row.memberState" :key="item.id">
              <label>{{item.memberName}}:</label>
              <p class="flex">
                <span v-if="item.retestType">
                  重新
                </span>
                <span>
                  {{ item.memberState | versionsAuth_memberState()}}
                </span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="认证报告">
          <template slot-scope="scope" >
            <template v-if="scope.row.viewReport==1">
              <template  v-for="(item) in scope.row.memberReview">
                <p v-if="item.reportUrl!=''&& scope.row.state != 1 && scope.row.state != 4 && scope.row.state != 5 " :key="item.memberCode">
                  <a target="_blank" class="blue report"
                    :href="item.reportUrl">
                    {{item.memberName}}认证报告
                  </a>
                </p>
              </template>
            </template>
            <!-- v-if=" scope.row.state != 1 && scope.row.state != 4 && scope.row.state != 5 "  -->
            <template v-if="scope.row.viewReport==2">
              <a class="blue pointer" @click="goStandardReport(scope.row)">查看报告</a>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import goldExport from '../components/goldExport';
  import store from '@/store/index.js';
  export default {
    beforeRouteEnter: (to,from,next) => {
      store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:to.query.breadcrumbName+'-版本记录', path: to.fullPath} );
      next()
    },
    components: { goldExport },
    data() {
      return {
        type:"2",//判断是应用还是版本
        //应用数据
        appData:{},
        //搜索form
        formData:{
          versionCode:'',
          time:[]
        },
        //列表数据
        tableData: [],
        total: 0,
        //翻页数据
        searchFormData: {
          page: 1,
          perPage: 10
        },
        // 隐私标准数据
        privacyData:[
          {memberName: '小米',state: 2},
          {memberName: 'OPPO',state: 2},
          {memberName: 'vivo',state: 2},
        ]
      };
    },
    created() {
      this.queryDetail();
      this.search();
    },
    beforeRouteLeave(to, from, next) {
      //设置下一个路由的meta,让列表页面缓存,即不刷新
      to.meta.keepAlive = true;
      next();
    },

    methods: {
      getRandom() {
        return  Math.random()
      },
      resetForm() {
        this.$refs['searchFormRef'].resetFields();
      },
      //搜索方法
      search() {
        this.$apis.goldStandardServer.queryVersionList({
          apkPackage:this.$route.query.apkPackage,//	必选	string	包名
          versionCode:this.formData.versionCode,//	可选	string	版本号
          startTime:this.formData.time[0],//	可选	int	开始时间
          endTime:this.formData.time[1],//	可选	int	结束时间
          page:this.searchFormData.page,//	可选	int	第几页
          perPage:this.searchFormData.perPage,//	可选	int	每页数量
        }).then(res => {
          if (res['code'] == 0 && res.data.data) {
            this.total = res.data.total;
            this.tableData = res.data.data.list;
          } else {
            this.total = 0;
            this.tableData = [];
          }
        });
      },
      //应用详情
      queryDetail() {
        this.$apis.goldStandardServer.queryDetail({
          apkPackage:this.$route.query.apkPackage,//	必选	string	包名
        }).then(res => {
          if (res['code'] == 0) {
            this.appData = res.data;
          } else {
            this.$message({
              message: "失败",
              type: "error"
            });
          }
        });
      },
      //导出方法
      exportClick(data) {
        //时间范围组件 取开始结束时间
        data.apkPackage = this.$route.query.apkPackage;
        data.startTime = data.time ? data.time[0] : "";
        data.endTime = data.time ? data.time[1] : "";
        this.$apis.goldStandardServer.exportVersion(data).then(res => {
          if (res['code'] == 0 && res.data.link) {
            let link = res.data.link;
            window.location.href = link;
          } else {
            this.$message({
              message: "失败",
              type: "error"
            });
          }
        });
      },
      goStandardReport(data) {
        this.$router.push({
          path:'/goldStandard/versions/standardReport',
          query:{
            id:data.id,
            breadcrumbName:"认证报告"
          }
        })
      },
      //点击编辑
      edit(data) {
        this.$refs["dialog"].show("", data);
      },
      //table点击更改一页多少行
      handleSizeChange(val) {
        this.searchFormData.perPage = val;
        this.search();
      },
      //table点击修改页码
      handleCurrentChange(val) {
        this.searchFormData.page = val;
        this.search();
      },
      json_versionsStandard_memberState(value){
        return { '1': "认证中", '2': "认证通过", '3': '认证未通过', '4': '认证终止'}['' + value] || value;
      }
    },
    beforeRouteLeave(to, from, next){
    //返回上一页：  缓存上一页  不缓存当前页(释放缓存，方便数据重新加载) 
    if(to.name === 'goldStandard') {
      to.meta.keepAlive = true;
      from.meta.keepAlive = false;
    }
    //去下一页 缓存当前 在去下一页
    if(to.name === 'standardReport') {
      from.meta.keepAlive = true;
    }
    next();
  }
  };
</script>
<style scoped lang="scss">
  .search-box{
    background-color: #ffffff;
    border-radius: 5px;
    margin-top: 20px;
    padding: 22px 20px 22px 47px;
    .search-content{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      /deep/ .el-form-item{
        margin-bottom: 0;
      }
      .app-info{
        display: flex;
        .logo{
          display: flex;
          align-items: top;
          /deep/ .el-image{
            width: 80px;
            height: 80px
          }
        }
        .border{
          margin: 0 30px;
          width: 1px;
          background: #ccc;
        }
      }
    }
  }
  .tableBox{
    .label-box{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .reference-box{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      min-height: 70px;
      .flex{
        justify-content: center;
        align-items: center;
        .svg-review-item{
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
      }
      .svg-review{
        position: absolute;
        right: -5px;
        bottom: 0;
        opacity: 0.5;
        width: 70px;
        height: 70px;
      }
    }
  }
</style>
