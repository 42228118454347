<template>
  <div class="exportBox">
    <el-form
      :rules="rules"
      class="exportContent"
      ref="formRef"
      :inline="true"
      :model="formData"
      label-width="80px"
      size="small"
    >
      <el-form-item label="认证时间" prop="time">
        <el-date-picker
          :disabled="dateDisabled"
          value-format="timestamp"
          v-model="formData.time"
          type="datetimerange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="金标状态" v-if="type !='1'" >
        <el-select @change="selectChange" v-model="formData.state" placeholder="请选择状态">
          <el-option
            v-for="(optionData,index) in standardSeelctData"
            :key="index"
            :label="optionData.label"
            :value="optionData.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="exportButtonBox">
      <el-button v-auth="permission.IDENTIFY_APK_ACTION_EXPORT" class="exportButton" type="primary" @click="onSubmit()"><span v-if="type=='1'">导出应用数据</span><span v-else>导出数据</span></el-button>
      <el-button v-auth="permission.GOLD_ACTION_EXPORT_ADV" v-if="type=='1'" class="exportButton" type="primary" @click="onSubmitAuth()">导出认证数据</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoldExport",
  props:{
    type: {
      type: String,
      required: false,
      default() {
          return {}
      }
    },
  },
  data() {
    var validateDate = (rule, value, callback) => {
      if (this.formData.state != 1) {
        if (value == "") {
          callback(new Error("请选择时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      formData: {
        time: "",
        state: "",
      },
      rules: {
        time: [{ validator: validateDate, trigger: "blur" }],
      },
      dateDisabled: false,
      standardSeelctData: [
        {
          label: "全部",
          value: ""
        },
        {
          label: "金标认证中",
          value: "1"
        },
        {
          label: "金标认证有效",
          value: "2"
        },
        {
          label: "金标认证失败",
          value: "3"
        },
        {
          label: "金标认证已过期",
          value: "4"
        },
      ],
    };
  },
  methods: {
    onSubmit() {
      this.$refs["formRef"].validate((valid) => {
        if (valid) {
          this.$emit("export", this.formData);
        }
      });
    },
    onSubmitAuth(){
      this.$refs["formRef"].validate((valid) => {
        if (valid) {
          this.$emit("exportAuth", this.formData);
        }
      });
    },
    selectChange(data) {
      if (data == 1) {
        this.dateDisabled = true;
        this.formData["time"] = "";
      } else {
        this.dateDisabled = false;
      }
    },
    resetForm() {
      this.formData = {};
      this.$emit("export", this.formData);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-select {
  display: block;
  position: relative;
}

.exportBox {
  display: flex;
  justify-content: space-between;
  padding: 22px 20px 4px 47px;

  .exportContent {
    display: flex;
    flex-wrap: wrap;
  }

  .exportButtonBox {
    display: flex; 
    justify-content: space-between;
    .exportButton {
      height: 32px;
      line-height: 0;
      text-align: center;
      padding: 10px 20px;
    }
  }
}
</style>