<template>
  <div class="page">
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px;align-items: center;">
        <div style="line-height: 40px">共{{count}}个测试项配置</div>
        <div class="subBox">
          <div v-if="standard!='security'">标准认证规则： 
            <el-select v-model="standardPassRule" placeholder="请选择">
              <el-option
                v-for="item in ruleList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-button class="common-button" type="primary" v-auth="permission.UNION_ACTION_UNDATE"  @click="onSaveData()">
          保存配置
          </el-button>
        </div>
        
      </div>
      <el-table
        :header-cell-style="{ 'text-align': 'center',}"
        :cell-style="{ 'text-align': 'center'}"
        class="tableBox2"
        :data="tableData"
        v-auth="permission.UNION_STANDARD_LIST" 
        :span-method="objectSpanMethod">
        <el-table-column label="测试项" prop="title" width="200"></el-table-column>
        <el-table-column label="测试编号" prop="code" width="130"></el-table-column>
        <el-table-column label="测试标准" prop="name" width="720">
          <template slot-scope="scope">
            <div style="text-align: left;">{{scope.row.name}}</div>
          </template> 
        </el-table-column>
        <el-table-column label="测试类型" width="130" prop="type">
          <template slot-scope="scope">
            <el-select v-model="scope.row.type" :class="{warn:scope.row.type==2}" placeholder="请选择" >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-html="
                `<span style='color:${getUrgencyColor(item.value)}'>${item.label}</span>`">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <!-- <el-table-column label="是否可见" width="110" prop="isFrontShow">
          <template slot-scope="scope">
            <el-select :disabled="scope.row.type==1||scope.row.state!=1" v-model="scope.row.isFrontShow" placeholder="请选择" >
              <el-option
                v-for="item in isShowList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
               >
              </el-option>
            </el-select>
          </template>
        </el-table-column> -->
        <el-table-column label="状态" prop="state" width="80">
          <template slot-scope="scope">
            <span>{{scope.row.state == 1?'正常':'关闭'}}</span>
          </template>
        </el-table-column>
        <el-table-column v-for="(item, index) in itemNameList" :key="index" :label="item">
          <template slot-scope="scope" >
            <template>
              <el-switch
                v-model="scope.row.openState[index]['state']"
                :active-value="1"
                :inactive-value="2"
                active-color="#28262C"
                inactive-color="#ccc">
              </el-switch>
            </template>
          </template>  
        </el-table-column>
      </el-table>
    
    </div>
  </div>
</template>

<script>

export default {
  props: {
      standard: {
        type: String,
      }
  },
  data(){
    return{
      formData:{},
      isShowlModal:false,
      options:[
        {
          value: 1,
          label: '测试项目'
        }, {
          value: 2,
          label: '警告项目'
        }
      ],
      
      isShowList:[
        {
          value: 1,
          label: '是'
        }, {
          value: 2,
          label: '否'
        }
      ],
      //列表数据
      itemNameList:[],
      tableData: [],
      standardPassRule:"",
      ruleList:[],
      count:0,
      code:this.standard,
    }
  },
  mounted(){
    this.getTableData();
    this.queryStandardRuleList();
  },
  methods:{
    //获取列表数据
    getTableData(){
      this.$apis.unionDeployServer.queryTermList({code:this.code}).then(res=>{
        if (res['code'] == 0) {
          this.count = res.data.count;
          this.tableData = res.data.list;
          this.itemNameList = res.data.itemNameList;
          this.standardPassRule = res.data.standardPassRule;
        }
      })
    },

    // changeType(type,index,state){
    //   if(type==1&&state==1){
    //     this.tableData[index].isFrontShow=1;
    //   }
    // },
    queryStandardRuleList(){
      this.$apis.unionDeployServer.queryStandardRuleList().then(res=>{
        if (res['code'] == 0) {
          this.ruleList=res.data;
        }
      })
    },

    onSaveData(){
      var dataList = []
      for(var i=0;i<this.tableData.length;i++){
        var dataObj={
          id:'',
          type:'',
          isFrontShow:'',
          openState:[],
          
        }
        dataObj.id=this.tableData[i]['id'];
        dataObj.type=this.tableData[i]['type'];
        dataObj.isFrontShow=this.tableData[i]['isFrontShow'];
        dataObj.openState=this.tableData[i]['openState'];
        dataList.push(dataObj);
      }
      let standardPass={
          code:this.code,
          value:this.standardPassRule,

      }
      this.$apis.unionDeployServer.updateSetting({
        data: JSON.stringify(dataList),
        standardPassRule:JSON.stringify(standardPass)
      }).then(res=>{
          if (res['code'] == 0) {
            this.$message({
            message: '保存成功',
            type: 'success'
          });
            this.getTableData();
          }
        })
    },

      // 获取紧急程度颜色
    getUrgencyColor(code) {
      if (code == 2) {
        return "orange"; 
      } 
      return "#666666";
    },

    // 合并单元格
    mergeCol(id, rowIndex) { 
      // id：属性名
      // rowIndex：行索引值
      var idName = this.tableData[rowIndex][id] // 获取当前单元格的值
      if (rowIndex > 0) { // 判断是不是第一行
        // eslint-disable-next-line eqeqeq
        if (this.tableData[rowIndex][id] != this.tableData[rowIndex - 1][id]) { // 先判断当前单元格的值是不是和上一行的值相等
          var i = rowIndex; var num = 0 // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
          while (i < this.tableData.length) { // 当索引值小于table的数组长度时，循环执行
            if (this.tableData[i][id] === idName) { // 判断循环的单元格的值是不是和当前行的值相等
              i++ // 如果相等，则索引值加1
              num++ // 合并的num计数加1
            } else {
              i = this.tableData.length // 如果不相等，将索引值设置为table的数组长度，跳出循环
            }
          }
          return {
            rowspan: num, // 最终将合并的行数返回
            colspan: 1
          }
        } else {
          return {
            rowspan: 0, // 如果相等，则将rowspan设置为0
            colspan: 1
          }
        }
      } else { // 如果是第一行，则直接返回
        let i = rowIndex; let num = 0
        while (i < this.tableData.length) { // 当索引值小于table的数组长度时，循环执行
          if (this.tableData[i][id] === idName) {
            i++
            num++
          } else {
            i = this.tableData.length
          }
        }
        return {
          rowspan: num,
          colspan: 1
        }
      }
    },

    // 这个方法是 element-ui提供的单元格合并方法
    // objectSpanMethod 传入了 { row, column, rowIndex, columnIndex }
    // row: 当前行
    // column: 当前列
    // rowIndex：当前行号
    // columnIndex ：当前列号
    // 1代表：独占一行
    // 更大的自然数：代表合并了若干行
    // 0：代表“消失”的哪那一个单元格，后面的单元格向前推一格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) { // 合并单元格
      switch (columnIndex) { // 将列索引作为判断值
      // 通过传递不同的列索引和需要合并的属性名，可以实现不同列的合并
        case 0:
          return this.mergeCol('title', rowIndex)
        // case 1:
        //   return this.mergeCol('title', rowIndex)
        // case 2:
        //   return this.mergeCol('title', rowIndex)
      }
    },
  },
  watch:{
    standard(){
      this.formData.code=this.standard;
      this.getTableData();
    },
  },
}
</script>

<style scoped lang="scss">
.wrapBox{
  margin-top: 0;
  padding: 0;
  .subBox{
    display: flex;
    align-items: center;
    .common-button{
      margin-left: 20px;
    }
  }
}
/deep/.warn{
    color: #FFDE61!important;
  }
</style>