<template>
    <div class="wrapBox">
      <el-tabs v-model="activeName"  @tab-click="handleClick" >
        <el-tab-pane label="概况" name="survey">
          <app-info-details v-if="activeName=='survey'"></app-info-details>
        </el-tab-pane>
        <el-tab-pane v-for="(item,index) in navList" :key="index" :label="item.name" :name="item.code">
          <app-standard-results  v-if="activeName==item.code" :standard = activeName></app-standard-results >
        </el-tab-pane>
        <el-tab-pane label="隐私测试" name="privacy">
          <app-privacy-results></app-privacy-results >
        </el-tab-pane>
      </el-tabs>
    </div>
</template>

<script>
import appStandardResults from './template/appStandardResults';
import appInfoDetails from "./template/appInfoDetails"
import appPrivacyResults  from "./template/appPrivacyResults"
import store from '@/store/index.js';
export default {
  beforeRouteEnter: (to,from,next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: to.query.breadcrumbName, path: to.fullPath} );
    next()
  },
  components: {appInfoDetails,appStandardResults,appPrivacyResults},
  data(){
    return{
       activeName:"survey",
       navList:[]	
    }
  },
  mounted(){
    this.queryStandardList();
  },
  methods: {
    handleClick(tab) {
      sessionStorage.setItem('standardTestReport_tab',tab.name);
    },
    queryStandardList(){
      this.$apis.goldDeployServer.queryStandardList().then(res=>{
        if (res['code'] == 0) {
          this.navList=res.data;
        }
      })
    },
  },
  activated(){
    if(sessionStorage.getItem('standardTestReport_tab')){
      this.activeName = sessionStorage.getItem('standardTestReport_tab')
    }
  },
  beforeRouteLeave(to, from, next){
    //返回上一页：  缓存上一页  不缓存当前页(释放缓存，方便数据重新加载) 
    if(to.name === 'versions') {
      to.meta.keepAlive = true;
      from.meta.keepAlive = false;
    }
    //去下一页 缓存当前 在去下一页
    if(to.name === 'reportDetails') {
      from.meta.keepAlive = true;
    }
    next();
  }
};
</script>

<style scoped lang="scss">
.el-tabs {
  height: 100%;
  ::v-deep .is-active {
    font-weight:bolder ;
  }
}
</style>
