<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <wk-export class="wrapBox" v-auth="permission.MANUFACTURER_ACTION_EXPORT" :controlData="exportData"
            @export="exportClick($event)"></wk-export>
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
      </div>
      <el-table
        class="tableBox"
        :data="tableData"
        v-auth="permission.MANUFACTURER_ACTION_LIST"
        stripe
        style="width: 100%">
        <el-table-column
          align="center"
          width="60"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="accountId"
          label="开发者ID">
        </el-table-column>
        <el-table-column
          align="center"
          prop="email"
          label="注册邮箱">
        </el-table-column>
        <el-table-column
          align="center"
          prop="type"
          label="类型">
          <template slot-scope="scope">
            <span>{{ utils.getDevelopType(scope.row.type)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="companyName"
          label="企业名称">
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="绑定厂商">
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberOpenid"
          label="绑定账号ID">
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberUserName"
          label="绑定账号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="state"
          width="80"
          label="状态">
          <template slot-scope="scope">
            <span>{{scope.row.state == 2?'已绑定':'已解绑' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="记录">
          <template slot-scope="scope">
            <div>{{scope.row.state == 2?'绑定时间':'解绑时间' }}</div>
            <span>{{scope.row.state == 2?scope.row.reviewTime:scope.row.unbindTime | parseTime() }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        total: 0,
        searchFormData: {
          page: 1,
          perPage: 10,
        },
        searchControlData: [
          {
            type: 'input',
            label: '开发者ID',
            placeholder: '请输入开发者ID',
            key: 'id'
          },
          {
            type: 'input',
            label: '注册邮箱',
            placeholder: '请输入注册邮箱',
            key: 'email'
          },
          {
            type: 'select',
            label: '类型',
            placeholder: '请选择类型',
            key: 'type',
            option: [
              {
                label: '全部',
                value: '0'
              },
              {
                label: '企业',
                value: 1
              },
              {
                label: '个人',
                value: 2
              },
              {
                label: '政府/事业单位',
                value: 3
              },
            ],
          },
          {
            type: 'input',
            label: '企业名称',
            placeholder: '请输入企业名称',
            key: 'companyName'
          },
          {
            type: 'select',
            label: '厂商',
            placeholder: '请选择厂商',
            key: 'memberCode',
            option: []
          },
          {
            type: 'input',
            label: '绑定账号',
            placeholder: '请输入绑定账号',
            key: 'memberUserName'
          },
          {
            type: 'select',
            label: '状态',
            placeholder: '请选择状态',
            key: 'state',
            option: [
              {
                label: '全部',
                value: 0
              },
              {
                label: '已绑定',
                value: 2
              },
              {
                label: '已解绑',
                value: 4
              },
            ],
          },
        ],
        exportData: [
          {
            type: 'rangDate',
            label: '绑定时间',
            key: 'time'
          },
        ],
        tableData: []
      }
    },
    created() {
      this.search()
      this.searchControlData.forEach((item, index) => {
        if (item.key == 'memberCode') {
          this.searchControlData[index].option = this.$store.getters['accountStore/getMemberList']
        }
      })
    },
    methods: {
      search(data) {
        if (data) {
          Object.assign(this.searchFormData, data)
          this.searchFormData.page = 1
        }
        this.$apis.accountBindServer.queryList(
          this.searchFormData).then(res => {
          if (res['code'] == 0 && res.data.data) {
            this.total = res.data.total
            this.tableData = res.data.data
          } else {
            this.total = 0
            this.tableData = []
          }
        })

      },
      // 导出
      exportClick(data) {
        data.startTime = data.time ? data.time[0] : "";
        data.endTime = data.time ? data.time[1] : "";
        this.$apis.accountBindServer.export(data).then(res => {
          if (res['code'] == 0 && res.data.link) {
            let link = res.data.link;
            window.location.href = link;
          }
        });
      },
      handleSizeChange(val) {
        this.searchFormData.perPage = val
        this.search()
      },
      handleCurrentChange(val) {
        this.searchFormData.page = val
        this.search()
      }
    }
  }
</script>
<style>
  .el-message-box--center .el-message-box__message {
    font-size: 20px;
  }

  .cancelButton {
    width: 98px;
    margin-left: 14px;
    margin-top: 14px;
  }

  .confirmButton {
    width: 98px;
    margin-left: 60px !important;
  }
</style>
<style scoped lang="scss">

  .page {
  }
</style>