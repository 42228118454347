let apiBase = {
  queryList:'/api/info/queryList',                  // 列表
  detail:'/api/info/detail',                      //  详情
  create:'/api/info/create',                  //  添加
  update:'/api/info/edit',                  //  编辑
  voteCreate:'/api/infoVote/create',                  //  添加投票
  voteUpdate:'/api/infoVote/edit',                  //  编辑投票
  voteRecordList:'/api/infoVote/queryItemRecordList',                  //  投票信息明细列表
  voteList:'/api/infoVote/queryList',                  //  投票信息列表
  voteExport:'/api/infoVote/export',                  //  投票信息列表
  close:'/api/info/close',                  //  关闭
  open:'/api/info/open',                  //  开启
  delete:'/api/info/delete',                  //  开启
  infoSignUpList:'/api/infoSignUp/queryList',                  //  报名列表
  SignUpExport:'/api/infoSignUp/export',                  //  报名列表导出
}
let newsServerObject = null
class newsServer{
  constructor(){}
  // 资讯管理列表
  queryList(data={}){
    return $http({
      url: apiBase.queryList,
      method: 'post',
      data: data,
    })
  }

  // 投票信息明细列表
  voteRecordList(data={}){
    return $http({
      url: apiBase.voteRecordList,
      method: 'post',
      data: data,
    })
  }
  // 投票信息列表
  voteList(data={}){
    return $http({
      url: apiBase.voteList,
      method: 'post',
      data: data,
    })
  }
    // 报名列表
  infoSignUpList(data={}){
    return $http({
      url: apiBase.infoSignUpList,
      method: 'post',
      data: data,
    })
  }
  // 报名列表导出
  SignUpExport(data={}){
    return $http({
      url: apiBase.SignUpExport,
      method: 'post',
      data: data,
    })
  }
  // 投票明细导出
  voteExport(data={}){
    return $http({
      url: apiBase.voteExport,
      method: 'post',
      data: data,
    })
  }

  detail(data={}){
    return $http({
      url: apiBase.detail,
      method: 'post',
      data: data,
    })
  }

  create(data={}){
	  return $http({
      method: 'post',
      url: apiBase.create,
      data:data
    })
   }
   update(data={}){
	  return $http({
      method: 'post',
      url: apiBase.update,
      data:data
    })
   }
  voteCreate(data={}){
	  return $http({
      method: 'post',
      url: apiBase.voteCreate,
      data:data
    })
   }

  voteUpdate(data={}){
	  return $http({
      method: 'post',
      url: apiBase.voteUpdate,
      data:data
    })
   }

  open(data={}){
    return $http({
      url: apiBase.open,
      method: 'post',
      data: data,
    })
  }
  close(data={}){
    return $http({
      url: apiBase.close,
      method: 'post',
      data: data,
    })
  }

  updateStateDisable(data={}){
    return $http({
      url: apiBase.updateStateDisable,
      method: 'post',
      data: data,
    })
  }

  delete(data={}){
    return $http({
      url: apiBase.delete,
      method: 'post',
      data: data,
    })
  }

}
function singleNewsServer(){
  if(newsServerObject === null){
    newsServerObject = new newsServer()
  }
  return newsServerObject
}
export default singleNewsServer()