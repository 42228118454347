<template>
  <div  class="page">
    <wk-search class="wrapBox" :search-control-data="searchControlData" @search="search($event)"></wk-search>
    <div  class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div class="line-40" >共{{total}}条数据</div> 
      </div>
      <el-table class="tableBox W100" :data="tableData" stripe >
          <el-table-column fixed align="center" width="60" type="index" label="序号"></el-table-column>
          <el-table-column fixed prop="apkName" label="应用名称"></el-table-column>
          <el-table-column width="70" prop="apkIcon" label="ICON">
            <template slot-scope="scope">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.apkIcon"
                :preview-src-list="[scope.row.apkIcon]" >
                <template slot="error">
                  <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
                </template>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="apkPackage" label="应用包名"></el-table-column>
          <el-table-column prop="description" label="干预操作"></el-table-column>
          <el-table-column prop="adminName" label="操作人"></el-table-column>
          <el-table-column prop="createTime" label="操作时间">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime | parseTime() }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="干预说明">
            <template slot-scope="scope">
              <el-button class="color-blue link-button" type="text" @click="onLook(scope.row.remark)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-right margin-t30">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="searchFormData.perPage"
            :current-page="searchFormData.page"
            layout="total, prev, pager, next, sizes"
            :total="total"
          ></el-pagination>
        </div>
        <el-dialog
          title="人工干预记录"
          :visible="dialogVisible"
          width="650px"
          @close="dialogVisible = false"
          :close-on-click-modal="false"
          center
          >
          <div>{{remark}}</div>
        </el-dialog>
    </div>
  </div>
</template>

<script>
import store from '@/store/index.js';
export default {
  beforeRouteEnter: (to,from,next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:'人工干预记录', path: to.fullPath} );
    next()
  },
  data(){
    return{
      dialogVisible: false,
      remark:"",
      //搜索form数据
      searchFormData: {
        page: 1,
        perPage: 10
      },
      //列表数据
      tableData: [],
      total: 0,
      searchControlData:[
        {
          type: "input",
          label: "应用名称",
          placeholder: "应用名称",
          key: "apkName"
        },
        {
          type: "input",
          label: "应用包名",
          placeholder: "应用包名",
          key: "apkPackage"
        },
        {
          type: "input",
          label: "操作人",
          placeholder: "操作人",
          key: "adminName",
        }, 
      ]
    }
  },
  created() {
    this.search();
  },
  methods:{
    onLook(text){
      this.dialogVisible = true;
      this.remark=text;
    },

    //搜索方法
    search(data) {
      if (data) {
        this.searchFormData = {
          page: 1,
          perPage: this.searchFormData.perPage,
          ...data
        };
      }

      this.$apis.goldStandardServer.queryInterveneList(this.searchFormData).then(res => {
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total;
          this.tableData = res.data.data;
        } else {
          this.total = 0;
          this.tableData = [];
        }
      });
    },
    //table点击更改一页多少行
    handleSizeChange(val) {
      this.searchFormData.perPage = val;
      this.search();
    },
    //table点击修改页码
    handleCurrentChange(val) {
      this.searchFormData.page = val;
      this.search();
    },
  }
}
</script>

<style>

</style>