/**
  *@fileName:WkUpload.vue
  *auth 吴全成
  *@time: 2021-03-24 18:27:02
  *
  */
<template>
<div>
  <el-upload
   class="assetsImg-uploader"
   :accept="accept"
   :multiple="false"
   :show-file-list="false"
   :action="configBaseURL + url"
   :on-success="handleImgSuccess"
   :on-error="handleImgError"
   :on-progress="uploadProgress"
   :before-upload="beforeUpload">
    <img v-if="imgUrl" :src="imgUrl" class="assetsImg" alt="banner图"/>
    <i v-else class="el-icon-plus assetsImg-uploader-icon"></i>
  </el-upload>
  <el-progress class="upload-progress" style="margin-top: -12px" color="#28262C" :status="progresStatus"
               v-if="showProgrees && showLoading"
               :percentage="percentage"></el-progress>
</div>
</template>

<script>
export default {
  name: 'WkUpload',
  props:{
    // 请求地址
    url:{
      type:String,
      default:'/api/uploadFile'
    },
    // 可以上传的文件类型
    accept:{
      type:String,
      default:'image/png,image/jpg,image/jpeg,image/svg+xml'
    },
    // 上传图片的绑定值，上传成功或者获取图片数据赋值并进行展示
    imgUrl:{
      type:String,
      default: ''
    },
    // 是否显示进度条
    showProgrees:{
      type:Boolean,
      default: true
    },
    // 上传文件成功的回调
    uploadChange:{
      type:Function,
    },
    // 上传文件失败的回调
    uploadError:{
      type:Function,
    }
  },
  data(){
    return{
      // 上传文件加载
      showLoading: false,
      uploadErrorInfo: '', // 上传文件错误
      // 上传进度条
      percentage: null,  // 进度条百分比
      progresStatus: null, // 进度条状态： success or exception
    }
  },
  created(){
    
  },
  methods: {
    // 监听上传事件 赋值给进度条
    uploadProgress(data) {
      this.percentage = parseInt(data.percent)
    },
    beforeUpload() {
      this.showLoading = true
      this.initUploadData()
    },
    // 初始化上传控件数据：进度条、错误信息
    initUploadData() {
      this.percentage = 0
      this.uploadErrorInfo = ''
      this.progresStatus = null
    },
    handleImgSuccess(res) {
      this.uploadErrorInfo = ''
      this.$emit('uploadChange',res)
      if (res['code'] == 0) {
        // 当进度条百分百，才更换进度条状态为成功
        if (this.percentage == 100) {
          this.progresStatus = 'success'
        }
      } else {
        // 当进度条百分百，才更换进度条状态为异常
        if (this.percentage == 100) {
          this.progresStatus = 'exception'
        }
      }
    },
    handleImgError(res){
      this.$message.error('服务器错误')
      // 当进度条百分百，才更换进度条状态为异常
      if (this.percentage == 100) {
        this.progresStatus = 'exception'
      }
    }
  },
}
</script>

<style scoped lang="scss">

  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }

  .assetsImg-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .assetsImg {
    margin: 0px !important;
    width: 178px;
    height: 178px;
    display: block;
  }
  .upload-progress{
    width: calc(100% + 55px); // 进度条的宽度+55px的偏移量。
  }

</style>
