<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="160px"
    class="infoForm"
  >
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="企业名称" prop="companyName">
          <el-input
            aria-label="企业名称"
            aria-autocomplete="none"
            clearable
            placeholder="请输入企业或公司名称"
            type="text"
            v-model.trim="form.companyName"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="联系人姓名" prop="contactName">
          <el-input
            aria-label="联系人姓名"
            aria-autocomplete="none"
            clearable
            placeholder="请输入联系人姓名"
            type="text"
            v-model.trim="form.contactName"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="联系人邮箱" prop="contactEmail">
          <el-input
            aria-label="联系人邮箱"
            aria-autocomplete="none"
            clearable
            placeholder="请输入联系人邮箱"
            type="text"
            v-model.trim="form.contactEmail"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input
            aria-label="联系电话"
            aria-autocomplete="none"
            clearable
            placeholder="请输入联系电话"
            type="text"
            v-model.trim="form.contactPhone"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="微信" prop="weixin">
          <el-input
            aria-label="微信"
            aria-autocomplete="none"
            clearable
            placeholder="请输入微信"
            type="text"
            v-model.trim="form.weixin"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="QQ" prop="qq">
          <el-input
            aria-label="QQ"
            aria-autocomplete="none"
            clearable
            placeholder="请输入QQ"
            type="text"
            v-model.trim="form.qq"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="统一信用社会代码" prop="licinseNumber">
          <el-input
            aria-label="统一信用社会代码"
            aria-autocomplete="none"
            clearable
            placeholder="请输入统一信用社会代码"
            type="text"
            v-model.trim="form.licinseNumber"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="上传营业执照号" prop="licinseNumberPhoto">
          <el-upload
            class="uploaderFile"
            accept=".png, .jpg"
            :data="{type:'account '}"
            :action="configBaseURL+'/api/uploadFile'"
            :show-file-list="false"
            :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'licinseNumberPhoto')}"
            :before-upload="beforeUpload"
          >
            <img v-if="form.licinseNumberPhoto" class="file" :src="form.licinseNumberPhoto" />
            <i v-else class="el-icon-plus icon businessBeforeImg"></i>
          </el-upload>
          <div class="themeColor">请上传营业执照，图片格式为png、jpg，文件大小不能超过2M。</div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item>
          <div class="submitBotton" @click="submitForm('form')">提交</div>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { checkPhone, checkWeixin } from "@/utils/RegExp.js";

export default {
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {},
  data() {
    return {
      rules: {
        /*type=1：企业*/
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        licinseNumber: [
          { required: true, message: "请填写统一信用社会代码", trigger: "blur" },
        ],
        licinseNumberPhoto: [
          { required: true, message: "请上传营业执照号", trigger: "blur" },
        ],
        /*共用*/
        contactName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contactEmail: [
          { required: true, message: "请输入联系人邮箱", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        weixin: [
          { required: true, message: "请输入联系人微信", trigger: "blur" },
          { required: true, validator: checkWeixin, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //上传前校验
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      if(fileType == '.jpg' || fileType == '.png'){
       
      }else{
        this.$message.error('请上传jpg或png的图片！');
        return false
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    //上传成功
    handleUpload(res, file, filelist, key) {
      if (res.code == 0) {
        this.form[key] = res.data.link;
        this.$refs.form.validateField(key);
      } else {
        this.$message.error("上传失败");
      }
    },

    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('确定提交编辑信息吗?', '', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              center: true,
              showClose: false, //是否显示右上角的x
              closeOnClickModal: false, //是否可以点击空白处关闭弹窗
          }).then(() => {
            this.$apis.developServer.update(this.form).then((res) => {
              if (res.code == 0) {
                this.$confirm('修改成功', '', {
                  confirmButtonText: '确定',
                  showCancelButton:false,
                  center: true,
                  showClose: false, //是否显示右上角的x
                  closeOnClickModal: false, //是否可以点击空白处关闭弹窗
                }).then(() => {
                  this.$router.back()
                })
              } else {
                this.$message.error("提交失败");
              }
            });
          }).catch(() => {   

          }); 
        } else {
          return false;
        }
      });  
    },
  },
};
</script>

<style scoped lang="scss">
</style>