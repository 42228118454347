<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
        <el-button class="common-button" v-auth="permission.NEWS_ACTION_ADD"   type="primary" @click="add()">
          添加资讯动态
        </el-button>
      </div>
      <el-table
        v-auth="permission.NEWS_ACTION_LIST"
        class="tableBox"
        :data="tableData"
        stripe
        style="width: 100%">
        <el-table-column
          width="60"
          align="center"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="标题">
        </el-table-column>
        <el-table-column
          width="70"
          prop="imageUrl"
          label="缩略图">
          <template slot-scope="scope">
            <el-image
              alt="banner缩略图"
              style="width: 50px; height: 40px;display:block"
              :src="scope.row.imageUrl">
              <template slot="error">
                <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
                width="300"
          align="center"
          prop="description"
          label="内容预览">
          <template slot-scope="scope">
            <span class="bread-text" v-if="scope.row.description" >{{scope.row.description?scope.row.description:'/'}}</span>
          </template>
        </el-table-column>
        <el-table-column
                width="60"
          align="center"
          prop="PV"
          label="数据">
          <template slot-scope="scope">
            <div>PV:{{scope.row.pv}}</div>
            <div>UV:{{scope.row.uv}}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="publishTime"
          label="发布时间">
          <template slot-scope="scope">
            <span>{{ scope.row.publishTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
                align="center"
                prop="state"
                width="80"
                label="类型">
          <template slot-scope="scope">
            <span>{{ scope.row.type == '1'?'资讯':'活动'}}</span>
          </template>
        </el-table-column>
        <el-table-column
                align="center"
                prop="state"
                width="80"
                label="活动方式">
          <template slot-scope="scope">
            <span>{{scope.row.type == '1'?'/': scope.row.type == '2'?'投票':'报名'}}</span>
          </template>
        </el-table-column>
        <el-table-column
                align="center"
                prop="state"
                width="80"
                label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.state == '1'?'启用':'关闭'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sort"
          width="80"
          label="置顶">
        </el-table-column>
        <el-table-column
          align="center"
          v-if="userInfo.roleName != '普通账号'"
          label="操作">
          <template slot-scope="scope">
            <el-button  v-auth="permission.NEWS_ACTION_EDIT"  class="color-blue link-button" type="text"
                       @click="edit(scope.row.id)">编辑
            </el-button>
            <el-button v-auth="permission.NEWS_ACTION_CONTROL"  class="color-blue link-button" type="text"
                       @click="changeStatus(scope.row)">{{scope.row.state == '1'?'关闭':'开启'}}
            </el-button>
            <el-button v-auth="permission.NEWS_ACTION_DELETE"  class="color-blue link-button" type="text"
                       @click="deleteClick(scope.row.id)">删除
            </el-button>
            <el-button v-auth="permission.NEWS_ACTION_VOTE_LIST"  v-if="scope.row.type == 2"  class="color-blue link-button" type="text"
                       @click="gotoVot(scope.row)">查看投票信息
            </el-button>
            <el-button  v-auth="permission.NEWS_ACTION_REGISTERLIST"  v-if="scope.row.type == 3"  class="color-blue link-button" type="text"
                       @click="gotoRegistration(scope.row)">查看报名信息
            </el-button>

          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <router-view/>
  </div>
</template>
<script>
  import store from '@/store/index.js';
  export default {
    beforeRouteEnter: (to,from,next) => {
      store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: "资讯管理", path: to.fullPath} );
      next()
    },
    data() {
      return {
        total: 0,
        dialogVisible: false,
        loading: false,
        searchFormData: {
          page: 1,
          perPage: 10,
        },
        searchControlData: [
          {
            type: 'input',
            label: '主题',
            placeholder: '请输入主题',
            key: 'title'
          },
          {
            type: 'rangDate',
            label: '发布时间',
            key: 'rangDate'
          },
          {
            type: 'select',
            label: '状态',
            placeholder: '请选择状态',
            key: 'state',
            option: [
              {
                label: '全部',
                value: ''
              },
              {
                label: '开启',
                value: '1'
              },
              {
                label: '关闭',
                value: '2'
              },
            ]
          }
        ],
        tableData: []
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
      }
    },

    created() {
      this.search()
    },
    methods: {
      gotoRegistration(data){
        this.$router.push({
          path:'/news/registration',
          query:{
            id:data.id,
            title: data.title
          }
        })
      },
      gotoVot(data){
        this.$router.push({
          path:'/news/vot',
          query:{
            id:  data.extraId,
            title:  data.title,
          }
        })
      },
      search(data) {
        this.loading = true
        if (data) {
          Object.assign(this.searchFormData, data)
          this.searchFormData.startTime = data.rangDate ? data.rangDate[0] : ''
          this.searchFormData.endTime = data.rangDate ? data.rangDate[1] : ''
          this.searchFormData.page = 1
        }
        this.$apis.newsServer.queryList(this.searchFormData).then(res => {
          if (res['code'] == 0 && res.data.data) {
            this.total = res.data.total
            this.tableData = res.data.data
          } else {
            this.total = 0
            this.tableData = []
          }
          this.loading = false
        })
      },
      add(){
        this.$router.push({
          path:'/news/info',
          query:{
            type: 'add'
          }
        })
      },
      edit(id) {
       this.$router.push({
         path:'/news/info',
         query:{
          type: 'edit',
          id: id
         }
       })
      },
      changeStatus(data) {
        // state: banner的当前状态： 1：开启；2：关闭
        if (data.state == 1) {
          this.$confirm('确定关闭该资讯动态？', '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            cancelButtonClass: 'cancelButton',
            confirmButtonClass: 'confirmButton',
            center: true
          }).then(() => {
            this.$apis.newsServer.close({
              id: data.id
            }).then(res => {
              if (res['code'] == 0) {
                this.search()
                this.$message({
                  type: 'success',
                  message: '关闭成功!'
                });
              }
            })
          })
        } else {
          this.$confirm('确定开启该资讯动态?', '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            cancelButtonClass: 'cancelButton',
            confirmButtonClass: 'confirmButton',
            type: 'info ',
            center: true
          }).then(() => {
            this.$apis.newsServer.open({
              id: data.id
            }).then(res => {
              if (res['code'] == 0) {
                this.search()
                this.$message({
                  type: 'success',
                  message: '启用成功!'
                });
              }
            })
          })
        }
      },
      deleteClick(id) {
        this.$confirm('确定删除该资讯动态', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'cancelButton',
          confirmButtonClass: 'confirmButton',
          type: 'info ',
          center: true
        }).then(() => {
          this.$apis.newsServer.delete({
            id: id
          }).then(res => {
            if (res['code'] == 0) {
              this.search()
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        })
      },
      handleSizeChange(val) {
        this.searchFormData.perPage = val
        this.search()
      },
      handleCurrentChange(val) {
        this.searchFormData.page = val
        this.search()
      }
    }
  }
</script>
<style>
  .el-message-box--center .el-message-box__message {
    font-size: 20px;
    color: #000000;
  }

  .cancelButton {
    width: 98px;
    margin-left: 14px;
    margin-top: 14px;
  }

  .confirmButton {
    width: 98px;
    margin-left: 60px !important;
  }
</style>
<style scoped lang="scss">
  .el-message-box--center .el-message-box__message {
    font-size: 20px;
    color: #000000;
  }
  .page {
  }
</style>