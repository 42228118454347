<template>
  <div class="page">
    <div class="content-box">
       <div class="content-left">
          <div class="content-title">
            文档版本
          </div>
          <el-scrollbar style="position: relative;flex: 1;height:100%;">
            <div class="remark-box">
              <div  class="remark-scroll">
                <ul class="remark-list">
                  <li class="remark-item" v-for="item in documentInfo.remarkList" :key="item.createTime">
                    <div class="list-title"><b class="b">版本 {{item.versionName}}</b> <span class="date">{{item.createTime | parseTime()}}</span></div>
                    <div class="list-content">
                      {{item.remark}}
                    </div>  
                    <div v-if="item.tag == 2" class="list-record"><b class="b">{{item.adminName}}</b>自动保存成功</div>
                    <div v-else class="list-record"><b class="b">{{item.adminName}}</b>编辑了文档、添加了修订备注</div>
                  </li>
                </ul>
              </div>
            </div>
          </el-scrollbar>
       </div>
       <div class="content-right">
          <div class="content-title">
            审核意见
          </div>
          <el-scrollbar style="position: relative;flex: 1;height:100%;">
            <div class="remark-box">
              <div  class="remark-scroll">
                <ul class="remark-list">
                  <li class="remark-item" v-for="item in documentInfo.reviewList" :key="item.createTime">
                    <div class="list-title">
                      <b class="b">版本 {{item.versionName}}</b> 
                      <span class="date" v-if="item.reviewState == 1">草稿</span>
                      <span class="date" v-if="item.reviewState == 2">待审核</span>
                      <span class="date" v-if="item.reviewState == 3">审核通过</span>
                      <span class="date" v-if="item.reviewState == 4">审核不通过</span>
                    </div>
                    <div class="list-content">
                      {{item.reviewComments}}
                    </div>  
                    <div class="list-record">
                      <b class="b">{{item.reviewAdminName}}</b>
                      <span class="date">{{item.reviewTime | parseTime()}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </el-scrollbar>
       </div>
    </div>
    <div class="foot">
      <el-button class="w150" type="primary" @click="onCancel()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.queryDocumentHistory()
  },
  data() {
    return {
      documentInfo:''
    }
  },
  methods:{
    //获取记录详情
    queryDocumentHistory(){
      this.$apis.documentServer.queryDocumentHistory({
        id:this.$route.query.id,//	string	id
      })
      .then((res) => {
        if (res.code == 0) {
          this.documentInfo = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    onCancel() {
      this.$router.back()
    },
  }
}
</script>

<style scoped lang="scss">
  .page{
    margin-top: 20px;
    padding: 26px 23px;
    border-radius: 5px;
    background-color: #fff;
    .content-box{
      display: flex;
      .content-title{
        padding-left: 17px;
        line-height: 43px;
        background: #28262C;
        font-size: 16px;
        font-weight: bold;
        color: #E7DCAE;
      }
      .content-left{
        flex: 1;
        border: 1px solid #DFDFDF;
        border-radius: 5px;
        overflow: hidden;
        .form-box{
          padding: 0 16px 0 16px ;
          /deep/ .el-form-item__label{
            font-weight: bold;
            color: #28262C;
          }
        }
      }
      .content-right{
        margin-left: 36px;
        width: 605px;
        border: 1px solid #DFDFDF;
        border-radius: 5px;
        overflow: hidden;
      }
      .remark-box{
        min-height:600px;
        max-height:996px;
        .remark-scroll{
          padding: 17px 22px 19px 22px;
          .remark-list{
            padding-bottom: 0;
            list-style: none;
            .remark-item{
              margin-bottom: 14px;
              padding:18px 21px 14px 19px;
              background: #F8F8F8;
              border-radius: 5px;
              .list-title{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 10px;
                font-size: 14px;
                .b{
                  color: #27252B;
                }
                .date{
                  color: #666;
                }
              }
              .list-content{
                // display: flex;
                white-space:normal;
                word-break:keep-all;
                word-wrap: break-word;
                line-height: 26px;
                color: #27252B;
                font-size: 14px;
              }
              .list-record{
                display: flex;
                margin-top: 10px;
                color: #666;
                line-height: 20px;
                font-size: 14px;
                .b{
                  margin-right: 20px;
                }
              }
            }
          }
        }
      }
    }
    .foot {
      margin-top: 20px;
      text-align: center;
    }
  }
</style>