// 开发者模块
export const  DEVELOP_MANAGE = 'develop_manage'  //开发者管理
export const  DEVELOP_MENU = 'develop_menu'     //开发者管理菜单
export const  DEVELOP_ACTION_LIST = 'develop_action_list'                             // 开发者模块菜单Listv  
export const  DEVELOP_ACTION_EXPORT = 'develop_action_export'                    // 导出 
export const  DEVELOP_ACTION_CHECK = 'develop_action_check'                       // 审核 
// 厂商账号绑定
export const  MANUFACTURER_MENU  = 'manufacturer_menu'  //厂商账号菜单
export const  MANUFACTURER_ACTION_LIST = 'manufacturer_action_list' //厂商账号列表 
export const  MANUFACTURER_ACTION_EXPORT = 'manufacturer_action_export'           // 导出



//金标管理
export const  GOLD_ACTION_MANAG = 'gold_action_manage'        //金标管理菜单
// 金标认证
export const  GOLD_ACTION_MENU = 'gold_action_menu' //金标认证菜单
export const  IDENTIFY_APK_ACTION_LIST ='identify_apk_action_list' //应用列表 
export const  IDENTIFY_APK_ACTION_EXPORT ='identify_apk_action_export' //导出应用数据 
export const  GOLD_ACTION_EXPORT_ADV ='gold_action_export_adv'  //导出应用认证数据 
export const  IDENTIFY_APK_ACTION_UNDATE_EXPIRE_TIME ='identify_apk_action_update_expire_time'//修改金标有效期
export const  IDENTIFY_APK_ACTION_QUERY_INTERVENE_LIST = 'identify_apk_action_query_intervene_list' //金标干预记录
export const  GOLD_ACTION_LIST = 'gold_action_list' //版本列表 
export const  GOLD_ACTION_EXPORT = 'gold_action_export'        // 导出版本数据 

// 应用信息管理（弃用）
export const APPLICATION_ACTION_CHECK =  'application_action_check'               // 审核


// 申诉管理
export const APPEAL_ACTION_MENU =  'appeal_action_menu'  //申诉管理菜单
export const APPEAL_ACTION_LIST =  'appeal_action_list'               // 列表   
export const APPEAL_ACTION_REVIEW =  'appeal_action_review'           // 审核
export const APPEAL_ACTION_DETAIL =  'appeal_action_detail'           // 查看
export const APPEAL_ACTION_CLOSE =  'appeal_action_close'             // 关闭

//联盟检测
export const ALLIANCE_DETECTION_MENU  = 'alliance_detection_menu' //联盟检测菜单
export const TASK_ACTION_QUERYPAGE  = 'task_action_queryPage' //任务列表 
export const TASK_ACTION_ADDTASK  = 'task_action_addTask' //添加任务
export const TEST_REPORT_RECEIVING_LIST  = 'test_report_receiving_list' //测试报告接收名单
export const TEST_REPORT_ADD_PEOPLE  = 'test_report_add_people' //测试报告添加人员
export const TEST_REPORT_DELETE_PEOPLE  = 'test_report_delete_people' //测试报告删除人员
export const LOOK_TEST_REPORT  = 'look_test_report' //查看认证报告  
export const SURVEY_DATA_EXPORT  = 'survey_data_export' //概况数据导出
export const STANDARD_DATA_EXPORT  = 'standard_data_export' //各标准数据导出
export const TEST_DETAILS_DATA_EXPORT  = 'test_details_data_export' //测试明细数据导出
export const FIRM_DATA_EXPORT  = 'firm_data_export' //厂商数据导出
export const TEST_DETAILS_FRIM_LOOK_REPORT  = 'test_details_firm_look_report' //测试明细厂商查看报告
export const TEST_DETAILS_FRIM_LOOK_DETAILS  = 'test_details_firm_look_details' //测试明细厂商查看详情
export const TASK_MERGE ='task_merge'//任务合并

//金标认证配置
export const  TERM_MANAGE_MENU = 'term_manage_menu' //金标认证配置菜单
export const  TERM_SUMMARY_LIST ='term_summary_list'//配置概要列表
export const  TERM_STANDARD_LIST ='term_standard_list'//标准配置列表
export const  TERM_ACTION_EDIT ='term_action_edit'//更新配置
export const  EDIT_TEST_METHOD = 'edit_test_method'//编辑测试方法

//联盟检测配置
export const  UNION_DEPLOY_MENU = 'union_deploy_menu' //联盟检测配置菜单
export const  UNION_SUMMARY_LIST ='union_summary_list'//配置概要列表
export const  UNION_STANDARD_LIST ='union_standard_list'//标准配置列表
export const  UNION_ACTION_UNDATE ='union_action_undate'//更新配置

// 白名单（弃用）
export const  WHITE_ACTION_LIST = 'white_action_list'                        // 列表
export const  WHITE_ACTION_EXPORT = 'white_action_export'                    // 导出 
export const  WHITE_ACTION_ADD = 'white_action_add'                          // 添加 
export const  WHITE_ACTION_DELETE = 'white_action_delete'                    // 删除 

//运营管理
export const  OPERATE_MANAGE = 'operate_manage' //运营管理 
// banner
export const  BANNER_ACTION_MENU = 'banner_action_menu'     //banner管理
export const  BANNER_ACTION_LIST='banner_action_list'       //banner列表 
export const  BANNER_ACTION_ADD = 'banner_action_add'       // 添加 
export const  BANNER_ACTION_EDIT = 'banner_action_edit'     // 编辑 
export const  BANNER_ACTION_DELETE = 'banner_action_delete' // 删除
export const  BANNER_ACTION_SWITCH = 'banner_action_switch' // 开启/关闭

// 资讯管理
export const NEWS_ACTION_MENU   = 'news_action_menu'  //资讯管理菜单
export const NEWS_ACTION_LIST  = 'news_action_list'   //资讯管理列表 
export const NEWS_ACTION_ADD  = 'news_action_add'                                             //  增加
export const NEWS_ACTION_DELETE = 'news_action_delete'                                        //  删除
export const NEWS_ACTION_EDIT = 'news_action_edit'                                            //  编辑
export const NEWS_ACTION_DETAIL = 'news_action_detail'                                        //  详情
export const NEWS_ACTION_CONTROL = 'news_action_control'                                      //  开启
export const NEWS_ACTION_VOTE_LIST = 'news_action_vote_list'                                  //  投票信息列表
export const NEWS_ACTION_VOTEINFOLIST = 'news_action_voteinfolist'                            //  投票详细列表
export const NEWS_ACTION_VOTEINFOLIST_EXPORT = 'news_action_voteinfolist_export'              //  投票详细列表导出
export const NEWS_ACTION_REGISTERLIST = 'news_action_registerlist'                            //  报名信息列表
export const NEWS_ACTION_REGISTE_EXPORT = 'news_action_registe_export'                        //  报名信息导出

//数据统计
export const DATA_STATISTICS_MENU   = 'data_statistics_menu'  //数据统计菜单
export const STAT_ACCOUNT_LIST = 'stat_account_list' //开发者统计列表
export const STAT_ACCOUNT_EXPORT = 'stat_account_export' //开发者统计导出
export const STAT_APK_EXPORT= 'stat_apk_export' //应用导出 
export const STAT_IDENTIFY_EXPORT= 'stat_identify_export' //金标认证数据导出
export const STAT_TIME_EXPORT= 'stat_time_export' //认证时效数据导出

//推荐文档
export const RECOMMEND_TEXT_MENU  = 'recommend_text_menu'  //推荐文档菜单
export const RECOMMEND_TEXT_LIST  = 'recommend_text_list'  //推荐文档列表
export const RECOMMEND_TEXT_ADD  = 'recommend_text_add'    //添加文档
export const RECOMMEND_TEXT_EDIT  = 'recommend_text_deit'    //编辑文档
export const RECOMMEND_TEXT_CONTROL = 'recommend_text_control'   //  开启
export const RECOMMEND_TEXT_DELETE = 'recommend_text_delete'   //删除


//文档中心
export const DOCUMENT_CENTER_MENU = 'document_center_menu'//文档中心菜单
export const DOCUMENT_CENTER_MANAGE='document_center_manage'//文档管理员
export const DOCUMENT_PANEL_ADD='document_panel_add'//文档导航添加节点
export const DOCUMENT_PANEL_EDIT='document_panel_edit'//文档导航编辑节点
export const DOCUMENT_PANEL_DELETE='document_panel_delete'//文档导航删除节点

//公告管理
export const NOTICE_MANAGE_MENU = 'notice_manage_menu'//公告管理菜单
export const NOTICE_MANAGE_LIST = 'notice_manage_list'   //列表
export const NOTICE_MANAGE_ADD = 'notice_manage_add'     //新增
export const NOTICE_MANAGE_EDIT = 'notice_manage_edit'   //编辑
export const NOTICE_MANAGE_DELETE = 'notice_manage_delete' //删除
export const NOTICE_MANAGE_SWITCH = 'notice_manage_switch' //开启关闭

//联盟成员
export const MEMBER_MENU = 'member_menu'//联盟成员菜单
export const MEMBER_LIST = 'member_list'   //列表
export const MEMBER_ADD = 'member_add'     //新增
export const MEMBER_EDIT = 'member_edit'   //编辑
export const MEMBER_DELETE = 'member_delete' //删除
export const MEMBER_SWITCH = 'member_switch' //开启关闭


//基础功能
export const BASIC_FUNCTIONS_MANAGE = 'basic_functions_manage'  
// 用户管理
export const USER_ACTION_MENU = 'user_action_menu'  //用户管理菜单 
export const USER_ACTION_LIST = 'user_action_list'   //列表 
export const USER_ACTION_ADD = 'user_action_add'                                 //  增加
export const USER_ACTION_EDIT = 'user_action_edit'                               //  编辑
export const USER_ACTION_RESET = 'user_action_reset'                             //  重置
export const USER_ACTION_DELETE = 'user_action_delete'                           //  删除

// 邮箱预警
export const ALERT_ACTION_MENU  = 'alert_action_menu'
export const ALERT_ACTION_LIST  = 'alert_action_list' //邮件预警列表
export const ALERT_ACTION_ADD  = 'alert_action_add'                               //  增加
export const ALERT_ACTION_DELETE = 'alert_action_delete'                          //  删除

// 同步日志
export const TOOL_ACTION_MENU =  'tool_action_menu'  
export const TOOL_ACTION_QUERYIDENTIFYSYNC = 'tool_action_queryIdentifySync'               // 列表 
export const TOOL_ACTION_QUERYAPKNEWVERSION = 'tool_action_queryApkNewVersion'               // 厂商状态查询
export const TOOL_ACTION_SYNCAGAIN =  'tool_action_syncAgain'               // 厂商状态查询重试

//权限配置
export const AUTHORITY_DEPLOY_MENU =  'authority_deploy_menu'   //菜单
export const AUTHORITY_DEPLOY_LIST =  'authority_deploy_list'   //列表
export const AUTHORITY_DEPLOY_ADD =  'authority_deploy_add'     //新增
export const AUTHORITY_DEPLOY_EDIT =  'authority_deploy_edit'   //编辑
export const AUTHORITY_DEPLOY_DELETE =  'authority_deploy_delete' //删除

//权限管理
export const AUTHORITY_MANAGE_MENU = 'authority_manage_menu' 
export const AUTHORITY_MANAGE_LIST = 'authority_manage_list'   //列表
export const AUTHORITY_MANAGE_ADD = 'authority_manage_add'     //新增
export const AUTHORITY_MANAGE_EDIT = 'authority_manage_edit'   //编辑
export const AUTHORITY_MANAGE_DELETE = 'authority_manage_delete' //删除

//批量同步日志
export const BATH_TOOL_ACTION_MENU = 'bath_tool_action_menu'  
export const BATH_TOOL_ACTION_QUERYIDENTIFYSYNC =  'tool_action_queryTaskIdentifySync'               // 列表 
export const BATH_TOOL_ACTION_SYNCAGAIN =  'tool_action_taskSyncAgain'               // 厂商状态查询重试


