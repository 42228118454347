<template>
    <button
      id="wk-button"
      class="el-button "
      @click="handleClick"
      :disabled="buttonDisabled || loading"
      :autofocus="autofocus"
      :type="nativeType"
      :class="[
        type ? 'el-button--' + type : '',
        buttonSize ? 'el-button--' + buttonSize : '',
        {
          'is-disabled': buttonDisabled,
          'is-loading': loading,
          'is-plain': plain,
          'is-round': round,
          'is-circle': circle
        }
      ]"
    >
      <i class="el-icon-loading" v-if="loading"></i>
      <i :class="icon" v-if="icon && !loading"></i>
      <span v-if="$slots.default"><slot></slot></span>
    </button>
</template>
<script>
  export default {
    name: 'WkButton',

    inject: {
      elForm: {
        default: ''
      },
      elFormItem: {
        default: ''
      }
    },

    props: {
      type: {
        type: String,
        default: 'default'
      },
      size: String,
      icon: {
        type: String,
        default: ''
      },
      nativeType: {
        type: String,
        default: 'button'
      },
      loading: Boolean,
      disabled: Boolean,
      plain: Boolean,
      autofocus: Boolean,
      round: Boolean,
      circle: Boolean
    },

    computed: {
      _elFormItemSize() {
        return (this.elFormItem || {}).elFormItemSize;
      },
      buttonSize() {
        return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
      },
      buttonDisabled() {
        return this.disabled || (this.elForm || {}).disabled;
      }
    },

    methods: {
      handleClick(evt) {
        this.$emit('click', evt);
      }
    }
  };
</script>

<style scoped lang="scss">
  #wk-button.el-button  {
    padding: 0 25px;
    line-height: 28px;
    height: 32px;
    overflow: hidden;
  }
  #wk-button.el-button {
    color: #444444;
    border-color: #ccc;
    background-color: #fff;
    &:hover {
      background:  #EAE9EA;
    }
    &:active {
      border-color: #27252B;
      background:  #EAE9EA;
    }
  }
  #wk-button.el-button--primary{
    color: #fff;
    background: #27252B;
    border-color: #27252B;
    &:hover {
      background: #535156;
      border-color: #535156;
    }
    &:active {
      background: #27252B;
      border-color: #27252B;
    }
  }
  #wk-button.is-disabled, 
  #wk-button.is-disabled:active, 
  #wk-button.is-disabled:focus, 
  #wk-button.is-disabled:hover{
    background: #F8F6F7;
    border-color: #CCCCCC;
    color: #CCCCCC;
  }

  #wk-button.el-button--big  {
    box-sizing: border-box;
    display: flex;   
    min-width: 130px;
    height: 50px;
    padding: 0 25px;
    line-height: 48px;
    font-size: 20px;
    text-align: center;
    overflow: hidden;
  }

</style>


