<template>
  <div class="timeBox">
    <div class="chartBox">
      <div class="titleBox">
        <h1 class="title">认证通过率数据图表</h1>
      </div>
      <div class="chart">
        <div class="screenBox">
          <el-radio-group v-model="searchFormData.type" style="margin-right:40px" @change="agreeChange()"> 
            <el-radio-button label="1">日</el-radio-button>
            <el-radio-button label="2">周</el-radio-button>
            <el-radio-button label="3">月</el-radio-button>
          </el-radio-group>
          <el-date-picker
            v-show="searchFormData.type=='1'"
            v-model="searchFormData.rangDate"
            value-format="timestamp"
            @change="changeRangDate()"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            :default-time="['00:00:00', '23:59:59']"
            >
          </el-date-picker>
          <el-date-picker
            v-show="searchFormData.type=='2'"
            v-model="searchFormData.rangDate"
            value-format="timestamp"
            @change="changeRangDate()"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptionsWeek"
            :default-time="['00:00:00', '23:59:59']"
            >
          </el-date-picker>
          <el-date-picker
            v-show="searchFormData.type=='3'"
            v-model="searchFormData.rangDate"
            value-format="timestamp"
            @change="changeRangDate('3')"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptionsMonth"
            :default-time="['00:00:00', '23:59:59']"
            >
          </el-date-picker>
           <!-- <div class="block" v-if="searchFormData.type=='2'">
              <el-date-picker
                v-model="searchFormData.startTime"
                type="week"
                format="yyyy 第 WW 周"
                placeholder="选择周"
                :picker-options="pickerOptionsWeek"
                @change="onValueChang"
                style="width: 180px"
              >
              </el-date-picker>
              至
              <el-date-picker
                v-model="searchFormData.endTime"
                type="week"
                format="yyyy 第 WW 周"
                placeholder="选择周"
                :picker-options="pickerOptionsWeek"
                 @change="onValueChang"
                style="width: 180px"
              >
              </el-date-picker>
            </div> -->
          
 
          <el-radio-group v-model="searchFormData.memberId"  @change="setBarChart()" style="float: right;">  
            <el-radio-button label="0">整体</el-radio-button>
            <el-radio-button label="2">OPPO</el-radio-button>
            <el-radio-button label="3">vivo</el-radio-button>
            <el-radio-button label="1">小米</el-radio-button>
          </el-radio-group>
        </div>
        <div id="timeBarChartBox"></div>         
      </div>
    </div>

    <div class="timeDataBox">
      <div class="titleBox">
        <h1 class="title">时效数据</h1>
        <el-button v-auth="permission.STAT_TIME_EXPORT" class="exportButton" type="primary" @click="exportClick()">导出数据</el-button>
      </div>
      <el-table
        class="tableBox"
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }">
        <el-table-column
          width="60"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="云测开始时间"
          width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.startTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="认证类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type==1">首次认证</span>
            <span v-if="scope.row.type==2">重新认证</span>
            <span v-if="scope.row.type==3">周期性认证</span>       
          </template>
        </el-table-column>
        <el-table-column
          prop="apkName"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="apkPackage"
          label="包名">
        </el-table-column>
        <el-table-column
          prop="versionName"
          label="版本名称">
        </el-table-column>
        <el-table-column
          prop="versionCode"
          label="版本号">
        </el-table-column>
        <el-table-column
          prop="reviewTime"
          label="云测结束时间"
          width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.reviewTime">{{ scope.row.reviewTime | parseTime() }}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="timeliness"
          label="耗时(小时)">
          <template slot-scope="scope">
            <span v-if="scope.row.timeliness">{{ scope.row.timeliness}}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column
            v-for="(item,index) in memberList"
            :key="index"
            :label="item.name+'云测'">
            <el-table-column             
              label="开始时间">
              <template slot-scope="scope">
                <span v-if="scope.row.timelinessList[index].startTime">{{ scope.row.timelinessList[index].startTime | parseTime() }}</span>
                <span v-else>/</span>
              </template>
          </el-table-column>
            <el-table-column             
              label="结束时间">
              <template slot-scope="scope">
              <span v-if="scope.row.timelinessList[index].reviewTime">{{ scope.row.timelinessList[index].reviewTime | parseTime() }}</span>
              <span v-else>/</span>
            </template>
          </el-table-column>
            <el-table-column             
              label="耗时(小时)">
              <template slot-scope="scope">
                <span v-if=" scope.row.timelinessList[index].timeliness">{{ scope.row.timelinessList[index].timeliness}}</span>
                <span v-else>/</span>
              </template>
          </el-table-column>
        
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  data(){
    return{
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() >= new Date(new Date().toLocaleDateString()).getTime()  || time.getTime() < new Date("2023-03-01").getTime() - 8.64e7//- 8.64e7代表3月1日当天可选
        },
      },
       pickerOptionsWeek:{
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() >= new Date(new Date().toLocaleDateString()).getTime()  || time.getTime() < new Date("2023-03-05").getTime()
        },
      },
       pickerOptionsMonth:{
        disabledDate(time) {
          return time.getTime() >= new Date(new Date().toLocaleDateString()).getTime()  || time.getTime() < new Date("2023-03-01").getTime() - 8.64e7//- 8.64e7代表3月1日当天可选
        },
      },
        // disabledDateWeek(time) {
        //   return time.getTime() >= new Date(new Date().toLocaleDateString()).getTime()  || time.getTime() < new Date("2023-03-05").getTime() //- 8.64e7代表3月1日当天可选
        // },
      //  pickerOptionsWeek: {
      //   firstDayOfWeek: 1,
      //   disabledDate: (time) => this.disabledDateWeek(time),
      // },
      total: 0,
      tableData: [], 
      searchFormData:{
        memberId:0,
        type:"1",
        rangDate:[new Date(new Date().getFullYear(),new Date().getMonth(),-30).getTime(),new Date().getTime()],
        startTime:"",
        endTime:"",
        page: 1,
        perPage: 10,
      },
      memberList:[],//厂商列表
      myBarChart:"",
      barOption:{
        title: {
          text: "认证时效",
          textStyle:{
            color:"#28262C",
            fontSize:16
          },
          left:"left",
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          top:30,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        barWidth:20,
        series: [
          {
            name:'',
            type:'bar',
            stack:'Ad',
            data: [],
            itemStyle: {
              normal: {
                color: '#ccc'
              }
            },
          },
          {
            name:'',
            type:'bar',
            stack:'Ad',
            data: [],
            itemStyle: { 
              normal: {
                color: '#b25000'
              }
            },
          },
          {
            name:'',
            type:'bar',
            stack:'Ad',
            data: [],
            itemStyle: {   
              normal: {
                color: '#a1855f'
              }
            },
          },
          {
            name:'',
            type:'bar',
            stack:'Ad',
            data: [],
            itemStyle: {  
              normal: {
                color: '#ccbc74'
              }
            },
          },
          {
            name:'',
            type:'bar',
            stack:'Ad',
            data: [],
            itemStyle: {   
              normal: {
                color: '#7e3721'
              }
            },
          },
          {
            name:'',
            type:'bar',
            stack:'Ad',
            data: [],
            itemStyle: {   
              normal: {
                color: '#e7b25e'
              }
            },
          },
          {
            name:'',
            type:'bar',
            stack:'Ad',
            data: [],
            itemStyle: {    
              normal: {
                color: '#4f450d'
              }
            },
          },
          {
            name:'',
            type:'bar',
            stack:'Ad',
            data: [],
            itemStyle: {  
              normal: {
                color: '#2c0318'
              }
            },
          },
          {
            name:'',
            type:'bar',
            stack:'Ad',
            data: [],
            itemStyle: {
              normal: {
                color: '#3e3932'
              }
            },
          },
        ],  
      },
    }
  },
  mounted(){
    this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: '';
    this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: '';
    this.getMemberList();
    this.queryTimelinessPage();
    this.setBarChart();
  },
  methods:{
    //改变日周月时触发  
    agreeChange(){
      this.searchFormData.page=1;
      if(this.searchFormData.type==1){
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-30).getTime(),new Date().getTime()- 8.64e7]
      } else if(this.searchFormData.type==2){
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-60).getTime(),new Date().getTime()- 8.64e7]
      }else{
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-60).getTime(),new Date().getTime()- 8.64e7]
      }
      this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: ''
      this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: ''
      this.queryTimelinessPage();
      this.setBarChart();
    },

    //选择时间时触发
    changeRangDate(type){
      this.searchFormData.page=1;
      this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: '';
      if(type == '3'){
          var date = new Date(this.searchFormData.rangDate[1]);
          // 下月1号-1秒 
          this.searchFormData.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime()-1000;
      }else{
        this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: '';
      }
      this.queryTimelinessPage();
      this.setBarChart();   
    },
    //获取厂商
    getMemberList(){
      this.$apis.unionAttestServer.enableMemberList().then(res=>{
         if (res['code'] == 0 && res.data) {
          this.memberList = res.data;
        }
      })
    },
    //获取列表数据
    queryTimelinessPage(){
      this.$apis.dataCensusServer.queryTimelinessPage(this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total
          this.tableData = res.data.data
        } else {
          this.total = 0
          this.tableData = []
        }
      })
    },

    setBarChart(){
      var barChart=document.getElementById("timeBarChartBox");
      barChart.style.width=window.innerWidth-300+'px';
      this.myBarChart = echarts.init(barChart);
      this.$apis.dataCensusServer.queryTimelinessForLine(this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data.series) {
          this.barOption.title.text="认证时效"; 
          this.barOption.legend.data=res.data.legend;
          this.barOption.xAxis.data=res.data.xAxis;  
          for(let key in res.data.series){
            this.barOption.series[key]["name"]=res.data.series[key]["name"];
            this.barOption.series[key]["data"]=res.data.series[key]["data"];
          }   
          this.myBarChart.setOption(this.barOption);
          window.addEventListener('resize', () => {
            barChart.style.width=window.innerWidth-300+'px';
            this.myBarChart.resize();
          })
        }else{
          for (var i = 0; i < this.barOption.series.length; i++) {  //置空关键！！！  清空名字和数据
            this.barOption.series[i].data = [];         
          }
          this.barOption.xAxis.data=[]; 
          this.barOption.title.text="暂无数据"; 
          this.myBarChart = echarts.init(barChart)  //再次初始化
          this.myBarChart.setOption(this.barOption, true)  //重新setOption
        }
      })
    },
    exportClick(){
      var data={
        startTime:"",
        endTime:"",
        type:'',
      }
      data.type = this.searchFormData.type;
      data.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0] : '';
      if(data.type == '3'){
        var date = new Date(this.searchFormData.rangDate[1]);
        data.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime()-1000;
      }else{
        data.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1] : '';
      }
      this.$apis.dataCensusServer.exportTimeliness(data).then(res => {
        if (res['code'] == 0 && res['data']['link']) {
          let link = res.data.link;
          window.location.href = link;
        }
      });
    },
    handleSizeChange(val) {
      this.searchFormData.perPage = val
      this.queryTimelinessPage()
    },
    handleCurrentChange(val) {
      this.searchFormData.page = val
      this.queryTimelinessPage()
    }
  }
}
</script>

<style scoped lang="scss">
.timeBox{
  .titleBox{
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #E4E7ED;
  }
  .chartBox{
    .chart{
      margin-top: 20px;
      #timeBarChartBox{
        margin-top:30px;
        width: 100%;
        height: 500px;
      }
    }
  }
  
  .timeDataBox{
    margin-top: 70px;
    /deep/ .el-tabs__content{
      overflow: initial;
    }
    /deep/ .el-table__header th {
      padding: 0;
      height: 32px!important;
    }
    .tableBox{
      margin-top: 20px;
    }
  }
  
}
</style>
<style>
.el-table thead.is-group th {
  background: #28262C;
  border:none;
}
</style>