let apiBase = {
  addDocument: '/api/docCenter/add', // 文档新增
  queryDocumentInfo: '/api/docCenter/queryDetail', // 文档详情
  heartBeatDocument: '/api/docCenter/heartBeat', // 文档锁定
  unlockDocument: '/api/docCenter/unlock', // 文档解锁
  updateDocument: '/api/docCenter/update', // 文档更新
  queryDocumentHistory: '/api/docCenter/queryDocHistory', // 文档记录
  queryHistoryDoc: '/api/docCenter/queryHistoryDoc', // 文档审核记录
  auditDocument: '/api/docCenter/review', // 文档审核
  recallDocument: '/api/docCenter/recall', // 文档撤回
  publishDocument: '/api/docCenter/publish', // 文档发布
  offShelfDocument: '/api/docCenter/offShelf', // 文档下架
  versionComparefDocument: '/api/docCenter/versionCompare', // 文档对吧
  

  
  queryReviewList: '/api/docCenter/queryReviewList', // 待审核列表
  queryPage: '/api/docCenter/queryPage',// 文档列表
  queryTreeList:'/api/docMenu/queryTreeList',//文档导航列表
  queryDetail:'/api/docMenu/queryDetail',//文档中心权限详情
  queryListByAuthorizedKey:'/api/admin/queryListByAuthorizedKey',//文档中心用户节点权限
  addDocMenu:'/api/docMenu/add',//导航管理添加节点
  queryDocMenuDetail:'/api/docMenu/queryDetail',//节点详情
  deleteDocMenu: '/api/docMenu/delete',//删除节点
  updateDocMenu: '/api/docMenu/update',//更新节点
  queryAddDefault:'/api/docMenu/queryAddDefault',//添加是子级继承父级权限
}
let DocumentServerObject = null
class DocumentServer{
  constructor(){}
  addDocument(data={}){
    return $http({
      url: apiBase.addDocument,
      method: 'post',
      data: data,
    })
  }
  queryDocumentInfo(data={}){
    return $http({
      url: apiBase.queryDocumentInfo,
      method: 'post',
      data: data,
    })
  }
  heartBeatDocument(data={}){
    return $http({
      url: apiBase.heartBeatDocument,
      method: 'post',
      data: data,
    })
  }
  unlockDocument(data={}){
    return $http({
      url: apiBase.unlockDocument,
      method: 'post',
      data: data,
    })
  }
  updateDocument(data={}){
    return $http({
      url: apiBase.updateDocument,
      method: 'post',
      data: data,
    })
  }
  queryDocumentHistory(data={}){
    return $http({
      url: apiBase.queryDocumentHistory,
      method: 'post',
      data: data,
    })
  }
  queryHistoryDoc(data={}){
    return $http({
      url: apiBase.queryHistoryDoc,
      method: 'post',
      data: data,
    })
  }
  auditDocument(data={}){
    return $http({
      url: apiBase.auditDocument,
      method: 'post',
      data: data,
    })
  }
  recallDocument(data={}){
    return $http({
      url: apiBase.recallDocument,
      method: 'post',
      data: data,
    })
  }
  publishDocument(data={}){
    return $http({
      url: apiBase.publishDocument,
      method: 'post',
      data: data,
    })
  }
  offShelfDocument(data={}){
    return $http({
      url: apiBase.offShelfDocument,
      method: 'post',
      data: data,
    })
  }
  versionComparefDocument(data={}){
    return $http({
      url: apiBase.versionComparefDocument,
      method: 'post',
      data: data,
    })
  }
  
   
  queryReviewList(data={}){
    return $http({
      url: apiBase.queryReviewList,
      method: 'post',
      data: data,
    })
  }

  queryPage(data={}){
    return $http({
      url: apiBase.queryPage,
      method: 'post',
      data: data,
    })
  }

  queryTreeList(data={}){
    return $http({
      url: apiBase.queryTreeList,
      method: 'post',
      data: data,
    })
  }

  queryDetail(data={}){
    return $http({
      url: apiBase.queryDetail,
      method: 'post',
      data: data,
    })
  }

  queryListByAuthorizedKey(data={}){
    return $http({
      url: apiBase.queryListByAuthorizedKey,
      method: 'post',
      data: data,
    })
  }

  addDocMenu(data={}){
    return $http({
      url: apiBase.addDocMenu,
      method: 'post',
      data: data,
    })
  }

  queryDocMenuDetail(data={}){
    return $http({
      url: apiBase.queryDocMenuDetail,
      method: 'post',
      data: data,
    })
  }

  deleteDocMenu(data={}){
    return $http({
      url: apiBase.deleteDocMenu,
      method: 'post',
      data: data,
    })
  }

  updateDocMenu(data={}){
    return $http({
      url: apiBase.updateDocMenu,
      method: 'post',
      data: data,
    })
  }

  queryAddDefault(data={}){
    return $http({
      url: apiBase.queryAddDefault,
      method: 'post',
      data: data,
    })
  }
}
function singlDocumentServer(){
  if(DocumentServerObject === null){
    DocumentServerObject = new DocumentServer()
  }
  return DocumentServerObject
}
export default singlDocumentServer()