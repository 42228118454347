let apiBase = {
    queryPage:'/api/adminRole/queryPage',  //用户组列表
    addAdminRole:'/api/adminRole/addAdminRole',//添加用户组
    queryDetail:'/api/adminRole/queryDetail',//编辑用户组
    updateAdminRole:'/api/adminRole/updateAdminRole', //更新用户组
    delete:'/api/adminRole/delete',//删除用户组 
  }
  let AuthorityServerObject = null
  class AuthorityServer{
    constructor(){}
  

    queryPage(data={}){
      return $http({
        url: apiBase.queryPage,
        method: 'post',
        data: data,
      })
    }
  
    
    addAdminRole(data={}){
      return $http({
        url: apiBase.addAdminRole,
        method: 'post',
        data: data,
      })
    }

    queryDetail(data={}){
      return $http({
        url: apiBase.queryDetail,
        method: 'post',
        data: data,
      })
    }
    
    updateAdminRole(data={}){
      return $http({
        url: apiBase.updateAdminRole,
        method: 'post',
        data: data,
      })
    }

    delete(data={}){
      return $http({
        url: apiBase.delete,
        method: 'post',
        data: data,
      })
    }
  }
  function singleAuthorityServer(){
    if(AuthorityServerObject === null){
      AuthorityServerObject = new AuthorityServer()
    }
    return AuthorityServerObject
  }
  export default singleAuthorityServer()