import Vue from 'vue';
import Vuex from 'vuex';
import moduleConfig from "@/common/module-config";
Vue.use(Vuex);
const store ={
  namespaced:true,
  state:{ //状态对象
    "moduleRouters": moduleConfig,//所有导航
    "key":'developer_manage',
  },
  mutations:{  //同步修改
    setNavModule(state, name){
      state.key = name;
    },
  },
  getters:{//监测状态
    getModuleName:(state,getters,rootState,rootGetters)=>{
      return state.key;
    },
    getModuleRouters:(state,getters,rootState,rootGetters)=>{
      return state.moduleRouters[state.key];
    },
  },
}

export default store

