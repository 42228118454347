<template>
  <el-dialog
    title="厂商状态查询"
    :visible="dialogVisible"
    width="600px"
    @close="closeModal"
    center>
    <el-form :model="ruleForm" :rules="ruleData" ref="formRef" label-width="100px" class="demo-ruleForm">
      <el-form-item label="厂商" prop="memberId">
        <el-select class="w400"  size="medium" v-model="ruleForm.memberId" placeholder="厂商">
          <el-option
            v-for="(item,index) in memberList"
            :key="index"
            :label="item.label"
            :value="item.id">
            {{item.label}}
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="包名" prop="apkPackage">
          <el-input class="w400" v-model="ruleForm.apkPackage" placeholder="请输入包名"></el-input>
      </el-form-item>
      <!-- <el-form-item label="账户id" prop="accountId">
          <el-input class="w400" v-model="ruleForm.accountId" placeholder="请输入账户id"></el-input>
      </el-form-item> -->
      <el-form-item class="w460 text-center">
        <el-button class="w150" type="primary" @click="onSubmit()">查询</el-button>
      </el-form-item>
      
      <el-form-item label="返回结果" prop="result">
          <el-input class="w400" type="textarea" :autosize="{ minRows: 10, maxRows: 10}" v-model="ruleForm.result" ></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        ruleForm: {
          memberId:null, // 厂商ID
          apkPackage: '',  // 包名
          // accountId: '',  // 账号id
          result: '',  // 返回结果
        },
        ruleData: {//角色表单校验
          memberId: [
            {required: true, message: '请选择厂商', trigger: ['blur', 'change']}
          ],
          apkPackage: [
            {required: true, message: '请填写包名', trigger: 'blur'},
          ],
          // accountId: [
          //   {required: true, message: '请填写账号id', trigger: 'blur'},
          // ],
        },
      }
    },
    computed: {
      memberList(){
        return this.$store.getters['accountStore/getMemberList'];
      }
    },
    methods: {
      onSubmit() {
        this.$refs['formRef'].validate(valid => {
          if (valid) {
            this.$apis.syncServer.queryApkNewVersion({
              'memberId':this.ruleForm.memberId,
              'apkPackage':this.ruleForm.apkPackage,
              // 'accountId':this.ruleForm.accountId,
            }).then(res => {
              if (res.code == 0) {
                this.ruleForm.result = res.data.result;
              } else {
                this.$message.error(res.message);
              }
            })
          }
        });
      },
      closeModal() {
        this.dialogVisible = false;
        this.showLoading = false;
        this.resetForm();
      },
      show() {
        this.dialogVisible = true;
      },
      resetForm() {
        this.$refs['formRef'].resetFields();
      },
    }
  }
</script>

<style scoped>
</style>
