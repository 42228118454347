<template>
	<div class="page">
		<el-form label-position="left" :model="ruleForm" :rules="ruleData" ref="formRef" label-width="100px" class="demo-ruleForm">
			<el-form-item label="标题" prop="title">
				<el-input show-word-limit :maxlength="20" class="w400" v-model="ruleForm.title" placeholder="请输入名称"></el-input>
			</el-form-item>
			<el-form-item label="描述" prop="description">
				<el-input
					:rows="6"
					:maxlength="120"
					show-word-limit
					type="textarea"
					class="w400" v-model="ruleForm.description" placeholder="请输入描述内容"></el-input>
			</el-form-item>
			<el-form-item label="发布时间" prop="publishTime">
				<el-date-picker
					class="w400"
					unlink-panels
					value-format="timestamp"
					v-model="ruleForm.publishTime"
					placeholder="请选择发布时间"
					type="datetime">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="消息类型" prop="type">
				<el-col :span="18">
					<el-radio-group
						:disabled="type == 'edit'"
						:span="16"
						v-model="ruleForm.type">
						<el-radio :label="1">资讯</el-radio>
						<el-radio :label="2">活动投票</el-radio>
						<el-radio :label="3">活动报名</el-radio>
					</el-radio-group>
				</el-col>

			</el-form-item>
			<el-form-item label="状态" prop="state">
				<el-col :span="11">
					<el-radio-group :span="16" v-model="ruleForm.state">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="2">关闭</el-radio>
					</el-radio-group>
				</el-col>
			</el-form-item>
			<el-form-item label="置顶" prop="topState">
				<el-col :span="11">
					<el-radio-group @change="topStateChange('waysType')" :span="16" v-model="ruleForm.topState">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="2">关闭</el-radio>
					</el-radio-group>
				</el-col>
			</el-form-item>
			<el-form-item prop="sort">
				<span class="w60">置顶序号：</span>
				<el-input
					:disabled="ruleForm.topState == 2"
				 class="w340"
				 placeholder="数字越大越靠前"
				 v-model="ruleForm.sort"></el-input>
			</el-form-item>
			<el-form-item label="图片" prop="imageUrl" :error="uploadErrorInfo">
				<el-col :span="11">
			<wk-upload
				class="w400"
			  @uploadChange="handleImgChange"
			  :imgUrl="ruleForm.imageUrl"
			  @uploadError="handleImgError"
			></wk-upload>
					<span> 图片尺寸234*157</span>
				</el-col>
			</el-form-item>
			<el-form-item label="资讯正文" prop="content">
				<el-col :span="22">
					<tinymce ref="editRef" :uploadBtn="true"   v-model="ruleForm.content" :height="600" />
				</el-col>
			</el-form-item>
			<div v-if="ruleForm.type == 2">
				<el-form-item label="投票标题" prop="voteTitle">
					<el-col :span="16" prop="sort">
						<el-input  show-word-limit :maxlength="20" class="w360" placeholder="请输入投票标题" v-model="ruleForm.voteTitle"></el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="投票说明" prop="desc">
					<el-col :span="16">
						<el-input
							:rows="5"
							:maxlength="80"
							show-word-limit
							type="textarea"
							class="w360" v-model="ruleForm.desc"></el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="投票方式" prop="waysType">
					<el-col :span="11">
						<el-radio-group
							:disabled="type == 'edit'"
							:span="16" v-model="ruleForm.waysType">
							<el-radio :label="1">单选</el-radio>
							<el-radio :label="2">多选</el-radio>
						</el-radio-group>
					</el-col>
				</el-form-item>
				<el-form-item label="选项" prop="selectType">
					<el-col :span="16">
						<div class="select-input" v-for="(item,index) in activitSelectData" :key="index">
							<el-col :span="18" style="display: flex">
								<span class="w70">选项{{index+1}}</span>
								<el-input
									:maxlength="40"
									show-word-limit
									v-model="item.voteTitle"
									placeholder="请输入选项内容"></el-input>
							</el-col>
							<svg-icon @click.native="reduceSelect(index)"
							          v-if="type == 'add' && activitSelectData.length>2  "
							          class="icon-img" svg-name="减"></svg-icon>
							<svg-icon @click.native="addSelect()" v-if=" index == activitSelectData.length-1 && type == 'add' && activitSelectData.length!=10"
							          class="icon-img" svg-name="add"></svg-icon>

						</div>
					</el-col>
				</el-form-item>
			</div>
			<div class="foot">
				<el-button class="w150" type="info" @click="cancel()">取消</el-button>
				<el-button class="w150" type="primary" @click="onSubmit()">提交</el-button>
			</div>
		</el-form>
	</div>

</template>
<script>
import Tinymce from '@/components/Tinymce';
import store from '@/store/index.js';
export default {
    beforeRouteEnter: (to,from,next) => {
        store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:to.query.id? "编辑资讯":'添加资讯', path: to.fullPath} );
        next()
    },
    components: {Tinymce},
    data() {
        var validateSelect = (rule, value, callback) => {
            if (!this.activitSelectData.every(item => {
                return item.voteTitle
            })) {
                callback(new Error('请填写选项内容'));
            } else {
                callback();
            }
        };
        var validateSort = (rule, value, callback) => {
            if (this.ruleForm.topState == 1 && !value) {
                callback(new Error('请填写置顶序号'));
            } else if (this.ruleForm.topState == 1 && (value < 0)) {
                callback("请填写大于等于0的正整数");
            } else if (this.ruleForm.topState == 1 && !(/^[0-9]+.?[0-9]*/.test(value))) {
                callback("请填写数字");
            } else {
                callback();
            }
        };
        return {
            uploadErrorInfo: '', // 上传的错误
            editorContent: '',
            type: '',
            ruleForm: {
                id: '',  // id
                sort: '',  // 置顶序号
                title: '', // 标题
                description: '', // 标题
                imageUrl: '', // 缩略图
                content: '',  // 资讯正文
                publishTime: '',  // 发布时间
                type: 1,// 类型: 1、资讯；2、活动；3、线上报名
                // typeInfo: 3,// 类型: 2、线上投票；
                state: 1,  // 状态:1、启用；2、停用
                topState: 2,  // 置顶:1、启用；2、停用
                voteTitle: '',
                desc: '',
                selectType: '',
                extraId: null,
                waysType: 1 // 选项类型:1、单选；2、多选
            },
            typeSelect: [
                {
                    value: 3,
                    label: '线上报名',
                },
                {
                    value: 2,
                    label: '线上投票',
                },
            ],
            activitSelectData: [{
                id: '',
                voteTitle: ''
            }, {
                id: '',
                voteTitle: ''
            }, {
                id: '',
                voteTitle: ''
            },
            ],
            ruleData: {
                title: [
                  {required: true, message: '请填写标题', trigger: 'blur'},
                  {min: 1, max: 20, message: '标题最多支持20个字', trigger: ['change', 'blur']},
                ],
                description: [
                    {required: true, message: '请填写描述内容', trigger: ['change', 'blur']},
                ],
                publishTime: [
                    {required: true, message: '请选择发布时间', trigger: ['change', 'blur']},
                ],
                imageUrl: [
                    {required: true, message: '请上传缩略图', trigger: ['change', 'blur']},
                ],
                type: [
                    {required: true, message: '', trigger: 'blur'}
                ],
                state: [
                    {required: true, message: '', trigger: 'blur'}
                ],
                topState: [
                    {required: true, message: '', trigger: 'blur'}
                ],
                voteTitle: [
                    {required: true, message: '请填写投票标题', trigger: ['change', 'blur']},
                    {min: 1, max: 20, message: '标题最多支持20个字', trigger: ['change', 'blur']},
                ],
                waysType: [
                    {required: true, message: '', trigger: ['change', 'blur']},
                ],
                content: [
                    {required: true, message: '请输入资讯内容', trigger: ['change', 'blur']},
                ],
                selectType: [
                    {required: true, validator: validateSelect, trigger: ['change', 'blur']},
                ],
                sort: [
                    {validator: validateSort, trigger: ['change', 'blur']},
                ],
                desc: [
                    {min: 0, max: 80, message: '最多支持80个字', trigger: 'blur'}
                ]
            },
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
        }
    },
    created() {
        this.type = this.$route.query.type;
        if (this.type == 'edit'){
            this.loadData(this.$route.query.id)
        }
    },
    methods: {
        getEditorContent(content){
            this.ruleForm.content = content
        },
        topStateChange(data) {
            if (data != null && this.ruleForm.topState == 2) {
                this.$refs['formRef'].clearValidate('sort');
            }
        },
        addSelect() {
            if (this.activitSelectData.length < 10) {
                this.activitSelectData.push({
                    id: '',
                    voteTitle: ''
                })
            } else {
                this.$message.warning('最多只能有10个选项！')
            }
        },
        reduceSelect(index) {
            if (this.activitSelectData.length > 2) {
                this.activitSelectData.splice(index, 1)
            } else {
                this.$message.warning('最少要有一个选项！')
            }
        },
        loadData(id) {
            this.$apis.newsServer.detail({
                id: id
            }).then(res => {
                if (res['code'] == 0) {
                    Object.assign(this.ruleForm, res.data)
                    this.ruleForm.topState = res.data.sort == 0 ? 2 : 1
                                this.$refs['editRef'].setContent(res.data.content)
                    if (utils.notEmpty(res.data.infoVote)) {
                        this.assignmentVoteData(res.data.infoVote)
                    }
                }
            })
        },
        assignmentVoteData(data) {
            this.ruleForm.voteTitle = data.title
            this.ruleForm.desc = data.description
            this.ruleForm.waysType = data.type
            this.ruleForm.extraId = data.id
            this.activitSelectData = data.infoVoteItem
        },
        handleImgChange(res) {
					this.uploadErrorInfo = ''
					if (res['code'] == 0) {
							this.ruleForm.imageUrl = res.data.link
							this.$refs['formRef'].validateField('imageUrl');//对图片进行移除校验
					} else {
							this.uploadErrorInfo = result.message
					}
        },
        handleImgError(res) {
            console.log(res)
        },
        onSubmit() {
					this.$refs['formRef'].validate( async valid => {
						if (valid) {
							if (this.ruleForm.type == 2) {
									await this.requestVote()
									this.requestInfo()
							} else {
									this.requestInfo()
							}
						}
					})
        },
        // 资讯请求接口
        requestInfo() {
            let params = {
                id: this.ruleForm.id,
                sort: this.ruleForm.topState == 1 ?Number( this.ruleForm.sort) : 0,
                title: this.ruleForm.title, // 标题
                description: this.ruleForm.description, // 标题
                imageUrl: this.ruleForm.imageUrl, // 缩略图
                content: this.ruleForm.content,  // 资讯正文
                publishTime: this.ruleForm.publishTime,  // 发布时间
                type: this.ruleForm.type,
                state: this.ruleForm.state,  // 状态:1、启用；2、停用
                extraId: this.ruleForm.extraId,
            }
            if (this.type == 'add') {
                this.$apis.newsServer.create(params).then(res => {
                    if (res['code'] == 0) {
                        this.$message.success('添加成功！')
                        this.close()
                    }
                })
            } else {
                this.$apis.newsServer.update(params).then(res => {
                    if (res['code'] == 0) {
                        this.$message.success('修改成功！')
                        this.close()
                    }
                })
            }
        },
        // 投票请求接口
        async requestVote() {
            var  itemTitle,votParams,data
            if (this.type == 'add') {
                    itemTitle = this.activitSelectData.map(item => {
                    if (!!item.voteTitle) {
                        return item.voteTitle
                    }
                })
                let votParams = {
                    title: this.ruleForm.voteTitle,
                    description: this.ruleForm.desc,
                    type: this.ruleForm.waysType,
                    itemTitle: itemTitle.join(',')
                }
                data  =  await this.$apis.newsServer.voteCreate(votParams)
                            if(data['code'] == 0){
                    this.ruleForm.extraId = data['data']['id']
                }
            } else {
                votParams = {
                    id: this.ruleForm.extraId,
                    title: this.ruleForm.voteTitle,
                    description: this.ruleForm.desc,
                    type: this.ruleForm.waysType,
                    modifyVoteItems: JSON.stringify(this.activitSelectData)
                }
                data  =  await this.$apis.newsServer.voteUpdate(votParams)
                if(data['code'] == 0){
                }
            }

        },
        cancel() {
					const h = this.$createElement;
					this.$msgbox({
						title: '',
						message: h('p', null, [
										h('p', null, '确定取消发布吗？ '),
										h('p', null, '离开页面后已编辑的内容将会丢弃')
						]),

						showCancelButton: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						center: true
					}).then(() => {
							this.$router.back()
					})
        },
        close() {
					this.$refs['formRef'].resetFields();
					this.$router.push({
							path: '/news/list'
					})
        }
    }
}
</script>
<style scoped lang="scss">
	.el-message-box--center .el-message-box__message {
		font-size: 20px;
		color: #000000;
	}

	.page {
		background-color: #fff;
		padding: 45px 100px;
		display: block;
		margin-top: 30px;
		border-radius: 10px;

	}

	.foot {
		text-align: center;
	}

	.select-input {
		display: flex;
		margin-bottom: 30px;
	}

	.icon-img {
		width: 40px;
		height: 40px;
		// padding: 10px;
		margin-right: 10px;
		cursor: pointer;
		margin-left: 20px;
	}

	.w340 {
		width: 340px;
	}

	.w60 {
		width: 60px;
	}
</style>