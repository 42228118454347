<template>
  <div class="appDataBox">
    <div class="chartBox">
      <div class="titleBox">
        <h1 class="title">应用数据图表</h1>
      </div>
      <div class="chart">
        <div class="screenBox">
          <el-radio-group v-model="searchFormData.type" style="margin-right:40px" @change="agreeChange()"> 
            <el-radio-button label="1">日</el-radio-button>
            <el-radio-button label="2">周</el-radio-button>
            <el-radio-button label="3">月</el-radio-button>
          </el-radio-group>
          <el-date-picker
          v-show="searchFormData.type !='3'"
            v-model="searchFormData.rangDate"
            @change="changeRangDate()"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            style="margin-right:40px"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
          <el-date-picker
          v-show="searchFormData.type =='3'"
            v-model="searchFormData.rangDate"
            @change="changeRangDate('3')"
            value-format="timestamp"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </div>
        <div id="appBarChartBox"></div>
        <div class="pancakeChartBox">
          <div id="appPancakeChartBox"></div>
          <div class="summary" v-for="(item,index) in this.pancakeOption.series[0].data" :key="index">
            <span>{{item.name}}</span>
            <span>{{item.value}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="appListBox">
      <div class="titleBox">
        <h1 class="title">应用数据明细</h1>
        <el-button v-auth="permission.STAT_APK_EXPORT" class="exportButton" type="primary" @click="exportClick()">导出数据</el-button>
      </div>
      <el-table
        class="tableBox"
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }">
        <el-table-column
          width="120" 
          prop="statTime"         
          label="日期">
        </el-table-column>
        <el-table-column
          prop="newNum"         
          label="新增认证应用">
        </el-table-column>
        <el-table-column
          prop="totalNum"         
          label="认证应用总数量">
        </el-table-column>
        <el-table-column
          prop="approveNum"         
          label="金标认证有效">
        </el-table-column>
        <el-table-column
          prop="refuseNum"         
          label="金标认证失败">
        </el-table-column>
        <el-table-column
          prop="ingNum"         
          label="金标认证中">
        </el-table-column>
        <el-table-column
          prop="expireNum"         
          label="金标已过期">
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  data(){
    return{
      pickerOptions:{
        disabledDate(time) {
          // const day = 730 * 24 * 3600 * 1000  // 31536000000
          // 返回小于当前日期并近一年内的日期
          return time.getTime() >= new Date(new Date().toLocaleDateString()).getTime() 
         
        },
      },
      // timePicker:"daterange",
      total: 0,
      tableData: [],
      category:"1",//判断是新增还是累计  1新增 2累计
      searchFormData:{
        type:"1",
        rangDate:[new Date(new Date().getFullYear(),new Date().getMonth(),-30).getTime(),new Date().getTime()- 8.64e7],
        startTime:"",
        endTime:"",
        page: 1,
        perPage: 10,
      },
      myBarChart:"",
      mypancakeChart:"",
      barOption:{
        title: {
          text: "应用概况趋势图",
          textStyle:{
            color:"#28262C",
            fontSize:16
          },
          left:"left",
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          top:30,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        barWidth:20,
        series: [
          {
            name: '',
            type: 'line',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#eb4e4e'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#a1855e'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //柱状图的背景色
              normal: {
                color: '#dba245'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //折线图颜色
              normal: {
                color: '#cdbc76'
              }
            },
          },
          {
            name: '',
            type: 'bar',
            stack: 'Ad',
            data: [],
            itemStyle: {    //折线图颜色
              normal: {
                color: '#d96136'
              }
            },
          },
        ],
        // color: ['#cdbc76', '#a1855e','#f7cf5d','red'],  
      },
      pancakeOption: {
        title: {
          text: "",
          textStyle:{
            color:"#28262C",
          },
          left:"center",
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: "pie",
            data: [],
            label:{
              formatter:(arg=>{
                return arg.name + arg.value
              })
            },
            radius:110
          },
        ],
        color: ['#a28460', '#e4b25f','#c7853e','#ccbc74'],     
      },
    }
  },
  mounted(){
    this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: ''
    this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: ''
    this.setBarChart();
    this.queryAppList();
    this.setPancakeChart()

  },
  methods:{

    //改变日周月时触发  
    agreeChange(){
      this.searchFormData.page=1;
      if(this.searchFormData.type==1){
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-30).getTime(),new Date().getTime()- 8.64e7]
      } else if(this.searchFormData.type==2){
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-90).getTime(),new Date().getTime()- 8.64e7]
      }else{
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-350).getTime(),new Date().getTime()- 8.64e7]
      }
      this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0] : ''
      this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1] : ''
      this.setBarChart();
      this.queryAppList();
      this.setPancakeChart();
    },


    //选择时间时触发
    changeRangDate(type){
      this.searchFormData.page=1;
      this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: '';
      if(type == '3'){
          var date = new Date(this.searchFormData.rangDate[1]);
          // 下月1号-1秒 
          this.searchFormData.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime()-1000;
      }else{
        this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: '';
      }
      this.setBarChart();
      this.setPancakeChart();
      this.queryAppList();       
    },

    setBarChart(){
      var barChart=document.getElementById("appBarChartBox");
      barChart.style.width=window.innerWidth-300+'px';
      this.myBarChart = echarts.init(barChart);
      this.$apis.dataCensusServer.queryForLine(this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data.series) {
          this.barOption.title.text="应用概况趋势图"; 
          this.barOption.legend.data=res.data.legend;
          this.barOption.xAxis.data=res.data.xAxis;        
          for(let key in res.data.series){
            this.barOption.series[key]["name"]=res.data.series[key]["name"];
            this.barOption.series[key]["data"]=res.data.series[key]["data"];
          }
          this.myBarChart.setOption(this.barOption);
          window.addEventListener('resize', () => {
            barChart.style.width=window.innerWidth-300+'px';
            this.myBarChart.resize();
          })
        }else{
          for (var i = 0; i < this.barOption.series.length; i++) {  //置空关键！！！  清空名字和数据
            this.barOption.series[i].data = [];         
          }
          this.barOption.xAxis.data=[]; 
          this.barOption.title.text="暂无数据"; 
          this.myBarChart = echarts.init(barChart)  //再次初始化
          this.myBarChart.setOption(this.barOption, true)  //重新setOption
        }
      })
    },

    setPancakeChart(){
      var pancakeChart=document.getElementById("appPancakeChartBox");
      pancakeChart.style.width=window.innerWidth-300+'px';
      this.mypancakeChart = echarts.init(pancakeChart);
      this.$apis.dataCensusServer.queryForPie(this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data.data) {
          this.pancakeOption.series[0].data = res.data.data;
          this.pancakeOption.title.text = res.data.statTime+"应用金标状态概况";
          this.mypancakeChart.setOption(this.pancakeOption);
          window.addEventListener('resize', () => {
            pancakeChart.style.width=window.innerWidth-300+'px';
            this.mypancakeChart.resize();
          })
        }
      })
    },

    queryAppList(){
      this.$apis.dataCensusServer.queryAppList(this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total
          this.tableData = res.data.data
        } else {
          this.total = 0
          this.tableData = []
        }
      })
    },

    exportClick(){
      var data={
        startTime:"",
        endTime:"",
        type:'',
      }
      data.type = this.searchFormData.type;
      data.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0] : '';
      if(data.type == '3'){
        var date = new Date(this.searchFormData.rangDate[1]);
        data.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime()-1000;
      }else{
        data.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1] : '';
      }
      this.$apis.dataCensusServer.exportApp(data).then(res => {
        if (res['code'] == 0 && res['data']['link']) {
          let link = res.data.link;
          window.location.href = link;
        }
      });
    },

    handleSizeChange(val) {
        this.searchFormData.perPage = val
        this.queryAppList()
    },
    handleCurrentChange(val) {
      this.searchFormData.page = val
      this.queryAppList()
    }
  }
}
</script>

<style scoped lang="scss">
.appDataBox{
  .titleBox{
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #E4E7ED;
  }
  .chartBox{
    .chart{
      margin-top: 20px; 
      #appBarChartBox{
        margin-top:30px;
        width: 100%;
        height: 500px;
      }
      .pancakeChartBox{
        width: 100%;
        height: 500px;
        #appPancakeChartBox{
          padding-top:30px ;
          width: 100%;
          height: 350px;
        }
        .summary{
          width: 140px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .appListBox{
    margin-top: 70px;
  }
  .tableBox{
    margin-top: 20px;
  }
}
</style>
<style>
.el-table thead.is-group th {
  background: #28262C;
  border:none;
  
}
</style>