const routes = [
  {
    path: '/unionDeploy',
    name: '',
    redirect:'/unionDeploy/list'
  },
  {
    path: '/unionDeploy/list',
    name: '',
    meta:{
      module:'gold_action_manage',
      menu:'/unionDeploy/list',
      title:'金标认证配置',
      keepAlive: true,
      breadcrumb: ['unionDeploy'],
      menuBreadcrumb: 'unionDeploy',
    },//menu 用于所属右侧菜单栏
    component: () => import('@/views/unionDeploy/list.vue')
  },
]

export default routes;
