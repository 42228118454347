<template>
  <div class="page">
    <docu-head></docu-head>
    <div class="wrapBox mainBox">
      <docu-menu @search="search"></docu-menu>
      <docu-list v-if="$route.query.docMenuId" ref="child"></docu-list>
    </div>
  </div>
</template>

<script>
import store from '@/store/index.js';
import docuHead from './components/head';
import docuMenu from './components/menu';
import docuList from './components/list';
export default {
  beforeRouteEnter: (to,from,next) => {
		store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: "文档中心", path: to.fullPath} );
		next()
  },
  components: {
    docuHead,
    docuMenu,
    docuList
  },
  data(){
    return{ 
      
    }
  },
 
  methods:{
    search(){
      this.$refs.child.getQueryPageList();
      this.$refs.child.getQueryDetail();
    }
  }
}
</script>

<style scoped lang="scss">
.page{ 
  .mainBox{
    display: flex;
  }
}
</style>