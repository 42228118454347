<template>
    <el-form :model="formData" ref="formData" label-width="140px" class="demo-formData">
      <el-form-item label="联系人姓名">
        <el-col :span="18">
          <el-input disabled v-model="formData.contactName"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="联系人邮箱">
        <el-col :span="18">
          <el-input disabled v-model="formData.email"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="联系人电话">
        <el-col :span="18">
          <el-input disabled v-model="formData.contactPhone"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="微信">
        <el-col :span="18">
          <el-input disabled v-model="formData.weixin"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="QQ">
        <el-col :span="18">
          <el-input disabled v-model="formData.qq"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="身份证号">
        <el-col :span="18">
          <el-input disabled v-model="formData.developId"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="身份证">
        <el-col :span="24" style="display: flex;flex-wrap: wrap" v-if="formData.type == 2">
          <el-image
            v-for="(item,index) in imageData"
            :key="index"
            class="assetsImg"
            :src="item"
            alt="身份证"
            :preview-src-list="imageData"
          >
            <template slot="error">
              <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
            </template>
          </el-image>
        </el-col>
      </el-form-item>
    </el-form>
</template>

<script>
  export default {
    name: "personalInfo",
    props:{
      formData: Object,
      imageData: Array
    },
    data(){
      return {
      }
    },
  }
</script>

<style scoped>

</style>
<style lang="scss">

  .assetsImg-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .assetsImg-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .assetsImg-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .assetsImg {
    width: 178px;
    height: 178px;
    display: block;
   padding-right: 17px;
    padding-bottom: 11px;
  }
</style>