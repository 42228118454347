<template>
  <div class="page">
		<wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <div class="wrapBox">
			<div class="flex justify-space-between" style="margin-bottom: 10px">
				<div style="line-height: 40px">共{{total}}条数据</div>
				<el-button v-auth="permission.MEMBER_ADD" class="common-button" type="primary" @click="add()">
					添加联盟成员
				</el-button>
			</div>
			<el-table
				class="tableBox"
				:data="tableData"
				stripe
				v-auth="permission.MEMBER_LIST"
				style="width: 100%">
				<el-table-column
					width="60"
					align="center"
					type="index"
					label="序号">
				</el-table-column>
				<el-table-column
					align="center"
					prop="sort"
					width="60"
					label="排序">
				</el-table-column>
				<el-table-column
					align="center"
					prop="name"
					label="名称">
				</el-table-column>
				<el-table-column
					width="70"
					prop="img"
					label="图片">
					<template slot-scope="scope">
						<el-image
							alt="图片"
							style="width: 50px; height: 40px;display:block"
							:src="scope.row.img">
							<template slot="error">
								<svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
							</template>
						</el-image>
					</template>
				</el-table-column>

				<el-table-column
					align="center"
					prop="companyName"
					label="企业全称">
				</el-table-column>
				<el-table-column
					align="center"
					prop="createTime"
					label="添加时间">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime | parseTime() }}</span>
					</template>
				</el-table-column>
				<el-table-column
					align="center"
					prop="category"
					width="100"
					label="类型">
					<template slot-scope="scope">
						<span v-if="scope.row.category==1">理事长单位</span>
						<span v-if="scope.row.category==2">理事单位</span>
						<span v-if="scope.row.category==3">生态单位</span>
						<span v-if="scope.row.category==4">合作伙伴</span>
					</template>
				</el-table-column>
				<el-table-column
					align="center"
					prop="state"
					width="80"
					label="状态">
					<template slot-scope="scope">
						<span>{{ scope.row.state == '1'?'启用':'关闭'}}</span>
					</template>
				</el-table-column>
				<el-table-column
					width="200"
					label="操作">
					<template slot-scope="scope">
						<el-button v-auth="permission.MEMBER_EDIT" class="color-blue link-button" type="text"
												@click="edit(scope.row.id)">编辑
						</el-button>
						<el-button v-auth="permission.MEMBER_SWITCH" class="color-blue link-button" type="text"
												@click="changeStatus(scope.row)">{{scope.row.state == '1'?'关闭':'开启'}}
						</el-button>
						<el-button v-auth="permission.MEMBER_DELETE" class="color-blue link-button" type="text"
												@click="deleteClick(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="text-right margin-t30">
				<el-pagination
					background
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:page-sizes="[10, 20, 50]"
					:page-size="searchFormData.perPage"
					:current-page="searchFormData.page"
					layout="total, prev, pager, next, sizes"
					:total="total"
				></el-pagination>
			</div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index.js';
export default {
	beforeRouteEnter: (to,from,next) => {
		store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: "联盟成员", path: to.fullPath} );
		next()
	},
	data(){
		return{
			total: 0,
			searchFormData: {
				page: 1,
				perPage: 10,
			},
			searchControlData: [
				{
					type: 'input',
					label: '名称',
					placeholder: '请输入名称',
					key: 'name'
				},
				{
					type: 'select',
					label: '状态',
					placeholder: '请选择状态',
					key: 'state',
					option: [
						{
							label: '全部',
							value: ''
						},
						{
							label: '开启',
							value: '1'
						},
						{
							label: '关闭',
							value: '2'
						},
					]
				},
				{
					type: 'select',
					label: '类型',
					placeholder: '请选择类型',
					key: 'category',
					option: [
						{
							label: '全部',
							value: ''
						},
						{
							label: '理事长单位',
							value: '1'
						},
						{
							label: '理事单位',
							value: '2'
						},
						{
							label: '生态单位',
							value: '3'
						},
						{
							label: '合作伙伴',
							value: '4'
						},
					]
				}
			],
			tableData: []
		}
	},
		created() {
    this.search()
  },
	methods:{
		 search(data){
			if (data) {
				Object.assign(this.searchFormData, data)
				this.searchFormData.page = 1
      }
			this.$apis.memberServer.queryPage(this.searchFormData).then(res => {
				if (res['code'] == 0 && res.data.data) {
					this.total = res.data.total
					this.tableData = res.data.data
				} else {
					this.total = 0
					this.tableData = []
				}
			})
		},
		add(){
			this.$router.push({
				path:'/member/info',
				query:{
					type: 'add'
				}
			})
    },
		edit(id){
			this.$router.push({
				path:'/member/info',
				query:{
					type: 'edit',
					id:id
				}
			})
		},
		changeStatus(data) {
			// state: banner的当前状态： 1：开启；2：关闭
			if (data.state == '1') {
				this.$confirm('确定关闭该成员信息?', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					center: true
				}).then(() => {
					this.$apis.memberServer.updateStateDisable({
						id: data.id
					}).then(res => {
						if (res['code'] == 0) {
							this.search()
							this.$message({
								type: 'success',
								message: '关闭成功!'
							});
						}
					})
				})
			} else {
				this.$confirm('确定启用该成员信息?', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					type: 'info ',
					center: true
				}).then(() => {
					this.$apis.memberServer.updateStateEnable({
						id: data.id
					}).then(res => {
						if (res['code'] == 0) {
							this.search()
							this.$message({
								type: 'success',
								message: '启用成功!'
							});
						}
					})
				})
			}
    },
		deleteClick(id) {
			this.$confirm('确定删除该成员信息吗', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'info ',
				center: true
			}).then(() => {
				this.$apis.memberServer.delete({
					id: id
				}).then(res => {
					if (res['code'] == 0) {
						this.search()
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					}
				})
			})
    },
		handleSizeChange(val) {
			this.searchFormData.perPage = val
			this.search()
		},
		handleCurrentChange(val) {
			this.searchFormData.page = val
			this.search()
		}	
	}
}
</script>

<style>

</style>