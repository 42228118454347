let apiBase = {
    queryPage:'/api/allianceMember/queryPage',        // 列表     
    queryDetail:'/api/allianceMember/queryDetail',    // 详情      
    addMember:'/api/allianceMember/add',              // 添加成员    
    updateMember:'/api/allianceMember/update',         //更新成员 
    updateStateDisable:'/api/allianceMember/updateStateDisable',//停用
    updateStateEnable:'/api/allianceMember/updateStateEnable',//开启
    delete:'/api/allianceMember/delete',//删除
}
  let MemberServerObject = null
  class MemberServer{
    constructor(){}
    queryPage(data={}){
      return $http({
        url: apiBase.queryPage,
        method: 'post',
        data: data,
      })
    }
    queryDetail(data={}){
      return $http({
        url: apiBase.queryDetail,
        method: 'post',
        data: data,
      })
    }
    addMember(data={}){
      return $http({
        url: apiBase.addMember,
        method: 'post',
        data: data,
      })
    }
    updateMember(data={}){
      return $http({
        url: apiBase.updateMember,
        method: 'post',
        data: data,
      })
    }
    updateStateDisable(data={}){
      return $http({
        url: apiBase.updateStateDisable,
        method: 'post',
        data: data,
      })
    }
    updateStateEnable(data={}){
      return $http({
        url: apiBase.updateStateEnable,
        method: 'post',
        data: data,
      })
    }
    delete(data={}){
      return $http({
        url: apiBase.delete,
        method: 'post',
        data: data,
      })
    }
  
  }
  function singleMemberServer(){
    if(MemberServerObject === null){
      MemberServerObject = new MemberServer()
    }
    return MemberServerObject
  }
  export default singleMemberServer()