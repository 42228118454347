<template>
  <div>
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <div class="wrapBox">
       <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
        <div>
          <el-button v-auth="permission.FIRM_DATA_EXPORT" class="search-button" type="primary" @click="exportData()" :loading="buttonLoading">
            <span v-if="!buttonLoading">导出数据</span> 
            <span v-else>正在导出</span> 
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="tableBox W100"
        >
        <el-table-column width="60" type="index" label="序号"></el-table-column>
        <el-table-column label="应用名称" prop="apkName"></el-table-column>
        <el-table-column label="包名" prop="apkPackage"></el-table-column>
        <el-table-column label="版本" >
          <template slot-scope="scope">
            <span>{{ scope.row.versionName}}({{scope.row.versionCode}})</span>
          </template>
        </el-table-column>
        <el-table-column label="开始时间">
          <template slot-scope="scope">
            <span>{{ scope.row.startTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结束时间">
          <template slot-scope="scope">
            <span>{{ scope.row.reviewTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="兼容标准" prop="compatible" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.compatible==3" style="text-align:left;">
              <span class="cursor" v-for="(item,index) in scope.row.compatibleFailCode" :key="index"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="red" v-if="item.termType==1"><i v-if="index">、</i>{{item.termCode}}</span>
              </span>
              <br>
              <span class="cursor" v-for="(item,index) in scope.row.compatibleFailCode" :key="item.termCode"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="warning" v-if="item.termType==2">{{item.termCode}}<i v-if="index != scope.row.compatibleFailCode.length-1">、</i></span>
              </span>        
            </div>
            <span v-else>
              <svg-icon class="svg-review" :svg-name="scope.row.compatible | versionsTask_memberState()"></svg-icon>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="稳定标准" prop="stable" width="200">
          <template slot-scope="scope">
           <div v-if="scope.row.stable==3" style="text-align:left;">
              <span class="cursor" v-for="(item,index) in scope.row.stableFailCode" :key="index"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="red" v-if="item.termType==1"><i v-if="index">、</i>{{item.termCode}}</span>
              </span>
              <br>
              <span class="cursor" v-for="(item,index) in scope.row.stableFailCode" :key="item.termCode"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="warning" v-if="item.termType==2">{{item.termCode}}<i v-if="index != scope.row.stableFailCode.length-1">、</i></span>
              </span>        
            </div>
            <span v-else>
              <svg-icon class="svg-review" :svg-name="scope.row.stable | versionsTask_memberState()"></svg-icon>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="安全标准" prop="security" width="200">
          <template slot-scope="scope">
           <div v-if="scope.row.security==3" style="text-align:left;">
              <span class="cursor" v-for="(item,index) in scope.row.securityFailCode" :key="index"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="red" v-if="item.termType==1"><i v-if="index">、</i>{{item.termCode}}</span>
              </span>
              <br>
              <span class="cursor" v-for="(item,index) in scope.row.securityFailCode" :key="item.termCode"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="warning" v-if="item.termType==2">{{item.termCode}}<i v-if="index != scope.row.securityFailCode.length-1">、</i></span>
              </span>        
            </div>
            <span v-else>
              <svg-icon class="svg-review" :svg-name="scope.row.security | versionsTask_memberState()"></svg-icon>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="功耗标准" prop="consumption" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.consumption==3" style="text-align:left;">
              <span class="cursor" v-for="(item,index) in scope.row.consumptionFailCode" :key="index"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="red" v-if="item.termType==1"><i v-if="index">、</i>{{item.termCode}}</span>
              </span>
              <br>
              <span class="cursor" v-for="(item,index) in scope.row.consumptionFailCode" :key="item.termCode"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="warning" v-if="item.termType==2">{{item.termCode}}<i v-if="index != scope.row.consumptionFailCode.length-1">、</i></span>
              </span>        
            </div>
            <span v-else>
              <svg-icon class="svg-review" :svg-name="scope.row.consumption | versionsTask_memberState()"></svg-icon>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="性能标准" prop="performance" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.performance==3" style="text-align:left;">
              <span class="cursor" v-for="(item,index) in scope.row.performanceFailCode" :key="index"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="red" v-if="item.termType==1"><i v-if="index">、</i>{{item.termCode}}</span>
              </span>
              <br>
              <span class="cursor" v-for="(item,index) in scope.row.performanceFailCode" :key="item.termCode"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="warning" v-if="item.termType==2">{{item.termCode}}<i v-if="index != scope.row.performanceFailCode.length-1">、</i></span>
              </span>        
            </div>
            <span v-else>
              <svg-icon class="svg-review" :svg-name="scope.row.performance | versionsTask_memberState()"></svg-icon>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="大屏标准" prop="large" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.large==3" style="text-align:left;">
              <span class="cursor" v-for="(item,index) in scope.row.largeFailCode" :key="index"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="red" v-if="item.termType==1"><i v-if="index">、</i>{{item.termCode}}</span>
              </span>
              <br>
              <span class="cursor" v-for="(item,index) in scope.row.largeFailCode" :key="item.termCode"  @click="onTestDetails(item.termCode,scope.row.apkPackage)">
                <span class="warning" v-if="item.termType==2">{{item.termCode}}<i v-if="index != scope.row.largeFailCode.length-1">、</i></span>
              </span>        
            </div>
            <span v-else>
              <svg-icon class="svg-review" :svg-name="scope.row.large | versionsTask_memberState()"></svg-icon>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="测试报告">
          <template slot-scope="scope">
            <el-button v-auth="permission.TEST_DETAILS_FRIM_LOOK_REPORT" class="color-blue link-button" type="text" @click="goLookReport(scope.row)">
              查看报告
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer margin-t30">
        <div class="tips">
        <span class="red">红色测试编号：</span>测试未通过项，会导致测试结果不通过，需要开发者优化。<br/>
        <span class="warning">橙色测试编号：</span>测试警告项，不会影响测试结果，建议开发者优化。
      </div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <test-item-details v-if="isShowlModal" :modalShow="isShowlModal"  :form="form" @closeModal="closeModal()" ></test-item-details>
  </div>
</template>

<script>
import testItemDetails from "../dialogs/testItemDetails";
export default {
  components: { testItemDetails },
  props: {
      memberId: {
        type: Number,
        required: true
      }
  },
  data() {
    return {
      buttonLoading:false,
      isShowlModal:false,
       form:{},
      tableData: [],
      total: 0,
      //搜索form组件
      searchControlData: [
        {
          type: "input",
          label: "应用名称",
          placeholder: "应用名称",
          key: "apkName",
        },
        {
          type: 'select',
          label: '兼容标准',
          key: 'compatible',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '稳定标准',
          key: 'stable',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '安全标准',
          key: 'security',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '功耗标准',
          key: 'consumption',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '性能标准',
          key: 'performance',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
        {
          type: 'select',
          label: '大屏标准',
          key: 'large',
          option: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '认证中',
              value: 1
            },
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            },
          ],
        },
      ],
      //搜索form数据
      searchFormData: {
        page: 1,
        perPage: 10,
        taskId:this.$route.query.id,
        memberId:this.memberId,
      },
    };
  },
  created(){
    this.search();
  },
  methods:{
    // 关闭modal
    closeModal(val) {
      this.isShowlModal = val;
    },
    
    onTestDetails(value,apkPackage,){
       this.form={
        taskId:this.$route.query.id,
        code:value,
        apkPackage:apkPackage,
        memberId:this.memberId,
      }
      this.isShowlModal = true; 
    },
    //搜索方法
    search(data) {
      if (data) {
        Object.assign(this.searchFormData, data)
        this.searchFormData.page = 1
      }
      this.$apis.unionAttestServer.queryIdentifyMemberList(this.searchFormData).then(res => {
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total;
          this.tableData = res.data.data;
        } else {
          this.total = 0;
          this.tableData = [];
        }
      });
    },
    memberId_to_memberName(memberId){
      let text='';
      switch (memberId) {
        case 1:
          text = "小米"
          break;
        case 2:
          text = "OPPO"
          break;
        case 3:
          text = "vivo"
          break;
      }
      return text; 
    },
    goLookReport(row) {
      this.$router.push({
        path:'/unionAttest/attestDetails/standardTestReport',
        query:{
          id: this.$route.query.id,
          apkPackage:row.apkPackage,
          memberId:this.memberId,
          title: this.$route.query.title,
          apkName: row.apkName?row.apkName:'',
          breadcrumbName: `${this.memberId_to_memberName(this.memberId)}${row.apkName?'-'+row.apkName:''}`,
        }
      })
    },
    //table点击更改一页多少行
    handleSizeChange(val) {
      this.searchFormData.perPage = val;
      this.search();
    },
    //table点击修改页码
    handleCurrentChange(val) {
      this.searchFormData.page = val;
      this.search();
    },

    exportData(){
      this.buttonLoading = true;
      this.$apis.unionAttestServer.taskIdentifyMemberExport({taskId:this.$route.query.id,memberId:this.memberId,}).then(res => {
        if (res['code'] == 0 && res['data']['link']) {
          this.buttonLoading = false;
          window.location.href = res.data.link;
        }
      });
    }
  },
  watch:{
    memberId(){
      this.searchFormData.memberId=this.memberId;
      this.search();
    },
  }, 
}
</script>

<style scoped lang="scss">
.svg-review{
  width: 30px;
  height: 30px;
}
.footer{
  display: flex;
  justify-content: space-between;
  .tips{
    color: #666;
    font-size: 14px;
  }
}
</style>
