let apiBase = {
    queryList: '/api/apkReviewManage/queryList',                 // 列表
    queryDetail: '/api/apkReviewManage/queryDetail',                     //  详情
    updateStateSuccess: '/api/apkReviewManage/updateStateSuccess',   
    updateStateFail: '/api/apkReviewManage/updateStateFail',               //  更改状态
    //  更改状态
}
let singleServerObject = null
class ApplicationServer {
    constructor() { }
    queryList(data = {}) {
        return $http({
            url: apiBase.queryList,
            method: 'post',
            data: data,
        })
    }

    queryDetail(data = {}) {
        return $http({
            url: apiBase.queryDetail,
            method: 'post',
            data: data,
        })
    }

    updateStateSuccess(data = {}) {
        return $http({
            method: 'post',
            url: apiBase.updateStateSuccess,
            data: data
        })
    }

    updateStateFail(data = {}) {
        return $http({
            method: 'post',
            url: apiBase.updateStateFail,
            data: data
        })
    }

}
function singleServer() {
    if (singleServerObject === null) {
        singleServerObject = new ApplicationServer()
    }
    return singleServerObject
}
export default singleServer()