<template>
  <div class="appInfoBox">
    <div class="appInfo">
      <div class="imgBox">
        <el-image class="screenshot"  :src="appInfo.apkIcon">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div class="textBox">
        <p>应用名称：<span>{{appInfo.apkName}}</span></p>
        <p>应用包名：<span>{{appInfo.apkPackage}}</span></p>
        <p>
          测试结果：<span v-if="appInfo.state==1">认证中</span><span v-if="appInfo.state==2">认证成功</span><span v-if="appInfo.state==3">认证失败</span>
        </p>
      </div>
      <div class="textBox">
        <p v-if="$route.query.memberId">测试版本：<span>{{appInfo.versionName}}({{appInfo.versionCode}})</span></p>
        <p>申请时间：<span>{{ appInfo.createTime | parseTime() }}</span></p>
        <p v-if="appInfo.reviewTime ">完成时间：<span>{{ appInfo.reviewTime | parseTime() }}</span></p>
      </div>
    </div>
    <el-table
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        class="tableBox"
        stripe
        v-if="standardNameList!=''"
      >
        <el-table-column label="测试方" prop="memberName" align="center" width="90"></el-table-column>
        <el-table-column label="测试机型" prop="device" align="center" width="120"></el-table-column>
        <el-table-column label="测试结果" prop="state" align="center" width="90">
          <template slot-scope="scope">
            <svg-icon class="svg-review" :svg-name="scope.row.state | versionsTask_memberState()"></svg-icon>
          </template>
        </el-table-column>
        <el-table-column v-for="(item, index) in standardNameList" :key="index" :label="item">
           <template slot-scope="scope" >
              <template v-for="(item2,index2) in scope.row.failCode[index]">
                <span :key="index2" class="red cursor" v-if="item2.termType==1">
                  <i v-if="index2">、</i><span @click="onTestDetails(item2.termCode,scope.row.apkPackage)">{{item2.termCode}}</span>
                </span> 
              </template>
              <br>
              <template v-for="(item2,index2) in scope.row.failCode[index]">   
                <span :key="index2" class="warning cursor" v-if="item2.termType==2">
                  <span @click="onTestDetails(item2.termCode,scope.row.apkPackage)">{{item2.termCode}}<i v-if="index2!=scope.row.failCode[index].length-1">、</i></span>
                </span>
              </template>
            </template>
        </el-table-column>
    </el-table>
    <div class="footer margin-t30">
      <div class="tips">
        <span class="red">红色测试编号：</span>测试未通过项，会导致测试结果不通过，需要开发者优化。<br/>
        <span class="warning">橙色测试编号：</span>测试警告项，不会影响测试结果，建议开发者优化。
      </div>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total">
      </el-pagination>
    </div>
    <test-item-details v-if="isShowlModal" :modalShow="isShowlModal" :form="form" @closeModal="closeModal()" ></test-item-details>
  </div>
</template>

<script>
import testItemDetails from "../dialogs/testItemDetails";
export default {
   components: { testItemDetails },
  data(){
    return{
      isShowlModal:false,
      form:{},
      searchFormData:{
        taskId:this.$route.query.id,
        apkPackage:this.$route.query.apkPackage,
        memberId:this.$route.query.memberId,
        page: 1,
        perPage: 10
      },
      appInfo:{},
      tableData:[],
      total: 0,
      standardNameList:[],
    }
  },
  created(){
    this.queryApkDetail();
    this.queryApkDeviceList()
  },
  methods:{
    // 关闭modal
    closeModal(val) {
      this.isShowlModal = val;
    },
    queryApkDetail(){
      if(this.$route.query.memberId){
        this.$apis.unionAttestServer.queryMemberApkDetail(this.searchFormData).then(res=>{
          if(res['code'] == 0){
            this.appInfo=res.data
          }
        })
      }else{
        this.$apis.unionAttestServer.queryApkDetail(this.searchFormData).then(res=>{
          if(res['code'] == 0){
            this.appInfo=res.data
          }
        })
      }
    },
    
    //获取应用信息和列表
    queryApkDeviceList(){
      this.$apis.unionAttestServer.queryApkDeviceList(this.searchFormData).then(res=>{
        if(res['code'] == 0){
          this.standardNameList = res.data.data.itemNameList;
          this.tableData=res.data.data.list;
          this.total=res.data.total
        }
      })
    },

    onTestDetails(value,apkPackage,){
       this.form={
        taskId:this.$route.query.id,
        code:value,
        apkPackage:apkPackage,
      }
      this.isShowlModal = true;
     
    },
    //table点击更改一页多少行
    handleSizeChange(val) {
      this.searchFormData.perPage = val;
      this.queryApkDeviceList();
    },
    //table点击修改页码
    handleCurrentChange(val) {
      this.searchFormData.page = val;
      this.queryApkDeviceList();
    },
  },
 
}
</script>

<style scoped lang="scss">
.appInfoBox{
  .appInfo{
    display: flex;
    justify-content: flex-start;
    align-items: top;
    margin-bottom: 20px;
    .imgBox{
      width: 80px;
      height: 80px;
      .el-icon-picture-outline{
        font-size: 80px;
        margin-top: -5px;
      }
    }
    .textBox{
      margin: 0 20px;
      p{
        margin-bottom: 5px;
      }
    }
  }
  .svg-review{
    width: 30px;
    height: 30px;
  }
  .footer{
    display: flex;
    justify-content: space-between;
    .tips{
      color: #666;
      font-size: 14px;
    }
  }
}

</style>
