<template>
  <div class="documentMenu">
		<div class="titleBox">
			<h1>文档中心</h1>
			<svg-icon class="navEdit cursor" svg-name="导航编辑" @click="goNavManage()"></svg-icon>
		</div>
    
    <el-scrollbar style="position: relative;flex: 1;height:100%;">
      <el-tree
        :data="menuData"
        node-key="id"
        :props="defaultProps"
        :default-expand-all="true"
        :expand-on-click-node="false"
        class="tree">
        <div class="custom-tree-node"  slot-scope="{ node,data}" @click="getList(data)">
          <el-tooltip :content="node.label"  placement="top" :disabled="isShowTooltipApkPackage">
              <p @mouseover="onMouseOver(node.label )" :class="{fristMenu: data.level==1,grey:data.showType==2}" class="penelName"><span :ref='node.label'>{{ node.label }}</span></p>
          </el-tooltip>
          <span v-if="data.showType==3">
            <img class="docIcon" src="@/assets/img/doc/publish.png" alt="">
          </span>
          <span v-if="data.showType==2">
            <img class="docIcon" src="@/assets/img/doc/notPublish.png" alt="">
          </span>
        </div>
      </el-tree>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
	data(){
		return{
		menuData: [],//导航列表
    	defaultProps: {
				children: 'children',
				label: 'title'
      },
			isShowTooltipApkPackage:true
		}
	},
	created(){
		this.getQueryTreeList()
	},
	methods:{
		//获取文档导航列表
    getQueryTreeList(){
      this.$apis.documentServer.queryTreeList().then(res=>{
         if (res['code'] == 0){
          this.menuData = res.data;
          if(!this.$route.query.docMenuId){
            this.$router.push({
              path:'/document/list',
              query:{
                docMenuId:this.menuData[0].id
              }
            })
          }
        }
      })
    },
		goNavManage(){
      this.$router.push({
        path:'/document/navManage',
        query:{}
      })
    },
		getList(data){
			this.$emit('search',data)
			this.$router.push({
        path:'/document/list',
        query:{
					docMenuId:data.id
				}
      })
		},
		onMouseOver(str) { // 内容超出，显示文字提示内容
        const tag = this.$refs[str];
        const parentWidth = tag.parentNode.offsetWidth;// 获取元素父级可视宽度要减去应用包名的长度
        const contentWidth = tag.offsetWidth; // 获取元素可视宽度
        this.isShowTooltipApkPackage = contentWidth <= parentWidth;
    },
	}
}
</script>

<style scoped lang="scss">
.documentMenu{
	width: 400px;
  padding-bottom: 30px;
  border: 1px solid #ccc;
	border-radius: 5px;
	overflow: hidden;
	.titleBox{
		height: 50px;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #28262C;
		color: #E7DCAE;
		h1{
			font-size: 16px;
			
		}
		.navEdit{
			width: 24px;
			height: 24px;
		}
	}
	.tree{
		height: 630px;
		// overflow-y: scroll;
		/deep/.el-tree-node__content {
			height: 50px;
			padding-right:22px;
			border-bottom: 1px solid #DCDCDC;
			box-sizing: border-box;
		}
		/deep/.el-tree-node__expand-icon{
			color: #28262C;
			font-size: 15px;
			margin-left: 13px;
		}
		/deep/.el-tree-node__expand-icon.is-leaf {
			color: transparent!important;
		}
		.custom-tree-node {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			.docIcon{
				width: 20px;
				height: 24px;
			}
			.penelName{
				overflow: hidden; //块元素超出隐藏
				width: 200px;
				height: 35px;
				line-height:35px;
				cursor: pointer;
				text-overflow:ellipsis;
				-o-text-overflow:ellipsis;
				-webkit-text-overflow:ellipsis;
				-moz-text-overflow:ellipsis;
				white-space:nowrap;
			}
			.fristMenu{
				font-weight: bolder;
			}
		}
	}
}
</style>