<template>
  <div>
    <el-form :model="contentData" ref="contentData" label-width="80px" class="demo-contentData">
      <el-form-item label="日志:">
        AndroidManifest xml文件中少android allowBackup属性或属性
      </el-form-item>
      <el-form-item label="姓名">
        <el-col :span="23">
          <el-input v-model="contentData.contactName"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="角色">
        <el-col :span="23">
          <el-select class="W100" v-model="contentData.contactPhone" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="用户组">
        <el-col :span="23">
          <el-select class="W100" v-model="contentData.contactPhone" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

  export default {
    props:{
      contentData: {
        type: Object,
        default:() => {
          return {}
        }
      },
    },
    data() {
      return {
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
      }
    }
  }
</script>

<style scoped>

</style>
<style lang="scss">

  .assetsImg-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .assetsImg-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .assetsImg-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .assetsImg {
    width: 178px;
    height: 178px;
    display: block;
   padding-right: 17px;
    padding-bottom: 11px;
  }
</style>