<template>
  <div class="page">
    <wk-search class="wrapBox" :search-control-data="searchControlData" @search="search($event)"></wk-search>
    <gold-export
      class="wrapBox"
      v-auth="permission.IDENTIFY_APK_ACTION_EXPORT"
      :type="type"
      @export="exportClick($event)"
      @exportAuth="exportClickAuth($event)">
    </gold-export>
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div class="line-40" >共{{total}}条数据</div>
        <router-link :to="{path:'/goldStandard/meddle/list'}">
          <el-button v-auth="permission.IDENTIFY_APK_ACTION_QUERY_INTERVENE_LIST" class="common-button" type="primary">
            人工干预记录
          </el-button>
        </router-link>
      </div>
      <el-table 
      class="tableBox W100" 
      :data="tableData" 
      stripe
      v-auth="permission.IDENTIFY_APK_ACTION_LIST"
      >
        <el-table-column fixed align="center" width="60" type="index" label="序号"></el-table-column>
        <el-table-column fixed prop="apkName" label="应用名称"></el-table-column>
        <el-table-column width="70" prop="apkIcon" label="ICON">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height:50px;display:block;"
              :src="scope.row.apkIcon"
              :preview-src-list="[scope.row.apkIcon]" >
              <template slot="error">
                <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="apkPackage" label="应用包名"></el-table-column>
        <el-table-column prop="state" label="金标状态">
          <template slot-scope="scope"> 
            <span v-if="scope.row.state == 1">金标认证中</span>
            <span v-if="scope.row.state == 2">金标认证有效</span>
            <span v-if="scope.row.state == 3">金标认证失败</span>
            <span v-if="scope.row.state == 4">金标已过期</span>
          </template>
        </el-table-column>
        <el-table-column prop="expireTime" label="金标有效期">
          <template slot-scope="scope">
            <span>{{ scope.row.expireTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="firstSendTime" label="首次认证通过时间">
          <template slot-scope="scope">
            <span>{{ scope.row.firstSendTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="accountId" label="开发者ID"></el-table-column>
        <el-table-column prop="companyName" label="企业名称">
          <template slot-scope="scope">
            <span v-if="scope.row.companyName">{{ scope.row.companyName}}</span>
            <span v-else>{{ scope.row.contactName}}</span>
          </template>
        </el-table-column>
        <el-table-column  label="版本记录">
          <template slot-scope="scope">
            <router-link :to="{path:'/goldStandard/versions/list',query:{apkPackage:scope.row.apkPackage,breadcrumbName:scope.row.apkName}}">
              <el-button v-auth="permission.GOLD_ACTION_LIST" class="color-blue link-button" type="text">
                版本记录
              </el-button>
            </router-link>
          </template>
        </el-table-column>
        <!-- 只有联盟管理员才可操作-->
        <el-table-column fixed="right" label="操作" v-if="userInfo.roleName != '普通账号' && userInfo.memberCode == 'ita'">
          <!--认证中不可编辑-->
          <template v-if="scope.row.state != 1" slot-scope="scope">
            <el-button v-auth="permission.IDENTIFY_APK_ACTION_UNDATE_EXPIRE_TIME" class="color-blue link-button" type="text" @click="edit(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <goldStandardInfo apkPackage="" ref="dialog" @update="onUpdate"></goldStandardInfo>
  </div>
</template>
<script>
  import goldStandardInfo from "./dialogs/goldStandardInfo";
  import goldExport from './components/goldExport';
  import store from '@/store/index.js';
  export default {
    beforeRouteEnter: (to,from,next) => {
      store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: "金标认证", path: to.fullPath} );
      next()
    },
    components: {goldStandardInfo, goldExport},
    data() {
      return {
        type:"1",//判断是应用还是版本
        //搜索form组件
        searchControlData: [
          {
            type: "input",
            label: "应用名称",
            placeholder: "应用名称",
            key: "apkName"
          },
          {
            type: "input",
            label: "应用包名",
            placeholder: "应用包名",
            key: "apkPackage"
          },
          {
            type: "input",
            label: "开发者ID",
            placeholder: "开发者ID",
            key: "accountId"
          },
          {
            type: "select",
            label: "认证类型",
            placeholder: "请选择状态",
            key: "type",
            option: [
              {
                label: "全部",
                value: ""
              },
              {
                label: "首次认证",
                value: 1
              },
              {
                label: "重新认证",
                value: 2
              },
              {
                label: "周期认证",
                value: 3
              }
            ]
          },
          {
            type: "select",
            label: "金标状态",
            placeholder: "请选择状态",
            key: "state",
            option: [
              {
                label: "全部",
                value: ""
              },
              {
                label: "金标认证中",
                value: 1
              },
              {
                label: "金标认证有效",
                value: 2
              },
              {
                label: "金标认证失败",
                value: 3
              },
              {
                label: "金标认证已过期",
                value: 4
              },
            ]
          }
        ],
        //列表数据
        tableData: [],
        total: 0,
        //搜索form数据
        searchFormData: {
          page: 1,
          perPage: 10,
          accountId:this.$route.query.id
        },
      };
    },
    computed: {
      userInfo() {
        return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
      }
    },
    created() {
      this.search();
    },
    methods: {
      //搜索方法
      search(data) {
        if (data) {
          this.searchFormData = {
            page: 1,
            perPage: this.searchFormData.perPage,
            ...data
          };
        }

        this.$apis.goldStandardServer.queryList(this.searchFormData).then(res => {
          if (res['code'] == 0 && res.data.data) {
            this.total = res.data.total;
            this.tableData = res.data.data;
          } else {
            this.total = 0;
            this.tableData = [];
          }
        });
      },
      //导出方法
      exportClick(data) {
        //时间范围组件 取开始结束时间
        data.startTime = data.time ? data.time[0] : "";
        data.endTime = data.time ? data.time[1] : "";

        this.$apis.goldStandardServer.exportApk(data).then(res => {
          if (res['code'] == 0 && res.data.link) {
            let link = res.data.link;
            window.location.href = link;
          } else {
            this.$message({
              message: "失败",
              type: "error"
            });
          }
        });
      },
      //导出方法
      exportClickAuth(data) {
        //时间范围组件 取开始结束时间
        data.createStartTime = data.time ? data.time[0] : "";
        data.createEndTime = data.time ? data.time[1] : "";

        this.$apis.goldStandardServer.exportAdv(data).then(res => {
          if (res['code'] == 0 && res.data.link) {
            let link = res.data.link;
            window.location.href = link;
          } else {
            this.$message({
              message: "失败",
              type: "error"
            });
          }
        });
      },
      //点击编辑
      edit(data) {
        this.$refs["dialog"].show(data);
      },
      //编辑成功后回调
      onUpdate() {
        this.search();
      },
      //table点击更改一页多少行
      handleSizeChange(val) {
        this.searchFormData.perPage = val;
        this.search();
      },
      //table点击修改页码
      handleCurrentChange(val) {
        this.searchFormData.page = val;
        this.search();
      },
    }
  };
</script>
<style  scoped lang="scss">
  /deep/ .el-message-box--center .el-message-box__message {
    font-size: 20px;
  }
</style>
