b<template>
 <div class="searchBox">
  <el-form class="searchContent" :model="formData" label-width="80px" size="small " ref="searchFormRef">
    <el-col :span="item.type == 'rangDate' ? 12:6"
            style=" max-width: 500px;"
            v-for="(item,index) in searchControlData"
            :key="index">
      <el-form-item :label="item.label" :prop="item.key" >
        <el-input  :placeholder="item.placeholder"  v-if="item.type == 'input'" v-model="formData[item.key]"></el-input>
        <el-select clearable  v-if="item.type == 'select'" v-model="formData[item.key]" :placeholder="item.placeholder">
          <el-option
                  v-for="(optionData,index) in item.option"
                  :key="index"
                  :label="optionData.label"
                  :value="optionData.value"></el-option>
        </el-select>
        <el-date-picker
                style="width: 400px"
                v-if="item.type == 'rangDate'"
                value-format="timestamp"
                v-model="formData[item.key]"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
    </el-col>
  </el-form>
  <div class="searchButtonX" :class="{searchButtonY:searchControlData.length>3}">
    <wk-button class="search-button" type="primary" @click="onSubmit()">查询</wk-button>
    <wk-button class="search-button empty-button" type="default" @click="resetForm()">清空</wk-button>
  </div>
 </div>
</template>

<script>
  export default {
    name: "WkSearch",
    props:{
     /*
     *searchControlData:[
      * {
         type: 'input', 查询组件的类型,必填，可选值为：input: 输入框；select:下拉选择框；rangDate: 日期范围选择器，
         label: '账号邮箱', 查询表单的label
         placeholder: '请输入账号邮箱',表单控件的placeholder，可传可不穿
         key: 'email'  查询参数，与表单控件绑定的值
       },
      * ]
     */
      searchControlData: {
          type: Array,
          required: false,
          default() {
              return []
          }
      },
      initData: {
          type: Object,
          required: false,
          default() {
              return {}
          }
      },
    },
    data(){
      return{
        formData:{},
        screenWidth :document.body.clientWidth,
      }
    },
    created(){
      this.formData = this.utils.deepClone(this.initData);
    },
    methods: {
      onSubmit() {
        this.$emit('search',this.formData)
      },
      resetForm() {
        let tempData = {}   // 临时表单对象，当查询参数传出去
        this.formData = {}  // 清空查询表单数据
        for(let item in this.searchControlData){
          tempData[ this.searchControlData[item].key] = ''
        }
        this.$emit('search',tempData)
      },
      getLength(){
        let count = 0;
        let spanLength = ''
        for(let item in this.searchControlData){
          if(this.searchControlData[item].type=='rangDate'){
            count++
          }
        }
        count = count + this.searchControlData.length
        spanLength = (3-count%4)*6
        return spanLength
      },
    }
  }
</script>

<style scoped lang="scss">
  /deep/ .el-select {
    display:block;
    position: relative;
  }
  .searchBox{
    display: flex;
    justify-content: space-between;
    padding: 22px 20px 5px 13px ;
    .searchContent{
      display: flex;
      flex-wrap: wrap;
      // flex-basis:88%;
    }
    .searchButtonX{
      display: flex;
      // flex-basis: 12%;
      .search-button{
        width: 80px;
        height: 32px;
        line-height: 0;
      }
    }
    .searchButtonY{
      display: flex;
      flex-basis: 12%;
      flex-direction: column;
      align-items: flex-end;
      .empty-button{
        margin-top: 18px;
      }
    }
  }
</style>