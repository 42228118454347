const routes = [
  {
    path: '',
    name: '',
    redirect:'/developer'
  },
  {
    path: '/developer',
    name: '',
    redirect:'/developer/list'
  },
  {
    path: '/developer/list',
    name: '',
    meta:{module:'develop_manage',menu:'/developer/list',title:'开发者管理'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/developer/list.vue'),
  },
  {
    path: '/developer/edit',
    name: '',
    meta:{module:'develop_manage',menu:'/developer/list',title:'开发者管理'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/developer/edit.vue'),
  },
]

export default routes;
