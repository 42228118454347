<template>
  <div class="navManagepage">
    <div class="titleBox">
      <div class="title">
        文档中心
      </div>
      <div class="addBtn cursor" v-auth="permission.DOCUMENT_PANEL_ADD" @click="addFristPanel('add')">
        <svg-icon class="addIcon" svg-name="添加节点"></svg-icon><span class="add">添加一级节点</span>
      </div>
    </div>
    <el-tree
      :data="menuData"
      node-key="id"
      :props="defaultProps"
      :default-expand-all="true"
      :expand-on-click-node="false"
      class="tree">
      <div class="custom-tree-node"  slot-scope="{ node,data}" >
        <div :class="{fristMenu: data.level==1,grey:data.showType==2}" >{{ node.label }}
          <span v-if="data.showType==3">
            <img class="docIcon" src="@/assets/img/doc/publish.png" alt="">
          </span>
          <span v-if="data.showType==2">
            <img class="docIcon" src="@/assets/img/doc/notPublish.png" alt="">
          </span>
        </div>
        <div class="operate">
          <div v-auth="permission.DOCUMENT_PANEL_EDIT" @click="editPanel('edit',data)">编辑节点</div>
          <div v-auth="permission.DOCUMENT_PANEL_ADD" v-if="data.level==1" @click="addPanel('add',data)">添加二级节点</div>
          <div v-auth="permission.DOCUMENT_PANEL_ADD" v-if="data.level==2" @click="addPanel('add',data)">添加三级节点</div>
          <div v-auth="permission.DOCUMENT_PANEL_DELETE" @click="deletePanel(data)">删除节点</div>
        </div>
      </div>
    </el-tree>
    <panel-point ref="modalRef" @search="getQueryTreeList()"></panel-point>
  </div>
</template>

<script>
import store from '@/store/index.js';
import panelPoint from './dialogs/panelPoint';
export default {
  beforeRouteEnter: (to,from,next) => {
		store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:"导航管理", path: to.fullPath} );
		next()
  },
  components: {panelPoint},
  data(){
    return{
      menuData: [],//导航列表
      defaultProps: {
        children: 'children',
        label: 'title'
      },
    }
  },
  created(){
    this.getQueryTreeList();
  },
  methods:{
    //获取文档导航列表
    getQueryTreeList(){
      this.$apis.documentServer.queryTreeList().then(res=>{
         if (res['code'] == 0){
            this.menuData = res.data
         }
      })
    },
    //添加节点
    addPanel(val,data){
      this.$refs.modalRef.show(val,data)
    },
    //编辑节点
    editPanel(val,data){
      this.$refs.modalRef.show(val,data)
    },
    //添加一级节点
    addFristPanel(val){
      this.$refs.modalRef.show(val)
    },
    deletePanel(data){
      if(data.level==1){
        const h = this.$createElement;
        this.$dialogs.confirm({
          title:`<span style="color:#D71F1F;"> 确定删除一级节点“${data.title}”？</span>`,
          content: `删除后该节点下的所有二级节点和文档将一并被删除`,
        }).then(() => {
          this.delete(data)
        }).catch((val) => {});
        // this.$msgbox({
        //   title: '',
        //   message: h('p', null, [
        //       h('p',  { style: 'color: #D71F1F' }, `确定删除一级节点“${data.title}”？`),
        //       h('p', null, '删除后该节点下的所有二级节点和文档将一并被删除')
        //   ]),
        //   showClose: false,
        //   showCancelButton: true,
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   center: true
        // }).then(() => {
        //   this.delete(data)
        // }).catch(()=>{
        // })
      }else if(data.level==2){
        const h = this.$createElement;
        this.$msgbox({
          title: '',
          message: h('p', null, [
              h('p',  { style: 'color: #D71F1F' }, `确定删除二级节点“${data.title}”？`),
              h('p', null, '删除后该节点下的所有三级节点和文档将一并被删除')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true
        }).then(() => {
          this.delete(data)
        }).catch(()=>{
        })
      }else{
         const h = this.$createElement;
        this.$msgbox({
          title: '',
          message: h('p', null, [
              h('p',  { style: 'color: #D71F1F' }, `确定删除三级节点“${data.title}”？`),
              h('p', null, '删除后该节点下的文档将一并被删除')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true
        }).then(() => {
          this.delete(data)
        }).catch(()=>{
        })
      }
     
    },
    delete(data){
       this.$apis.documentServer.deleteDocMenu({id:data.id}).then(res=>{
         if (res['code'] == 0){
          this.getQueryTreeList();
         }
      })
    },

    // allowDrop(draggingNode, dropNode, type) {
    //   //draggingNode 拖动的节点 dropNode放置的节点 type 放置类型
    //   console.log("allow-draggingNode",draggingNode.data.level)
    //   console.log("allow-dropNode",dropNode.data.level)
      
    //   //当一级节点有二级节点时
    //   if (draggingNode.data.level == '1' && draggingNode.data.children.length >0) {
    //     if(dropNode.data.level == '1'){
    //       return type == 'prev'|| type == 'next'||type == 'inner'
    //     }else if(dropNode.data.level == '2'){
    //       return type == 'prev'|| type == 'next'
    //     }
    //   } else if( draggingNode.data.level == '1' && draggingNode.data.children.length ==0){
    //     if(dropNode.data.level == '2'){
    //       return  type == 'inner'||type == 'prev'|| type == 'next'
    //     }
    //     if(dropNode.data.level == '3'){
    //       return type == 'prev'|| type == 'next'
    //     }
    //   }else{
    //   }
    // },
  },

  







}
</script>

<style scoped lang="scss">
  .navManagepage{
    width: 100%;
    min-height: 500px;
    margin-top: 10px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
   
    .titleBox{
      height: 26px;
      padding: 10px 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background:#28262C;
      .title{
        color: #E7DCAE;
        font-size: 16px;
        font-weight: bold;
      }
      .addBtn{
        display: flex;
        align-items: center;
        .addIcon{
          width: 22px;
          margin-right:8px;
        }
        .add{
          color: #fff;
        }
      }
    }
    .tree{
      border-left: 1px solid #DCDCDC;
      border-right: 1px solid #DCDCDC;
      /deep/.el-tree-node__content {
			  height: 50px;
        border-bottom: 1px solid #DCDCDC;
		  }
      /deep/.el-tree-node__expand-icon{
        color: #28262C;
        font-size: 15px;
        margin-left: 13px;
      }
      /deep/.el-tree-node__expand-icon.is-leaf {
        color: transparent!important;
      }
      .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
				line-height:35px;
        font-size: 14px;
        .docIcon{
          width: 20px;
          height: 24px;
          vertical-align: middle;
          margin-left: 10px;
        }
        .operate{
          display: flex;
          justify-content: space-between;
          width: 700px;
          div{
            margin: 0 80px;
            font-size: 14px;
            color: #27252B;
          }
        }
        .fristMenu{
				  font-weight: bolder;
			  }
      }
    }
  }
</style>