<template>
  <el-dialog
    title="添加邮箱"
    :visible="dialogVisible"
    width="600px"
    @close="closeModal"
    center>
    <el-form :model="ruleForm" :rules="ruleData" ref="formRef" label-width="100px" class="demo-ruleForm">
      <el-form-item label="邮箱" prop="email">
          <el-input class="w360" v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
          <el-input class="w360" :span="16" placeholder="请输入姓名" v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="厂商" prop="memberId">
        <el-select class="w360" :disabled="selectDisable" size="medium" v-model="ruleForm.memberId" placeholder="请选择厂商">
          <el-option
                  v-for="(item,index) in memberList"
                  :key="index"
                  :label="item.label"
                  :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="w460 text-center">
        <el-button class="w150" type="primary" @click="onSubmit()">提交</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        ruleForm: {
          type:1,
          email: '',
          name: '',
          roleId: '',
          memberId: '',
        },
        ruleData: {//角色表单校验
          name: [
            {required: true, message: '请填写姓名', trigger: 'blur'},
          ],
          email: [
            {required: true, message: '请填写邮箱', trigger: 'blur'},
            {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
          ],
          memberId: [
            {required: true, message: '请选择厂商', trigger: ['blur', 'change']}
          ],
        },
        memberList: [],
        selectDisable: false
      }
    },
    created() {
      this.getSelectList()
    },
    computed: {
      getUserInfo() {
        return this.$store.getters["accountStore/getUserInfo"]
      }
    },
    methods: {
      getSelectList() {
        this.memberList = this.$store.getters["accountStore/getMemberList"]
        this.memberList.forEach(item => {
          if (item.value == this.getUserInfo.memberCode) {
            if (this.getUserInfo.memberCode != 'ita') {
              this.selectDisable = true
              this.ruleForm.memberId = eval(this.getUserInfo.memberId)
            }
          }
        })
      },
      onSubmit() {
          this.$refs['formRef'].validate(valid => {
            if (valid) {
                this.$apis.emailAlertServer.addEmail(this.ruleForm).then(result => {
                  if (result.code == 0) {
                    this.$emit('search', {})
                    this.closeModal()
                  }
                })
            }
          })
      },
      closeModal() {
        this.dialogVisible = false
        this.showLoading = false
        this.initUploadData()
        this.resetForm()
      },
      show(type) {
        this.type = type
        this.dialogVisible = true
      },
      resetForm() {
        this.$refs['formRef'].resetFields();
      },
      // 初始化上传控件数据：进度条、错误信息
      initUploadData(){
        this.percentage = 0
        this.uploadErrorInfo = ''
        this.progresStatus = null
      },
    }
  }
</script>

<style scoped>

</style>
<style lang="scss">
.el-progress-bar{
  width: 412px;
}
  .assetsImg-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .assetsImg-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .assetsImg-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .assetsImg {
    margin: 0px !important;
    width: 178px;
    height: 178px;
    display: block;
  }
</style>