import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const store ={
  namespaced: true,
  state:{ //状态对象：共享值的集合
    "standardList": [],// 厂商权限数据：管理员 and 普通用户
  },
  mutations:{  //同步修改
    set(state, obj){//初始化
      for(let name in obj){
        state[name] = obj[name];
      }
    },
  },
  getters:{//监测状态
    getsStandardList(state){
      return state.standardList
    },
  },
  actions:{//异步修改
  }
}

export default store
 
