import Vuex from 'vuex'
const routes = [
  {
    path: '/unionAttest',
    name: '',
    redirect: '/unionAttest/list'
  },
  {
    path: '/unionAttest/list',
    name: 'unionAttest',
    meta: {
      module: 'gold_action_manage',
      menu: '/unionAttest/list',
      keepAlive: true,
      title: '联盟检测',
      breadcrumb: ['unionAttest'],
      menuBreadcrumb: 'unionAttest',
    },
    component: () => import('@/views/unionAttest/list.vue')
  },
  {
    path: '/unionAttest/testReportList',
    name: 'testReportList',
    meta: {
      module: 'gold_action_manage',
      menu: '/unionAttest/list',
      title: '测试报告接收名单',
    },
    component: () => import('@/views/unionAttest/testReportList.vue')
  },
  {
    path: '/unionAttest/attestDetails',
    name: 'attestDetails',
    meta: {
      module: 'gold_action_manage',
      menu: '/unionAttest/list',
      title: '测试报告',
      keepAlive: true,
      breadcrumb: ['unionAttest','attestDetails'],
      menuBreadcrumb: 'attestDetails',
    },
    component: () => import('@/views/unionAttest/attestDetails.vue')
  },
  {
    path: '/unionAttest/attestDetails/standardTestReport',
    name: 'standardTestReport',
    meta: {
      module: 'gold_action_manage',
      menu: '/unionAttest/list',
      title: '查看报告',
      keepAlive: true,
      breadcrumb: ['unionAttest','attestDetails','standardTestReport'],
      menuBreadcrumb: 'standardTestReport',
    },
    component: () => import('@/views/unionAttest/standardTestReport.vue')  
  },
  {
    path: '/unionAttest/attestDetails/standardTestReport/reportDetails',
    name: 'testReportDetails',
    meta: {
      module: 'gold_action_manage',
      menu: '/unionAttest/list',
      title: '报告详情',
      breadcrumb: ['unionAttest','attestDetails','standardTestReport','reportDetails'],
      menuBreadcrumb: 'reportDetails',
    },
    component: () => import('@/views/unionAttest/reportDetails.vue')
  },
]

export default routes;