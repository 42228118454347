<template>
  <div class="page">
    <wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
        <el-button class="common-button" v-auth="permission.USER_ACTION_ADD" type="primary" @click="add">添加用户
        </el-button>
      </div>
      <el-table
        v-auth="permission.USER_ACTION_LIST"
        class="tableBox"
        :data="tableData"
        stripe
        style="width: 100%">
        <el-table-column
          width="60"
          align="center"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          prop="email"
          align="center"
          label="账号邮箱">
        </el-table-column>
        <el-table-column
          width="70"
          align="center"
          prop="name"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="memberName"
          align="center"
          label="角色">
        </el-table-column>
        <el-table-column
          prop="roleName"
          align="center"
          width="100"
          label="用户组">
        </el-table-column>
        <el-table-column
          prop="modifyTime"
          align="center"
          label="编辑时间">
          <template slot-scope="scope">
            <span>{{ scope.row.modifyTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operatorName"
          align="center"
          width="100"
          label="操作人">
        </el-table-column>
        <el-table-column
          v-if="currentUserInfo.roleName != '普通账号'"
          align="center"
          label="操作">
          <template slot-scope="scope"
                    v-if="scope.row.memberCode == currentUserInfo.memberCode || currentUserInfo.memberCode== 'ita'">
            <el-button
              v-auth="permission.USER_ACTION_EDIT"
              class="color-blue link-button"
              type="text"
              @click="edit(scope.row.id)">编辑
            </el-button>
            <el-button
              v-auth="permission.USER_ACTION_RESET"
              class="color-blue link-button"
              type="text"
              @click="resetPassword(scope.row.id,scope.row.email)">重置密码
            </el-button>
            <el-button
              :class="{'currentUser':currentUserInfo.id == scope.row.id}"
              v-auth="permission.USER_ACTION_DELETE"
              class="color-blue deleteButton link-button"
              type="text"
              @click="deleteClick(scope.row.email)">
              <span v-if="currentUserInfo.id != scope.row.id">
                删除
              </span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <user-info ref="modalRef" @search="search"></user-info>
  </div>
</template>
<script>
  import userInfo from './userInfo'

  export default {
    components: {userInfo},
    data() {
      return {
        total: 0,
        searchFormData: {
          page: 1,
          perPage: 10,
        },
        searchControlData: [
          {
            type: 'input',
            label: '账号邮箱',
            placeholder: '请输入账号邮箱',
            key: 'email'
          },
          {
            type: 'input',
            label: '姓名',
            placeholder: '请输入姓名',
            key: 'name'
          },
          {
            type: 'select',
            label: '角色',
            key: 'memberCode',
            placeholder: '请选择角色',
            option: [],
          }
        ],
        tableData: [],
      }
    },
    created() {
      this.search()
      this.searchControlData.forEach((item, index) => {
        if (item.key == 'memberCode') {
          this.searchControlData[index].option = this.$store.getters['accountStore/getMemberList']
        }
      })
    },
    computed: {
      currentUserInfo() {
        return this.$store.getters["accountStore/getUserInfo"]
      }
    },
    methods: {
      search(data) {
        if (data) {
          Object.assign(this.searchFormData, data)
          this.searchFormData.page = 1
        }
        this.$apis.accountServer.queryList(this.searchFormData).then(res => {
          if (res['code'] == 0 && res.data.data) {
            this.total = res.data.total
            this.tableData = res.data.data
          } else {
            this.total = 0
            this.tableData = []
          }
        })
      },

      add() {
        this.$refs.modalRef.show('add')
      },

      edit(id) {
        this.$refs.modalRef.loadData(id)
        this.$refs.modalRef.show('edit')
      },

      resetPassword(id, email) {
        const h = this.$createElement;
        let self = this
        this.$msgbox({
          title: '',
          message: h('p', {style: 'text-align: center'}, [
            h('p', null, '账号邮箱：' + email),
            h('p', null, '点击重置将会发送一个新密码到邮箱，是否重置？'),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.$apis.accountServer.resetPwd({
            email: email
          }).then(res => {
            if (res['code'] == 0) {
              this.$message({
                type: 'success',
                message: '重置成功!'
              });
              if (id == this.currentUserInfo.id) {
                localStorage.clear();
                // 先执行清除本地缓存，再跳转登录页
                setTimeout(function () {
                  self.$router.push({path: "/login"});
                }, 10);
              }
            }
          })

        })
      },

      deleteClick(data) {
        const h = this.$createElement;
        this.$msgbox({
          title: '',
          message: h('p', {style: 'text-align: center'}, [
            h('p', null, '账号邮箱：' + data),
            h('p', null, '点击删除将会删除该账号，是否删除?'),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.$apis.accountServer.deleteInfo({
            email: data
          }).then(res => {
            if (res['code'] == 0) {
              this.search()
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        })
      },

      handleSizeChange(val) {
        this.searchFormData.perPage = val
        this.search()
      },
      handleCurrentChange(val) {
        this.searchFormData.page = val
        this.search()
      }
    }
  }
</script>
<style>
  .el-message-box--center .el-message-box__message {
    font-size: 20px;
  }

  .cancelButton {
    width: 98px;
    margin-left: 14px;
    margin-top: 14px;
  }

  .confirmButton {
    width: 98px;
    margin-left: 60px !important;
  }
</style>
<style scoped lang="scss">
  .page {
    // 当前用户不可以删除自己的账号
    .currentUser {
      margin-left: 35px;
    }
  }
</style>