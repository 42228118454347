  

import modulesFiles from './template';
const wkCustom = {
  install: function (Vue, options) {
    for(let name in modulesFiles){
      Vue.component(name,modulesFiles[name])
    }
  }
};

export default wkCustom;