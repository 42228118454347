<template>
  <el-dialog
    title="测试方法"
    :visible="isShowlModal"
    width="1000px"
    @close="closeModal()"
    :close-on-click-modal="false"
    center
  >
    <div class="testCode" >
      <p>测试项：{{formData.title}}</p>
      <p>测试编号：{{formData.code}}</p>
      <p>测试标准：{{formData.name}}</p>
    </div>
    <el-form class="form" :model="ruleForm" :rules="ruleData" ref="refForm" label-width="50px">
      <h1>编辑测试方法：</h1>
      <el-form-item label="状态" prop="isShow">
        <el-radio-group :span="16" v-model="ruleForm.isShow">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="2">关闭</el-radio>
        </el-radio-group>
			</el-form-item>
     <el-form-item label="正文" prop="testMethod">
      <div class="tinymce">
        <tinymce  ref="testRef" :uploadBtn="false"   v-model="ruleForm.testMethod" :height="600" />
      </div>
			</el-form-item>
    </el-form>
    <div class="footer">
      <wk-button type="primary" class="exportButton" @click="onSubmit()">保&nbsp;&nbsp;存</wk-button>
    </div>
  </el-dialog>
</template>

<script>
import Tinymce from '@/components/Tinymce';
export default {
  components: {Tinymce},
  props: {
    isShowlModal: Boolean,
    formData:Object,
  },
  data() {
    return {
      //数据
      dialogVisible:false,
      isDisabled:false,
      ruleForm: {
        id: '',  // id
        isShow:2,
        testMethod:"",
      },
      ruleData: {      
        testMethod: [
            {required: true, message: '请输入内容', trigger: ['change', 'blur']},
        ],       
      },
    };
  },
  created(){
    this.ruleForm.id=this.formData.id;
    this.ruleForm.isShow=this.formData.isShow;
    this.ruleForm.testMethod=this.formData.testMethod;
  },
  methods: {

    //提交
    onSubmit() {
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
         this.$apis.goldDeployServer.editTestMethod({
          id:this.ruleForm.id,
          isShow:this.ruleForm.isShow,
          testMethod:this.ruleForm.testMethod,
         }).then(res => {
            if (res['code'] == 0) {
              this.closeModal();
            } else {
              this.$message({
                message: res['msg'],
                type: "error"
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //关闭dialog
    closeModal() {
      this.$emit("closeModal", false);
    }, 
  }
};
</script>

<style scoped lang="scss">
.testCode{
  background: rgb(250, 250, 250);
  padding: 10px;
  margin-bottom: 10px;
  p{
    margin-bottom: 5px;
  }
}
.footer{
  margin-top: 20px;
  text-align: center;
  .exportButton{
    width: 150px;
  }
} 
.form{
  margin-top: 20px;
}
/deep/.el-form-item__label{
  text-align: left!important;
}

</style>

