<template>
  <el-dialog
    :visible="dialogVisible"
    width="680px"
    @close="closeModal"
    center>
    <div class="title" slot="title">{{title}}</div>
    <el-form 
      label-width="150px" 
      ref="form" 
      :model="formData">
      <el-form-item class="margin-b20" label="发布类型" prop="publishState" :rules="[  { required: true, message: '不能为空'},  ]" label-width="auto">
        <el-radio v-model="formData.publishState" label="3">立即发布</el-radio>
        <el-radio v-model="formData.publishState" label="2">定时发布</el-radio>
      </el-form-item>
      <div class="date-box margin-b30" v-if="formData.publishState==2">
        <el-form-item label="发布时间" prop="publishTime" :rules="[  { required: true, message: '不能为空'},  ]" label-width="auto">
          <el-date-picker
            v-model="formData.publishTime"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="text-center margin-b30" v-if="menuData.versionName">发布后文档《{{data.title}}》的{{menuData.versionName}}版本将自动下架</div>
    </el-form>
    <div class="footer">
      <wk-button type="primary" class="exportButton" @click="onSubmit('form')">确定</wk-button>
      <wk-button class="exportButton" @click="closeModal()">取消</wk-button>
    </div>
  </el-dialog>
</template>

<script>

  export default {
    props:['menuData'],
    data() {
			return {
        title:'',
        data:'',
				dialogVisible:false,
        formData:{
          publishState:"3",
          publishTime:"",
        },
			}
    },
    created(){
      
    },
    methods: {
      onSubmit(formName){
        this.$refs[formName].validate((valid) => {
          if(valid){
              this.$apis.documentServer.publishDocument({
                id:this.data.id,//	必选	int	id
                docMenuId:this.$route.query.docMenuId,//	必选	int	导航id
                publishState:this.formData.publishState,//	必选	int	发布状态2定时发布3立即发布
                publishTime:this.formData.publishTime?this.formData.publishTime.getTime():'',//	可选	int	定时发布时间
              }).then(res=>{
                if (res['code'] == 0){
                  this.$message('发布成功');
                  window.location.reload();
                }
              })
          }       
        })
      },
      show(data) {
        this.data=data;
        this.title=`确定发布文章${data.title}的${data.versionName}版本？`;
        this.dialogVisible = true;
      },
      closeModal() {
        this.dialogVisible = false;
        this.$emit('getQueryPageList', {})
        this.resetForm();
      },
      resetForm() {
        this.$refs['form'].resetFields();
      },
    }
  }
</script>

<style lang="scss" scoped>
.title{
  color:#D71F1F;
  font-size: 16px;
}
.footer{
  margin-top: 20px;
  text-align: center;
  .exportButton{
    width: 150px;
    margin: 0 22px;
    letter-spacing: 6px !important;
  }
}
</style>
