import { render, staticRenderFns } from "./personalInfo.vue?vue&type=template&id=31c15f8a&scoped=true"
import script from "./personalInfo.vue?vue&type=script&lang=js"
export * from "./personalInfo.vue?vue&type=script&lang=js"
import style1 from "./personalInfo.vue?vue&type=style&index=1&id=31c15f8a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c15f8a",
  null
  
)

export default component.exports