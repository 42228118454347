const routes = [
	{
		path: '/member',
		name: '',
		redirect:'/member/list'
	},
	{
		path: '/member/list',
		name: '',
		meta:{
			module:'operate_manage',
			menu:'/member/list',
			title:'联盟成员',
			breadcrumb:['member'],
			menuBreadcrumb: 'member',
		},//menu 用于所属右侧菜单栏
		component: () => import('@/views/member/list.vue'),
	},
	{
		path: '/member/info',
		name: '',
		meta:{
			module:'operate_manage',
			menu:'/member/list',
			title:'添加联盟成员',
			breadcrumb:['member','info'],
			menuBreadcrumb: 'info',
		},//menu 用于所属右侧菜单栏,breadcrumb用于面包屑
		component: () => import('@/views/member/info.vue'),
	},
]
  
export default routes;
  