<template>
  <div class="page">
	<wk-search
      class="wrapBox"
      :search-control-data="searchControlData"
      @search="search($event)"
    ></wk-search>
    <div class="wrapBox">
      <div class="flex justify-space-between" style="margin-bottom: 10px">
        <div style="line-height: 40px">共{{total}}条数据</div>
        <el-button v-auth="permission.NOTICE_MANAGE_ADD" class="common-button" type="primary" @click="add()">
          添加公告
        </el-button>
      </div>
      <el-table
        class="tableBox"
        :data="tableData"
        stripe
				v-auth="permission.NOTICE_MANAGE_LIST"
        style="width: 100%">
        <el-table-column
          width="60"
          align="center"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="标题">
        </el-table-column>
        <el-table-column
          width="70"
          prop="picUrl"
          label="缩略图">
          <template slot-scope="scope">
            <el-image
              alt="banner缩略图"
              style="width: 50px; height: 40px;display:block"
              :src="scope.row.picUrl">
              <template slot="error">
                <svg-icon class="error-icon" svg-name="加载失败"></svg-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="publishTime"
          label="生效时间">
          <template slot-scope="scope">
            <span>{{ scope.row.publishTime | parseTime() }}</span>
          </template>
        </el-table-column>
				<el-table-column
          align="center"
          prop="createTime"
          label="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | parseTime() }}</span>
          </template>
        </el-table-column>
        <el-table-column
					align="center"
					prop="state"
					width="80"
					label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.state == '1'?'启用':'关闭'}}</span>
          </template>
        </el-table-column>
				<el-table-column
					width="200"
          label="操作">
					<template slot-scope="scope">
            <el-button v-auth="permission.NOTICE_MANAGE_EDIT" class="color-blue link-button" type="text"
                       @click="edit(scope.row.id)">编辑
            </el-button>
            <el-button v-auth="permission.NOTICE_MANAGE_SWITCH" class="color-blue link-button" type="text"
                       @click="changeStatus(scope.row)">{{scope.row.state == '1'?'关闭':'开启'}}
            </el-button>
            <el-button v-auth="permission.NOTICE_MANAGE_DELETE" class="color-blue link-button" type="text"
                       @click="deleteClick(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index.js';
export default {
	beforeRouteEnter: (to,from,next) => {
		store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: "公告管理", path: to.fullPath} );
		next()
	},
	data(){
		return{
			total: 0,
			searchFormData: {
				page: 1,
				perPage: 10,
			},
			searchControlData: [
				{
					type: 'input',
					label: '标题',
					placeholder: '请输入标题',
					key: 'title'
				},
				{
					type: 'rangDate',
					label: '生效时间',
					key: 'rangDate'
				},
				{
					type: 'select',
					label: '状态',
					placeholder: '请选择状态',
					key: 'state',
					option: [
						{
							label: '全部',
							value: ''
						},
						{
							label: '开启',
							value: '1'
						},
						{
							label: '关闭',
							value: '2'
						},
					]
				}
			],
			tableData: []
		}
	},
	created() {
    this.search()
  },
	methods:{
		 search(data){
			if (data) {
				Object.assign(this.searchFormData, data)
				this.searchFormData.startTime = data.rangDate ? data.rangDate[0] : ''
				this.searchFormData.endTime = data.rangDate ? data.rangDate[1] : ''
				this.searchFormData.page = 1
      }
			this.$apis.noticeServer.queryPage(this.searchFormData).then(res => {
				if (res['code'] == 0 && res.data.data) {
					this.total = res.data.total
					this.tableData = res.data.data
				} else {
					this.total = 0
					this.tableData = []
				}
			})
		},
		add(){
			this.$router.push({
				path:'/notice/info',
				query:{
					type: 'add'
				}
			})
    },
		edit(id){
			this.$router.push({
				path:'/notice/info',
				query:{
					type: 'edit',
					id:id
				}
			})
		},
		changeStatus(data) {
			// state: banner的当前状态： 1：开启；2：关闭
			if (data.state == '1') {
				this.$confirm('确定关闭该公告?', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					center: true
				}).then(() => {
					this.$apis.noticeServer.updateStateDisable({
						id: data.id
					}).then(res => {
						if (res['code'] == 0) {
							this.search()
							this.$message({
								type: 'success',
								message: '关闭成功!'
							});
						}
					})
				})
			} else {
				this.$confirm('确定启用该公告?', '', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: 'cancelButton',
					confirmButtonClass: 'confirmButton',
					type: 'info ',
					center: true
				}).then(() => {
					this.$apis.noticeServer.updateStateEnable({
						id: data.id
					}).then(res => {
						if (res['code'] == 0) {
							this.search()
							this.$message({
								type: 'success',
								message: '启用成功!'
							});
						}
					})
				})
			}
    },
		deleteClick(id) {
			this.$confirm('确定删除该公告吗', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				cancelButtonClass: 'cancelButton',
				confirmButtonClass: 'confirmButton',
				type: 'info ',
				center: true
			}).then(() => {
				this.$apis.noticeServer.delete({
					id: id
				}).then(res => {
					if (res['code'] == 0) {
						this.search()
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					}
				})
			})
    },
		handleSizeChange(val) {
			this.searchFormData.perPage = val
			this.search()
		},
		handleCurrentChange(val) {
			this.searchFormData.page = val
			this.search()
		}	
	}
}
</script>

<style>

</style>