<template>
  <el-dialog
    class="customMessageBox"
    :visible="isShow"
    :width="width"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="cancel()"
    center>
      <div slot="title" class="title">
        {{title}}
      </div>
    <el-scrollbar class="contentBox">
      <component   :is="contentSlot" :contentData="contentData"></component>
    </el-scrollbar>
    <div class="operation">
      <div class="btn confirm" @click="confirm()">确定</div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name:'custom',
    props: {
        width: {
          type: String,
          default: "500px"
        },
        title: {
          type: String,
          default: ""
        },
        contentSlot: {
          type: Object,
        },
        contentData: {
          type: Object,
          default:() => {
            return {}
          }
        },
    },
    data() {
      return {
        isShow:false,
        resolve: '',
        reject: '',
        promise: '', // 保存promise对象
      }
    },
    methods: {
      // 确定,将promise断定为resolve状态
        confirm: function () {
          this.isShow = false;
          this.resolve(this.contentData);
          this.remove();
        },
        // 取消,将promise断定为reject状态
        cancel: function () {
          this.isShow = false;
          this.reject('cancel');
          this.remove();
        },
        // 弹出messageBox,并创建promise对象
        show: function () {
          this.isShow = true;
          this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
          });
          return this.promise;
        },
        remove: function () {
          setTimeout(() => {
            this.destroy();
          }, 300);
        },
        destroy: function () {
          this.$destroy();
        }
    }
  }
</script>

<style scoped lang="scss">
.customMessageBox{
  /deep/ .el-dialog__header{
    padding: 40px 50px 0 50px;
  }
  /deep/ .el-dialog__body{
    padding: 25px 50px 0 50px ;
  }
  /deep/ .el-scrollbar__wrap{
    overflow: auto;
  }
  .title {
    padding-bottom: 20px ;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-bottom:1px solid #ccc;
  }
  .contentBox{
    position: relative;
    max-height:500px;
    box-sizing: border-box;
  }
  .operation{
    display: flex;
    justify-content: space-evenly;
    padding-top: 38px ;
    padding-bottom: 40px ;
    .btn {
      width: 198px;
      height: 38px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background: #fff;
      text-align: center;
      line-height: 38px;
      font-size: 16px;
      color: #444;
      cursor: pointer;
    }
    .confirm {
      background: #27252B;
      color: #fff;
    }
    .cancel{
      &:hover {
        border-color: #ccc;
        background: #27252B;
        color: #fff;
      }
    }
  }
}
</style>