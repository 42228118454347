<template>
	<div class="documentBox">
		<h1 class="documentTitle">{{menuData.title}}</h1> 
    <div v-if="menuData" class="documentInfoBox">
      <div class="documentInfo" >
        <div class="documentText">
          <div class="blurb">
            <h5>简介</h5>
            <div  v-if="menuData.state==1">
              <p>当前发布版本：{{menuData.versionName}}</p>
              <p>发布时间：{{menuData.publishTime | parseTime()}}</p>
            </div>
            <div v-else>
              <p>未发布文档</p>
            </div>
          </div>
          <div class="authority">
            <h5>权限说明</h5>
            <p>文档审核发布人：
              <span v-for="(item,index) in menuData.reviewAdminList" :key="index">
                <span v-if="index">、</span>{{item.adminName}}
              </span>
            </p>
            <p>
              文档编辑人：
              <span v-for="(item,index) in menuData.editAdminList" :key="index">
                <span v-if="index">、</span>{{item.adminName}}
              </span>
            </p>
          </div>
        </div>
        <div class="buttonBox" v-if="isEditAuthor && menuData.isSupportUpdate==1">  
          <el-button type="primary" @click="onUpdateDocument(menuData)" >
            更新文档
          </el-button>
        </div>
      </div>
      <div v-if="tableData.length" class="dataListBox">
        <div class="height400">
          <el-table 
          :data="tableData"
          class="tableBox"
          header-row-class-name="tableBox-bg"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }">
            <el-table-column  label="序号" type="index" >
            </el-table-column>
            <el-table-column prop="versionName" label="版本" >
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.reviewState==1">草稿</span>
                <span v-if="scope.row.reviewState==2">待审核</span>
                <span v-if="scope.row.reviewState==3 && scope.row.publishState==1">审核通过未发布</span>
                <span v-if="scope.row.reviewState==3 && scope.row.publishState==2">审核通过未到发布时间</span>
                <span v-if="scope.row.reviewState==3 && scope.row.publishState==3">已发布</span>
                <span v-if="scope.row.reviewState==3 && scope.row.publishState==4">已下架</span>
                <span v-if="scope.row.reviewState==4">审核未通过</span> 
              </template>
            </el-table-column>
            <el-table-column prop="editAdminName" label="最后编辑人" width="100">
            </el-table-column>
            <el-table-column prop="editTime"  width="180" label="最后编辑时间">
              <template slot-scope="scope">
                <span>{{ scope.row.editTime | parseTime() }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="reviewAdminName" label="审核人">
            </el-table-column>
            <el-table-column prop="reviewTime" width="180" label="审核时间">
              <template slot-scope="scope">
                <span>{{ scope.row.reviewTime | parseTime() }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="publishTime" width="180" label="发布时间">
              <template slot-scope="scope">
                <span>{{ scope.row.publishTime | parseTime() }}</span>
              </template> 
            </el-table-column>
            <el-table-column  label="记录">
              <template slot-scope="scope">
                <el-button  class="color-blue link-button" type="text">
                  <router-link tag="span" :to="{path:'/document/record',query:{id:scope.row.id}}" >记录</router-link>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column width="150" prop="address" label="操作">
              <template slot-scope="scope">
                <el-button  class="color-blue link-button" type="text" >
                  <router-link tag="a" :to="{path:'/document/detail',query:{id:scope.row.id,docMenuId:$route.query.docMenuId}}" target="_blank">查看</router-link>
                </el-button>
                
                <!-- <span v-if="scope.row.reviewState==1">草稿</span>
                <span v-if="scope.row.reviewState==2">待审核</span>
                <span v-if="scope.row.reviewState==3 && scope.row.publishState==1">审核通过未发布</span>
                <span v-if="scope.row.reviewState==3 && scope.row.publishState==2">审核通过未到发布时间</span>
                <span v-if="scope.row.reviewState==3 && scope.row.publishState==3">已发布</span>
                <span v-if="scope.row.reviewState==3 && scope.row.publishState==4">已下架</span>
                <span v-if="scope.row.reviewState==4">审核未通过</span>  -->

                <template v-if="isProcesstAuthor">
                  <el-button v-if="scope.row.reviewState==2 && isProcesstAuthor"  class="color-blue link-button" type="text" >
                    <router-link tag="span" :to="{path:'/document/audit',query:{id:scope.row.id,docMenuId:$route.query.docMenuId}}" >审核</router-link>
                  </el-button>
                  <el-button v-if="scope.row.reviewState==3 && scope.row.publishState==3"  class="color-blue link-button" type="text" @click="offShelfDocument(scope.row)">下架</el-button>
                  <el-button v-if="(scope.row.reviewState==3 && scope.row.publishState==4)||(scope.row.reviewState==3 && scope.row.publishState==1)"  class="color-blue link-button" type="text" @click="onPublisDocument(scope.row)">发布</el-button>
                  <el-button v-if="scope.row.reviewState==3 && scope.row.publishState==2"  class="color-blue link-button" type="text" @click="onRecallDocument(scope.row)">撤回</el-button>
                </template>
                
                <template v-if="isEditAuthor">
                  <el-button v-if="scope.row.reviewState==4"  class="color-blue link-button" type="text" >
                    <router-link tag="span" :to="{path:'/document/edit',query:{id:scope.row.id,docMenuId:$route.query.docMenuId}}" >重新编辑</router-link>
                  </el-button>
                  <el-button v-if="scope.row.reviewState==1"  class="color-blue link-button" type="text" >
                    <router-link tag="span" :to="{path:'/document/edit',query:{id:scope.row.id,docMenuId:$route.query.docMenuId}}" >编辑</router-link>
                  </el-button>
                  <el-button v-if="scope.row.reviewState==2 "  class="color-blue link-button" type="text" @click="onRecallDocument(scope.row)">撤回</el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="text-right margin-t30">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="searchFormData.perPage"
            :current-page="searchFormData.page"
            layout="total, prev, pager, next, sizes"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <div v-else class="list-no text-center" >
        <div>
          <div v-if="isEditAuthor">
            <div class="list-msg">当前标题没有文档，请先创建文档</div>
            <router-link tag="span" :to="{path:'/document/add',query:{docMenuId:$route.query.docMenuId}}" >
              <el-button type="primary" >
                创建文档
              </el-button>
            </router-link>
          </div>
          <div v-else>
            <div class="list-msg">当前标题没有权限</div>
          </div>
        </div>
      </div>
    </div>
    <publis-document ref="modalRef" :menuData="menuData" @getQueryPageList="getQueryPageList()"></publis-document>
	</div>
</template>

<script>
import publisDocument from '../dialogs/publisDocument';
export default {
  components: {publisDocument},
  computed: {
    userInfo() {
      return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
    },
  },
  watch: {
    '$route.query.docMenuId' () {
      this.getQueryPageList();
      this.getQueryDetail();
    },
    '$route.query.date' () {
      this.getQueryPageList();
      this.getQueryDetail();
    },
  },
	data(){
		return{
      searchFormData: {
        page: 1,
        perPage: 10,
      },
			tableData:[],//文档列表
      menuData:'',//菜单数据
      isEditAuthor:false,
      isProcesstAuthor:false,
		}
	},
	created(){
    this.getQueryPageList();
    this.getQueryDetail();
  },
	methods:{
    onUpdateDocument(item) {
      if(this.$route.query.docMenuId){
        this.$router.push({
          path:'/document/update',
          query:{
            docMenuId: this.$route.query.docMenuId,
            id: item.docId,
          }
        })
      }else{
        this.$message.error('请先选择菜单');
      }
    },
    onRecallDocument(item) {
        let typeText = item.reviewState==2?'草稿状态':'审核通过未发布状态';
        this.$dialogs.confirm({
          title:`<span style="color:#D71F1F;"> 确定撤回文档《${item.title}》的${item.versionName}版本？</span>`,
            content: `
            撤回后文档《${item.title}》的${item.versionName}版本将变为${typeText}`,
        }).then(() => {
          this.$apis.documentServer.recallDocument({
            docMenuId:this.$route.query.docMenuId,
            id:item.id,
          }).then(res=>{
            if (res['code'] == 0){
              this.$message.success('撤回成功')
              this.getQueryPageList();
            } else {
              this.$message.error(res.message);
            }
          })
        }).catch((val) => {});
    },
    offShelfDocument(item) {
        this.$dialogs.confirm({
          title:`<span style="color:#D71F1F;">确定下架《${item.title}》？</span>`,
          content: `下架后节点《${this.menuData.title}》将变为未发布状态`,
        }).then(() => {
          this.$apis.documentServer.offShelfDocument({
            docMenuId:this.$route.query.docMenuId,
            id:item.id,
          }).then(res=>{
            if (res['code'] == 0){
              this.$message.success('下架成功');
              this.getQueryPageList();
            }
          })
        }).catch((val) => {});
    },
    onPublisDocument(item) {
      this.$refs.modalRef.show(item)
    },
		//获取文档中心权限详情
    getQueryDetail(){
      this.$apis.documentServer.queryDetail({id:this.$route.query.docMenuId}).then(res=>{
        if (res['code'] == 0){
          this.menuData = res.data;
          this.judgeAuthority();
        }
      })
    },

    //获取文档信息列表
    getQueryPageList(data=''){
      if (data) {
        this.searchFormData.page = 1
      }
      this.$apis.documentServer.queryPage({
        ...this.searchFormData,
        docMenuId:this.$route.query.docMenuId,
      }).then(res=>{
        if (res['code'] == 0 ){
          if(typeof res.data.data !='undefined' ){
            this.total = res.data.total
            this.tableData = res.data.data;
          }else{
            this.total = 1;
            this.tableData = [];
          }
        }else{
          this.total = 1;
          this.tableData = [];
          this.$message.error(res.message);
        }
      })
    },
    handleSizeChange(val) {
      this.searchFormData.perPage = val;
      this.getQueryPageList();
    },
    handleCurrentChange(val) {
      this.searchFormData.page = val;
      this.getQueryPageList();
    },
    //当前账号是否有编辑
    judgeAuthority(){
      var userId=this.userInfo.id;
      //判断用户是否有编辑权限
      for(var i=0; i<this.menuData.editAdminList.length; i++){
        if(userId.indexOf(this.menuData.editAdminList[i].adminId)!=-1){
          this.isEditAuthor = true;
          break;
        }else{
          this.isEditAuthor = false;
        }
      }
      //判断用户是否有审核权限
      for(var i=0; i<this.menuData.reviewAdminList.length; i++){
        if(userId.indexOf(this.menuData.reviewAdminList[i].adminId)!=-1){
          this.isProcesstAuthor = true;
          break;
        }else{
          this.isProcesstAuthor = false;
        }
      }
    },
	}
}
</script>

<style scoped lang="scss">
.documentBox{
	width: 80%;
	margin-left: 30px;
	border: 1px solid #ccc;
	border-radius: 5px;
  overflow: hidden;
	.documentTitle{
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    background-color:#28262C  ;
    color: #E7DCAE;
		font-size:16px;
	}
  .documentInfoBox{
    
  }
  .documentInfo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 22px;
    padding-bottom: 0;
    .documentText{
      display: flex;
      height: 80px;
      .blurb{
        margin-right: 30px;
      }
      h5{
        margin-bottom: 5px;
      }
      p{
        height: 20px;
        line-height: 20px;
        color: #27252B;
        font-size: 12px;
      }
    }
  }
  /deep/ .tableBox-bg  th{
    height: 48px;
    background: #F0F0F0 ;
    color: #28262C;
  }
	.dataListBox{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
		padding: 20px;
    .height400{
      min-height: 400px;
    }
	}
  .list-no{
    border-top: 1px solid #ccc;
    padding: 200px 0;
    .list-msg{
      margin-bottom: 50px ;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>