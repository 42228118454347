<template>
  <div class="page">
    <div class="content-box">
       <div class="content-left">
          <div class="content-title">
            文档版本
          </div>
          <el-form label-position="top" :model="formData" ref="formRef"  class="form-box">
            <div class="dateTime" @click="documentAutoServer.editMsg()">
              编辑提示：
              <span>{{one}}<span>:</span></span>
              <span>{{two}}<span>:</span></span>
              <span>{{three}}</span>
            </div>
            <el-form-item class="margin-t10" label="文档标题" prop="title"  :rules="[  { required: true, message: '不能为空'},{min: 1, max: 40, message: '标题最多支持40个字', trigger: ['change', 'blur']},  ]">
              <el-input
                v-model="formData.title" 
                placeholder="请输入">
              </el-input>
            </el-form-item>
            <el-form-item class="margin-t10" label="文档类型" prop="type"  :rules="[  { required: true, message: '不能为空'},  ]">
              <el-radio-group v-model="formData.type">
                <el-radio-button label="1">中文</el-radio-button>
                <el-radio-button label="2">英文</el-radio-button>
                <el-radio-button label="3">中英文</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-show="formData.type=='1' || formData.type=='3'"  class="tinymce-box" label="文档内容-中文" prop="content"  :rules="[  { required: formData.type=='1' || formData.type=='3'?true:false, message: '不能为空'},  ]">
              <tinymce ref="editRef1" :uploadBtn="true" v-model="formData.content" width="100%" :height="600" />
            </el-form-item>
            <el-form-item v-show="formData.type=='2' || formData.type=='3'"  class="tinymce-box" label="文档内容-英文" prop="englishContent"  :rules="[  { required: formData.type=='2' || formData.type=='3'?true:false, message: '不能为空'},  ]">
              <tinymce ref="editRef2" :uploadBtn="true" v-model="formData.englishContent " width="100%" :height="600" />
            </el-form-item>
            <el-form-item label="文档修订备注"  prop="remark" :rules="[  { required: true, message: '不能为空'},  ]">
              <div>
                <el-input
                  :rows="6"
                  show-word-limit
                  type="textarea"
                  v-model="formData.remark" 
                  placeholder="请输入内容">
                </el-input>
              </div>
            </el-form-item>
          </el-form>
       </div>
    </div>
    <div class="foot">
      <el-button class="w150" type="primary" @click="onAudit()">提交审核</el-button>
      <el-button class="w150" type="primary" @click="onSubmit()">保存</el-button>
      <el-button class="w150" @click="onCancel()">取消</el-button>
    </div>
  </div>
</template>

<script>
import store from '@/store/index.js';
import Tinymce from '@/components/Tinymce';
import documentAutoServer from '../documentAutoServer.js';
export default {
  async beforeRouteEnter (to,from,next) {
    let res = await window.$apis.documentServer.heartBeatDocument({
      docMenuId:to.query.docMenuId,
      type:'1',
    });
    if(res.code != 0){
      window.$msgbox.error(res.message)
    }else{
		  store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: `${res.data.docMenuTitle} / 新增文档`, path: ''} );
      next()
    }
  },
  components: {Tinymce},
  data() {
    return {
      documentAutoServer:null,
      formData:{
        title:'',
        type:'1',
        content:'',
        englishContent :'',
        remark:'',
      },
      flag: null,
      one : '00', // 时
      two : '00', // 分
      three : '00', // 秒
      abc : 0, // 秒的计数
      cde : 0, // 分的计数
      efg : 0, // 时的计数
    }
  },
  watch:{
    one (newVal,oldVal) {
      this.documentAutoServer.autoSave(newVal.charAt(1),(timeOver)=>{
        this.$refs['formRef'].validate(valid => {
          if (valid) {
            timeOver && this.onAdd(1);
          }else{
            if(timeOver){
              this.$msgbox({
                  closeOnClickModal:false,
                  title: '保存提示',
                  message: '内容缺失，自动保存失败',
              }).then(() => {
              }).catch((val) => {});
              this.$router.replace({
                path: "/document/list",
                query:{
                  docMenuId:this.$route.query.docMenuId,
                  date:new Date().getTime()
                }
              });
            }
          }
        })
      });
    },
  },
  created(){
    this.documentAutoServer = new documentAutoServer()
    this.documentAutoServer.editMsg();
  },
  mounted(){
    this.startHandler();
  },
  methods:{
    startHandler(){
      this.flag = setInterval(()=>{
        if(this.three === 60 || this.three === '60'){
          this.three = '00';
          this.abc = 0;
          this.heartBeatDocument();//每1分钟请求一次服务器，锁定文档
          if(this.two === 60 || this.two === '60'){
            this.two = '00';
            this.cde = 0;
            if(this.efg+1 <= 9){
              this.efg++;
              this.one = '0' + this.efg;
            }else{
              this.efg++;
              this.one = this.efg;
            }
          }else{
            if(this.cde+1 <= 9){
              this.cde++;
              this.two = '0' + this.cde;
            }else{
              this.cde++;
              this.two = this.cde;
            }
          }
        }else{
          if(this.abc+1 <= 9){
            this.abc++;
            this.three = '0' + this.abc;
          }else{
            this.abc++;
            this.three=this.abc;
          }
        }
      },1000)
    },
    heartBeatDocument(){
      this.$apis.documentServer.heartBeatDocument({
        docMenuId:this.$route.query.docMenuId,//	必选	int	分类id
        type:'1',
      });
    },
    unlockDocument(){
      this.$apis.documentServer.unlockDocument({
        docMenuId:this.$route.query.docMenuId,//	必选	int	分类id
        type:'1',
      });
    },
    onCancel() {
      this.$msgbox({
          title: '确定取消吗？',
          message: '离开页面后已编辑的内容将会丢弃',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true
      }).then(() => {
          this.$router.back()
      }).catch((val) => {});
    },
    onSubmit() {
      this.$refs['formRef'].validate(valid => {
        if (valid) {
          this.onAdd(1);
        }
      })
    },
    onAudit() {
      this.$refs['formRef'].validate(valid => {
          if (valid) {
            this.onAdd(2);
          }
      })
    },
    onAdd(reviewState,callback=''){
      this.$apis.documentServer.addDocument({
        tag:callback?2:1,//	操作类型 1点击保存 2自动保存
        docMenuId:this.$route.query.docMenuId,//	必选	int	分类id
        title:this.formData.title,//	必选	string	标题
        type:this.formData.type,//	必选	string	类型
        content:this.formData.content,//	必选	string	内容
        englishContent:this.formData.englishContent,//	必选	string	内容
        reviewState:reviewState,//	必选	int	提交状态1保存2提交审核
        remark:this.formData.remark,//	选选	string	备注
      })
      .then((res) => {
        if (res.code == 0) {
          this.$message(reviewState==1?'保存成功':'提交审核成功');
          this.$router.replace({
            path: "/document/list",
            query:{
              docMenuId:this.$route.query.docMenuId,
              date:new Date().getTime()
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.flag);
    this.unlockDocument();
  },
}
</script>

<style scoped lang="scss">
  .page{
    margin-top: 20px;
    padding: 26px 23px;
    border-radius: 5px;
    background-color: #fff;
    .content-box{
      display: flex;
      .content-title{
        display: flex;
        justify-content: space-between;
        padding:0 17px ;
        line-height: 43px;
        background: #28262C;
        font-size: 16px;
        font-weight: bold;
        color: #E7DCAE;
      }
      .content-left{
        flex: 1;
        border: 1px solid #DFDFDF;
        border-radius: 5px;
        overflow: hidden;
        .form-box{
          position: relative;
          padding: 0 16px ;
          .dateTime{
            position: absolute;
            top: 10px;
            right: 20px;
            cursor: pointer;
          }
          /deep/ .el-form-item__label{
            font-weight: bold;
            color: #28262C;
          }
          .tinymce-box{
            position: relative;
          }
        }
      }
      .content-right{
        margin-left: 36px;
        width: 605px;
        border: 1px solid #DFDFDF;
        border-radius: 5px;
        overflow: hidden;
      }
    }
    .foot {
      margin-top: 20px;
      text-align: center;
    }
  }
</style>