<template>
  <div class="page">
    <el-form label-position="left" :model="ruleForm" :rules="ruleData" ref="formRef" label-width="100px" class="demo-ruleForm">
			<el-form-item label="文档标题" prop="title">
				<el-input show-word-limit :maxlength="20" class="w400" v-model.trim="ruleForm.title" placeholder="请输入名称"></el-input>
			</el-form-item>
			<el-form-item label="发布时间" prop="publishTime">
				<el-date-picker
					class="w400"
					unlink-panels
					value-format="timestamp"
					v-model="ruleForm.publishTime"
					placeholder="请选择发布时间"
					type="datetime">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="状态" prop="state">
				<el-col :span="11">
					<el-radio-group :span="16" v-model="ruleForm.state">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="2">关闭</el-radio>
					</el-radio-group>
				</el-col>
			</el-form-item>
			<el-form-item label="排序" prop="sort">
				<el-input
					class="w340"
					placeholder="数字越大越靠前"
					v-model.trim="ruleForm.sort">
				 </el-input>
			</el-form-item>
			<el-form-item prop="picDefaultUrl" label="默认图标" :error="uploadErrorInfo">
				<wk-upload
					class="w400"
					@uploadChange="handlePicDefaultUrlChange"
					:imgUrl="ruleForm.picDefaultUrl"
				></wk-upload>
			</el-form-item>
			<el-form-item prop="picActiveUrl" label="选中图标" :error="uploadErrorInfo">
				<wk-upload
					class="w400"
					@uploadChange="handlePicActiveUrlChange"
					:imgUrl="ruleForm.picActiveUrl"
				></wk-upload>
			</el-form-item>
			<el-form-item  label="文档链接" prop="docMenuId">
				<el-select  v-model="ruleForm.docMenuId" placeholder="请选择" >
					<el-option
            v-for="(item,index) in menuData"
            :key="index"
            :label="item.title"
            :value="item.id*1">
					</el-option>
        </el-select>
			</el-form-item>
			<div class="foot">
				<el-button class="w150" type="primary" @click="onSubmit()">提交</el-button>
				<el-button class="w150" type="info" @click="cancel()">取消</el-button>
			</div>
		</el-form>
  </div>
</template>

<script>
import store from '@/store/index.js';
export default {
	beforeRouteEnter: (to,from,next) => {
		store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:to.query.id? "编辑推荐":'添加推荐', path: to.fullPath} );
		next()
  },
  data(){
		var validateSort = (rule, value, callback) => {
		if ( value < 0) {
				callback("请填写大于或等于0的正整数");
			} else if ( !(/^[0-9]+.?[0-9]*/.test(value))) {
				callback("请填写数字");
			} else {
				callback();
			}
		};
		return{
			uploadErrorInfo: '', // 上传的错误
			type: '',//判断是添加还是编辑
			ruleForm: {
				id:"",
				title:"",
				publishTime:"",
				state:"",
				sort:"",
				picDefaultUrl:"",
				picActiveUrl:"",
				docMenuId:"",
			},
			ruleData: {
				title: [
					{required: true, message: '请填写标题', trigger: 'blur'},
					{min: 1, max: 20, message: '标题最多支持20个字', trigger: ['change', 'blur']},
				],
				publishTime: [
					{required: true, message: '请选择发布时间', trigger: ['change', 'blur']},
				],
				state: [
					{required: true, message: '请选择状态', trigger: 'blur'}
				],
				sort: [
					{required: true,validator: validateSort, trigger: ['change', 'blur']},
				],
				docMenuId: [
					{required: true, message: '请选择文档链接', trigger: 'blur'}
				],
				picDefaultUrl: [
					{required: true, message: '请上传默认图', trigger: ['change', 'blur']},
				],
				picActiveUrl: [
					{required: true, message: '请上传激活图', trigger: ['change', 'blur']},
				],
      },
			selectTree: [],
			menuData: [],//导航列表
      defaultProps: {
        children: 'children',
        label: 'title',
      },
		}
	},
	created(){
		this.getQueryTreeList();
		this.type = this.$route.query.type;
	},
	methods:{
		//获取文档导航列表
    getQueryTreeList(){
      this.$apis.recommendTextServer.queryPublishTreeList().then(res=>{
         if (res['code'] == 0){
        	this.menuData = res.data;
					if (this.type == 'edit'){
						this.loadData(this.$route.query.id);
					}
         }
      })
    },
		//详情
		loadData(id) {
			this.$apis.recommendTextServer.queryDetail({
					id: id
			}).then(res => {	
				if (res['code'] == 0) {
					Object.assign(this.ruleForm, res.data)
					var docMenuId=this.ruleForm.docMenuId;
					//判断详情文档有没有被下架
					for(var i=0;i<this.menuData.length;i++){
						if(docMenuId==this.menuData[i].id){	
							this.ruleForm.docMenuId=this.menuData[i].id;
							return;
						}else{
							this.ruleForm.docMenuId="";
						}
					}
				}
			})
    },


		//上传默认图
    handlePicDefaultUrlChange(res) {
			this.uploadErrorInfo = ''
			if (res['code'] == 0) {
					this.ruleForm.picDefaultUrl = res.data.link
					this.$refs['formRef'].validateField('picDefaultUrl');//对图片进行移除校验
			} else {
					this.uploadErrorInfo = result.message
			}
		},

		//上传选中图
		handlePicActiveUrlChange(res) {
			this.uploadErrorInfo = ''
			if (res['code'] == 0) {
					this.ruleForm.picActiveUrl = res.data.link
					this.$refs['formRef'].validateField('picActiveUrl');//对图片进行移除校验
			} else {
					this.uploadErrorInfo = result.message
			}
		},
		
		//提交
		onSubmit() {
			this.$refs['formRef'].validate( async valid => {
				if (valid) {
					this.requestInfo()
				}
			})
		},
		 // 提交
    requestInfo() {
			let params = {
				id: this.ruleForm.id,
				title: this.ruleForm.title, // 标题
				publishTime: this.ruleForm.publishTime,  // 发布时间
				state: this.ruleForm.state,  // 状态:1、启用；2、停用
				sort: this.ruleForm.sort,
				type: this.ruleForm.type,
				picDefaultUrl: this.ruleForm.picDefaultUrl, // 默认图
				picActiveUrl: this.ruleForm.picActiveUrl, // 选中图
				docMenuId: this.ruleForm.docMenuId,
			}
			if (this.type == 'add') {
				this.$apis.recommendTextServer.addDoc(params).then(res => {
					if (res['code'] == 0) {
						this.$message.success('添加成功！')
						this.close()
					}
				})
			} else {
				this.$apis.recommendTextServer.updateDoc(params).then(res => {
					if (res['code'] == 0) {
						this.$message.success('修改成功！')
						this.close()
					}
				})
			}
    },
		cancel() {
			const h = this.$createElement;
			this.$msgbox({
				title: '',
				message: h('p', null, [
						h('p', null, '确定取消发布吗？ '),
						h('p', null, '离开页面后已编辑的内容将会丢弃')
				]),

				showCancelButton: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				center: true
			}).then(() => {
					this.$router.back()
			})
		},
		close() {
			this.$refs['formRef'].resetFields();
			this.type="";
			this.$router.push({
				path: '/recommend/list'
			})
    }
	},
}
</script>

<style scoped lang="scss">
	.el-message-box--center .el-message-box__message {
		font-size: 20px;
		color: #000000;
	}

	.page {
		background-color: #fff;
		padding: 45px 100px;
		display: block;
		margin-top: 30px;
		border-radius: 10px;
		min-height: 800px;
	}
	.setstyle {
		min-height: 200px;
		padding: 0 !important;
		margin: 0;
		overflow: auto;
		cursor: default !important;
	}
	.foot {
		text-align: center;
	}

	.select-input {
		display: flex;
		margin-bottom: 30px;
	}

	.icon-img {
		width: 40px;
		height: 40px;
		// padding: 10px;
		margin-right: 10px;
		cursor: pointer;
		margin-left: 20px;
	}

	.w340 {
		width: 340px;
	}

	.w60 {
		width: 60px;
	}
</style>