<template>
 <div class="page">
  <wk-search
    class="wrapBox"
    :search-control-data="searchControlData"
    @search="loadData($event)"
  ></wk-search>
  <div class="wrapBox">
   <div class="flex justify-space-between" style="margin-bottom: 10px">
    <div style="line-height: 40px">共{{total}}条数据</div>
    <el-button v-auth="permission.NEWS_ACTION_VOTEINFOLIST_EXPORT" class="common-button" type="primary" @click="exportClick()">
     导出
    </el-button>
   </div>
   <el-table
     class="tableBox"
     :data="tableData"
     stripe
     style="width: 100%">
    <el-table-column
      width="60"
      align="center"
      type="index"
      label="序号">
    </el-table-column>
    <el-table-column
      align="center"
      prop="accountId"
      label="开发者ID">
    </el-table-column>
    <el-table-column
      width="160"
      prop="email"
      label="注册邮箱">
     <template slot-scope="scope">
      <span>{{ scope.row.email?scope.row.email:'/'}}</span>
     </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="type"
      label="类型">
     <template slot-scope="scope">
      <span v-if="scope.row.type">{{scope.row.type =='1'?'企业':scope.row.type=='2'?'个人':'政府、事业单位' }}</span>
      <span v-else>/</span>
     </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="companyName"
      label="企业名称">
     <template slot-scope="scope">
      <span>{{ scope.row.companyName?scope.row.companyName:'/'}}</span>
     </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="contactName"
      label="联系人">
     <template slot-scope="scope">
      <span>{{ scope.row.contactName?scope.row.contactName:'/'}}</span>
     </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="contactPhone"
      label="联系电话">
     <template slot-scope="scope">
      <span>{{ scope.row.contactPhone?scope.row.contactPhone:'/'}}</span>
     </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="contactEmail"
      label="联系邮箱">
     <template slot-scope="scope">
      <span>{{ scope.row.contactEmail?scope.row.contactEmail:'/'}}</span>
     </template>
    </el-table-column>
    <el-table-column
      align="center"
      prop="createTime"
      label="投票时间">
     <template slot-scope="scope">
      <span>{{ scope.row.createTime | parseTime() }}</span>
     </template>
    </el-table-column>
   </el-table>
   <div class="text-right margin-t30">
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="searchFormData.perPage"
      :current-page="searchFormData.page"
      layout="total, prev, pager, next, sizes"
      :total="total"
    ></el-pagination>
   </div>
  </div>
 </div>
</template>
<script>
    import store from '@/store/index.js';
    export default {
        beforeRouteEnter: (to,from,next) => {
          store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name:'投票明细', path: to.fullPath} );
          next()
        },
        data() {
            return {
                voteId: null,
                total: 0,
                dialogVisible: false,
                loading: false,
                searchFormData: {
                    page: 1,
                    perPage: 10,
                    infoVoteItemId: null,
                },
                searchControlData: [
                    {
                        type: 'input',
                        label: '注册邮箱',
                        placeholder: '请输入注册邮箱',
                        key: 'email'
                    },
                    {
                        type: 'input',
                        label: '企业名称',
                        placeholder: '请输入企业名称',
                        key: 'companyName'
                    },
                    {
                        type: 'input',
                        label: '联系人',
                        placeholder: '请输入联系人',
                        key: 'contactName'
                    },
                ],
                tableData: [],
                exportData: [
                    {
                        type: 'rangDate',
                        label: '绑定时间',
                        key: 'time'
                    },
                ],
            }
        },
        computed: {
            userInfo() {
                return this.$store.getters["accountStore/getUserInfo"]; //获取用户信息
            }
        },

        created() {
            this.voteId = this.$route.query.voteId;
            this.id = this.$route.query.id;
            this.loadData()
        },
        methods: {
            loadData(data) {
                this.loading = true
                if (data) {
                    this.searchFormData = {
                        page: 1,
                        perPage: this.searchFormData.perPage,
                        ...data
                    };
                }
                this.searchFormData.infoVoteItemId = this.id
                this.$apis.newsServer.voteRecordList(this.searchFormData).then(res => {
                    if (res['code'] == 0 && res.data.data) {
                        this.total = res.data.total
                        this.tableData = res.data.data
                    } else {
                        this.total = 0
                        this.tableData = []
                    }
                    this.loading = false
                })
            },
            handleSizeChange(val) {
                this.searchFormData.perPage = val
                this.loadData()
            },
            handleCurrentChange(val) {
                this.searchFormData.page = val
                this.loadData()
            },
            // 导出
            exportClick(data) {
                this.$apis.newsServer.voteExport({
                    infoVoteItemId:this.voteId
                }).then(res => {
                    if (res['code'] == 0 && res.data.link) {
                        let link = res.data.link;
                        window.location.href = link;
                    }
                });
            },
        }
    }
</script>
<style>
 .el-message-box--center .el-message-box__message {
  font-size: 20px;
  color: #000000;
 }

 .cancelButton {
  width: 98px;
  margin-left: 14px;
  margin-top: 14px;
 }

 .confirmButton {
  width: 98px;
  margin-left: 60px !important;
 }
</style>
<style scoped lang="scss">
 .el-message-box--center .el-message-box__message {
  font-size: 20px;
  color: #000000;
 }

 .page {
 }
</style>