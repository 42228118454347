<template>
  <div class="index-box">
    <commen-head></commen-head>
    <div class="content-box">
      <commen-menu></commen-menu>
      <vue-scroll>
        <div class="content">
          <el-breadcrumb class="breadcrumb-box" separator="/">
            <el-breadcrumb-item
              class="bread-text"
              v-for='(key,index) in breadcrumb'
              :key='index'
              @click.native="(index+1 < breadcrumb.length ) && onNavigate(filterMenu(key))"
              style="cursor: pointer">
              {{filterMenu(key).name}}
            </el-breadcrumb-item>
          </el-breadcrumb>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </div>
        <commen-foot></commen-foot>
      </vue-scroll>
    </div>
  </div>
</template>
<script>
import commenHead from "@/components/Head";
import commenMenu from "@/components/Menu";
import commenFoot from '@/components/Foot';
import { mapState } from 'vuex'
export default {
  components: {
    commenHead,
    commenMenu,
    commenFoot,
  },
  computed: {
    breadcrumb() {
      let arr = []
      if(this.$route.meta.menuBreadcrumb){
        arr = this.$route.meta.breadcrumb;//['news','vot','detail']
      }else{
        arr = [this.$route.meta.title];//[title]
      }
      return arr;
    },
  },
  methods: {
    filterMenu (key) {
      if(this.$store.getters["breadcrumbStore/keyGetMenuBreadcrumb"](key)){
        return this.$store.getters["breadcrumbStore/keyGetMenuBreadcrumb"](key);
      }else{
        return {name:key}
      }
    },
    onNavigate(item) {
      window.location.href=item.path;
    },
  }
};
</script>

<style scoped lang="scss">
  /deep/ .el-breadcrumb__inner {
    display: inline-block;
    font-weight: 700;
    color: black;
    max-width: 120px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
.index-box {
  height: 100%; //减底部高度
  // height: calc(100% - 54px); //减底部高度

  .content-box {
    height: calc(100% - 80px); //减头部高度
    display: flex;
    .content {
      flex: 1;
      padding: 30px 20px;
      .breadcrumb-box{
        display: flex;
        align-items: center;
        height: 24px;
        /deep/ .el-breadcrumb__inner{
          max-width: 200px;
        }
      }
    }

  }
}

</style>

