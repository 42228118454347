import { render, staticRenderFns } from "./vot.vue?vue&type=template&id=0c1c0302&scoped=true"
import script from "./vot.vue?vue&type=script&lang=js"
export * from "./vot.vue?vue&type=script&lang=js"
import style0 from "./vot.vue?vue&type=style&index=0&id=0c1c0302&prod&lang=css"
import style1 from "./vot.vue?vue&type=style&index=1&id=0c1c0302&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c1c0302",
  null
  
)

export default component.exports