let apiBase = {
  queryList:'/api/tool/queryIdentifySync',                  // 同步列表     
  queryAgain:'/api/tool/syncAgain',                         // 接口重试       
  queryApkNewVersion:'/api/tool/queryApkNewVersion',                // 状态查询     
  
}
let SyncServerObject = null
class SyncServer{
  constructor(){}
  queryList(data={}){
    return $http({
      url: apiBase.queryList,
      method: 'post',
      data: data,
    })
  }
  queryAgain(data={}){
    return $http({
      url: apiBase.queryAgain,
      method: 'post',
      data: data,
    })
  }
  queryApkNewVersion(data={}){
    return $http({
      url: apiBase.queryApkNewVersion,
      method: 'post',
      data: data,
    })
  }
}
function singleSyncServer(){
  if(SyncServerObject === null){
    SyncServerObject = new SyncServer()
  }
  return SyncServerObject
}
export default singleSyncServer()