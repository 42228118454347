import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import developerManage from './developerManage'//开发者管理
import goldManage from './goldManage'
import operationManage from './operationManage'
import systemManage from './systemManage'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: '',
    component: () => import('@/views/Index.vue'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/views/login/Login.vue')
      },
    ],
  },
  {
    path: '',
    name: '',
    meta:{module:'developerManage',menu:'/developer/list',title:'开发者管理',toPath:'/developer/list'},//menu 用于所属右侧菜单栏
    component: () => import('@/views/AdminIndex.vue'),
    children:[
      ...developerManage,
      ...goldManage,
      ...operationManage,
      ...systemManage,
    ]
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/components/Error.vue')
  },
  {
    path: '*',
    name: '*',
    component: () => import('@/components/Error.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
router.beforeEach((to, from, next) => {
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
  const Token = localStorage.getItem('Token'); // 登录token
  if(to.name != 'login' && to.name != 'error'  && to.name != 'modifyPassword' ){//非登录页 非错误页 非修改密码页
    if (!Token) {//有token
      localStorage.clear();
      // 先执行清除本地缓存，再跳转登录页
      setTimeout(function() {
        next({name: 'login'})//跳转登录页
      }, 10);
    }
  }
  next();
});
export default router
