import axios from 'axios'
import router from '../../router'
import { Message } from 'element-ui'
let configBaseURL = process.env.VUE_APP_API //全局环境变量
// let configBaseURL = 'http://192.168.2.186:8080' //全局环境变量
//使用create方法创建axios实例
const Service = axios.create({
  timeout: 5000, // 请求超时时间
  baseURL: encodeURI(configBaseURL),
})
Service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 添加请求拦截器
Service.interceptors.request.use(config => {
  let baseConfig = {
    token: localStorage.getItem('Token') ? localStorage.getItem('Token') :  ''
  };

  if(config.url == '/api/task/addTask'){
    
  }else{
    config.data = {
      ...config.data,
      ...baseConfig,
    }
  }
  return config
},error =>{
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
  switch(response.data.code){
    case  11000:
      localStorage.clear()
      // alert('登陆已过期');
      router.replace({                        
        path: '/login',                        
        query: { 
            redirect: router.currentRoute.fullPath 
        }
      });
      break;
    case  11012:
      localStorage.clear()
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
      break;
  }
  if(response.data['code']==0){
    return response.data;
  }else{
    Message.error(response.data.message);
    return response.data;
  }
}, error => {
  if(error && error.response){
    switch (error.response.status) {
      case 403:
        Message.error('禁止访问');
        break;
      case 404:
        Message.error('接口不存在');
        break;
      case 405:
        Message.error('不允许的HTTP请求方法');
        break;
      case 500:
        Message.error('服务器错误');
        break;
      default:
        Message.error('服务端错误');
        break;
    }
  }
  else {
    Message.error('请求超时');
  }
  return error
})
export default Service