var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"编辑金标有效期","visible":_vm.dialogVisible,"width":"650px","close-on-click-modal":false,"center":""},on:{"close":function($event){_vm.dialogVisible = false}}},[_c('el-form',{ref:"refForm",attrs:{"model":_vm.formData,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"名称"}},[_c('template',{slot:"label"},[_c('el-image',{staticClass:"assetsImg",attrs:{"src":_vm.formData.apkIcon}},[_c('template',{slot:"error"},[_c('svg-icon',{staticClass:"error-icon",attrs:{"svg-name":"加载失败"}})],1)],2)],1),_c('p',[_vm._v(_vm._s(_vm.formData.apkName))]),_c('p',[(_vm.formData.state == 1)?_c('span',[_vm._v("应用信息未通过审核")]):_vm._e(),(_vm.formData.state == 2)?_c('span',[_vm._v("金标认证有效")]):_vm._e(),(_vm.formData.state == 3)?_c('span',[_vm._v("金标认证失败")]):_vm._e(),(_vm.formData.state == 4)?_c('span',[_vm._v("金标已过期")]):_vm._e()])],2),_c('el-form-item',{attrs:{"label":"金标有效期","prop":"expireDay","rules":[
          { required: true, message: '不能为空'},
          { type: 'number', message: '必须为数字值'},
          { validator: this.numberRule, trigger: 'blur'},  
        ]}},[_c('el-input',{staticClass:"expireDay-input",attrs:{"type":"age","autocomplete":"off"},model:{value:(_vm.formData.expireDay),callback:function ($$v) {_vm.$set(_vm.formData, "expireDay", _vm._n($$v))},expression:"formData.expireDay"}})],1),_c('el-form-item',{attrs:{"label":"人工干预说明","prop":"remark","rules":[
          { required: true, message: '不能为空'}, 
        ]}},[_c('el-input',{staticClass:"w400",attrs:{"rows":6,"maxlength":250,"show-word-limit":"","type":"textarea","placeholder":"请输入内容"},model:{value:(_vm.formData.remark),callback:function ($$v) {_vm.$set(_vm.formData, "remark", $$v)},expression:"formData.remark"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"medium","type":"primary","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("确定")]),_c('el-button',{attrs:{"size":"medium","type":"info"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取消")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }