<template>
  <div class="devBox">
    <div class="chartBox">
      <div class="titleBox">
        <h1 class="title">开发者数据图表</h1>
      </div>
      <div class="chart">
        <div class="screenBox">
          <el-radio-group v-model="searchFormData.type" style="margin-right:40px" @change="agreeChange()"> 
            <el-radio-button label="1">日</el-radio-button>
            <el-radio-button label="2">周</el-radio-button>
            <el-radio-button label="3">月</el-radio-button>
          </el-radio-group>
          <el-date-picker
          v-show="searchFormData.type !='3'"
            v-model="searchFormData.rangDate"
            value-format="timestamp"
            @change="changeRangDate()"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            :default-time="['00:00:00', '23:59:59']"
            >
          </el-date-picker>
          <el-date-picker
          v-show="searchFormData.type =='3'"
            v-model="searchFormData.rangDate"
            value-format="timestamp"
            @change="changeRangDate('3')"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            :default-time="['00:00:00', '23:59:59']"
            >
          </el-date-picker>
          <el-radio-group v-model="category"  @change="setLineChart()" style="float: right;">  
            <el-radio-button label="1">新增</el-radio-button>
            <el-radio-button label="2">累计</el-radio-button>
          </el-radio-group>
        </div>
        <div id="lineChartBox">

        </div>         
      </div>
    </div>
    
    <div class="devDataBox">
      <div class="titleBox">
        <h1 class="title">开发者数据明细</h1>
        <el-button v-auth="permission.STAT_ACCOUNT_EXPORT" class="exportButton" type="primary" @click="exportClick()">导出数据</el-button>
      </div>
      <el-table
        class="tableBox"
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }">
        <el-table-column
          width="120" 
          prop="statTime"         
          label="日期">
        </el-table-column>
        <el-table-column      
          label="开发者新增数据">
           <el-table-column           
              prop="newNum"
              label="新增注册开发者">
            </el-table-column>
            <el-table-column        
              prop="auditPassNum"
              label="新增开发者">
            </el-table-column>
            <el-table-column  
              prop="effectiveNum"
              label="新增有效开发者">
            </el-table-column>
            <el-table-column
              prop="publishNum"
              label="新增认证开发者">
            </el-table-column>
        </el-table-column>
        <el-table-column
          label="开发者累计数据">
           <el-table-column             
              prop="totalNum"
              label="累计注册开发者">
            </el-table-column>
            <el-table-column
              prop="totalAuditPassNum"
              label="累计开发者">
            </el-table-column>
            <el-table-column
              prop="totalEffectiveNum"
              label="累计有效开发者">
            </el-table-column>
            <el-table-column              
              prop="totalPublishNum"
              label="累计认证开发者">
            </el-table-column>
        </el-table-column>
      </el-table>
      <div class="text-right margin-t30">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFormData.perPage"
          :current-page="searchFormData.page"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  data(){
    // 1677666852
    return{
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() >= new Date(new Date().toLocaleDateString()).getTime()
          
        },
      },
      // timePicker:"daterange",
      total: 0,
      tableData: [],
      category:"1",//判断是新增还是累计  1新增 2累计
      searchFormData:{
        type:"1",
        rangDate:[new Date(new Date().getFullYear(),new Date().getMonth(),-30).getTime(),new Date().getTime()- 8.64e7],
        startTime:"",
        endTime:"",
        page: 1,
        perPage: 10,
      },
      myLineChart:"",
      lineOption:{
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '',
            type: 'line',
            data: []
          },
          {
            name: '',
            type: 'line',
            data: []
          },
          {
            name: '',
            type: 'line',
            data: []
          },
          {
            name: '',
            type: 'line',
            data: []
          },
        ],
      }
    }
  },
  mounted(){
    this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: ''
    this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: ''
    this.setLineChart();
    this.queryDevList();
  },
  methods:{
    // 获取日期
		// getNowTime(temp) {
		// 	var now = new Date(temp);
		// 	var year = now.getFullYear(); 	//得到年份
		// 	var month = now.getMonth() + 1; //得到月份
		// 	var date = now.getDate(); 		//得到日期
		// 	month = month.toString().padStart(2, "0");
		// 	date = date.toString().padStart(2, "0");
		// 	return `${year}-${month}-${date}`;
		// },

    //改变日周月时触发  
    agreeChange(){
      this.searchFormData.page=1;
      if(this.searchFormData.type==1){
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-30).getTime(),new Date().getTime()- 8.64e7]
      } else if(this.searchFormData.type==2){
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-90).getTime(),new Date().getTime()- 8.64e7]
      }else{
        this.searchFormData.rangDate=[new Date(new Date().getFullYear(),new Date().getMonth(),-350).getTime(),new Date().getTime()- 8.64e7]
      }
      this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: ''
      this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: ''
      this.setLineChart();
      this.queryDevList();
    },


    //选择时间时触发
    changeRangDate(type){
      this.searchFormData.page=1;
      this.searchFormData.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0]: '';
      if(type == '3'){
          var date = new Date(this.searchFormData.rangDate[1]);
          // 下月1号-1秒 
          this.searchFormData.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime()-1000;
      }else{
        this.searchFormData.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1]: '';
      }
      this.setLineChart();
      this.queryDevList();   
    },


    //获取折线图数据
    setLineChart() {
      var lineChart=document.getElementById("lineChartBox");
      lineChart.style.width=window.innerWidth-300+'px';
      this.myLineChart = echarts.init(lineChart);

      if(this.category==1){
        //新增开发者
        this.$apis.dataCensusServer.queryNewForLine(this.searchFormData).then(res=>{
          if (res['code'] == 0 && res.data.series) {
            this.lineOption.legend.data=res.data.legend;
            this.lineOption.xAxis.data=res.data.xAxis;        
            for(let key in res.data.series){
              this.lineOption.series[key]["name"]=res.data.series[key]["name"];
              this.lineOption.series[key]["data"]=res.data.series[key]["data"];
            }
            this.myLineChart.setOption(this.lineOption,true);
            window.addEventListener('resize', () => {
              lineChart.style.width=window.innerWidth-300+'px';
              this.myLineChart.resize();
            })
          }else{
             for (var i = 0; i < this.lineOption.series.length; i++) {  //置空关键！！！  清空名字和数据
              this.lineOption.series[i].data = [];
              // this.lineOption.series[i].name = '';              
            }
            this.lineOption.xAxis.data=[]; 
            this.myLineChart = echarts.init(lineChart)  //再次初始化
            this.myLineChart.setOption(this.lineOption, true)  //重新setOption
          }
        })
      }else{
        //累计开发者
        this.$apis.dataCensusServer.queryTotalForLine(this.searchFormData).then(res=>{
          if (res['code'] == 0 &&  res.data.series) {
            this.lineOption.legend.data=res.data.legend;
            this.lineOption.xAxis.data=res.data.xAxis;        
            for(let key in res.data.series){
              this.lineOption.series[key]["name"]=res.data.series[key]["name"];
              this.lineOption.series[key]["data"]=res.data.series[key]["data"];
            }
            this.myLineChart.setOption(this.lineOption);
            window.addEventListener('resize', () => {
              lineChart.style.width=window.innerWidth-300+'px';
              this.myLineChart.resize();
            })
          } else {

          }
        })
      }        
    },

    //获取列表数据
    queryDevList(){
      this.$apis.dataCensusServer.queryDevList(this.searchFormData).then(res=>{
        if (res['code'] == 0 && res.data.data) {
          this.total = res.data.total
          this.tableData = res.data.data
        } else {
          this.total = 0
          this.tableData = []
        }
      })
    },


    exportClick(){
      var data={
        startTime:"",
        endTime:"",
        type:'',
      }
      data.type = this.searchFormData.type;
      data.startTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[0] : '';
      if(data.type == '3'){
        var date = new Date(this.searchFormData.rangDate[1]);
        data.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime()-1000;
      }else{
        data.endTime = this.searchFormData.rangDate ? this.searchFormData.rangDate[1] : '';
      }
      this.$apis.dataCensusServer.exportDev(data).then(res => {
        if (res['code'] == 0 && res['data']['link']) {
          let link = res.data.link;
          window.location.href = link;
        }
      });
    },

    handleSizeChange(val) {
      this.searchFormData.perPage = val
      this.queryDevList()
    },
    handleCurrentChange(val) {
      this.searchFormData.page = val
      this.queryDevList()
    }
  },
}
</script>

<style scoped lang="scss">
.devBox{
  .titleBox{
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #E4E7ED;
  }
  .chartBox{
    .chart{
      margin-top: 20px;
      #lineChartBox{
        margin-top:30px;
        width: 100%;
        height: 500px;
      }
    }
  }
  .devDataBox{
    margin-top: 70px;
    /deep/ .el-tabs__content{
      overflow: initial;
    }
    /deep/ .el-table__header th {
      padding: 0;
      height: 32px!important;
    }
  }
  .tableBox{
    margin-top: 20px;
  }
}
</style>
<style>
.el-table thead.is-group th {
  background: #28262C;
  border:none;
}
</style>
